import { createSlice } from "@reduxjs/toolkit";

///////////////////////////
// SET COMPARABLE PROPERTY FUNCTION
// const addRemoveComparableProperties = (
//   state,
//   { searchID, propertyID, newState }
// ) => {
//   const searchToSet = state[searchID];

//   // If there are already comparables, check if it's an add/remove and update the set accordingly.
//   // If there are no comparables, create a new set
//   const comps = JSON.parse(searchToSet.comparableProperties);
//   const newCompSet = comps
//     ? newState
//       ? comps.concat([propertyID])
//       : comps.filter((propID) => propID !== propertyID)
//     : newState && [propertyID];

//   return {
//     ...state,
//     [`${searchID}`]: {
//       ...searchToSet,
//       comparableProperties: JSON.stringify(newCompSet),
//     },
//   };
// };

const valuationSlice = createSlice({
  name: "valuation",
  initialState: {},
  reducers: {
    setValuationData(state, action) {
      const {
        id,
        valuationPropertyId,
        valuationPrice,
        valuationName,
        valuationDate,
        soldSearchId,
        forsaleSearchId,
        rentalSearchId,
        planningSearchId,
        newBuildSearchId,
        ...rest
      } = action.payload;
      const valuation = state[id] || {};
      return {
        ...state,
        [id]: {
          ...valuation,
          valuationId: id,
          valuationPropertyId,
          valuationPrice,
          valuationName,
          valuationDate,
          soldSearchId,
          forsaleSearchId,
          rentalSearchId,
          planningSearchId,
          newBuildSearchId,
          ...rest,
        },
      };
    },
    setValuationPriceRedux(state, action) {
      const { id, valuationPrice, valuationPriceSource } = action.payload;
      const valuation = state[id] || {};
      return {
        ...state,
        [id]: {
          ...valuation,
          valuationPrice,
          valuationPriceSource,
        },
      };
    },
    setValuationSearchesRedux(state, action) {
      const {
        id,
        soldSearchId,
        forsaleSearchId,
        rentalSearchId,
        planningSearchId,
        newBuildSearchId,
      } = action.payload;
      const valuation = state[id] || {};
      return {
        ...state,
        [id]: {
          ...valuation,
          ...(soldSearchId && { soldSearchId }),
          ...(forsaleSearchId && { forsaleSearchId }),
          ...(rentalSearchId && { rentalSearchId }),
          ...(planningSearchId && { planningSearchId }),
          ...(newBuildSearchId && { newBuildSearchId }),
        },
      };
    },
  },
});

export const {
  setValuationData,
  setValuationPriceRedux,
  setValuationSearchesRedux,
} = valuationSlice.actions;

export default valuationSlice.reducer;
