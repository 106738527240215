import { useCallback } from "react";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { setSearchFilter as setSearchFilterAction } from "../../app/settingsSlice";

const getSearchFilter = createSelector(
  ({ settings }) => settings,
  (settings) => settings.searchFilter
);

/**
 * Convenience hook for accessing searchFilter from Redux
 */
const useSearchFilter = () => {
  const dispatch = useDispatch();

  const searchFilter = useSelector(getSearchFilter);

  const setReduxSearchFilter = useCallback(
    (newFilter) => {
      dispatch(setSearchFilterAction(newFilter));
    },
    [dispatch]
  );

  return [searchFilter, setReduxSearchFilter];
};

export default useSearchFilter;
