import SearchFilterFormItem from "./SearchFilterFormItem";

const TagFilter = (props) => {
  const {
    properties,
    tags,
    includeTags,
    excludeTags,
    setSearchFilter,
    sortOrder,
  } = props;

  let allTags = [];
  properties.map((prop) => {
    const tags = [].concat(prop?.rmHistoryTags, prop?.zHistoryTags, prop?.tags);
    const uniqueTags = Array.from(new Set(tags));
    uniqueTags.map((tag) => tag && allTags.push(tag));
  });

  const uniqueTags = Array.from(new Set(allTags));

  const occurrences = allTags.reduce(function (acc, curr) {
    return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
  }, {});

  //   console.log(occurrences);
  //   const sortedOccurrences = occurrences?.sort((occ) => console.log(occ));
  let sortedOccurrences = {};

  if (sortOrder === "name") {
    sortedOccurrences = Object.entries(occurrences)
      .sort(([a], [b]) => a.localeCompare(b))
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  } else {
    sortedOccurrences = Object.entries(occurrences)
      .sort(([, a], [, b]) => b - a)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  }

  const sortedUniqueTags = Object.keys(sortedOccurrences);

  const toggleTag = (tagName, type) => {
    if (type === "include") {
      if (includeTags.includes(tagName)) {
        // Remove the tag from includeTags
        setSearchFilter({
          includeTags: includeTags.filter((tag) => tag !== tagName),
          excludeTags,
        });
      } else {
        // Add the tag to includeTags and remove it from excludeTags if it exists
        setSearchFilter({
          includeTags: [...includeTags, tagName],
          excludeTags: excludeTags.filter((tag) => tag !== tagName),
        });
      }
    } else if (type === "exclude") {
      if (excludeTags.includes(tagName)) {
        // Remove the tag from excludeTags
        setSearchFilter({
          includeTags,
          excludeTags: excludeTags.filter((tag) => tag !== tagName),
        });
      } else {
        // Add the tag to excludeTags and remove it from includeTags if it exists
        setSearchFilter({
          includeTags: includeTags.filter((tag) => tag !== tagName),
          excludeTags: [...excludeTags, tagName],
        });
      }
    }
  };

  const isTagIncluded = (tagName) => includeTags.includes(tagName);
  const isTagExcluded = (tagName) => excludeTags.includes(tagName);

  return (
    <div className="tag-holder">
      {sortedUniqueTags?.map((tag) => (
        <SearchFilterFormItem
          key={tag}
          label={tag}
          checked={tags.includes(tag)}
          isIncluded={isTagIncluded(tag)}
          isExcluded={isTagExcluded(tag)}
          includeTag={() => toggleTag(tag, "include")}
          excludeTag={() => toggleTag(tag, "exclude")}
          count={sortedOccurrences[tag]}
        />
      ))}
    </div>
  );
};

export default TagFilter;
