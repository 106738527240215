import React from "react";
import { Bar } from "react-chartjs-2";
import { getSalesChartOptions } from "./chartjs-options";

const getSalesDataSecondary = (data) => {
  return JSON.parse(JSON.stringify(data)).map((item) => {
    return {
      x: new Date(item[0]).getTime(),
      y: item[1].nonNewSales,
    };
  });
};

const getSalesDataNew = (data) => {
  return JSON.parse(JSON.stringify(data)).map((item) => {
    return {
      x: new Date(item[0]).getTime(),
      y: item[1].newSales,
    };
  });
};

const TotalSalesGraph = ({ salesData }) => {
  const locationSalesData = JSON.parse(JSON.stringify(salesData))[
    "dataPoints"
  ].reverse();

  const salesChartOptions = getSalesChartOptions();

  const salesDataSecondary = getSalesDataSecondary(locationSalesData);
  const salesDataNew = getSalesDataNew(locationSalesData);

  salesData = {
    datasets: [
      {
        label: "New Builds",
        backgroundColor: "rgba(237,125,49,1)",
        borderColor: "rgba(237,125,49,0)",
        data: salesDataNew,
        fill: true,
        pointRadius: 0,
      },
      {
        label: "Second Hand",
        backgroundColor: "rgba(68,114,196,1)",
        borderColor: "rgba(68,114,196,0)",
        data: salesDataSecondary,
        fill: true,
        pointRadius: 0,
      },
    ],
  };

  return (
    <Bar
      data={salesData}
      height={250}
      width={500}
      options={salesChartOptions}
    />
  );
};

export default TotalSalesGraph;
