import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getSearchSelector } from "../selectors";

const getPropertiesFromCache = (cache, propertyIds) =>
  propertyIds.reduce((acc, propertyID) => {
    const hit = cache[propertyID];
    if (hit) {
      acc.push(hit);
    }
    return acc;
  }, []);

/**
 * Get the properties from the cache
 */
const getProperties = createSelector(
  ({ properties }) => properties,
  (_, propertyIds) => propertyIds,
  getPropertiesFromCache
);

/**
 * Convencience hook for getting access to property results
 */
const useSearchResults = (searchId) => {
  const { propertyIds = [] } = useSelector((state) =>
    getSearchSelector(state, searchId)
  );
  // cache hits
  const properties = useSelector((state) => getProperties(state, propertyIds));

  return [properties, propertyIds];
};

export default useSearchResults;
