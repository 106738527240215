import { useState } from "react";
import SearchFilterFormItem from "./SearchFilterFormItem";
import useSearchFilter from "../useSearchFilter";
import useDeviceWidth from "data/hooks/useDeviceWidth";

import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import TagFilter from "./TagFilter";

import "./searchFilter.css";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import useSearchFilterDrawer from "pages/Search/Data/useSearchFilterDrawer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const styles = {
  viewButtons: { minWidth: "20px", minHeight: "34px" },
  editSearchButton: {
    minHeight: "34px",
    backgroundColor: "white",
    minWidth: "40px",
    marginRight: "6px",
  },
  sortButton: {
    padding: "0 !important",
    backgroundColor: "white",
    minHeight: "34px",
  },
};

const SortButton = ({ sortOrder, setSortOrder }) => (
  <div
    className="search-results-header-controls-sort"
    style={{ userSelect: "none" }}
  >
    <Select
      name="sort"
      variant="outlined"
      value={sortOrder || "cheapest"}
      onChange={(e) => setSortOrder(e.target.value)}
      renderValue={() => (
        <>
          Sort&nbsp;&nbsp;
          <FontAwesomeIcon icon={regular("arrow-down-a-z")} />
        </>
      )}
      IconComponent={() => null}
      style={styles.sortButton}
    >
      <MenuItem value="" disabled dense>
        Show First...
      </MenuItem>
      <MenuItem key={"name"} value={"name"} dense>
        <div>
          <FontAwesomeIcon icon={regular("arrow-down-a-z")} />
          &nbsp;&nbsp;&nbsp;{sortOrder === "name" ? <u>Name</u> : <>Name</>}
        </div>
      </MenuItem>
      <MenuItem key={"total"} value={"total"} dense>
        <div>
          <FontAwesomeIcon icon={regular("arrow-up-9-1")} />
          &nbsp;&nbsp;&nbsp;{sortOrder === "total" ? <u>Total</u> : <>Total</>}
        </div>
      </MenuItem>
    </Select>
  </div>
);

const KeywordFilterTextbox = ({ filterKeyword, setFilterKeyword }) => {
  const [isSmallWidth] = useDeviceWidth();

  return (
    <div style={{ display: "flex", textAlign: "center" }}>
      <TextField
        multiline
        value={filterKeyword}
        onChange={(e) => setFilterKeyword(e.target.value)}
        placeholder="Keywords..."
        style={{
          fontSize: isSmallWidth ? "13px" : "15px",
          minWidth: "130px",
        }}
        variant="outlined"
        size="small"
      />
      {filterKeyword !== "" && (
        <IconButton
          type="submit"
          aria-label="search"
          onClick={() => setFilterKeyword("")}
          style={{ maxHeight: "32px", maxWidth: "32px", color: "green" }}
        >
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};

const SearchFilter = ({ properties }) => {
  const [searchFilter, setSearchFilter] = useSearchFilter();
  const [searchFilterDrawerOpen, setSearchFilterDrawerOpen] =
    useSearchFilterDrawer();
  const [sortOrder, setSortOrder] = useState("name");

  const totalProperties = properties.length;
  const totalLR = properties.filter(
    (prop) => prop.propertySource === "landreg"
  ).length;
  const totalRightmove = properties.filter(
    (prop) => prop.propertySource === "rightmove"
  ).length;
  const totalZoopla = properties.filter(
    (prop) => prop.propertySource === "zoopla"
  ).length;

  const totalUnknownBed = properties.filter(
    (prop) =>
      !prop.beds &&
      prop.beds !== 0 &&
      !prop.rmBeds &&
      prop.rmBeds !== 0 &&
      !prop.zBeds &&
      prop.zBeds !== 0
  ).length;
  const total0Bed = properties.filter(
    (prop) => prop.beds === 0 || prop.rmBeds === 0 || prop.zBeds === 0
  ).length;
  const total1Bed = properties.filter(
    (prop) => prop.beds === 1 || prop.rmBeds === 1 || prop.zBeds === 1
  ).length;
  const total2Bed = properties.filter(
    (prop) => prop.beds === 2 || prop.rmBeds === 2 || prop.zBeds === 2
  ).length;
  const total3Bed = properties.filter(
    (prop) => prop.beds === 3 || prop.rmBeds === 3 || prop.zBeds === 3
  ).length;
  const total4PlusBed = properties.filter(
    (prop) => prop.beds >= 4 || prop.rmBeds >= 4 || prop.zBeds >= 4
  ).length;

  const totalSold = properties.filter(
    (prop) => prop.listingStatus === "sold" && prop.propertySource === "landreg"
  ).length;
  const totalForSale = properties.filter(
    (prop) => prop.listingStatus === "for_sale"
  ).length;
  const totalUnderOffer = properties.filter(
    (prop) =>
      (prop.listingStatus === "sold" ||
        prop.listingStatus === "sale_under_offer") &&
      (prop.propertySource === "zoopla" || prop.propertySource === "rightmove")
  ).length;

  return (
    <div className="search-tags-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "4px",
        }}
      >
        <div>
          <h3 style={{ margin: "6px" }}>{totalProperties} Total Properties</h3>
          <div style={{ margin: "4px 6px" }}>
            {searchFilter?.includeTags.length > 0 ||
            searchFilter?.excludeTags.length > 0 ? (
              <>
                {searchFilter?.includeTags.length > 0 && (
                  <>
                    {`${searchFilter?.includeTags.length} included tag${
                      searchFilter?.includeTags.length > 1 ? "s" : ""
                    }`}
                    &nbsp;&nbsp;
                    <a
                      href="#"
                      onClick={() => setSearchFilter({ includeTags: [] })}
                    >
                      Clear Filter
                    </a>
                  </>
                )}
                {searchFilter?.excludeTags.length > 0 && (
                  <>
                    {searchFilter?.includeTags.length > 0 && (
                      <>&nbsp;&nbsp;|&nbsp;&nbsp;</>
                    )}
                    {`${searchFilter?.excludeTags.length} excluded tag${
                      searchFilter?.excludeTags.length > 1 ? "s" : ""
                    }`}
                    &nbsp;&nbsp;
                    <a
                      href="#"
                      onClick={() => setSearchFilter({ excludeTags: [] })}
                    >
                      Clear Filter
                    </a>
                  </>
                )}
              </>
            ) : (
              <>No tags selected</>
            )}
          </div>
        </div>
        <div
          style={{
            paddingTop: "5px",
            display: "flex",
          }}
        >
          <SortButton sortOrder={sortOrder} setSortOrder={setSortOrder} />
          &nbsp;&nbsp;
          <div>
            <Button
              size="small"
              variant="outlined"
              color="default"
              onClick={() => {
                window.analytics.track("Hide Search Filters", {
                  clickSource: "SearchResultsHeader",
                });
                setSearchFilterDrawerOpen(!searchFilterDrawerOpen);
              }}
              style={styles.editSearchButton}
              className="highlight-filter-button"
            >
              Apply Filter&nbsp;
              <FontAwesomeIcon
                icon={regular("tags")}
                style={{ fontSize: "1.1em" }}
              />
            </Button>
          </div>
        </div>
      </div>
      <br />
      <TagFilter
        properties={properties}
        tags={searchFilter.tags}
        includeTags={searchFilter.includeTags}
        excludeTags={searchFilter.excludeTags}
        setSearchFilter={setSearchFilter}
        sortOrder={sortOrder}
      />
      {/* <KeywordFilterTextbox
        filterKeyword={searchFilter.keyword}
        setFilterKeyword={(value) => setSearchFilter({ keyword: value })}
      />
      <br />
      <SearchFilterFormItem
        checked={searchFilter.includeLandreg}
        setChecked={(value) => setSearchFilter({ includeLandreg: value })}
        label={"LandReg"}
        count={totalLR}
      />
      <SearchFilterFormItem
        checked={searchFilter.includeRightmove}
        setChecked={(value) => setSearchFilter({ includeRightmove: value })}
        label={"Rightmove"}
        count={totalRightmove}
      />
      <SearchFilterFormItem
        checked={searchFilter.includeZoopla}
        setChecked={(value) => setSearchFilter({ includeZoopla: value })}
        label={"Zoopla"}
        count={totalZoopla}
      />
      <br />
      <SearchFilterFormItem
        checked={searchFilter.includeUnknownBed}
        setChecked={(value) => setSearchFilter({ includeUnknownBed: value })}
        label={"Unknown Beds"}
        count={totalUnknownBed}
      />
      <SearchFilterFormItem
        checked={searchFilter.include0Bed}
        setChecked={(value) => setSearchFilter({ include0Bed: value })}
        label={"0 Bed (Studio/Land)"}
        count={total0Bed}
      />
      <SearchFilterFormItem
        checked={searchFilter.include1Bed}
        setChecked={(value) => setSearchFilter({ include1Bed: value })}
        label={"1 Bed"}
        count={total1Bed}
      />
      <SearchFilterFormItem
        checked={searchFilter.include2Bed}
        setChecked={(value) => setSearchFilter({ include2Bed: value })}
        label={"2 Bed"}
        count={total2Bed}
      />
      <SearchFilterFormItem
        checked={searchFilter.include3Bed}
        setChecked={(value) => setSearchFilter({ include3Bed: value })}
        label={"3 Bed"}
        count={total3Bed}
      />
      <SearchFilterFormItem
        checked={searchFilter.include4PlusBed}
        setChecked={(value) => setSearchFilter({ include4PlusBed: value })}
        label={"4+ Bed"}
        count={total4PlusBed}
      />
      <br />
      <SearchFilterFormItem
        checked={searchFilter.includeSold}
        setChecked={(value) => setSearchFilter({ includeSold: value })}
        label={"Sold"}
        count={totalSold}
      />
      <SearchFilterFormItem
        checked={searchFilter.includeForSale}
        setChecked={(value) => setSearchFilter({ includeForSale: value })}
        label={"For Sale"}
        count={totalForSale}
      />
      <SearchFilterFormItem
        checked={searchFilter.includeUnderOffer}
        setChecked={(value) => setSearchFilter({ includeUnderOffer: value })}
        label={"Under Offer"}
        count={totalUnderOffer}
      /> */}
    </div>
  );
};

export default SearchFilter;
