import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import zooplaZLogo from "assets/zooplaZLogo.png";
import HMLRLogo from "assets/HMLRLogo.png";
import rmHouseLogo from "assets/rmHouseLogo.png";
import useDeviceWidth from "data/hooks/useDeviceWidth";
import {
  measurePropertiesSqftAverage,
  measurePropertiesPriceAverage,
  measurePropertiesPpsfAverage,
} from "services/propertyHelpers";
import { convertSpecToReadable } from "services/search";
import { formatMoneyFull } from "services/formatting";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: "10px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
  },
}))(Tooltip);

const SearchStatistics = ({
  totalLandregResults,
  totalRmResults,
  totalZooplaResults,
}) => {
  const [isSmallWidth] = useDeviceWidth();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: isSmallWidth ? "column" : "row",
        padding: "4px 0",
      }}
    >
      <div
        className="flex-center-center"
        style={{ fontSize: "1.05em", flexDirection: "column" }}
      >
        {totalLandregResults > 0 && (
          <div className="flex-center-center">
            {totalLandregResults}&nbsp;
            <img
              style={{
                objectFit: "cover",
                objectPosition: "100% 0",
                width: "24px",
              }}
              src={HMLRLogo}
              alt="Land Registry"
              height="23px"
            />
            &nbsp;Land Registry
          </div>
        )}

        {totalRmResults > 0 && (
          <div className="flex-center-center">
            {totalRmResults}&nbsp;
            <img src={rmHouseLogo} alt="Rightmove" height="26px" />
            &nbsp;Rightmove
          </div>
        )}
        {totalZooplaResults > 0 && (
          <div className="flex-center-center">
            {totalZooplaResults}&nbsp;
            <img src={zooplaZLogo} alt="Zoopla" height="20px" />
            &nbsp;Zoopla
          </div>
        )}
        {/* {properties.filter((prop) => prop.starred).length + " Starred"}
      {"  &  "}
      {properties.filter((prop) => prop.hidden).length + " Hidden"} */}
      </div>
    </div>
  );
};

const SearchAverageStatistics = ({ properties }) => {
  const withSqft = properties.filter((prop) => prop.sqft > 0);
  const ppsfAverage = measurePropertiesPpsfAverage("sold", withSqft);
  const avgPrice = measurePropertiesPriceAverage("sold", properties);

  return (
    <>
      {!isNaN(avgPrice) && (
        <div
          className="flex-center-center"
          style={{ fontSize: "1.05em", paddingTop: "2px" }}
        >
          Average Price £{formatMoneyFull(avgPrice, 0)}
        </div>
      )}
    </>
  );
};

const SearchSqftStatistics = ({ properties }) => {
  const withSqft = properties.filter((prop) => prop.sqft > 0);
  const avgSqft = measurePropertiesSqftAverage("sold", withSqft);
  return (
    !isNaN(avgSqft) && (
      <div
        className="flex-center-center"
        style={{ fontSize: "1.05em", paddingTop: "2px" }}
      >
        Average Size {avgSqft}sqft
      </div>
    )
  );
};

const SearchResultsStats = ({ totalResults, properties, search, children }) => {
  const [open, setOpen] = useState(false);
  const [isSmallWidth] = useDeviceWidth();

  const totalLandregResults = properties.filter(
    (prop) => prop.propertySource === "landreg"
  ).length;
  const totalRmResults = properties.filter(
    (prop) => prop.propertySource === "rightmove"
  ).length;
  const totalZooplaResults = properties.filter(
    (prop) => prop.propertySource === "zoopla"
  ).length;

  const searchLimitHit =
    totalLandregResults >= 300 ||
    totalRmResults >= 300 ||
    totalZooplaResults >= 300;

  const handleClick = () => {
    setOpen(true);
  };

  const searchDescription = search.spec && convertSpecToReadable(search);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <HtmlTooltip
        placement="bottom"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        open={open}
        title={
          <>
            <Typography color="inherit" style={{ textAlign: "center" }}>
              <u>{totalResults} Total Search Results</u>
            </Typography>
            {searchLimitHit && (
              <div
                style={{
                  color: "red",
                  textAlign: "center",
                  paddingBottom: "4px",
                }}
              >
                Warning: Reached limit of 300 <br />
                {totalLandregResults >= 300
                  ? "Land Registry "
                  : totalRmResults >= 300
                  ? totalZooplaResults >= 300
                    ? "Rightmove and Zoopla "
                    : "Rightmove "
                  : "Zoopla "}
                Properties
                <br />
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                marginBottom: "8px",
                fontStyle: "italic",
              }}
            >
              {searchDescription}
            </div>

            <SearchStatistics
              totalLandregResults={totalLandregResults}
              totalRmResults={totalRmResults}
              totalZooplaResults={totalZooplaResults}
            />
            <SearchAverageStatistics properties={properties} />
            <SearchSqftStatistics properties={properties} />
          </>
        }
      >
        <div
          style={{ display: "flex", fontSize: "1em", alignItems: "center" }}
          onClick={handleClick}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          {children}
        </div>
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

export default SearchResultsStats;
