import Button from "@material-ui/core/Button";
import PropertyThumbnail from "components/PropertyThumbnail";

const MiniMapPopup = ({ property }) => {
  return (
    <div>
      <Button
        variant="text"
        size="small"
        color="primary"
        style={{ minWidth: "14px", fontSize: "18px" }}
      >
        <PropertyThumbnail property={property} smallImage={true} />
      </Button>
    </div>
  );
};

export default MiniMapPopup;
