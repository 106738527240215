import { useState, useEffect, useCallback } from "react";
import Debug from "debug";
import useInterval from "@use-it/interval";
import { getSearchResults } from "../../graphql/functions";
import { useSelector, useDispatch } from "react-redux";
import {
  setPropertyIds,
  setSearchDetails,
  setSearchCompleteStatuses,
} from "../slices/searchSlice";
import { getSearchSelector } from "../selectors";

import { resetSearchFilter as resetSearchFilterAction } from "app/settingsSlice";
import useSearchFilterDrawer from "pages/Search/Data/useSearchFilterDrawer";

const debug = Debug("lw:useSearch");

const SEARCH_DELAY = 1000;
const MAX_SEARCH_COUNT = 30;

const increment = (x) => x + 1;

const setSearchResultsRedux = (
  searchId,
  result,
  storeSearchSpec,
  dispatch,
  setFinished
) => {
  const {
    zooplaSearchComplete,
    landregSearchComplete,
    rmSearchComplete,
    planningSearchComplete,
    newBuildSearchesComplete,
    propertyIDList,
    searchSpec,
    searchName,
    searchLocationName,
    comparableProperties,
    dateRun,
    searchSortOrder,
    showTodayPrice,
    parentValuationId,
  } = result;
  const properties = JSON.parse(propertyIDList) || [];
  dispatch(
    setPropertyIds({
      id: searchId,
      propertyIds: properties,
    })
  );
  dispatch(
    setSearchCompleteStatuses({
      id: searchId,
      zooplaSearchComplete,
      landregSearchComplete,
      rmSearchComplete,
      planningSearchComplete,
      newBuildSearchesComplete,
    })
  );
  const jsonSpec = JSON.parse(JSON.parse(searchSpec));
  if (storeSearchSpec) {
    dispatch(
      setSearchDetails({
        id: searchId,
        name: searchName,
        locationName: searchLocationName,
        spec: {
          ...jsonSpec,
          searchradius: jsonSpec.searchradius * 1609,
        },
        comparableProperties: comparableProperties,
        dateRun,
        zooplaSearchComplete,
        landregSearchComplete,
        rmSearchComplete,
        planningSearchComplete,
        newBuildSearchesComplete,
        searchSortOrder,
        showTodayPrice,
        parentValuationId,
      })
    );
  }
  if (
    !!zooplaSearchComplete &&
    !!landregSearchComplete &&
    !!rmSearchComplete &&
    !!(jsonSpec?.search_plan ? !!planningSearchComplete : true) &&
    !!(jsonSpec?.search_type === "newbuild"
      ? newBuildSearchesComplete >= 2
      : true)
  ) {
    debug(`All searches completed for searchId ${searchId}`);
    setFinished(true);
  }
};

/**
 * Custom hook to get search results. returns [error, isFinished]
 *
 * @param {*} searchId
 */
function useSearch(searchId) {
  const [searchCount, setSearchCount] = useState(0); // how many times has the search been run?
  const [isFinished, setFinished] = useState(false); // is the search done for any number of reasons
  const [isError, setError] = useState(false); // is the search done for any number of reasons
  const [delay, setDelay] = useState(null); // presence of a delay turns the interval on, null turns it off
  const dispatch = useDispatch();
  const [, setSearchFilterDrawerOpen] = useSearchFilterDrawer();

  const runSearch = useCallback(
    async (storeSearchSpec) => {
      if (searchId) {
        debug("Querying backend for Search Results: searchId = " + searchId);
        const result = await getSearchResults(searchId);
        if (result) {
          setSearchResultsRedux(
            searchId,
            result,
            storeSearchSpec,
            dispatch,
            setFinished
          );
          dispatch(resetSearchFilterAction());
          setSearchFilterDrawerOpen(false);
        } else {
          setError(true);
          setFinished(true);
        }
        // keep track of search count
        setSearchCount(increment);
      }
    },
    [searchId, dispatch]
  );

  const cleanup = () => {
    debug("Cleaning up old search");
    setDelay(null);
    setSearchCount(0);
    setError(false);
    setFinished(false);
  };

  useEffect(() => {
    debug("searchId has changed");
    return cleanup;
  }, [searchId]);

  const {
    propertyIds,
    spec,
    zooplaSearchComplete,
    landregSearchComplete,
    rmSearchComplete,
    planningSearchComplete,
    newBuildSearchesComplete,
  } = useSelector((state) => getSearchSelector(state, searchId));
  const hasCachedSearch =
    !!spec &&
    !!propertyIds &&
    propertyIds.length > 0 &&
    !!zooplaSearchComplete &&
    !!landregSearchComplete &&
    !!rmSearchComplete &&
    !!(spec.search_plan ? !!planningSearchComplete : true) &&
    !!(spec.search_type === "newbuild" ? newBuildSearchesComplete === 3 : true);
  // kick off a search if we need to
  useEffect(() => {
    if (!hasCachedSearch) {
      runSearch(true);
    } else {
      debug("Search is cached! Setting finished = true");
      setFinished(true);
    }
  }, [runSearch, hasCachedSearch]);

  const firstSearchDone = searchCount > 0;
  const isMaxedOut = searchCount === MAX_SEARCH_COUNT;

  // interval on if the first search didn't get the lot
  useEffect(() => {
    if (firstSearchDone && !isFinished) {
      debug(`creating interval to gather remaining results`);
      setDelay(SEARCH_DELAY);
    }
  }, [firstSearchDone, isFinished]);

  // interval off
  useEffect(() => {
    if (delay && isFinished) {
      debug("Removing interval");
      setDelay(null);
    }
  }, [delay, isFinished]);

  useInterval(runSearch, delay);

  useEffect(() => {
    if (isMaxedOut) {
      debug("Too many searches performed");
      setFinished(true);
    }
  }, [isMaxedOut]);

  return [isError, isFinished];
}

export default useSearch;
