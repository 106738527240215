import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { useLocalStorage } from "@rehooks/local-storage";
import SearchResult from "./SearchResult";
import { withStyles } from "@material-ui/core/styles";

import SearchResultsHeader from "./SearchResultsHeader";
import SearchResultSkeleton from "./SearchResultSkeleton";
import useSortedSearchResults from "../../data/hooks/useSortedSearchResults";
import useSelectedResultsProperty from "./Data/useSelectedResultsProperty";
import useSearchFilterDrawer from "./Data/useSearchFilterDrawer";

import LinearProgress from "@material-ui/core/LinearProgress";
import "./searchResultsStyle.css";
import SearchFilter from "./SearchFilter";
import useFilteredSearchResults from "./useFilteredSearchResults";

import useDeviceWidth from "data/hooks/useDeviceWidth";
import { getAnalysedProperties } from "services/propertyHelpers";
import useValuation from "data/hooks/useValuation";
import { getSearchSelector, getValuationSelectorOrNull } from "data/selectors";
import usePropertyData from "data/hooks/usePropertyData";
import useSearchResults from "data/hooks/useSearchResults";

const Results = ({
  searchId,
  properties = [],
  viewType,
  searchFinished,
  propertyIds,
  valuationData,
  valuationProperty,
}) => {
  const [selectedResult, setSelectedResult] = useSelectedResultsProperty();
  const [filteredProperties] = useFilteredSearchResults(searchId);
  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();

  useEffect(() => {
    if (selectedResult) {
      let id = document.getElementById(selectedResult);
      try {
        id.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      } catch (e) {
        console.log(
          "Could not scroll to property (likely due to being filtered out)"
        );
      }
      setSelectedResult(null);
    }
  }, [selectedResult, setSelectedResult]);

  useEffect(() => {
    if (searchId) {
      let id = document.getElementById("search-results-top");
      try {
        id.firstElementChild.scrollIntoView(true);
      } catch (e) {
        console.log("Could not scroll to top of search-results-top");
      }
    }
  }, [searchId]);

  return (
    <div className={"search-results-wrapper"}>
      <div
        className={"search-results-wrapper-inner scrollbox nice-scrollbar"}
        id="search-results-top"
      >
        {filteredProperties?.map((property) => (
          <SearchResult
            key={property.propertyID}
            searchId={searchId}
            property={property}
            isSelected={property.propertyID === selectedResult}
            viewType={viewType}
            isSmallWidth={isSmallWidth}
            isMediumWidth={isMediumWidth}
            isLargeWidth={isLargeWidth}
            valuationData={valuationData}
            valuationProperty={valuationProperty}
          />
        ))}
        {(!searchFinished || propertyIds.length !== properties.length) && (
          <ResultSkeletons />
        )}
      </div>
    </div>
  );
};

const ResultSkeletons = () => {
  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      {skeletons.map((key) => (
        <React.Fragment key={key}>
          <SearchResultSkeleton />
        </React.Fragment>
      ))}
    </div>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    // borderRadius: 5,
  },
  // colorPrimary: {
  //   backgroundColor:
  //     theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  // },
  bar: {
    // borderRadius: 5,
    backgroundColor: "#168039",
  },
}))(LinearProgress);

const SearchResultsProgressBar = ({ progress }) => {
  return (
    <div
      className="search-results-progress-bar-container"
      style={{
        opacity: progress === 100 || isNaN(progress) ? 0 : 1,
      }}
    >
      <BorderLinearProgress variant="determinate" value={progress} />
    </div>
  );
};

const SearchResults = ({
  search,
  error,
  searchFinished,
  cacheFinished,
  searchId,
  searchName,
  searchSpec = {},
  searchSortOrder,
}) => {
  const sortOrder = searchSortOrder;
  // const filterKeyword = useSelector(getFilterKeyword);

  const [properties, propertyIds] = useSortedSearchResults(
    searchId,
    sortOrder,
    searchSpec.searchlat,
    searchSpec.searchlng
  );

  const [searchFilterDrawerOpen] = useSearchFilterDrawer();

  const [viewType] = useLocalStorage("result_style", "full");

  const getProgress = () => {
    const propertyCount = properties.length;
    const analysedProperties = getAnalysedProperties(search, properties);
    const analysedTotal = analysedProperties.length;

    return (analysedTotal / propertyCount) * 100;
  };

  const valuationId = search.parentValuationId;

  useValuation(valuationId);
  const dummyValuationSearch = useSelector((state) =>
    getSearchSelector(state, valuationId)
  );
  const valuationData = useSelector((state) =>
    getValuationSelectorOrNull(state, valuationId)
  );
  // Populate redux with PropertyData from the backend
  usePropertyData(valuationId, dummyValuationSearch.propertyIds.length > 0);
  const [[valuationProperty]] = useSearchResults(valuationId);

  if (error) {
    return (
      <div>
        <p>
          We could not find that search! Try starting a new one{" "}
          <Link to="/search">here</Link>
        </p>
      </div>
    );
  }
  return (
    <>
      {searchFilterDrawerOpen ? (
        <SearchFilter properties={properties} />
      ) : (
        <>
          <SearchResultsProgressBar progress={getProgress()} />
          <SearchResultsHeader
            searchId={searchId}
            searchSpec={searchSpec}
            searchName={searchName}
            searchFinished={searchFinished}
            cacheFinished={cacheFinished}
            properties={properties}
            propertyIds={propertyIds}
            searchSortOrder={searchSortOrder}
          />
          <Results
            searchId={searchId}
            properties={properties}
            viewType={viewType}
            searchFinished={searchFinished}
            propertyIds={propertyIds}
            valuationData={valuationData}
            valuationProperty={valuationProperty}
          />
        </>
      )}
    </>
  );
};

export default SearchResults;
