import { Source, Layer } from "react-mapbox-gl";

import {
  LINE_ATTRIBS,
  FILL_ATTRIBS,
  LABEL_ATTRIBS,
  LABEL_LAYOUT,
} from "./defaults";

const POSTCODE_LINES_SOURCE = {
  type: "vector",
  url: `https://api.mapbox.com/v4/prchapman.9kknvw23.json?secure&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
};

const PostcodeOutlines = () => {
  return (
    <>
      <Source id="postcodes-source" tileJsonSource={POSTCODE_LINES_SOURCE} />
      <Layer
        id="postcodes-lines"
        type="line"
        sourceId="postcodes-source"
        sourceLayer="PostalDistrictBoundariesSHP-7dieyi"
        paint={LINE_ATTRIBS}
      />
      <Layer
        id="postcodes-fill"
        type="fill"
        sourceId="postcodes-source"
        sourceLayer="PostalDistrictBoundariesSHP-7dieyi"
        paint={FILL_ATTRIBS}
        before="settlement-subdivision-label"
      />
      <Layer
        id="postcodes-labels"
        type="symbol"
        sourceId="postcodes-source"
        sourceLayer="PostalDistrictBoundariesSHP-7dieyi"
        paint={LABEL_ATTRIBS}
        layout={LABEL_LAYOUT("PostDist")}
        // before="settlement-subdivision-label"
      />
    </>
  );
};

export default PostcodeOutlines;
