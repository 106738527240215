export const measurePropertiesPriceAverage = (searchType, properties) => {
  const allResultsAverage = Math.round(
    properties.reduce((a, b) => a + b.price, 0) / properties.length
  );
  return allResultsAverage;
};
export const measurePropertiesLatestPriceAverage = (searchType, properties) => {
  // For comps >1 & showTodayPrice
  const measuredProperties = properties.filter(
    (prop) => prop.latestLwEstimatedPrice > 0
  );
  const allResultsAverage = Math.round(
    measuredProperties.reduce((a, b) => a + b.latestLwEstimatedPrice, 0) /
      measuredProperties.length
  );
  return allResultsAverage;
};

export const measurePropertiesPpsfAverage = (searchType, properties) => {
  const measuredProperties =
    searchType === "sale" || searchType === "newbuild"
      ? properties.filter((prop) => prop.ppsf > 25 && prop.ppsf < 5000)
      : properties.filter((prop) => prop.ppsfpyr > 0 && prop.ppsfpyr < 1000);

  const totalMeasured = measuredProperties.length;
  const allResultsAverage =
    searchType === "sale" || searchType === "newbuild"
      ? Math.round(
          measuredProperties.reduce((a, b) => a + b.ppsf, 0) / totalMeasured
        )
      : Math.round(
          measuredProperties.reduce((a, b) => a + b.ppsfpyr, 0) / totalMeasured
        );

  return allResultsAverage;
};
export const measurePropertiesLatestPpsfAverage = (searchType, properties) => {
  // For comps >1 & showTodayPrice
  const measuredProperties = properties.filter(
    (prop) => prop.ppsf > 0 && prop.latestLwEstimatedPrice > 0
  );
  const allResultsAverage = Math.round(
    measuredProperties.reduce((a, b) => a + b.latestPpsf, 0) /
      measuredProperties.length
  );
  return allResultsAverage;
};

export const measurePropertiesSqftAverage = (searchType, properties) => {
  const measuredProperties = properties.filter(
    (prop) => prop.sqft > 100 && prop.sqft < 10000
  );
  const totalMeasured = measuredProperties.length;
  const allResultsAverage = Math.round(
    measuredProperties.reduce((a, b) => a + b.sqft, 0) / totalMeasured
  );

  return allResultsAverage;
};

// export const measurePropertiesMonthlyAverage = (searchType, properties) => {
//   const measuredProperties =
//     searchType === "sale"
//       ? properties.filter((prop) => prop.ppsf > 25 && prop.ppsf < 5000)
//       : properties.filter((prop) => prop.ppsfpyr > 0 && prop.ppsfpyr < 1000);
//   const totalMeasured = measuredProperties.length;
//   const allResultsAverage =
//     searchType === "sale"
//       ? Math.round(
//           measuredProperties.reduce((a, b) => a + b.ppsf, 0) / totalMeasured
//         )
//       : Math.round(
//           measuredProperties.reduce((a, b) => a + b.ppsfpyr, 0) / totalMeasured
//         );

//   return allResultsAverage;
// };

export const getUnanalysedProperties = (properties) => {
  return properties.filter((prop) => {
    return (
      !prop?.sqft ||
      !prop?.landworth ||
      !prop?.zHistoryURL ||
      !prop?.rmHistoryURL ||
      !prop?.lrSoldPriceHistoryURL
    );
  });
};

export const getAnalysedProperties = (search, properties) => {
  return properties.filter((prop) => {
    const thresholdDate = new Date(Date.now() - 2 * 864e5).toISOString();
    const thresholdDate30 = new Date(Date.now() - 2 * 864e5 * 30).toISOString();
    const rmHistoryExpired = prop?.rmHistoryCachedDate < thresholdDate;
    const sqftDataExpired = prop?.sqftCachedDate < thresholdDate30;
    const lwDataExpired =
      prop?.propertySource !== "landreg" && // No need to recalculate landworth for LR properties as soldDate doesn't change
      prop?.lwCachedDate < thresholdDate;
    const lwLatestMissing =
      prop?.propertySource === "landreg" &&
      (prop?.latestLwCachedDate < thresholdDate || !prop?.latestLandworth);
    const needsLastSaleLandworth =
      prop?.propertySource === "valuation" &&
      prop?.lastSalePrice &&
      !prop?.lastSaleLandworth;

    const loadedOldSearch =
      search.dateRun < thresholdDate || prop?.propertySource === "valuation";

    return (
      prop?.sqft &&
      prop?.landworth &&
      prop?.zHistoryURL &&
      prop?.rmHistoryURL &&
      prop?.lrSoldPriceHistoryURL &&
      !needsLastSaleLandworth &&
      (loadedOldSearch ||
        (!rmHistoryExpired &&
          !lwDataExpired & !lwLatestMissing &&
          !sqftDataExpired))
    );
  });
};

export const extractMatchAddress = (address) => {
  return address
    .toLowerCase()
    .replace(/,/g, "")
    .replace(/\./g, "")
    .replace(/'/g, "")
    .replace(/\//g, "")
    .replace(/-/g, "")
    .replace(/\s/g, "")
    .replace(/apartment/g, "flat");
};

export const convertLrPropertyTypeToFullText = (propertyType) => {
  switch (propertyType) {
    case "S":
      return "Semi-detached";
    case "D":
      return "Detached";
    case "T":
      return "Terraced";
    case "F":
      return "Flat";
    case "O":
      return "Other";
    default:
      return propertyType;
  }
};
