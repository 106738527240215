import React from "react";
import { basemaps, heatmaps } from "../Map/heatmap.definitions";
import useMapSettingsDrawer from "../Search/Data/useMapSettingsDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "./mapSettings.css";

const DynamicHeatmapLabel = ({ showCircle }) => (
  <>
    Dynamic Heatmap{" "}
    <span style={{ fontWeight: "bold" }}>{showCircle ? "On" : "Off"}</span>
  </>
);
const PerspectiveLabel = ({ selectedPerspective }) => (
  <>
    <span style={{ fontWeight: "bold" }}>
      {selectedPerspective === "3D" ? "3D" : "2D"}
    </span>{" "}
    View
  </>
);

const DrawerContainer = ({ props }) => {
  const {
    selectedHeatmap,
    setSelectedHeatmap,
    selectedBasemap,
    setSelectedBasemap,
    selectedPerspective,
    setSelectedPerspective,
    setShowCircle,
    showCircle,
    findMe,
    showPostcodeDistricts,
    setShowPostcodeDistricts,
    showBoroughs,
    setShowBoroughs,
  } = props;
  const [, setMapSettingsDrawerOpen] = useMapSettingsDrawer();

  const toggle2D3D = (event) => {
    const newMode = selectedPerspective === "2D" ? "3D" : "2D";
    window.analytics.track("Toggle 2D/3D", { newMode: newMode });

    setSelectedPerspective(newMode);
  };
  const toggleShowCircle = (event) => {
    window.analytics.track("Toggle Dynamic Heatmap", {
      newState: !showCircle,
    });
    setShowCircle();
  };

  return (
    <div className="sidebar-container map-settings-container nice-scrollbar">
      <div
        className="popup-close-button"
        onClick={() => setMapSettingsDrawerOpen(false)}
      >
        X
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "1.4rem",
          fontWeight: "bold",
          paddingBottom: "12px",
        }}
      >
        Map Settings
      </div>

      <div className="basemap-selector">
        <span style={{ fontWeight: "bold" }}>
          Basemap&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <Select
          name="basemap"
          id="basemap"
          value={selectedBasemap}
          onChange={(e) => {
            const newBasemap = e.target.value;
            window.analytics.track("Change Basemap", { basemap: newBasemap });
            setSelectedBasemap(newBasemap);
          }}
          className="basemap-selector-select"
        >
          {basemaps.map((basemap) => (
            <MenuItem key={basemap.name} value={basemap.name}>
              <FontAwesomeIcon icon={["far", basemap.icon]} />
              &nbsp;&nbsp;{basemap.viewValue}
            </MenuItem>
          ))}
        </Select>
      </div>
      <br />
      <div className="heatmap-selector">
        <span style={{ fontWeight: "bold" }}>
          Heatmap&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <Select
          name="heatmap"
          id="heatmap"
          value={selectedHeatmap}
          onChange={(e) => {
            const newHeatmap = e.target.value;
            window.analytics.track("Change Heatmap", { heatmap: newHeatmap });
            setSelectedHeatmap(newHeatmap);
            if (newHeatmap !== "landworth" && showCircle) setShowCircle();
          }}
          className="heatmap-selector-select"
        >
          {heatmaps.map((heatmap) => (
            <MenuItem key={heatmap.name} value={heatmap.name}>
              <FontAwesomeIcon icon={["far", heatmap.icon]} />
              &nbsp;&nbsp;{heatmap.viewValue}
            </MenuItem>
          ))}
        </Select>
      </div>
      <br />
      <div className="toggle-input perspective-switch">
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={selectedPerspective === "3D"}
                onChange={toggle2D3D}
              />
            }
            label={
              <PerspectiveLabel selectedPerspective={selectedPerspective} />
            }
            labelPlacement="start"
          />
        </FormGroup>
      </div>
      <br />
      <div className="toggle-input dynamic-heatmap-switch">
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch checked={showCircle} onChange={toggleShowCircle} />
            }
            label={<DynamicHeatmapLabel showCircle={showCircle} />}
            labelPlacement="start"
          />
        </FormGroup>
      </div>
      <br />
      <div className="toggle-input perspective-switch">
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={showPostcodeDistricts}
                onChange={() =>
                  setShowPostcodeDistricts(!showPostcodeDistricts)
                }
              />
            }
            label={"Show Postcodes"}
            labelPlacement="start"
          />
        </FormGroup>
      </div>
      <br />
      <div className="toggle-input perspective-switch">
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={showBoroughs}
                onChange={() => setShowBoroughs(!showBoroughs)}
              />
            }
            label={"Show Boroughs"}
            labelPlacement="start"
          />
        </FormGroup>
      </div>
      <div className="map-control" style={{ display: "none" }}>
        <button
          onClick={() => {
            window.analytics.track("Toggle 2D/3D", { newMode: "2D" });
            setSelectedPerspective("2D");
          }}
          disabled={selectedPerspective === "2D"}
        >
          2D
        </button>
        <button
          onClick={() => {
            window.analytics.track("Toggle 2D/3D", { newMode: "3D" });
            setSelectedPerspective("3D");
          }}
          disabled={selectedPerspective === "3D"}
        >
          3D
        </button>
        <br />
        <button
          onClick={() => {
            window.analytics.track("Find User");
            findMe();
          }}
        >
          Find Me
        </button>
      </div>
    </div>
  );
};

const MapSettings = (props) => {
  const [mapSettingsDrawerOpen, setMapSettingsDrawerOpen] =
    useMapSettingsDrawer();
  const toggleDrawer = (open) => (event) => {
    if (event?.type === "keydown" && event?.key !== "Esc") {
      return;
    }
    setMapSettingsDrawerOpen(open);
  };

  return (
    <SwipeableDrawer
      open={mapSettingsDrawerOpen || false}
      variant="temporary"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(false)}
      // Fixes to compensate for iOS usability
      //   disableBackdropTransition={!iOS}
      //   disableDiscovery={iOS}
      disableDiscovery={true}
      disableSwipeToOpen={true}
      className="map-settings-drawer"
    >
      <DrawerContainer props={props} />
    </SwipeableDrawer>
  );
};

export default MapSettings;
