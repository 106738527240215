import React, { useMemo, useCallback, useEffect } from "react";
import { runSearch } from "../../graphql/functions";
import { withRouter, useRouteMatch } from "react-router-dom";
import { Formik } from "formik";
import { debounce } from "debounce";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { setCircleRadius as setCircleRadiusAction } from "./circleSlice";

import useSearchDrawer from "./Data/useSearchDrawer";
import useSearchView from "data/hooks/useSearchView";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";
import useLocation from "./useLocation";
import { setPopupInfo as setMapPopupInfoAction } from "data/slices/mapSlice";
import { setFilterKeyword as setFilterKeywordAction } from "app/settingsSlice";
import { setSearchDetails } from "../../data/slices/searchSlice";

import { MonitorIncoming, MonitorOutgoing } from "./SearchFormMonitors";

import SearchFormSlim from "./SearchFormSlim";
import SearchNavBar from "./SearchNavbar";
import SearchHome from "../SearchHome";
import SearchError from "../SearchError";
import SearchResults from "./SearchResults";
import Map from "../Map";

import useSearch from "../../data/hooks/useSearch";
import usePropertyData from "../../data/hooks/usePropertyData";
import { setCircleCenter } from "./circleSlice";
import useSelectedMapProperty from "./Data/useSelectedMapProperty";
import useSelectedResultsProperty from "./Data/useSelectedResultsProperty";
import { getSearchSelector } from "../../data/selectors";
import { convertSpecToReadable } from "services/search";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import NotEnoughCredits from "components/credits/NotEnoughCredits";
import DEFAULT_SEARCH_SPEC from "./DEFAULT_SEARCH_SPEC";

import SearchViewControlButton from "components/SearchViewControlButton";

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const RADIUS_DEBOUNCE_MS = 100;

const handleFormikValidate = (values) => {
  console.log("CHECKING");
  const errors = {};
  if (!values.search_zoopla && !values.search_landreg && !values.search_rm) {
    errors.search_zoopla = "You must select at least one source";
  }
  if (!values.include_forsale && !values.include_sold) {
    errors.include_forsale = "You must select at least one option";
  }
  if (!values.newbuild && !values.oldbuild) {
    errors.newbuild = "You must select at least one build type";
  }
  return errors;
};

const PreloadedSearchForm = (props) => {
  const { spec, searchId, searchName } = props;
  const { searchlat, searchlng } = spec;
  const dispatch = useDispatch();
  const [, selectMap] = useSelectedMapProperty();
  const [, selectResult] = useSelectedResultsProperty();

  // reset selected property
  useEffect(() => {
    selectMap(null);
    selectResult(null);
    document.title = `Landworth: ${convertSpecToReadable({
      spec,
      name: searchName,
      locationName: searchName,
    })}`;
  }, [searchId]);

  // center the map on co-ordinates loaded from 'spec' with a 3000ms delay to allow the map to load
  useEffect(() => {
    setTimeout(() => {
      dispatch(setCircleCenter({ lat: searchlat, lng: searchlng }));
    }, 3000);
  }, [searchId, searchlat, searchlng, dispatch]);
  return (
    <SearchFormSlim
      spec={spec}
      values={props.values}
      setFieldValue={props.setFieldValue}
      setValues={props.setValues}
      submitForm={props.submitForm}
    />
  );
};

const SearchFormDrawer = (props) => {
  const { searchId, searchError, searchSpec, searchName } = props;
  const [searchDrawerOpen, setSearchDrawerOpen] = useSearchDrawer();
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown" && event.key !== "Esc") {
      return;
    }
    setSearchDrawerOpen(open);
  };

  // if we have the spec already (i.e. cached or just triggered) render the form
  // otherwise throw up a spinner
  const searchForm = searchSpec ? (
    <PreloadedSearchForm
      {...props}
      spec={searchSpec}
      searchId={searchId}
      searchName={searchName}
    />
  ) : searchId ? (
    <p>Loading search...</p>
  ) : (
    <SearchFormSlim
      values={props.values}
      setFieldValue={props.setFieldValue}
      setValues={props.setValues}
      submitForm={props.submitForm}
    />
  );

  return (
    <SwipeableDrawer
      open={searchDrawerOpen || false}
      variant="temporary"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(false)}
      // Fixes to compensate for iOS usability
      disableBackdropTransition={!iOS}
      // disableDiscovery={iOS}
      disableDiscovery={true}
      disableSwipeToOpen={true}
      className="search-form-drawer"
    >
      {!searchError && searchForm}
    </SwipeableDrawer>
  );
};

const LowerSearchFrame = (props) => {
  const { searchId, searchError } = props;
  const search = useSelector((state) => getSearchSelector(state, searchId));

  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.phone * 2}px)`);

  return (
    <>
      <SearchFormDrawer {...props} />
      <div className={`search-results-column ${props.searchListColumnWidth}`}>
        {searchId === "NOT_ENOUGH_CREDITS" ? (
          <div
            // className="search-results-header"
            style={{
              flexDirection: "column",
              height: "100%",
              // backgroundColor: "white",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "0",
                left: "0",
                paddingTop: isNarrow ? "5px" : "4px",
                zIndex: "999",
                float: "left",
              }}
            >
              <SearchViewControlButton variant={"list_button"} />
            </div>
            <div className="flex-center-center" style={{ height: "100%" }}>
              <NotEnoughCredits actionType="search" />
            </div>
          </div>
        ) : searchError ? (
          <SearchError />
        ) : !!searchId ? (
          <SearchResults
            {...props}
            error={searchError}
            searchName={search?.name ? search.name : ""}
            searchSortOrder={
              search?.searchSortOrder ? search.searchSortOrder : "cheapest"
            }
          />
        ) : (
          <SearchHome setSearchDrawerOpen={props.setSearchDrawerOpen} />
        )}
      </div>
    </>
  );
};

const FormikEnabledContainer = (props) => {
  const { history, spec, mapColumnWidth } = props;

  const routeMatch = useRouteMatch("/search/:searchId");
  const searchId = routeMatch?.params?.searchId;
  const [searchError, searchFinished] = useSearch(searchId);
  const cacheFinished = usePropertyData(searchId, searchFinished);
  const search = useSelector((state) => getSearchSelector(state, searchId));

  const getCircleDetails = createSelector(
    ({ circle }) => circle,
    (circle) => circle
  );
  const { radius, center } = useSelector(getCircleDetails);
  const dispatch = useDispatch();
  const setCircleRadius = useCallback(
    (radius) => dispatch(setCircleRadiusAction(radius)),
    [dispatch]
  );
  const setReduxFilterKeyword = useCallback(
    (newFilterKeyword) => dispatch(setFilterKeywordAction(newFilterKeyword)),
    [dispatch]
  );

  const debounceSetRadius = useMemo(
    () => debounce(setCircleRadius, RADIUS_DEBOUNCE_MS),
    [setCircleRadius]
  );

  const [searchView, setSearchView] = useSearchView();
  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.narrow}px)`);
  const location = useLocation(center);
  const [, setSearchDrawerOpen] = useSearchDrawer();

  const performSearch = async (name, values) => {
    const runSearchResponse = await runSearch(
      name ? name : "LOCATION ERROR",
      "search",
      values
    );
    const searchResponseStatus = runSearchResponse.responseStatus;
    const newSearchId = runSearchResponse.searchId;

    window.analytics.track("Run Property Search", {
      searchSpec: JSON.stringify(values),
      search_type: values.search_type,
      search_landreg: values.search_landreg,
      search_rm: values.search_rm,
      search_zoopla: values.search_zoopla,
      search_plan: values.search_plan,
      include_forsale: values.include_forsale,
      include_sold: values.include_sold,
      newSearchId: newSearchId,
    });

    if (searchResponseStatus === "NOT_ENOUGH_CREDITS") {
      history.push(`/search/NOT_ENOUGH_CREDITS`);
      if (searchView === "full_map") {
        isNarrow ? setSearchView("full_list") : setSearchView("split");
      }
    } else if (newSearchId) {
      dispatch(
        setSearchDetails({
          id: newSearchId,
          name,
          values,
        })
      );
      dispatch(setMapPopupInfoAction({ popupInfo: null }));

      history.push(`/search/${newSearchId}`);
      if (searchView === "full_map") {
        isNarrow ? setSearchView("full_list") : setSearchView("split");
      }
      setReduxFilterKeyword("");
    } else {
      history.push(`/search/error`);
    }
  };

  return (
    <Formik
      initialValues={search?.spec ? search.spec : DEFAULT_SEARCH_SPEC}
      enableReinitialize
      // validate={(values) => handleFormikValidate}
      validator={() => ({})}
      onSubmit={(values, { setSubmitting }) => {
        performSearch(location, values);
        setSubmitting(false);
        setSearchDrawerOpen(false);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        setFieldValue,
        setValues,
        submitForm,
      }) => (
        <>
          <MonitorOutgoing setCircleRadius={debounceSetRadius} />
          <MonitorIncoming
            searchradius={radius}
            searchlng={center[0]}
            searchlat={center[1]}
          />

          <div
            style={{ flex: "0 1 auto" }}
            className="search-page search-page-navbar"
          >
            <SearchNavBar
              values={values}
              setFieldValue={setFieldValue}
              submitForm={submitForm}
              isSubmitting={isSubmitting || (searchId && !searchFinished)}
            />
          </div>
          <div
            className="search-page row"
            style={{ flex: "1 1 100px", overflowY: "auto" }}
          >
            <div className={`map-column ${mapColumnWidth}`}>
              <Map
                isLoggedIn={true}
                submitForm={submitForm}
                isSubmitting={isSubmitting || (searchId && !searchFinished)}
              />
            </div>

            <LowerSearchFrame
              search={search}
              searchId={searchId}
              searchError={searchError}
              searchSpec={search.spec}
              error={searchError}
              searchFinished={searchFinished}
              cacheFinished={cacheFinished}
              searchName={search.name ? search.name : ""}
              {...props}
              values={values}
              setValues={setValues}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting || (searchId && !searchFinished)}
              submitForm={submitForm}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </>
      )}
    </Formik>
  );
};

export default withRouter(FormikEnabledContainer);
