import { Field } from "formik";
import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputBase from "@material-ui/core/InputBase";
import { formatMoneyRounded } from "services/formatting";

// prettier-ignore
const sizes = [0, 100, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2250, 2500, 3000, 3500, 4000, 5000, 7500, 10000, 100000];

const MenuItem = withStyles({
  root: {
    justifyContent: "center",
  },
})(MuiMenuItem);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    // fontSize: "17px !important",
    padding: "6px 26px 6px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

const SizePickers = (props) => {
  const { values, setFieldValue } = props;
  return (
    <div className={"search-form-item"}>
      {/* <div className={"search-form-label"}></div> */}
      <Field
        component={Select}
        name="sizemin"
        id="sizemin"
        value={values?.sizemin}
        onChange={(e, newValue) => {
          if (newValue) {
            setFieldValue("sizemin", newValue.props.value);
          }
        }}
        input={<BootstrapInput />}
        style={{ width: "110px" }}

        // MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        {sizes.map((size) => {
          return size <= values?.sizemax ? (
            <MenuItem key={size} value={size} dense>
              {sizes[0] === size ? (
                <>
                  Min&nbsp;&nbsp;
                  <FontAwesomeIcon icon={["far", "ruler"]} />
                </>
              ) : (
                `${formatMoneyRounded(size, 0)} sqft`
              )}
            </MenuItem>
          ) : null;
        })}
      </Field>
      <div className={"search-form-to-label"}>to</div>
      <Field
        component={Select}
        name="sizemax"
        id="sizemax"
        value={values?.sizemax}
        onChange={(e, newValue) => {
          if (newValue) {
            setFieldValue("sizemax", newValue.props.value);
          }
        }}
        input={<BootstrapInput />}
        style={{ width: "110px" }}

        // MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        {sizes.map((size) => {
          return size >= values?.sizemin ? (
            <MenuItem key={size} value={size} dense>
              {sizes[sizes.length - 1] === size ? (
                <>
                  Max&nbsp;&nbsp;
                  <FontAwesomeIcon icon={["far", "ruler"]} />
                </>
              ) : (
                `${formatMoneyRounded(size, 0)} sqft`
              )}
            </MenuItem>
          ) : null;
        })}
      </Field>
    </div>
  );
};

export default SizePickers;
