import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    width: "auto",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    borderRadius: "10px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
    marginTop: "4px",
    textAlign: "center",
  },
}))(Tooltip);

const GenericTooltip = ({ text, children, style = {} }) => {
  const contentRef = useRef(null);
  const hiddenRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (contentRef.current && hiddenRef.current) {
        const contentHeight = contentRef.current.clientHeight;
        const hiddenHeight = hiddenRef.current.clientHeight;

        console.log(text);
        console.log(contentHeight);
        console.log(hiddenHeight);
        console.log(hiddenHeight > contentHeight);

        setIsEllipsisActive(hiddenHeight > contentHeight);
      }
    };

    checkEllipsis();
    window.addEventListener("resize", checkEllipsis);
    return () => window.removeEventListener("resize", checkEllipsis);
  }, [text]);

  const contentStyle = {
    ...style,
  };

  const hiddenStyle = {
    ...style,
    display: "block",
    visibility: "hidden",
    position: "absolute",
    overflow: "initial !important", // This needs to drill down into <div class=result-address>
    WebkitLineClamp: "unset", // This needs to drill down into <div class=result-address>
    top: "-9999px",
    left: "-9999px",
    maxWidth: contentStyle.maxWidth || "auto",
    width: "100px",
    maxHeight: "none",
    WebkitLineClamp: "unset",
  };

  return (
    <>
      <HtmlTooltip
        placement="bottom-middle"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        PopperProps={{ marginTop: 0 }}
        enterDelay={300}
        enterNextDelay={300}
        title={text}
        disableHoverListener={!isEllipsisActive}
      >
        <div ref={contentRef} style={contentStyle}>
          {children}
        </div>
      </HtmlTooltip>
      <div ref={hiddenRef} style={hiddenStyle}>
        {text}
      </div>
    </>
  );
};

export default GenericTooltip;
