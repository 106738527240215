import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import "./splashStyle.css";
import useDeviceWidth from "data/hooks/useDeviceWidth";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1.2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  header: {
    fontSize: "25px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const [isNarrowWidth] = useDeviceWidth();
  const { children, topLink, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isNarrowWidth ? "column" : "row",
          alignItems: "center",
          // textAlign: isNarrowWidth ? "right" : "initial",
        }}
      >
        <Typography
          className={classes.header}
          style={{ fontWeight: "bold", padding: "8px" }}
        >
          {children}
        </Typography>
        <Typography className={classes.header} style={{ lineHeight: "18px" }}>
          {topLink}
        </Typography>
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: "400px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  title,
  message,
  buttonLabel,
  analyticsMessage,
  buttonTrigger,
}) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    const now = new Date();
    window.analytics.track(analyticsMessage, {
      triggerDate: now.toISOString(),
    });
    if (buttonTrigger) buttonTrigger();
    setOpen(false);
  };

  const bookDemoLink = (
    <div className="book-demo-link">
      <a
        href="https://calendly.com/landworth/landworth-pro-demo"
        target="_blank"
      >
        BOOK A DEMO
      </a>
    </div>
  );
  const homepageLink = (
    <div className="book-demo-link">
      <a href="https://www.landworth.org/" target="_blank">
        Home
      </a>
    </div>
  );

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll={"paper"}
      >
        <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
