import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Field } from "formik";

import Locator from "pages/Search/Locator";

import { formatMonthsAsYears } from "services/formatting";
import deviceSizes from "pages/styleConstants";

import { useSelector } from "react-redux";
import useLocation from "./useLocation";
import { getCircleDetails } from "data/selectors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import Tooltip from "@material-ui/core/Tooltip";

import useSearchDrawer from "./Data/useSearchDrawer";
import { defaultCircle } from "./circleSlice";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

const MenuItem = withStyles({
  root: {
    justifyContent: "center",
  },
})(MuiMenuItem);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    lineHeight: "1.43",
  },
  input: {
    // borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    fontWeight: "bold",
    padding: "0px 3px 0px 6px",
    fontSize: "13px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

const SearchNavBar = ({
  values,
  setFieldValue,
  soldHistoryMonths,
  setSoldHistoryMonths,
  searchType = "sale",
  setSearchType,
  submitForm,
  isSubmitting,
}) => {
  const isMediumWidth = useMediaQuery(`(max-width:${deviceSizes.n780}px)`);

  const noSourcesEnabled = useMemo(
    () =>
      !values ||
      (!!values &&
        !values.search_rm &&
        !values.search_landreg &&
        !values.search_zoopla &&
        !values.search_plan),

    [values]
  );
  const noLocationSelected = useMemo(
    () =>
      !!values &&
      values.searchlat === defaultCircle.center[1] &&
      values.searchlng === defaultCircle.center[0],
    [values]
  );

  const SearchDrawerButton = ({ position }) => {
    const [searchDrawerOpen, setSearchDrawerOpen] = useSearchDrawer();
    const routeMatch = useRouteMatch("/search/:searchId");
    const searchId = routeMatch?.params?.searchId;

    const openSearchDrawer = () => {
      window.analytics.track("Show Search Form", { clickSource: "header" });
      setSearchDrawerOpen(true);
    };

    return (
      <LightTooltip
        title={
          noLocationSelected
            ? "Search for a location first or explore the map"
            : "Click to set your Data Sources"
        }
      >
        <div>
          <Button
            variant={searchDrawerOpen ? "text" : "contained"}
            color={noSourcesEnabled ? "primary" : "secondary"}
            size={isMediumWidth ? "medium" : "small"}
            onClick={() => openSearchDrawer()}
            disabled={noLocationSelected}
            style={{
              minWidth: "30px",
              padding: "5px",
              // height: "100%",
              // borderRadius:
              //   position === "left" ? "0px 4px 4px 0px" : "4px 0 0 4px",
              // ...(isMediumWidth && { borderRadius: "0px 4px 4px 0px" }),
            }}
          >
            &nbsp;&nbsp;
            <FontAwesomeIcon
              icon={regular("sliders")}
              style={{ fontSize: "1.1em" }}
            />
            &nbsp;&nbsp;
            {!isMediumWidth && (
              <div style={{ fontSize: "15px" }}>&nbsp;&nbsp;Sources&nbsp;</div>
            )}
          </Button>
        </div>
      </LightTooltip>
    );
  };

  const LocationSearch = ({ defaultValue }) => {
    return (
      <>
        <Locator defaultValue={defaultValue} />
      </>
    );
  };

  const SearchButton = () => (
    <LightTooltip
      title={
        noSourcesEnabled
          ? noLocationSelected
            ? "Search for a location first or explore the map"
            : "Enable a Source before running a Search"
          : "Run Your Search"
      }
    >
      <div style={{ ...(noSourcesEnabled && { cursor: "not-allowed" }) }}>
        <Button
          color="primary"
          variant="contained"
          size={isMediumWidth ? "medium" : "small"}
          type="submit"
          disabled={noSourcesEnabled || isSubmitting}
          onClick={submitForm}
          style={{
            minWidth: "30px",
            padding: "5px",
            // ...(isMediumWidth && { borderRadius: "4px 0 0 4px" }),
          }}
          // size="large"
        >
          <div style={{ fontSize: "15px" }}>
            {
              <>
                &nbsp;&nbsp;&nbsp;
                {!isMediumWidth && <>Search&nbsp;&nbsp;</>}
                <FontAwesomeIcon
                  icon={regular("magnifying-glass")}
                  style={{ fontSize: "1.1em" }}
                />
                &nbsp;&nbsp;
              </>
            }
          </div>
        </Button>
      </div>
    </LightTooltip>
  );

  const { radius, center } = useSelector(getCircleDetails);
  const location = useLocation(center);

  const defaultLocation =
    center[0] === -0.1817481 && center[1] === 51.5466251 ? undefined : location;

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#f8f4ff",
        alignContent: "start",
        padding: isMediumWidth ? "2px 0" : "5px 0",
        userSelect: "none",
        justifyContent: "center",
      }}
    >
      {isMediumWidth ? (
        <>
          <SearchDrawerButton position={"left"} />
          &nbsp;&nbsp;
          <div
            className="flex-center-center"
            style={
              {
                // width: "100%",
                // justifyContent: "center",
              }
            }
          >
            <LocationSearch defaultValue={defaultLocation} />
          </div>
          &nbsp;&nbsp;
          <SearchButton />
        </>
      ) : (
        <div
          className="flex-center-center"
          style={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          <SearchDrawerButton position={"left"} />
          &nbsp;&nbsp;
          <LocationSearch defaultValue={defaultLocation} />
          &nbsp;&nbsp;
          <SearchButton />
        </div>
      )}
    </div>
  );
};

export default SearchNavBar;
