import { useMemo } from "react";
import { withRouter } from "react-router-dom";
import Auth from "@aws-amplify/auth";

import LandworthIndicator from "../../../components/landworthIndicator";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import useLocation from "../../Search/useLocation";
import useSearchDrawer from "../../Search/Data/useSearchDrawer";

import { useField } from "formik";

const styles = {
  popupContainer: {
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    maxWidth: "290px",
    minWidth: "268px",
    padding: "20px 10px 20px",
  },
};

// const renderPercentChange = (popupHistoryData) => {
//   const value = popupHistoryData && popupHistoryData.dataPoints[0].lwc1y;
//   return (
//     <div className="percent-change-div">
//       1 Year Change =&nbsp;
//       <div
//         className="percent-change-indicator"
//         style={{
//           backgroundColor:
//             value > 0 ? "green" : value < 0 ? "red" : "lightgrey",
//         }}
//       >
//         {popupHistoryData ? (value > 0 ? "+" : null) + value + "%" : "...."}
//       </div>
//     </div>
//   );
// };

const renderLandworthDisplay = (landworthData) => {
  let output;
  if (landworthData) {
    landworthData.totalWeight > 10 && landworthData.worth >= 50
      ? (output = (
          <LandworthIndicator landworthData={landworthData} value={null} />
        ))
      : (output = "Too few sales to calculate average price");
  } else {
    output = <div className="loader-small"></div>;
  }

  return (
    <div
      style={{
        height: "63px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {output}
    </div>
  );
};

const MapLandworthPopup = ({
  popupLandworthData,
  // popupHistoryData,
  setCircleCenter,
  feature,
  propertyTypeToggle,
  updatePropertyTypeToggle,
  history,
  isLoggedIn,
  submitForm,
  isSubmitting,
}) => {
  const { lat, lng } = feature.geometry.coordinates;
  const popupLocationData = useLocation([lng, lat]);
  const [, setSearchDrawerOpen] = useSearchDrawer();

  const [search_landreg] = useField("search_landreg");
  const [search_rm] = useField("search_rm");
  const [search_zoopla] = useField("search_zoopla");
  const noSourcesEnabled = useMemo(
    () => !search_rm.value && !search_landreg.value && !search_zoopla.value,
    [search_landreg, search_rm, search_zoopla]
  );

  const refineSearchLocation = (setCircleCenter, feature) => {
    window.analytics.track("Click Refine Search (Map Popup)", {
      clickSource: "map-popup",
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        setCircleCenter({
          lat: feature.geometry.coordinates.lat,
          lng: feature.geometry.coordinates.lng,
        });
        setSearchDrawerOpen(true);
        if (!history.location.pathname.startsWith("/search")) {
          history.push("/search");
        }
      })
      .catch(() => history.push("/search"));
  };
  const searchLocation = (setCircleCenter, feature, submitForm) => {
    window.analytics.track("Click Run Search (Map Popup)", {
      clickSource: "map-popup",
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        setCircleCenter({
          lat: feature.geometry.coordinates.lat,
          lng: feature.geometry.coordinates.lng,
        });
        try {
          submitForm();
        } catch {
          console.log("Cannot Run Search as user not logged in");
        }
        if (!history.location.pathname.startsWith("/search")) {
          setSearchDrawerOpen(true);
          history.push("/search");
        }
      })
      .catch(() => {
        history.push("/search");
      });
  };

  const referrerDomain = useMemo(() => {
    const referrer = document.referrer;
    var a = document.createElement("a");
    a.href = referrer;
    console.log("Embedded on " + a.hostname);
    return a.hostname;
  });

  return (
    <div style={styles.popupContainer} key={feature.geometry.coordinates}>
      <div className="popup-exact-name">
        {popupLocationData ? (
          popupLocationData
        ) : (
          <div className="loader-small"></div>
        )}
      </div>
      {renderLandworthDisplay(popupLandworthData)}

      <div style={{ padding: "6px 0 16px", backgroundColor: "white" }}>
        <Button
          variant={propertyTypeToggle === "houses" ? "contained" : "text"}
          size="small"
          // color={propertyTypeToggle === "houses" ? "secondary" : "default"}
          onClick={() => updatePropertyTypeToggle("houses")}
        >
          Houses
        </Button>
        <Button
          variant={propertyTypeToggle === "all" ? "contained" : "text"}
          size="small"
          // color={propertyTypeToggle === "all" ? "secondary" : "default"}
          onClick={() => updatePropertyTypeToggle("all")}
        >
          All
        </Button>
        <Button
          variant={propertyTypeToggle === "flats" ? "contained" : "text"}
          size="small"
          // color={propertyTypeToggle === "flats" ? "secondary" : "default"}
          onClick={() => updatePropertyTypeToggle("flats")}
        >
          Flats
        </Button>
      </div>
      {/* <br />
      {renderPercentChange(popupHistoryData)} */}
      {/* <br style={{ userSelect: "none" }} /> */}

      {!isLoggedIn ? ( // IF USER IS NOT LOGGED IN
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() =>
            history.push(
              `/join/map.${referrerDomain}.${new Date()
                .toLocaleString("default", {
                  month: "short",
                })
                .toLowerCase()}${new Date().getFullYear().toString().slice(-2)}`
            )
          }
        >
          <FontAwesomeIcon icon={["far", "search"]} />
          &nbsp;&nbsp;Search
        </Button>
      ) : (
        // IF USER HAS NO SOURCES ENABLED
        <span style={{ userSelect: "none" }}>
          <Button
            variant="contained"
            size="medium"
            color={noSourcesEnabled ? "primary" : "secondary"}
            onClick={() => refineSearchLocation(setCircleCenter, feature)}
          >
            {noSourcesEnabled && "Start Search"}&nbsp;
            <FontAwesomeIcon icon={regular("search-location")} />
            &nbsp;
          </Button>
          &nbsp;
          {!noSourcesEnabled && ( // IF USER HAS 1+ SOURCE ENABLED
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() =>
                searchLocation(setCircleCenter, feature, submitForm)
              }
              disabled={noSourcesEnabled || isSubmitting}
            >
              Search&nbsp;&nbsp;
              <FontAwesomeIcon
                icon={regular("search")}
                style={{ fontSize: "1.1em" }}
              />
            </Button>
          )}
        </span>
      )}
    </div>
  );
};

export default withRouter(MapLandworthPopup);
