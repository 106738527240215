import React from "react";

const PropertyBeds = ({ property, hideBedText }) => {
  const { beds, propertySource, rmBeds, zBeds, propertyType } = property;
  let bedString = null;
  if (
    propertySource === "zoopla" ||
    (propertySource === "rightmove" && propertyType !== "Land")
  ) {
    bedString = `${beds}`;
  } else {
    if (rmBeds) {
      bedString = rmBeds;
    } else if (zBeds) {
      bedString = zBeds;
    }
  }

  return (
    bedString && (
      <>
        {bedString}
        {!hideBedText && " Bed"}
      </>
    )
  );
};

export default PropertyBeds;
