import PlanningAppMultiSelect from "./PlanningAppMultiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const types = [
  "Full",
  "Outline",
  "Amendment",
  "Conditions",
  "Heritage",
  "Trees",
  "Advertising",
  "Telecoms",
  "Other",
];

const PlanningAppState = (props) => {
  const { values, setFieldValue } = props;
  return (
    <div className="flex-center-center">
      <FontAwesomeIcon icon={regular("memo-circle-info")} />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type&nbsp;&nbsp;&nbsp;
      <PlanningAppMultiSelect
        {...props}
        options={types}
        fieldName={"planningAppTypes"}
      />
    </div>
  );
};

export default PlanningAppState;
