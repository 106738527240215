import HouseType from "./Widgets/HouseType";
import IncludeNewOld from "./Widgets/IncludeNewOld";
import PricePickers from "./Widgets/PricePickers";
import PropertyTypePicker from "./Widgets/PropertyTypePicker";
import SearchRadiusPicker from "./Widgets/SearchRadiusPicker";
import SizePickers from "./Widgets/SizePickers";
import IncludeFreeholdLeasehold from "./Widgets/IncludeFreeholdLeasehold";
import IncludeSharedOwnership from "./Widgets/IncludeSharedOwnership";
import BedPickers from "./Widgets/BedPickers";
import IncludeForSaleSold from "./Widgets/IncludeForSaleSold";
import SearchType from "./Widgets/SearchType";

const RightmoveForm = (props) => {
  return (
    <>
      <SearchType {...props} />
      <br />
      <PropertyTypePicker {...props} />
      <br />
      <IncludeNewOld {...props} />
      <br />
      <IncludeForSaleSold {...props} />
      <br />
      <PricePickers {...props} />
      <br />
      <BedPickers {...props} />
      <br />
      {/* <IncludeSharedOwnership {...props} /> <br /> */}
    </>
  );
};

export default RightmoveForm;
