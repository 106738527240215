import useSearchView from "./useSearchView";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";

const useDeviceWidth = (overrideSearchView) => {
  const [searchView] = useSearchView();

  const actualSearchView = !!overrideSearchView
    ? overrideSearchView
    : searchView;

  const lowThreshold = deviceSizes?.phone;
  const mediumThreshold = deviceSizes?.n780;
  const highThreshold = deviceSizes?.n1080;
  const isSmallWidth = useMediaQuery(
    `(max-width:${
      actualSearchView === "split" ? lowThreshold * 2 : lowThreshold
    }px)`
  );
  const isMediumWidth = useMediaQuery(
    `(max-width:${
      actualSearchView === "split" ? mediumThreshold * 2 : mediumThreshold
    }px)`
  );
  const isLargeWidth = useMediaQuery(
    `(max-width:${
      actualSearchView === "split" ? highThreshold * 2 : highThreshold
    }px)`
  );

  return [isSmallWidth, isMediumWidth, isLargeWidth];
};

export default useDeviceWidth;
