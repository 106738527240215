import SearchResult from "../../Search/SearchResult";
import SearchResultsCommandBar from "../../Search/SearchResultsCommandBar";

import useDeviceWidth from "data/hooks/useDeviceWidth";
import { useLocalStorage } from "@rehooks/local-storage";

const ValuationReportSearchResults = (props) => {
  const {
    valuationData,
    valuationProperty,
    searchId,
    search,
    properties,
    filteredProperties,
    searchFinished,
  } = props;

  const [isSmallWidth, isMediumWidth, isLargeWidth] =
    useDeviceWidth("full_list");

  const [viewType] = useLocalStorage("result_style", "full");

  return (
    <>
      <div>
        {properties.length > 0 && (
          <SearchResultsCommandBar
            search={search}
            searchId={searchId}
            searchSpec={search?.spec}
            searchFinished={searchFinished}
            properties={properties}
            filteredProperties={filteredProperties}
          />
        )}
        {filteredProperties.map((property) => (
          <SearchResult
            key={property.propertyID}
            searchId={searchId}
            property={property}
            // isSelected={property.propertyID === selectedResult}
            selectMapResult={() => {}}
            isSmallWidth={isSmallWidth}
            isMediumWidth={isMediumWidth}
            isLargeWidth={isLargeWidth}
            viewType={viewType}
            valuationData={valuationData}
            valuationProperty={valuationProperty}
          />
        ))}
      </div>
    </>
  );
};

export default ValuationReportSearchResults;
