import useSearchFilter from "pages/Search/useSearchFilter";
import useSearchFilterDrawer from "pages/Search/Data/useSearchFilterDrawer";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";
import useDeviceWidth from "data/hooks/useDeviceWidth";

const useStyles = makeStyles({
  filterButton: {
    minHeight: "34px",
    maxHeight: "34px",
    backgroundColor: "white",
    minWidth: "40px",
    "&:hover": {
      borderColor: "#212121",
      backgroundColor: "white",
    },
  },
  filterButtonHighlighted: {
    minHeight: "34px",
    maxHeight: "34px",
    backgroundColor: "#168039",
    color: "white",
    minWidth: "40px",
    "&:hover": {
      borderColor: "#212121",
      backgroundColor: "#0f5927",
    },
  },
});

const SearchViewShowFiltersButton = (props) => {
  const classes = useStyles();

  const [searchFilter] = useSearchFilter();
  const [searchFilterDrawerOpen, setSearchFilterDrawerOpen] =
    useSearchFilterDrawer();

  const [isSmallWidth, isMediumWidth] = useDeviceWidth();

  const hasTags =
    searchFilter.includeTags?.some((tag) => !!tag) ||
    searchFilter.excludeTags?.some((tag) => !!tag);

  return (
    <Button
      size="small"
      variant="outlined"
      color={"default"}
      onClick={() => {
        window.analytics.track("Show Search Filters", {
          clickSource: "SearchResultsHeader",
        });
        setSearchFilterDrawerOpen(!searchFilterDrawerOpen);
      }}
      className={
        !hasTags ? classes.filterButton : classes.filterButtonHighlighted
      }
    >
      {!isMediumWidth && !hasTags && (
        <>
          <div style={{ margin: "2px 6px 0 0" }}>Filter</div>
        </>
      )}

      {hasTags ? (
        <>
          <div style={{ margin: "2px 6px 0 0" }}>Filter</div>

          <FontAwesomeIcon
            icon={regular("filter")}
            style={{ fontSize: "1.1em", margin: "0 5px 0 0" }}
          />
          <div style={{ marginTop: "2px" }}>
            {props.totalFilteredProperties + "/" + props.properties.length}
          </div>
        </>
      ) : (
        <FontAwesomeIcon
          icon={regular("filter")}
          style={{ fontSize: "1.1em" }}
        />
      )}
    </Button>
  );
};

export default SearchViewShowFiltersButton;
