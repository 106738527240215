import { useMemo } from "react";
import {
  getComparableScore,
  getComparableGrade,
  getComparableGradeColor,
} from "services/comparables";

const PropertyComparableGrade = ({
  valuationData,
  valuationProperty,
  property,
  showScore = true,
}) => {
  const { price } = property;

  const valuationPropertyMerged = {
    price: valuationData?.valuationPrice,
    sqft: valuationProperty?.sqft,
    beds: valuationProperty?.beds,
    valuationDate: valuationData?.valuationDate,
    lat: valuationProperty?.lat,
    lng: valuationProperty?.lng,
  };

  const comparableScore = useMemo(
    () => getComparableScore(valuationPropertyMerged, property),
    [valuationPropertyMerged, property]
  );

  return isNaN(comparableScore) ? (
    <div className="loader-small" />
  ) : (
    <div
      style={{
        color: price >= 0 ? getComparableGradeColor(comparableScore) : "black",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        fontWeight: "normal",
      }}
    >
      <span
        style={{ fontSize: "1.6em", fontWeight: "bold", lineHeight: "1.1em" }}
      >
        {getComparableGrade(comparableScore)}
      </span>
      {showScore && (
        <span style={{ fontSize: "0.75em" }}>
          {Math.round(comparableScore * 100)}%
        </span>
      )}
    </div>
  );
};

export default PropertyComparableGrade;
