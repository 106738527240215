import { createSlice } from "@reduxjs/toolkit";

///////////////////////////
// SET COMPARABLE PROPERTY FUNCTION
const addRemoveComparableProperties = (
  state,
  { searchID, propertyID, newState }
) => {
  const searchToSet = state[searchID];

  // If there are already comparables, check if it's an add/remove and update the set accordingly.
  // If there are no comparables, create a new set
  const comps = JSON.parse(searchToSet.comparableProperties);
  const newCompSet = comps
    ? newState
      ? comps.concat([propertyID])
      : comps.filter((propID) => propID !== propertyID)
    : newState && [propertyID];

  return {
    ...state,
    [`${searchID}`]: {
      ...searchToSet,
      comparableProperties: JSON.stringify(newCompSet),
    },
  };
};
const setSortOrder = (state, { searchID, searchSortOrder }) => {
  const searchToSet = state[searchID];

  return {
    ...state,
    [`${searchID}`]: {
      ...searchToSet,
      searchSortOrder,
    },
  };
};
const setShowTodayPriceFunction = (state, { searchID, showTodayPrice }) => {
  const searchToSet = state[searchID];

  return {
    ...state,
    [`${searchID}`]: {
      ...searchToSet,
      showTodayPrice,
    },
  };
};

const setCustomName = (state, { searchID, searchCustomName }) => {
  const searchToSet = state[searchID];

  return {
    ...state,
    [`${searchID}`]: {
      ...searchToSet,
      searchCustomName,
    },
  };
};

const searchSlice = createSlice({
  name: "search",
  initialState: {},
  reducers: {
    setPropertyIds(state, action) {
      const { id, propertyIds } = action.payload;
      const search = state[id] || {};
      return {
        ...state,
        [id]: {
          ...search,
          ...(!!propertyIds && { propertyIds }),
        },
      };
    },
    setSearchDetails(state, action) {
      const {
        id,
        name,
        locationName,
        spec,
        comparableProperties,
        dateRun,
        zooplaSearchComplete,
        landregSearchComplete,
        rmSearchComplete,
        planningSearchComplete,
        newBuildSearchesComplete,
        searchSortOrder,
        showTodayPrice,
        parentValuationId,
      } = action.payload;
      const search = state[id] || {};
      return {
        ...state,
        [id]: {
          ...search,
          name,
          locationName,
          spec,
          comparableProperties,
          dateRun,
          zooplaSearchComplete,
          landregSearchComplete,
          rmSearchComplete,
          planningSearchComplete,
          newBuildSearchesComplete,
          searchSortOrder,
          showTodayPrice,
          parentValuationId,
        },
      };
    },
    setSearchCompleteStatuses(state, action) {
      const {
        id,
        zooplaSearchComplete,
        landregSearchComplete,
        rmSearchComplete,
        planningSearchComplete,
        newBuildSearchesComplete,
      } = action.payload;
      const search = state[id] || {};
      return {
        ...state,
        [id]: {
          ...search,
          zooplaSearchComplete,
          landregSearchComplete,
          rmSearchComplete,
          planningSearchComplete,
          newBuildSearchesComplete,
        },
      };
    },
    setPropertyComparableRedux(state, { payload }) {
      return addRemoveComparableProperties(state, payload);
    },
    setSearchSortOrder(state, { payload }) {
      return setSortOrder(state, payload);
    },
    setSearchCustomName(state, { payload }) {
      return setCustomName(state, payload);
    },
    setShowTodayPrice(state, { payload }) {
      return setShowTodayPriceFunction(state, payload);
    },
    addDummySearch(state, action) {
      const { valuationId, valuationPropertyId } = action.payload;
      const search = {
        searchId: valuationId,
        name: "Valuation Dummy Search " + valuationId,
        propertyIds: [valuationPropertyId],
        rmSearchComplete: true,
        zooplaSearchComplete: true,
        landregSearchComplete: true,
        planningSearchComplete: true,
        newBuildSearchesComplete: 3,
        searchSortOrder: "cheapest",
        showTodayPrice: false,
      };
      return {
        ...state,
        [valuationId]: search,
      };
    },
  },
});

export const {
  setPropertyIds,
  setSearchDetails,
  setSearchCompleteStatuses,
  setSearchSortOrder,
  setSearchCustomName,
  setShowTodayPrice,
  setPropertyComparableRedux,
  addDummySearch,
} = searchSlice.actions;

export default searchSlice.reducer;
