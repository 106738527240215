import { getDistanceBetweenTwoPoints } from "./geocoding";
import { differenceInCalendarDays } from "date-fns";

const attributeWeighting = {
  location: { totalWeight: 0.3, maxDiff: 3 },
  date: { totalWeight: 0.1, maxDiff: 3650 },
  sqft: { totalWeight: 0.3, maxDiff: 0.5 },
  beds: { totalWeight: 0.2, maxDiff: 2 },
  price: { totalWeight: 0.1, maxDiff: 0.5 },
};

export const getComparableScore = (valuationProperty, comparable) => {
  let priceWeight = 0,
    bedsWeight = 0,
    sqftWeight = 0,
    dateWeight = 0,
    locationWeight = 0;

  // Handle difference in price
  if (
    comparable.price <
      valuationProperty.price * attributeWeighting.price.maxDiff ||
    comparable.price >
      valuationProperty.price * (attributeWeighting.price.maxDiff + 1)
  ) {
    // console.log("Price comparison out of bounds");
    priceWeight = 0;
  } else {
    const priceFraction =
      1 -
      Math.abs(comparable.price - valuationProperty.price) /
        (valuationProperty.price * attributeWeighting.price.maxDiff);
    priceWeight = attributeWeighting.price.totalWeight * priceFraction;
  }

  // Handle difference in sqft
  if (!comparable.sqft || comparable.sqft < 0) {
    sqftWeight = 0.1;
  } else if (
    comparable.sqft <
      valuationProperty.sqft * (1 - attributeWeighting.sqft.maxDiff) ||
    comparable.sqft >
      valuationProperty.sqft * (1 + attributeWeighting.sqft.maxDiff)
  ) {
    sqftWeight = 0;
  } else {
    const sqftFraction =
      1 -
      Math.abs(comparable.sqft - valuationProperty.sqft) /
        (valuationProperty.sqft * attributeWeighting.sqft.maxDiff);
    sqftWeight = attributeWeighting.sqft.totalWeight * sqftFraction;
  }

  const compBeds = comparable.beds
    ? comparable.beds
    : comparable.rmBeds
    ? comparable.rmBeds
    : comparable.zBeds;

  // Handle difference in beds
  if (!compBeds || typeof valuationProperty.beds === "undefined") {
    bedsWeight = attributeWeighting.beds.totalWeight * 0.5;
  } else {
    const bedsFraction =
      valuationProperty.beds >= 5 && compBeds >= 5
        ? 1
        : Math.max(
            1 -
              Math.abs(compBeds - valuationProperty.beds) /
                attributeWeighting.beds.maxDiff,
            0
          );
    bedsWeight = attributeWeighting.beds.totalWeight * bedsFraction;
  }

  // Handle difference in location
  const comparableDistance = getDistanceBetweenTwoPoints(
    comparable,
    valuationProperty
  );
  if (comparableDistance > attributeWeighting.location.maxDiff) {
    // console.log("Location comparison out of bounds");
  } else {
    const locationFraction =
      1 - comparableDistance / attributeWeighting.location.maxDiff;
    locationWeight = attributeWeighting.location.totalWeight * locationFraction;
  }

  // Handle difference in date

  const months = differenceInCalendarDays(
    new Date(valuationProperty.valuationDate),
    new Date(comparable.dateSold)
  );
  if (months > attributeWeighting.date.maxDiff) {
    // console.log(`Date more than ${attributeWeighting.date.maxDiff} days ago`);
  } else {
    const dateFraction = 1 - months / attributeWeighting.date.maxDiff;
    dateWeight = attributeWeighting.date.totalWeight * dateFraction;
  }

  const totalScore =
    locationWeight + dateWeight + sqftWeight + bedsWeight + priceWeight;

  // console.log(`...${comparable.address}...${Math.round(totalScore * 100)}%...`);
  // console.log("Location weight is " + Math.round(locationWeight * 100) + "%");
  // console.log("Date weight is " + Math.round(dateWeight * 100) + "%");
  // console.log("Sqft weighting is " + Math.round(sqftWeight * 100) + "%");
  // console.log("Beds weight is " + Math.round(bedsWeight * 100) + "%");
  // console.log("Price weighting is " + Math.round(priceWeight * 100) + "%");

  return totalScore;
};

export const getComparableGrade = (comparableScore) => {
  if (comparableScore > 0.8) {
    return "A";
  } else if (comparableScore > 0.6) {
    return "B";
  } else if (comparableScore > 0.4) {
    return "C";
  } else if (comparableScore > 0.2) {
    return "D";
  } else if (comparableScore > 0.1) {
    return "E";
  } else {
    return "F";
  }
};
export const getComparableGradeColor = (comparableScore) => {
  if (comparableScore > 0.8) {
    return "rgb(82, 202, 87)";
  } else if (comparableScore > 0.6) {
    return "rgb(97, 180, 137)";
  } else if (comparableScore > 0.4) {
    return "rgb(112, 158, 186)";
  } else if (comparableScore > 0.2) {
    return "rgb(128, 134, 242)";
  } else if (comparableScore > 0.1) {
    return "rgb(148, 105, 200)";
  } else {
    return "rgb(172, 67, 112)";
  }
};
