import { createTheme } from "@material-ui/core/styles";
import { theme } from "@landworth/landworth-theme";

const landworthTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    // primary: {
    //   main: "#35478C",
    //   contrastText: "#fff",
    // },
    secondary: {
      main: "#168039",
      contrastText: "#fff",
    },
  },
  overrides: {
    ...theme.overrides,
    MuiButton: {
      ...theme.overrides.MuiButton,
      sizeSmall: { fontWeight: "normal" },
    },
  },
});
export default landworthTheme;
