import { createSelector } from "reselect";

const EMPTY_SEARCH = {
  propertyIds: [],
};
const EMPTY_VALUATION = {
  valuationPropertyId: null,
};
const EMPTY_PROPERTY = {
  propertyID: null,
};

export const getSearchSelector = createSelector(
  ({ searches }) => searches,
  (_, searchId) => searchId,
  (searches, searchId) => {
    const search = searches[searchId];
    return search ? search : EMPTY_SEARCH;
  }
);
export const getPropertySelector = createSelector(
  ({ properties }) => properties,
  (_, propertyID) => propertyID,
  (properties, propertyID) => {
    const property = properties[propertyID];
    return property ? property : EMPTY_PROPERTY;
  }
);

export const getShowTodayPrice = createSelector(
  ({ settings }) => settings,
  (settings) => settings.showTodayPrice
);

export const getFilterKeyword = createSelector(
  ({ settings }) => settings,
  (settings) => settings.filterKeyword
);

export const getLocation = createSelector(
  ({ locations }) => locations, // locations slice
  (_, position) => `${position[0]}::${position[1]}`, // position key
  (locations, key) => locations[key] // location name for key
);

export const getValuationSelector = createSelector(
  ({ valuations }) => valuations,
  (_, valuationId) => valuationId,
  (valuations, valuationId) => {
    const valuation = valuations[valuationId];
    return valuation ? valuation : EMPTY_VALUATION;
  }
);
export const getValuationSelectorOrNull = createSelector(
  ({ valuations }) => valuations,
  (_, valuationId) => valuationId,
  (valuations, valuationId) => {
    const valuation = valuations[valuationId];
    return valuation ? valuation : null;
  }
);

export const getMapPopupInfo = createSelector(
  ({ map }) => map,
  (map) => map.popupInfo
);

const EMPTY_LOCATIONHISTORY = {
  data: "EMPTY",
};

export const getLocationHistorySelector = createSelector(
  ({ locationHistorys }) => locationHistorys,
  (_, locationHistoryId) => locationHistoryId,
  (locationHistorys, locationHistoryId) => {
    const locationHistory = locationHistorys[locationHistoryId];
    return locationHistory ? locationHistory : EMPTY_LOCATIONHISTORY;
  }
);

export const getCircleDetails = createSelector(
  ({ circle }) => circle,
  (circle) => circle
);

export const getCreditData = createSelector(
  ({ credits }) => credits,
  (credits) => credits
);
