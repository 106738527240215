import React from "react";
// import { heatmaps } from "./heatmap.definitions";
import { useLocalStorage } from "@rehooks/local-storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import HeatmapSelector from "../../components/HeatmapSelector";

const expandedHeight = "190px";
const collapsedHeight = "48px";

//prettier-ignore
const legendLabels = {
  off: {},
  landworth: {legendMin: "£100/ft²", legendLow: "£200/ft²", legendMid: "£400/ft²", legendHigh: "£600/ft²", legendMax: "£800/ft² +"},
  weight: {legendMin: "0", legendLow: "50", legendMid: "100", legendHigh: "150", legendMax: "200 +"},
  lwc1y: {legendMin: "-20%", legendLow: "-10%", legendMid: "0%", legendHigh: "+10%", legendMax: "+20%"},
  lwc3y: {legendMin: "-20%", legendLow: "-10%", legendMid: "0%", legendHigh: "+10%", legendMax: "+20%"},
  sc1y: {legendMin: "-20%", legendLow: "-10%", legendMid: "0%", legendHigh: "+10%", legendMax: "+20%"},
  sc3y: {legendMin: "-20%", legendLow: "-10%", legendMid: "0%", legendHigh: "+10%", legendMax: "+20%"},
  m: {legendMin: "-20%", legendLow: "-10%", legendMid: "0%", legendHigh: "+10%", legendMax: "+20%"},
};

const renderListColors = (heatmap) => {
  //prettier-ignore
  const legendColorsRBG = ["#c22323","#c12527","#c0262b","#bf282e","#be2932","#bd2b36","#bc2d3a","#ba2e3e","#b93042","#b83245","#b73349","#b6354d","#b53651","#b43855","#b33a59","#b23b5c","#b13d60","#b03e64","#af4068","#ae426c","#ac4370","#ab4573","#aa4777","#a9487b","#a84a7f","#a74b83","#a64d86","#a54f8a","#a4508e","#a35292","#a25496","#a1559a","#9f579d","#9e58a1","#9d5aa5","#9c5ca9","#9b5dad","#9a5fb1","#9960b4","#9862b8","#9764bc","#9665c0","#9567c4","#9469c8","#936acb","#916ccf","#906dd3","#8f6fd7","#8e71db","#8d72de","#8c74e2","#8b75e6","#8a77ea","#8979ee","#887af2","#877cf5","#867ef9","#857ffd","#8481fe","#8382fb","#8283f8","#8185f5","#8086f2","#7f87ef","#7e88ec","#7d8ae9","#7d8be6","#7c8ce3","#7b8ee0","#7a8fdd","#7990da","#7891d8","#7793d5","#7794d2","#7695cf","#7597cc","#7498c9","#7399c6","#729ac3","#719cc0","#709dbd","#709eba","#6fa0b7","#6ea1b4","#6da2b2","#6ca3af","#6ba5ac","#6aa6a9","#69a7a6","#69a9a3","#68aaa0","#67ab9d","#66ac9a","#65ae97","#64af94","#63b091","#63b28f","#62b38c","#61b489","#60b586","#5fb783","#5eb880","#5db97d","#5cbb7a","#5cbc77","#5bbd74","#5abe71","#59c06e","#58c16b","#57c269","#56c466","#55c563","#55c660","#54c75d","#53c95a","#52ca57",];
  //prettier-ignore
  const legendColorsGOR = ["#359b2d","#3ca135","#43a83c","#4aae44","#51b54b","#58bb53","#5fc15b","#66c862","#6dce6a","#74d472","#7bdb79","#82e181","#89e888","#90ee90","#97ef8a","#9ef084","#a4f17e","#abf278","#b2f372","#b9f46c","#bff567","#c6f661","#cdf75b","#d4f855","#daf94f","#e1fa49","#e8fb43","#eaf83e","#ecf539","#edf234","#efef2e","#f1ec29","#f3e924","#f4e51f","#f6e21a","#f8df15","#fadc0f","#fbd90a","#fdd605","#ffd300","#ffd300","#ffce00","#ffc900","#ffc400","#ffbf00","#ffba00","#ffb500","#ffb000","#ffab00","#ffa600","#ffa100","#ff9c00","#ff9700","#ff9200","#ff8d00","#ff8600","#ff7d00","#ff7300","#ff6900","#ff6000","#ff5600","#ff4d00","#ff4300","#ff3a00","#ff3000","#ff2600","#ff1d00","#ff1300","#ff0a00","#ff0000",];
  //prettier-ignore
  const legendColorsGWR = ["#177700","#1b7904","#1f7c09","#237e0d","#278012","#2b8316","#2f851b","#33881f","#378a23","#3b8c28","#3f8f2c","#439131","#479335","#4b963a","#4f983e","#549a43","#589d47","#5c9f4b","#60a250","#64a454","#68a659","#6ca95d","#70ab62","#74ad66","#78b06a","#7cb26f","#80b473","#84b778","#88b97c","#8cbc81","#90be85","#94c089","#98c38e","#9cc592","#a0c797","#a4ca9b","#a8cca0","#accfa4","#b0d1a9","#b4d3ad","#b8d6b1","#bcd8b6","#c0daba","#c4ddbf","#c9dfc3","#cde1c8","#d1e4cc","#d5e6d0","#d9e9d5","#ddebd9","#e1edde","#e5f0e2","#e9f2e7","#edf4eb","#f1f7ef","#f5f9f4","#f9fbf8","#fdfefd","#fefdfd","#fdf8f8","#fbf4f4","#faefef","#f8ebeb","#f7e7e7","#f5e2e2","#f4dede","#f2d9d9","#f1d5d5","#efd0d0","#eecccc","#edc8c8","#ebc3c3","#eabfbf","#e8baba","#e7b6b6","#e5b1b1","#e4adad","#e2a9a9","#e1a4a4","#dfa0a0","#de9b9b","#dc9797","#db9292","#d98e8e","#d88989","#d68585","#d58181","#d37c7c","#d27878","#d07373","#cf6f6f","#cd6a6a","#cc6666","#cb6262","#c95d5d","#c85959","#c65454","#c55050","#c34b4b","#c24747","#c04343","#bf3e3e","#bd3a3a","#bc3535","#ba3131","#b92c2c","#b72828","#b62323","#b41f1f","#b31b1b","#b11616","#b01212","#ae0d0d","#ad0909","#ab0404","#aa0000",];

  let legendColors = [];

  if (heatmap === "landworth" || heatmap === "weight") {
    legendColors = legendColorsRBG;
  } else if (heatmap === "m") {
    legendColors = legendColorsGOR;
  } else {
    legendColors = legendColorsGWR;
  }

  return legendColors.map((color) => {
    return <li key={color} style={{ backgroundColor: color }}></li>;
  });
};

const renderHeaderColorbar = (selectedHeatmap, size) => {
  return (
    <div className="legend-vertical-colors">
      <ul className={"colorbar-" + size}>
        {renderListColors(selectedHeatmap)}
      </ul>
    </div>
  );
};

const MapLegend = ({
  selectedHeatmap,
  setSelectedHeatmap,
  setShowCircle,
  showCircle,
  heatmapRange,
}) => {
  const [expanded, setExpanded] = useLocalStorage("legend_expanded", false);
  // const heatmapJson = heatmaps.find(({ name }) => name === selectedHeatmap);

  const setLegendExpansion = (newState) => {
    window.analytics.track(newState ? "Expand Legend" : "Collapse Legend");
    setExpanded(newState);
  };

  const legendMax =
    heatmapRange && selectedHeatmap === "landworth" ? (
      <>
        <FontAwesomeIcon icon={solid("square-arrow-up")} />
        &nbsp;£{Math.round(heatmapRange[2])}/ft²
      </>
    ) : (
      legendLabels[selectedHeatmap].legendMax
    );
  const legendHigh =
    heatmapRange && selectedHeatmap === "landworth" ? (
      <>&nbsp;</>
    ) : (
      legendLabels[selectedHeatmap].legendHigh
    );
  const legendMid =
    heatmapRange && selectedHeatmap === "landworth" ? (
      <>
        <FontAwesomeIcon icon={solid("square-arrow-left")} />
        &nbsp;£{Math.round(heatmapRange[1])}/ft²
      </>
    ) : (
      legendLabels[selectedHeatmap].legendMid
    );
  const legendLow =
    heatmapRange && selectedHeatmap === "landworth" ? (
      <>&nbsp;</>
    ) : (
      legendLabels[selectedHeatmap].legendLow
    );
  const legendMin =
    heatmapRange && selectedHeatmap === "landworth" ? (
      <>
        <FontAwesomeIcon icon={solid("square-arrow-down")} />
        &nbsp;£{Math.round(heatmapRange[0])}/ft²
      </>
    ) : (
      legendLabels[selectedHeatmap].legendMin
    );

  return (
    <div
      className="map-legend"
      style={{ height: expanded ? expandedHeight : collapsedHeight }}
    >
      <div className="legend-vertical">
        <div
          className="legend-vertical-title"
          onClick={() => setLegendExpansion(!expanded)}
          style={{
            marginTop: expanded ? "2px" : "7px",
          }}
        >
          {!expanded &&
            selectedHeatmap !== "off" &&
            renderHeaderColorbar(selectedHeatmap, "small")}
          <span>
            {expanded ? (
              <div style={{ fontWeight: "bold !important" }}>
                <HeatmapSelector
                  selectedHeatmap={selectedHeatmap}
                  setSelectedHeatmap={setSelectedHeatmap}
                  showCircle={showCircle}
                  setShowCircle={setShowCircle}
                />
              </div>
            ) : (
              "Legend"
            )}
          </span>{" "}
          {expanded ? (
            <FontAwesomeIcon
              icon={["far", "angle-double-down"]}
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={["far", "angle-double-up"]}
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
          )}
        </div>

        {expanded && selectedHeatmap !== "off" && (
          <div className="legend-vertical-inner-container">
            {renderHeaderColorbar(selectedHeatmap, "large")}
            <div className="legend-vertical-value-labels">
              <div className="legend-vertical-label max">{legendMax}</div>
              <div className="legend-vertical-label">{legendHigh}</div>
              <div className="legend-vertical-label">{legendMid}</div>
              <div className="legend-vertical-label">{legendLow}</div>
              <div className="legend-vertical-label">{legendMin}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapLegend;
