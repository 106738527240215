import { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Auth } from "aws-amplify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const templateId = "template_m0m0bq7";

const EmailSentPending = () => <div className="loader-small"></div>;
const EmailSentConfirmation = () => (
  <div style={{ textAlign: "center" }}>
    <b>Message sent! We aim to respond to all enquiries within 24 hours</b>
  </div>
);

const EmailUsWidget = () => {
  const [emailMessage, setEmailMessage] = useState("");
  const [emailMessageError, setEmailMessageError] = useState(null);
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setName(user.attributes.name);
      setEmail(user.attributes.email);
    });
  }, []);

  const sendFeedback = () => {
    setEmailSending(true);
    const variables = {
      message: emailMessage,
      from_name: name,
      from_email: email,
      datetime: new Date(),
    };
    window.emailjs
      .send("service_fb4wiwq", templateId, variables)
      .then((res) => {
        console.log("Email successfully sent!");
        setEmailSending(false);
        setEmailSent(true);
      })
      .catch((err) => console.error("Email sending failed:", err));
  };

  const handleSubmit = () => {
    if (emailMessage.length <= 5)
      setEmailMessageError("Your message is too short!");
    else {
      setEmailMessageError(null);
      sendFeedback();
    }
  };

  return (
    <>
      <form
        style={{
          width: "40%",
          minWidth: "250px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "0.8em",
        }}
      >
        {/* <TextField
          id="name"
          label="From"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br /> */}
        {!emailSent && (
          <TextField
            id="email-message"
            label="Type your message here"
            multiline
            rows={4}
            value={emailMessage}
            disabled={emailSending}
            onChange={(e) => setEmailMessage(e.target.value)}
            variant="outlined"
            style={{ width: "100%", height: "120px" }}
            error={!!emailMessageError}
            helperText={emailMessageError}
          />
        )}
        {!emailSending && !emailSent && (
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={handleSubmit}
            style={{ fontSize: "15px", marginTop: "20px" }}
          >
            Send Message&nbsp;&nbsp;
            <FontAwesomeIcon
              icon={["far", "envelope-open-text"]}
              style={{ fontSize: "18px" }}
            />
          </Button>
        )}
      </form>
      <br />
      {emailSending && <EmailSentPending />}
      {emailSent && <EmailSentConfirmation />}
    </>
  );
};

export default EmailUsWidget;
