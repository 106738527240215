import { useState, useEffect, useCallback, useMemo } from "react";

import { useRouteMatch } from "react-router-dom";

import useValuation from "data/hooks/useValuation";
import useSearch from "data/hooks/useSearch";
import useSearchResults from "data/hooks/useSearchResults";
import usePropertyData from "data/hooks/usePropertyData";

import { Pages } from "@landworth/fiverr-chiran-landworth";

import { setValuationPrice as setValuationPriceDDB } from "graphql/functions";
import { setValuationPriceRedux } from "../../data/slices/valuationSlice";

import Heatmap from "pages/Map/Heatmap";
import Basemap from "pages/Map/Basemap";
import ValuationReportSearch from "./SearchTabs/ValuationReportSearch";
import { useSelector, useDispatch } from "react-redux";
import { getSearchSelector } from "data/selectors";

import { runValuationSearches, fetchPpsfChecker } from "graphql/functions";
import { setValuationSearchesRedux } from "data/slices/valuationSlice";
import { setFilterKeyword as setFilterKeywordAction } from "app/settingsSlice";
import useLocationHistory from "data/hooks/useLocationHistory";
import { getValuationSelector } from "../../data/selectors";
import { getCreditData as getCreditDataRedux } from "data/selectors";

import MapLegend from "pages/Map/MapLegend";

const ValuationReportHolder = (props) => {
  const { isLoggedIn, history } = props;
  const dispatch = useDispatch();

  // Grab valuationId from URL and load valuation data to redux
  const matchParams = useRouteMatch("/report/:valuationId")?.params;
  const valuationId = matchParams?.valuationId;
  // fetch valuation and run dummy search
  useValuation(valuationId);

  // When dummy search has been created, redux is filled with valuation & search data
  // Now trigger usePropertyData hook to download property data for dummy search, then get the valuation property itself
  const dummyValuationSearch = useSelector((state) =>
    getSearchSelector(state, valuationId)
  );
  // cache hits
  const valuation = useSelector((state) =>
    getValuationSelector(state, valuationId)
  );

  useEffect(() => {
    document.title =
      "Landworth: Valuation Report for " + valuation.valuationName;
  }, [valuation]);

  // Populate redux with PropertyData from the backend
  usePropertyData(valuationId, dummyValuationSearch.propertyIds.length > 0);
  const [[property]] = useSearchResults(valuationId);

  const setReduxFilterKeyword = useCallback(
    (newFilterKeyword) => dispatch(setFilterKeywordAction(newFilterKeyword)),
    [dispatch]
  );

  const runAllSearches = async (confirmedPrice) => {
    if (isLoggedIn) {
      const newSearchIdsResponse = await runValuationSearches(
        valuation.valuationId,
        valuation.valuationName,
        property.lat,
        property.lng,
        property.propertyType,
        property.beds,
        confirmedPrice
      );

      const newSearchIds = newSearchIdsResponse.searchIds;

      // setActiveTabByKey("sold");
      dispatch(
        setValuationSearchesRedux({
          id: valuation.valuationId,
          ...newSearchIds,
        })
      );
      setReduxFilterKeyword("");
      // history.push(`${history.location.pathname}/`);
    } else {
      history.push(`/join/report_${valuation.valuationId}`);
    }
  };

  const setPrice = async (price, priceSource) => {
    console.log("Setting price to " + price);
    window.analytics.track("Valuation - Accept Price", {
      price,
      priceSource,
    });

    const setValuationPriceLocal = () => {
      dispatch(
        setValuationPriceRedux({
          id: valuation.valuationId,
          valuationPrice: price,
          valuationPriceSource: priceSource,
        })
      );
    };

    setValuationPriceDDB(valuationId, price, priceSource);
    // subscribeToValuation(valuationId, trigger);
    setValuationPriceLocal();

    if (!valuation.soldSearchId) runAllSearches(price);
  };

  const acceptValuation = useCallback((condition) => {
    console.log("Valuation Accepted - Condition: " + condition);

    const valuationPrice =
      condition === 1
        ? Math.round((property.landworth - property.lwsd / 2) * property.sqft)
        : condition === 2
        ? Math.round((property.landworth - property.lwsd / 4) * property.sqft)
        : condition === 3
        ? Math.round(property.landworth * property.sqft)
        : condition === 4
        ? Math.round((property.landworth + property.lwsd / 4) * property.sqft)
        : Math.round((property.landworth + property.lwsd / 2) * property.sqft);
    const valuationPriceSource =
      condition === 1
        ? "ppsflow"
        : condition === 2
        ? "ppsflowavg"
        : condition === 3
        ? "ppsfavg"
        : condition === 4
        ? "ppsfavghigh"
        : "ppsfhigh";
    setPrice(valuationPrice, valuationPriceSource);
  });

  const [, soldSearchFinished] = useSearch(valuation?.soldSearchId); // Populates redux with SearchData
  const [, forsaleSearchFinished] = useSearch(valuation?.forsaleSearchId); // Populates redux with SearchData
  const [, rentalSearchFinished] = useSearch(valuation?.rentalSearchId); // Populates redux with SearchData
  const [, planningSearchFinished] = useSearch(valuation?.planningSearchId); // Populates redux with SearchData
  const [, newBuildSearchFinished] = useSearch(valuation?.newBuildSearchId); // Populates redux with SearchData

  usePropertyData(valuation?.soldSearchId, soldSearchFinished);
  usePropertyData(valuation?.forsaleSearchId, forsaleSearchFinished);
  usePropertyData(valuation?.rentalSearchId, rentalSearchFinished);
  usePropertyData(valuation?.planningSearchId, planningSearchFinished);
  usePropertyData(valuation?.newBuildSearchId, newBuildSearchFinished);

  const soldSearch = useSelector((state) =>
    getSearchSelector(state, valuation?.soldSearchId)
  );
  const forsaleSearch = useSelector((state) =>
    getSearchSelector(state, valuation?.forsaleSearchId)
  );
  const rentalSearch = useSelector((state) =>
    getSearchSelector(state, valuation?.rentalSearchId)
  );
  const planningSearch = useSelector((state) =>
    getSearchSelector(state, valuation?.planningSearchId)
  );
  const newBuildSearch = useSelector((state) =>
    getSearchSelector(state, valuation?.newBuildSearchId)
  );
  //   const [soldProperties] = useSearchResults(valuation?.soldSearchId);
  //   const [forsaleProperties] = useSearchResults(valuation?.forsaleSearchId);
  //   const [rentalProperties] = useSearchResults(valuation?.rentalSearchId);

  const soldResults = useMemo(() => {
    return soldSearch?.propertyIds.length > 0
      ? soldSearch?.propertyIds.length
      : soldSearch.rmSearchComplete &&
        soldSearch.zooplaSearchComplete &&
        soldSearch.landregSearchComplete
      ? -1
      : 0;
  }, [soldSearch]);
  const forsaleResults = useMemo(() => {
    return forsaleSearch?.propertyIds.length > 0
      ? forsaleSearch?.propertyIds.length
      : forsaleSearch.rmSearchComplete &&
        forsaleSearch.zooplaSearchComplete &&
        forsaleSearch.landregSearchComplete
      ? -1
      : 0;
  }, [forsaleSearch]);
  const rentalResults = useMemo(() => {
    return rentalSearch?.propertyIds.length > 0
      ? rentalSearch?.propertyIds.length
      : rentalSearch.rmSearchComplete &&
        rentalSearch.zooplaSearchComplete &&
        rentalSearch.landregSearchComplete
      ? -1
      : 0;
  }, [rentalSearch]);
  const planningResults = useMemo(() => {
    return planningSearch?.propertyIds.length > 0
      ? planningSearch?.propertyIds.length
      : planningSearch.planningSearchComplete
      ? -1
      : 0;
  }, [planningSearch]);
  const newBuildResults = useMemo(() => {
    return newBuildSearch?.propertyIds.length > 0
      ? newBuildSearch?.propertyIds.length
      : newBuildSearch.newBuildSearchesComplete >= 2
      ? -1
      : 0;
  }, [newBuildSearch]);

  const startDate = property?.dateRun
    ? property?.dateRun.slice(0, 10)
    : valuation?.valuationDate?.slice(0, 8) + "01";

  // Generate Chart data
  const [locationHistoryPriceData, locationHistorySalesData] =
    useLocationHistory(
      "latlng",
      JSON.stringify({
        lat: property?.lat,
        long: property?.lng,
        type: property?.propertyTypeParent + "s",
      }),
      startDate,
      15,
      !!property // Stops the hook from running if property doesn't exist - workaround as cannot trigger hook conditionally
    );

  const isHistoryLoaded =
    property?.zHistoryURL &&
    property?.rmHistoryURL &&
    (property?.lrSoldPriceHistoryURL || property?.lrSoldPriceHistory);

  const [heatmapRange, setHeatmapRange] = useState(null);
  const updateDynamicHeatmap = async (center, circleRadius) => {
    if (!!center[0]) {
      const rangeData = await fetchPpsfChecker(
        { lng: center[0], lat: center[1] },
        circleRadius
      );
      const data = rangeData && JSON.parse(rangeData);
      const newRange = [data.min, data.avg, data.max];
      setHeatmapRange(newRange);
    }
  };

  useEffect(() => {
    updateDynamicHeatmap([property?.lng, property?.lat], 750);
  }, [property]);

  useEffect(() => {
    window.analytics.track("Load Valuation Report", {
      valuationId: valuationId,
      valuationName: valuation.valuationName,
    });
  }, []);

  const creditData = useSelector((state) => getCreditDataRedux(state));

  const updateValuationTitle = (title, valuationId) => {
    console.log("NEW TITLE: ", title);
    console.log("Valuation ID: ", valuationId);
  };
  const updateValuationDescription = (description, valuationId) => {
    console.log("NEW DESCRIPTION: ", description);
    console.log("Valuation ID: ", valuationId);
  };
  const updateValuationPrice = (price, valuationId) => {
    console.log("NEW PRICE SOURCE: ", price);
    console.log("Valuation ID: ", valuationId);
  };
  const updateValuationPriceSource = (priceSource, valuationId) => {
    console.log("NEW PRICE SOURCE: ", priceSource);
    console.log("Valuation ID: ", valuationId);
  };

  const isBetaTester = useMemo(
    () =>
      creditData.orgId === "landworth.org" ||
      creditData.orgId === "5NtRq07U" || // Mel White
      creditData.orgId === "pivotfinance.co.uk" ||
      creditData.orgId === "goldentreefs.co.uk" ||
      creditData.orgId === "octopus.realestate" ||
      creditData.orgId === "kuflink.com" ||
      creditData.orgId === "Fd3ebiQW" || // Rosey Cassidy
      creditData.orgId === "1j0uTmxi" || // Jed Shamel
      creditData.orgId === "0yz2bmKz" || // Wayne Dennett
      creditData.orgId === "NedqfLuB" || // Solomon New Homes
      creditData.orgId === "yRofa1K4" || // Harry Stephenson Clarke
      creditData.orgId === "u2GGSuXF" ||
      creditData.orgId === "jsQ7aiEM" ||
      creditData.orgId === "tpx8BPMd" || // Richt - Zuricbts@gmail.com
      creditData.orgId === "PVWIsARD" || // Dave - Zuricbts2@gmail.com
      creditData.orgId === "Qj0R8QjS" || // Ian Humphreys - Brickflow
      creditData.orgId === "5CfgcMJB" || // Kevin Cooper - PropertyDealNetwork - kevincooperuk@gmail.com
      creditData.orgId === "NuCJx6Jv" || // Philip Haines - Proma
      creditData.orgId === "p3SbIWl0"
        ? true
        : false,
    [creditData.orgId]
  );

  const profileData = useMemo(
    () =>
      creditData.orgId === "landworth.org"
        ? {
            profileName: "Paul Chapman",
            profileImage:
              "https://s3.eu-west-1.amazonaws.com/assets.landworth.org/images/paul_chapman_headshot.jpg",
            profileLogo:
              "https://assets-global.website-files.com/62ee00331cef28178b143335/63084a2072ead4bcd246c1d8_LANDWORTH-logo-v2-h140-p-500.png",
          }
        : creditData.orgId === "5NtRq07U"
        ? {
            profileName: "Mel White",
            profileImage:
              "https://s3.eu-west-1.amazonaws.com/assets.landworth.org/images/mel_white_headshot.jpg",
            profileLogo:
              "https://assets-global.website-files.com/62ee00331cef28178b143335/63084a2072ead4bcd246c1d8_LANDWORTH-logo-v2-h140-p-500.png",
          }
        : creditData.orgId === "pivotfinance.co.uk"
        ? {
            profileName: "Pivot Finance",
          }
        : creditData.orgId === "goldentreefs.co.uk"
        ? {
            profileName: "Golden Tree Financial Services",
          }
        : creditData.orgId === "octopus.realestate"
        ? {
            profileName: "Octopus Real Estate",
          }
        : creditData.orgId === "kuflink.com"
        ? {
            profileName: "Kuflink",
          }
        : creditData.orgId === "Fd3ebiQW"
        ? {
            profileName: "Rosey Cassidy",
          }
        : creditData.orgId === "1j0uTmxi"
        ? {
            profileName: "Jed Shamel",
          }
        : creditData.orgId === "0yz2bmKz"
        ? {
            profileName: "Wayne Dennett",
          }
        : creditData.orgId === "NedqfLuB"
        ? {
            profileName: "Solomon New Homes",
          }
        : creditData.orgId === "yRofa1K4"
        ? {
            profileName: "Harry Stephenson Clarke",
          }
        : creditData.orgId === "Qj0R8QjS"
        ? {
            profileName: "Ian Humphreys",
          }
        : creditData.orgId === "5CfgcMJB"
        ? {
            profileName: "Kevin Cooper",
          }
        : creditData.orgId === "NuCJx6Jv"
        ? {
            profileName: "Philip Haines",
          }
        : // : creditData.orgId === "u2GGSuXF"
          // ? {
          //     profileName: "Kuflink",
          //   }
          // : creditData.orgId === "jsQ7aiEM"
          // ? {
          //     profileName: "Kuflink",
          //   }
          // : creditData.orgId === "p3SbIWl0"
          // ? {
          //     profileName: "Kuflink",
          //   }
          {},
    [creditData.orgId]
  );

  return property ? (
    <>
      <Pages.ValuationReport
        betaTester={isBetaTester}
        profileData={profileData}
        property={property}
        valuation={valuation}
        chartDataPrice={locationHistoryPriceData}
        chartDataSales={locationHistorySalesData}
        acceptValuation={acceptValuation}
        isHistoryLoaded={isHistoryLoaded}
        heatmapLayer={
          <Heatmap
            selectedHeatmap={"landworth"}
            selectedPerspective={"2D"}
            heatmapRange={heatmapRange}
          />
        }
        basemapLayer={<Basemap selectedBasemap={"hybrid"} />}
        tabContent={[
          { content: <div>LOCATION HISTORY GRAPHS</div> },
          {
            results: soldResults,
            searchId: valuation.soldSearchId,
            tabType: "sold",
            content: (
              <ValuationReportSearch
                searchId={valuation.soldSearchId}
                valuationData={valuation}
                valuationProperty={property}
                searchType={"sold"}
              />
            ),
          },
          {
            results: forsaleResults,
            searchId: valuation.forsaleSearchId,
            tabType: "forsale",
            content: (
              <ValuationReportSearch
                searchId={valuation.forsaleSearchId}
                valuationData={valuation}
                searchType={"forsale"}
              />
            ),
          },
          {
            results: rentalResults,
            searchId: valuation.rentalSearchId,
            tabType: "rental",
            content: (
              <ValuationReportSearch
                searchId={valuation.rentalSearchId}
                valuationData={valuation}
                searchType={"rental"}
              />
            ),
          },
          // Only show planning tab if there is no planning search Id, but all other search Ids exist
          !(
            valuation.soldSearchId &&
            valuation.forsaleSearchId &&
            valuation.rentalSearchId &&
            !valuation.newBuildSearchId
          )
            ? {
                results: newBuildResults,
                searchId: valuation.newBuildSearchId,
                content: (
                  <ValuationReportSearch
                    searchId={valuation.newBuildSearchId}
                    valuationData={valuation}
                    valuationProperty={property}
                    searchType={"newbuild"}
                  />
                ),
              }
            : { results: -99, content: <div>NEW MISSING</div> },
          !(
            // Only show planning tab if there is no planning search Id, but all other search Ids exist
            (
              valuation.soldSearchId &&
              valuation.forsaleSearchId &&
              valuation.rentalSearchId &&
              !valuation.planningSearchId
            )
          )
            ? {
                results: planningResults,
                searchId: valuation.planningSearchId,
                content: (
                  <ValuationReportSearch
                    searchId={valuation.planningSearchId}
                    valuationData={valuation}
                    searchType={"planning"}
                  />
                ),
              }
            : { results: -99, content: <div>PLANNING MISSING</div> },
        ]}
        // legend={
        //   <MapLegend
        //     selectedHeatmap={"landworth"}
        //     // setSelectedHeatmap={setSelectedHeatmap}
        //     // selectedPerspective={selectedPerspective}
        //     // showCircle={circle.visible}
        //     heatmapRange={heatmapRange}
        //     // setShowCircle={toggleCircle}
        //   />
        // }
        updateValuationTitle={updateValuationTitle}
        updateValuationDescription={updateValuationDescription}
        updateValuationPrice={updateValuationPrice}
        updateValuationPriceSource={updateValuationPriceSource}
      />
    </>
  ) : (
    <div className="flex-center-center" style={{ height: "100%" }}>
      <div className="loader-big"></div>
    </div>
  );
};

export default ValuationReportHolder;
