import { Source, Layer } from "react-mapbox-gl";

import {
  LINE_ATTRIBS,
  FILL_ATTRIBS,
  LABEL_ATTRIBS,
  LABEL_LAYOUT,
} from "./defaults";

const BOROUGH_LINES_SOURCE = {
  type: "vector",
  url: `https://api.mapbox.com/v4/prchapman.8khk7x0t.json?secure&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
};

const BoroughOutlines = () => {
  return (
    <>
      <Source id="borough-source" tileJsonSource={BOROUGH_LINES_SOURCE} />
      <Layer
        id="borough-lines"
        type="line"
        sourceId="borough-source"
        sourceLayer="district_borough_unitary_regi-bmfp5y"
        paint={LINE_ATTRIBS}
      />
      <Layer
        id="borough-fill"
        type="fill"
        sourceId="borough-source"
        sourceLayer="district_borough_unitary_regi-bmfp5y"
        paint={FILL_ATTRIBS}
        before="settlement-subdivision-label"
      />
      <Layer
        id="borough-labels"
        type="symbol"
        sourceId="borough-source"
        sourceLayer="district_borough_unitary_regi-bmfp5y"
        paint={LABEL_ATTRIBS}
        layout={LABEL_LAYOUT("NAME")}
        // before="settlement-subdivision-label"
      />
    </>
  );
};

export default BoroughOutlines;
