import { createSlice } from "@reduxjs/toolkit";

const locationHistorySlice = createSlice({
  name: "locationHistory",
  initialState: {},
  reducers: {
    setLocationHistoryDataRedux(state, action) {
      const {
        locationHistoryId,
        locationHistoryPriceData,
        locationHistorySalesData,
      } = action.payload;
      const locationHistory = state[locationHistoryId] || {};
      return {
        ...state,
        [locationHistoryId]: {
          ...locationHistory,
          locationHistoryPriceData,
          locationHistorySalesData,
        },
      };
    },
  },
});

export const { setLocationHistoryDataRedux } = locationHistorySlice.actions;

export default locationHistorySlice.reducer;
