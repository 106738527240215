import React, { useState, useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles({
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "24px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  flexCenterCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
  },
  imagePreview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
  },
  profilePicture: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "8px",
  },
});

const UserAccountSettings = () => {
  const classes = useStyles();
  const [name, setName] = useState("Paul");
  const [companyName, setCompanyName] = useState("Landworth");
  const [ppsfPpsm, setPpsfPpsm] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("userAccountSettings"));
    if (savedData) {
      setName(savedData.name);
      setCompanyName(savedData.companyName);
      setPpsfPpsm(savedData.ppsfPpsm);
      setProfilePictureURL(savedData.profilePictureURL);
    }
  }, []);

  useEffect(() => {
    const saveData = () => {
      const dataToSave = {
        name,
        companyName,
        ppsfPpsm,
        profilePictureURL,
      };
      localStorage.setItem("userAccountSettings", JSON.stringify(dataToSave));
      console.log("Saved data to local storage: ", dataToSave);
    };

    const timer = setTimeout(saveData, 1000);
    return () => clearTimeout(timer);
  }, [name, companyName, ppsfPpsm, profilePictureURL]);

  const handleNameChange = (e) => setName(e.target.value);
  const handleCompanyNameChange = (e) => setCompanyName(e.target.value);
  const handlePpsfPpsmChange = (e) => setPpsfPpsm(e.target.checked);
  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
    setProfilePictureURL(URL.createObjectURL(e.target.files[0]));
  };
  const handleDeleteProfilePicture = () => {
    setProfilePicture(null);
    setProfilePictureURL(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !companyName) {
      alert("Name and Company Name are required.");
      return;
    }
    // Submit your updated information to the backend
  };

  return (
    <div className={classes.flexCenterCenter}>
      <div className={classes.container}>
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: "24px" }}
        >
          Account Settings
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            value={name}
            onChange={handleNameChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Company Name"
            value={companyName}
            onChange={handleCompanyNameChange}
            fullWidth
            margin="normal"
            required
          />
          <div className={classes.toggleContainer}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: ppsfPpsm ? "normal" : "bold" }}
            >
              PPSF
            </Typography>
            <Switch
              checked={ppsfPpsm}
              onChange={handlePpsfPpsmChange}
              color="primary"
            />
            <Typography
              variant="subtitle1"
              style={{ fontWeight: ppsfPpsm ? "bold" : "normal" }}
            >
              PPSM
            </Typography>
          </div>
          <Typography variant="subtitle1">Profile Picture:</Typography>
          <input
            type="file"
            accept="image/*"
            onChange={handleProfilePictureChange}
            style={{ marginBottom: "8px" }}
          />
          <div className={classes.imagePreview}>
            {profilePictureURL ? (
              <img
                src={profilePictureURL}
                alt="Profile"
                className={classes.profilePicture}
              />
            ) : (
              <AccountCircleIcon
                className={classes.profilePicture}
                color="disabled"
              />
            )}
          </div>
          {profilePicture && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteProfilePicture}
              style={{ marginBottom: "16px" }}
            >
              Delete Profile Picture
            </Button>
          )}
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "16px" }}
            disabled={!name || !companyName}
          >
            Save Changes
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UserAccountSettings;
