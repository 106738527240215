import { useEffect } from "react";
import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const MenuItem = withStyles({
  root: {
    justifyContent: "center",
  },
})(MuiMenuItem);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    lineHeight: "1.43",
  },
  input: {
    // borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    fontWeight: "bold",
    padding: "0px 3px 0px 6px",
    fontSize: "13px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

const SearchRadiusDropdown = (props) => {
  const { searchradius, setFieldValue, radiusMarks, secretClick } = props;

  if (
    !!searchradius &&
    radiusMarks.filter((mark) => searchradius === mark.value).length === 0
  ) {
    radiusMarks.push({
      value: searchradius,
      dropdownLabel: `${(searchradius / 1609).toFixed(2)} mile`,
    });
    radiusMarks.sort((a, b) => a.value - b.value);
  }

  return (
    <div className={"search-form-item search-form-radius"}>
      <div
        className={"search-form-label"}
        style={{ marginRight: "10px", marginTop: "0px" }}
        onClick={secretClick}
      >
        <FontAwesomeIcon icon={regular("location-crosshairs")} />
        &nbsp;&nbsp;&nbsp;Search Radius
      </div>

      <Select
        name="search_radius"
        id="search_radius"
        value={searchradius || 402}
        input={<BootstrapInput />}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue) {
            setFieldValue("searchradius", newValue);
          }
        }}
      >
        {radiusMarks.map((radius) => (
          <MenuItem key={radius.value} value={radius.value} dense>
            {radius.dropdownLabel}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SearchRadiusDropdown;
