import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import style from "./planningItem.module.scss";
import GoogleStreetViewThumbnail from "components/GoogleStreetViewThumbnail";
import PlanningFullText from "components/tooltips/PlanningFullText";
import TagsSection from "../ResponsiveSearchResult/TagsSection";
import {
  PlanningStatusLarge,
  PlanningStatusSmall,
} from "components/planning/PlanningStatus";
import useLocalStorage from "@rehooks/local-storage";

const PlanningAppIDLink = (props) => {
  const { planningAppID, pageURL, mobile } = props;

  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={style.lastCell}
      onClick={(e) => {
        e.stopPropagation();
        window.analytics.track("Clicked Planning AppID Link", {
          planningAppID,
        });
        window.open(pageURL, "_blank");
      }}
    >
      <div className="flex-center-center">
        {!mobile && (
          <FontAwesomeIcon
            icon={regular("arrow-up-right-from-square")}
            style={{
              fontSize: "1.3em",
              marginRight: "5px",
              marginBottom: "2px",
            }}
          />
        )}

        <span>{planningAppID}</span>
      </div>
    </a>
  );
};

const PlanningItem = ({
  isSmallWidth,
  isMediumWidth,
  isLargeWidth,
  status,
  lat,
  lng,
  address,
  tags = [],
  date,
  description,
  planningAppID,
  numDocuments,
  planningDocsURL,
  pageURL,
  appSize,
  numDwellings,
  property,
}) => {
  const mobile = useMediaQuery("(max-width:960px)");
  const [viewType, setViewType] = useLocalStorage("result_style");

  const PlanningDocsLink = (props) => (
    <div className={style.planningDocs}>
      <a
        className={style.count}
        onClick={(e) => {
          e.stopPropagation();
          window.analytics.track("Clicked Planning Documents Link", {
            planningAppID,
          });
          window.open(planningDocsURL || pageURL, "_blank");
        }}
      >
        <div className="flex-center-center">
          <FontAwesomeIcon icon={regular("file-magnifying-glass")} />
          <span>{numDocuments}</span>
        </div>
      </a>
    </div>
  );

  const PlanningIcons = () => (
    <>
      <div className={style.fullText} style={{ padding: "2px 0 2px 5px" }}>
        <PlanningFullText
          text={
            <div>
              <FontAwesomeIcon icon={regular("circle-info")} />
            </div>
          }
          tooltip={description}
        />
      </div>
      &nbsp;&nbsp;&nbsp;
      <PlanningDocsLink />
    </>
  );

  return (
    <TableRow style={{ borderStyle: "hidden" }}>
      <TableCell className="statusCell">
        {!isSmallWidth && (
          <>
            {isLargeWidth && (
              <div className={style.date} style={{ paddingBottom: "3px" }}>
                <span>{date}</span>
              </div>
            )}
            <div className="flex-center-center">
              <PlanningStatusLarge status={status} />
            </div>
            <div className="flex-center-center" style={{ paddingTop: "5px" }}>
              <span className={style.appSize}>{appSize}</span>
              {numDwellings > 0 && <span>&nbsp;({numDwellings} units)</span>}
            </div>
          </>
        )}

        {isSmallWidth && (
          <div className={style.firstCellMobile}>
            <div className={style.date} style={{ paddingBottom: "2px" }}>
              {date}
            </div>
            <PlanningStatusSmall status={status} />
            <div className={style.appSize} style={{ paddingTop: "4px" }}>
              {appSize}
            </div>
          </div>
        )}
      </TableCell>
      {viewType !== "slim" && (
        <TableCell
          align="left"
          className={style.tableCell}
          style={{ width: "167px" }}
        >
          <GoogleStreetViewThumbnail
            lat={lat}
            lng={lng}
            height={mobile ? "90px" : "120px"}
          />
        </TableCell>
      )}
      <TableCell
        align="left"
        className={style.tableCell}
        style={{ maxWidth: "150px" }}
      >
        <div className={style.address}>{address}</div>
        {isSmallWidth && (
          <>
            <div
              className="flex-center-center"
              style={{ paddingTop: "6px", justifyContent: "left" }}
            >
              <PlanningAppIDLink
                planningAppID={planningAppID}
                pageURL={pageURL}
                mobile={isSmallWidth}
              />
            </div>
            <div
              className={style.fullText}
              style={{ paddingTop: "6px", justifyContent: "start" }}
            >
              <div className="flex-center-center">
                <PlanningIcons />
              </div>
            </div>
          </>
        )}
      </TableCell>

      {!isLargeWidth && (
        <TableCell align="center">
          <div
            className={style.fullText}
            style={{ width: "50px", paddingTop: "4px" }}
          >
            <PlanningFullText
              text={
                <div
                  className="flex-center-center"
                  style={{ flexDirection: "column" }}
                >
                  <FontAwesomeIcon icon={regular("circle-info")} />
                </div>
              }
              tooltip={description}
            />
          </div>
        </TableCell>
      )}

      {!isMediumWidth && (
        <TableCell align="left" className={style.tableCell}>
          <div className={style.chips}>
            <div
              className="result-tags-list nice-slim-scrollbar"
              style={{ maxHeight: "120px", marginRight: "5px" }}
            >
              <TagsSection property={property} />
            </div>
          </div>
        </TableCell>
      )}
      {!isLargeWidth && (
        <TableCell align="left" className={style.tableCell}>
          {numDocuments > 0 ? (
            <PlanningDocsLink />
          ) : (
            <div style={{ fontSize: "0.9em", color: "grey" }}>-</div>
          )}
        </TableCell>
      )}
      {!isLargeWidth && (
        <TableCell align="left">
          <div className={style.date}>
            <span>{date}</span>
          </div>
        </TableCell>
      )}
      {!isSmallWidth && (
        <TableCell align="right" className={style.tableCell}>
          {isMediumWidth && !isSmallWidth && tags?.length > 0 && (
            <div className="flex-center-center nice-slim-scrollbar">
              <div className={style.chips}>
                <TagsSection property={{ tags: [tags[0]] }} />
              </div>
            </div>
          )}
          <div style={{ padding: "5px 0" }}>
            <PlanningAppIDLink
              planningAppID={planningAppID}
              pageURL={pageURL}
              mobile={isSmallWidth}
            />
          </div>

          {isLargeWidth && !isSmallWidth && (
            <div className="flex-center-center">
              <PlanningIcons />
            </div>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default PlanningItem;
