import React from "react";

const PropertySqft = ({ sqft, setSqft, hideSizeText }) => {
  let display;
  if (!sqft) display = <div className="loader-small"></div>;
  else if (sqft > 200 && sqft < 8000)
    display = (
      <>
        <span>
          <span>{Math.round(sqft).toLocaleString()}</span>
          <span style={{ fontSize: "13px" }}>ft²</span>
        </span>
      </>
    );
  else
    display = (
      <div className="missing-attribute-text">
        Unknown{!hideSizeText && " Size"}
      </div>
      // <form onSubmit={setSqft}>
      //   <input
      //     style={{ width: "30px" }}
      //     type="text"
      //     name="sqftValue"
      //     placeholder="Sqft"
      //     onClick={(e) => e.stopPropagation()}
      //   />
      //   <input type="submit" value="Set" />
      // </form>
    );
  return <>{display}</>;
};

export default PropertySqft;
