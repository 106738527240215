import React, { PureComponent, useState } from "react";
import "./landworthIndicator.css";
import {
  formatDateDDMMMYYY,
  capitaliseFirstLetter,
} from "../services/formatting";
import PropertyLandworthScore from "./PropertyLandworthScore";

import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import useLocalStorage from "@rehooks/local-storage";

const barColors = ["#178d17", "#3f9878", "#35a4ce", "#335885", "#1f1243"];

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 16,
    height: 10,
    padding: 0,
    // display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(6px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#35a4ce",
        borderColor: "#35a4ce",
      },
    },
  },
  thumb: {
    width: 6,
    height: 6,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const LandworthIndicator = (props) => {
  const { property, landworthData, value, date, propertyTypeParent } = props;

  const [displaySqm, setDisplaySqm] = useLocalStorage("displaySqm", false);

  let indicatorTitle = `Average${
    propertyTypeParent && propertyTypeParent !== "other"
      ? ` ${capitaliseFirstLetter(propertyTypeParent)}`
      : ""
  } Sold Price`;
  // if (propertyTypeParent) indicatorTitle += ` for ${propertyTypeParent}s`;
  if (date) indicatorTitle += ` on ${formatDateDDMMMYYY(date)}`;
  const units = !displaySqm ? "ft²" : "m²";

  const minValue = landworthData.worth - 2 * landworthData.stdev;
  const maxValue = landworthData.worth + 2 * landworthData.stdev;

  const leftLabel = value
    ? "-100%"
    : `£${Math.round(
        !displaySqm
          ? landworthData.worth - landworthData.stdev?.toLocaleString()
          : (landworthData.worth - landworthData.stdev?.toLocaleString()) *
              10.7639
      )?.toLocaleString()}/${units}`;
  const averageLabel = `${Math.round(
    !displaySqm ? landworthData.worth : landworthData.worth * 10.7639
  )?.toLocaleString()}/${units}`;

  const rightLabel = value
    ? "+100%"
    : `£${Math.round(
        !displaySqm
          ? landworthData.worth + landworthData.stdev
          : (landworthData.worth + landworthData.stdev) * 10.7639
      )?.toLocaleString()}/${units}`;

  const valueLabel = Math.round(
    !displaySqm ? value : value * 10.7639
  ).toLocaleString();

  const valueMarkerShift =
    (value <= minValue
      ? 0
      : value >= maxValue
      ? 260
      : ((value - minValue) / (4 * landworthData.stdev)) * 260) - 12;

  const toggleUnits = (newState) => {
    window.analytics.track("Toggle Units", {
      units: newState ? "sqm" : "sqft",
    });
    setDisplaySqm(newState);
  };

  return (
    <div className="lwContainer">
      <div id="v4mContainerLarge">
        <div id="v4mIndicator">
          <div className="v4mMiddle">
            <div className="v4mMiddleTop">
              <div className="v4mLowLabel"></div>
              <div className="v4mTitle">{indicatorTitle}</div>
              {!property && (
                <div className="v4mHighLabel">
                  <span
                    onClick={() => toggleUnits(!displaySqm)}
                    style={{ cursor: "pointer" }}
                  >
                    ft²&nbsp;
                  </span>
                  <AntSwitch
                    checked={displaySqm}
                    color="secondary"
                    // size="small"
                    onChange={() => toggleUnits(!displaySqm)}
                    name="sqftsqm"
                    // inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <span
                    onClick={() => toggleUnits(!displaySqm)}
                    style={{ cursor: "pointer" }}
                  >
                    &nbsp;m²
                  </span>
                </div>
              )}
            </div>
            <div className="v4mBarFigures">
              <div className="v4mLowValue">
                <span>{leftLabel}</span>
              </div>
              <div className="v4mValueMarker">
                <span>£{averageLabel}</span>
              </div>
              <div className="v4mHighValue">
                <span>{rightLabel}</span>
              </div>
            </div>

            <div className="v4mBar">
              <div className="colorBar">
                {barColors.map((color) => {
                  return (
                    <span
                      key={color}
                      className="gradstep"
                      style={{ backgroundColor: color }}
                    ></span>
                  );
                })}
                <span className="gradstep"></span>
              </div>
            </div>

            {value && (
              <div className="v4mMiddleBottom">
                <div
                  className="v4mMarkerBottomContainer"
                  style={{ left: `${valueMarkerShift}px` }}
                >
                  <div className="v4mMarkerBottom"></div>
                  <div className="v4mMarkerLabel">
                    <PropertyLandworthScore property={property} />
                    {property.ppsf > 0 &&
                      Number.isFinite(property.ppsf) &&
                      `£${valueLabel}/${units}`}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandworthIndicator;
