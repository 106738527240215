import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { runSearch, runValuation } from "../../../graphql/functions";
import { useDispatch } from "react-redux";
import { setSearchDetails } from "../../../data/slices/searchSlice";

import useDeviceWidth from "data/hooks/useDeviceWidth";

const searchSpecBase = {
  // search_type: "sale", // Eventually we should be able to change this to "rent"
  // search_rm: false,
  // search_landreg: true,

  searchradius: 402,
  include_forsale: true,
  include_sold: true,
  sold_history_months: 12,

  //// Property Spec fields
  // pricemin: price * 0.75,
  // pricemax: price * 1.25,
  bedmin: 0,
  bedmax: 10,
  sizemin: 0,
  sizemax: 10000,
  newbuild: true,
  oldbuild: true,
  build_type: "both",
  keywords: "",
  include_freehold: true,
  include_leasehold: true,
  include_shared: false,
};

const PropertyLinkButtonRow = ({ property, history }) => {
  const {
    propertyID,
    propertyType,
    propertyTypeParent,
    addressLong,
    beds,
    price,
    lat,
    lng,
    listingURL,
    propertySource,
    sqft,
    zHistoryURL,
    rmHistoryURL,
  } = property;

  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();
  const [isRunningValuation, setIsRunningValuation] = useState(false);

  const dispatch = useDispatch();
  const performSearch = async (name, spec) => {
    const newSearchId = await runSearch(name, "property", spec).searchId;
    if (newSearchId) {
      dispatch(
        setSearchDetails({
          id: newSearchId,
          name,
          spec,
        })
      );
      history.push(`/search/${newSearchId}`);
    } else {
      history.push(`/search/error`);
    }
  };

  const performValuation = async (spec) => {
    setIsRunningValuation(true);
    const runValuationResponse = await runValuation({
      valuationMode: "ppsf",
      valuationInputSpec: spec,
      isLoggedIn: true,
    });

    const resStatus = runValuationResponse.responseStatus;
    if (
      resStatus === "OK" ||
      resStatus === "USER_NOT_FOUND" || // REMOVE WHEN READY
      resStatus === "ERROR_IN_CREDIT_DATABASE" // REMOVE WHEN READY
    ) {
      window.open(`/report/${runValuationResponse.valuationId}`);
      setIsRunningValuation(false);
    } else {
      window.open(`/report/error/${runValuationResponse.responseStatus}`);
      setIsRunningValuation(false);
    }
  };

  const handleClick = (button) => {
    switch (button) {
      case "valuation":
        // window.open(listingURL);
        performValuation({
          valuationName: `Rightmove Listing: ${addressLong}`,
          propertyTypeParent,
          propertyType,
          beds,
          sqft,
          // postcode: localLocationSpec.postcode,
          lat,
          lng,
        });

        window.analytics.track("Click Run Valuation", {
          propertyID,
          propertyTypeParent,
          source: "button",
        });
        break;

      case "soldcomps":
        // window.open(listingURL);
        performSearch(`Find Sold Comps for ${propertyID}`, {
          ...searchSpecBase,
          search_type: "sale",
          search_rm: false,
          search_landreg: true,
          searchlat: lat,
          searchlng: lng,
          property_type: propertyTypeParent + "s",
          pricemin: Math.round(price * 0.5),
          pricemax: Math.round(price * 1.5),
          // sizemin: 0,
          // sizemax: 10000,
        });

        window.analytics.track("Click Find Sold Comps", {
          propertyID,
          propertyTypeParent,
          source: "button",
        });
        break;
      case "rentalcomps":
        // window.open(listingURL);
        performSearch(`Find Rental Comps for ${propertyID}`, {
          ...searchSpecBase,
          search_type: "rent",
          search_rm: true,
          search_landreg: false,
          searchlat: lat,
          searchlng: lng,
          property_type: propertyTypeParent + "s",
          pricemin: 0,
          pricemax: 50000,
          bedmin: beds,
          bedmax: beds,
        });

        window.analytics.track("Click Find Rental Comps", {
          propertyID,
          propertyTypeParent,
          source: "button",
        });
        break;
      case "zoopla":
        window.open(listingURL);
        window.analytics.track("Click Property Source Link", {
          propertyID,
          propertyTypeParent,
          source: "button",
        });
        break;
      case "rightmove":
        window.open(listingURL);
        window.analytics.track("Click Property Source Link", {
          propertyID,
          propertyType,
          propertySource,
          source: "button",
        });
        break;
      case "landreg":
        window.open(
          `https://landregistry.data.gov.uk/data/ppi/transaction/${propertyID.toUpperCase()}/current`
        );
        window.analytics.track("Click Property Source Link", {
          propertyID,
          propertyType,
          zHistoryURL,
          rmHistoryURL,
          source: "button",
        });
        break;
      case "streetview":
        window.open(
          `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`
        );
        window.analytics.track("Click Streetview Button", {
          propertyID,
          propertyType,
          source: "button",
        });
        break;
      case "birdseye":
        window.open(
          `https://www.bing.com/maps?cp=${lat}~${lng}&lvl=19&style=b`
        );
        window.analytics.track("Click Birds-Eye Button", {
          propertyID,
          propertyType,
          source: "button",
        });
        break;

      default:
        console.log("Invalid button");
    }
  };

  const PropertyLinkButtonGeneric = ({
    name,
    label,
    icon,
    color = "default",
    disabled = false,
    loading = false,
  }) => {
    return (
      <Button
        variant="contained"
        color={color}
        size="small"
        onClick={() => handleClick(name)}
        className="property-link-button"
        disabled={disabled}
      >
        <div className="flex-center-center property-link-button-inner-container">
          {!isSmallWidth && (
            <span className="property-link-button-label">
              {label}&nbsp;&nbsp;
            </span>
          )}
          <FontAwesomeIcon icon={["far", icon]} />
          &nbsp;&nbsp;
          {loading && <div className="loader-tiny" />}
        </div>
      </Button>
    );
  };

  return (
    <div className="link-button-row-container">
      {/* {(propertySource === "zoopla" || propertySource === "rightmove") && (
        <>
          <PropertyLinkButtonGeneric
            name="soldcomps"
            label="Sold Comps"
            icon="search"
          />
          <PropertyLinkButtonGeneric
            name="rentalcomps"
            label="Rental Comps"
            icon="percent"
          />
        </>
      )} */}

      {propertySource === "landreg" && (
        <PropertyLinkButtonGeneric
          name="landreg"
          label="Land Registry"
          icon="external-link-alt"
        />
      )}
      {propertySource === "zoopla" && (
        <PropertyLinkButtonGeneric
          name="zoopla"
          label="Zoopla"
          icon="external-link-alt"
        />
      )}
      {propertySource === "rightmove" && (
        <PropertyLinkButtonGeneric
          name="rightmove"
          label="Rightmove"
          icon="external-link-alt"
        />
      )}
      <PropertyLinkButtonGeneric
        name="streetview"
        label="Street View"
        icon="street-view"
      />
      <PropertyLinkButtonGeneric
        name="birdseye"
        label="Birds-Eye View"
        icon="dove"
      />
      {/* <PropertyLinkButtonGeneric
        name="valuation"
        label="Run Valuation"
        icon="calculator"
        // color="secondary"
        disabled={sqft <= 0 || isRunningValuation}
        loading={!sqft || sqft === 0 || isRunningValuation}
      /> */}
    </div>
  );
};

export default withRouter(PropertyLinkButtonRow);
