export const getLocationFromLatLng = async (lat, lng) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

  const response = await fetch(url);
  const { features } = await response.json();

  let title = "";
  let postcode = "";

  features.forEach((element) => {
    if (element.id.startsWith("address")) {
      title += element.text + ", ";
    }
  });
  if (title === "") {
    features.forEach((element) => {
      if (element.id.startsWith("poi")) {
        title += element.text + ", ";
      }
    });
  }

  features.forEach((element) => {
    if (element.id.startsWith("postcode")) {
      title += element.text;
      postcode = element.text;
      postcode = postcode.substr(0, postcode.indexOf(" "));
    }
  });
  if (title === "") {
    title = "NO DATA: Select another location";
  }

  return title;
};
export const getAutocompleteFromText = async (text) => {
  let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?autocomplete=true&country=gb&limit=3`;

  url += `&types=region,postcode,district,place,address`;
  url += `&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

  const response = await fetch(url);
  const { features } = await response.json();

  return features;
};

export const getAutocompleteFromTextOS = async (text) => {
  let url = `https://api.os.uk/search/places/v1/find?maxresults=100&minmatch=0.6&output_srs=EPSG:4326&query=${text}`;
  url += `&key=${process.env.REACT_APP_OS_API_KEY_PROD}`;

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "User-Agent":
        "Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1",
      "Accept-Encoding": "gzip, deflate, br",
    },
  });
  const { results } = await response.json();

  return results;
};

export const getDistanceBetweenTwoPoints = (cord1, cord2) => {
  if (cord1.lat === cord2.lat && cord1.lng === cord2.lng) {
    return 0;
  }

  const radlat1 = (Math.PI * cord1.lat) / 180;
  const radlat2 = (Math.PI * cord2.lat) / 180;

  const theta = cord1.lng - cord2.lng;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  // dist = dist * 1.609344; //convert miles to km

  return dist;
};
