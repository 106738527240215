import { useContext, useCallback } from "react";
import { Context } from "./Store";

const useSelectedResultsProperty = () => {
  const [state, dispatch] = useContext(Context);

  const select = useCallback(
    (id) => {
      dispatch({ type: "SET_SELECTED_RESULTS_PROPERTY", id });
    },
    [dispatch]
  );

  return [state.RESULTS_SELECTED, select];
};

export default useSelectedResultsProperty;
