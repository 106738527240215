import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 200,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const TodayPriceWarning = ({ iconSize = "1.1em" }) => (
  <>
    <HtmlTooltip
      placement="right-start"
      title={
        <>
          <Typography color="inherit">
            <u>Low Confidence Warning</u>
          </Typography>
          Landworth has a lower confidence in this number as there were few
          sales in the area around this property
        </>
      }
    >
      <Icon
        style={{
          fontSize: iconSize,
          height: "auto",
          width: "auto",
          opacity: "0.7",
        }}
      >
        <FontAwesomeIcon
          icon={["far", "exclamation-square"]}
          style={{ color: "red" }}
        />
      </Icon>
    </HtmlTooltip>
  </>
);

export default TodayPriceWarning;
