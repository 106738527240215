import React from "react";
import PropertyBeds from "./PropertyBeds";
import PropertyType from "./PropertyType";

import Chip from "@material-ui/core/Chip";

const isCouncil = (property) => {
  const { epcTenure, zHistoryTags, rmHistoryTags } = property;
  // If epcTenure string contains the string (social) then return true OR if zHistoryTags or rmHistoryTags array contains the string "Ex-Council" then return true

  return (
    epcTenure?.includes("(social)") ||
    zHistoryTags?.includes("Ex-Council") ||
    rmHistoryTags?.includes("Ex-Council")
  );
};

const WarningChip = ({ label, height, width }) => (
  <Chip
    style={{
      fontSize: "0.7em",
      fontWeight: "400",
      width,
      height,
      textAlign: "center",
      backgroundColor: "#707070",
      color: "#ffffffde",
    }}
    label={<>{label}</>}
    color={"secondary"}
    size={"small"}
  />
);

const PropertyBuildBedsType = ({ property }) => {
  const { newbuild } = property;
  const hasBeds = property.beds || property.rmBeds || property.zBeds;

  return (
    <div style={{ textAlign: "center" }}>
      {newbuild && (
        <>
          NEW&nbsp;
          {/* <br /> */}
        </>
      )}

      {hasBeds && (
        <>
          <PropertyBeds property={property} />{" "}
        </>
      )}
      <PropertyType property={property} />
      {isCouncil(property) && (
        <>
          <br />
          &nbsp;
          <WarningChip label={<>Council</>} />
          &nbsp;
        </>
      )}
    </div>
  );
};

export default PropertyBuildBedsType;
