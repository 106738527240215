import { Chip, IconButton } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

const SearchFormItem = ({
  isIncluded,
  isExcluded,
  includeTag,
  excludeTag,
  label,
  count,
}) => {
  const handleIncludeTag = (e) => {
    e.stopPropagation();
    includeTag();
  };

  const handleExcludeTag = (e) => {
    e.stopPropagation();
    excludeTag();
  };

  let chipColor;
  if (isIncluded) {
    chipColor = "secondary";
  } else if (isExcluded) {
    chipColor = "red";
  } else {
    chipColor = "default";
  }

  const chipBackgroundColor = {
    red: "#7F1616",
  }[chipColor];

  const combinedStyles = {
    ...(isExcluded && { color: "white" }),
    userSelect: "none",
  };

  return (
    <div
      className={"search-filter-item"}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Chip
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <IconButton onClick={handleExcludeTag} style={{ padding: "4px" }}>
              <Remove
                style={{
                  fontSize: "0.7em",
                  color: isIncluded || isExcluded ? "white" : "black",
                }}
              />
            </IconButton>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                pointerEvents: "none",
                userSelect: "none",
              }}
            >
              <div
                style={{
                  borderLeft: "1px solid #999",
                  height: "1.6em",
                  margin: "0 6px 0 4px",
                }}
              ></div>
              <div style={combinedStyles}>
                <span>{label}</span>
                <span
                  style={{
                    fontSize: "1.1em",
                    fontWeight: "bold",
                    margin: "0 2px 0 8px",
                  }}
                >
                  {count}
                </span>
              </div>
              <div
                style={{
                  borderRight: "1px solid #999",
                  height: "1.6em",
                  margin: "0 4px 0 6px",
                }}
              ></div>
            </div>
            <IconButton onClick={handleIncludeTag} style={{ padding: "4px" }}>
              <Add
                style={{
                  fontSize: "0.7em",
                  color: isIncluded || isExcluded ? "white" : "black",
                }}
              />
            </IconButton>
          </div>
        }
        style={{
          margin: "2px 2px 1px 2px",
          backgroundColor: chipBackgroundColor,
          width: "100%",
        }}
        key={label}
        color={chipColor}
        size={"medium"}
      />
    </div>
  );
};

export default SearchFormItem;
