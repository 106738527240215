import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { Container } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ComparableGradeInfo = (props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <HtmlTooltip
        placement="bottom"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        open={open}
        title={
          <div style={{ textAlign: "center" }}>
            <Typography color="inherit">
              <u>Similarity Grade A-F</u>
            </Typography>
            Grades provide a quick way to see how similar a sold property is to
            the property you're valuing.
            <br />
            <br />
            We compare location, price, size and age of sale.
            <br />
            <br />
            NOTE: Grades do not take into account the property condition - this
            should be judged manually to ensure the property is in a similar
            state.
          </div>
        }
      >
        <Container
          onClick={handleClick}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          {props.children}
        </Container>
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

export default ComparableGradeInfo;
