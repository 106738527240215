import React, { useState, useEffect } from "react";

import { Pages } from "@landworth/fiverr-chiran-landworth";
import { useHistory } from "react-router";
import { fetchLandworth, runValuation } from "graphql/functions";
// import { useValuationSearchResultsClean } from "data/hooks/useValuationSearchResultsClean";

const runVal = async (
  valuationMode,
  valuationParameters,
  referringDomainName,
  history,
  isLoggedIn
) => {
  // console.log(valuationParameters);
  const runValuationResponse = await runValuation({
    valuationMode,
    valuationInputSpec: valuationParameters,
    referringDomainName,
    isLoggedIn,
  });
  // console.log(runValuationResponse);
  const resStatus = runValuationResponse.responseStatus;
  if (
    resStatus === "OK" ||
    resStatus === "USER_NOT_FOUND" || // REMOVE WHEN READY
    resStatus === "ERROR_IN_CREDIT_DATABASE" // REMOVE WHEN READY
  ) {
    setTimeout(
      () => history.push(`/report/${runValuationResponse.valuationId}`),
      1800
    );
  } else {
    history.push(`/report/error/${runValuationResponse.responseStatus}`);
  }
};

const ValuationSpecsHolder = ({ isLoggedIn, localLocationSpec }) => {
  // console.log(localLocationSpec);
  const history = useHistory();
  useEffect(() => {
    document.title = "Landworth: Confirm Property Details";
  }, []);

  const [landworthPpsf, setLandworthPpsf] = useState();
  useEffect(async () => {
    if (localLocationSpec) {
      const lw = await fetchLandworth(
        localLocationSpec.lat,
        localLocationSpec.lng,
        "all",
        process.env.REACT_APP_CURRENT_DATE
      );

      setLandworthPpsf(Math.round(lw.worth));
    }
  }, []);

  if (!localLocationSpec) history.replace("/report");

  const runValuation = (propertySpec) => {
    window.analytics.track(`Valuation - Run Valuation`);
    // console.log(localLocationSpec);
    // console.log(propertySpec);
    const valuationParameters = {
      valuationName: localLocationSpec.valuationName,
      postcode: localLocationSpec.postcode,
      lat: localLocationSpec.lat,
      lng: localLocationSpec.lng,
      propertyType: !!localLocationSpec.property_type
        ? localLocationSpec.property_type
        : propertySpec.propertyTypeParent,
      propertyTypeParent: propertySpec.propertyTypeParent,
      sqft:
        propertySpec.sizeUnit === "sqft"
          ? Math.round(propertySpec.size)
          : Math.round(propertySpec.size * 10.7639),
      size: propertySpec.size,
      sizeUnit: propertySpec.sizeUnit,
      epcSqm: localLocationSpec.epcSqm,
      beds: propertySpec.bedrooms,
      newbuild: propertySpec.newbuild,
      hmlrid: propertySpec.hmlrid,
      lmk_key: localLocationSpec.lmk_key,
      uprn: localLocationSpec.uprn,
      inspection_date: localLocationSpec.inspection_date,
      currentEnergyRating: localLocationSpec.currentEnergyRating,
      currentEnergyScore: localLocationSpec.currentEnergyScore,
      potentialEnergyRating: localLocationSpec.potentialEnergyRating,
      potentialEnergyScore: localLocationSpec.potentialEnergyScore,
      local_authority_id: localLocationSpec.local_authority_id,
      local_authority_name: localLocationSpec.local_authority_name,
      matchAddress: localLocationSpec.matchAddress,
      floorLevel: localLocationSpec.floor_level,
      epcConstructionAgeBand: localLocationSpec.construction_age_band,
      lastSaleDate: localLocationSpec.lastSaleDate,
      lastSalePrice: localLocationSpec.lastSalePrice,
    };

    const valuationMode = !!localLocationSpec.uprn ? "uprn" : "ppsf";

    runVal(
      valuationMode,
      valuationParameters,
      localLocationSpec.referringDomainName,
      history,
      isLoggedIn
    );
  };

  return localLocationSpec ? (
    <Pages.ValuationSpecs
      localLocationSpec={{ ...localLocationSpec, landworth: landworthPpsf }}
      runValuation={runValuation}
    />
  ) : (
    <>ERROR: localLocationSpec missing</>
  );
};

export default ValuationSpecsHolder;
