import { useState, useEffect, useCallback } from "react";
import Debug from "debug";
import useInterval from "@use-it/interval";
import { getPropertyData } from "../../graphql/functions";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import shuffle from "../../pages/Search/shuffle";
import {
  addPropertiesToCache,
  giveUpOnProperties,
} from "../slices/propertiesSlice";
import { getSearchSelector } from "../selectors";

const debug = Debug("lw:usePropertyData");

const TOTAL_SEARCHES = 45;
const DELAY = 2000;

const decrement = (x) => x - 1;

/**
 * Custom hook to get translate search results into property data
 */
function usePropertyData(searchID, searchFinished) {
  const [remaining, setRemaining] = useState(TOTAL_SEARCHES);
  const [delay, setDelay] = useState(DELAY); // should be 2000
  const [finished, setFinished] = useState(false);
  const dispatch = useDispatch();

  const { propertyIds, dateRun } = useSelector((state) =>
    getSearchSelector(state, searchID)
  );

  // considered missing is absent, missing size or landworth, or data has expired
  const isMissingFromCache = (cache) => (propertyID) => {
    const cacheHit = cache[propertyID];
    const thresholdDate = new Date(Date.now() - 2 * 864e5).toISOString();
    const thresholdDate30 = new Date(Date.now() - 2 * 864e5 * 30).toISOString();
    const rmHistoryExpired = cacheHit?.rmHistoryCachedDate < thresholdDate;
    const sqftDataExpired = cacheHit?.sqftCachedDate < thresholdDate30;
    const lwDataExpired =
      cacheHit?.propertySource !== "landreg" && // No need to recalculate landworth for LR properties as soldDate doesn't change
      cacheHit?.lwCachedDate < thresholdDate;
    const lwLatestMissing =
      cacheHit?.propertySource === "landreg" &&
      (cacheHit?.latestLwCachedDate < thresholdDate ||
        !cacheHit?.latestLandworth);
    const needsLastSaleLandworth =
      cacheHit?.propertySource === "valuation" &&
      cacheHit?.lastSalePrice &&
      !cacheHit?.lastSaleLandworth;

    const loadedOldSearch =
      dateRun < thresholdDate || cacheHit?.propertySource === "valuation";

    const isPlanningResult = cacheHit?.propertySource === "plan";

    const isCacheMiss =
      !cacheHit ||
      (!isPlanningResult &&
        (!cacheHit.sqft ||
          !cacheHit.landworth ||
          !cacheHit.zHistoryURL ||
          !cacheHit.rmHistoryURL ||
          (!cacheHit.lrSoldPriceHistory && !cacheHit.lrSoldPriceHistoryURL) || // Handles the fact that lrSoldPriceHistoryURL was introduced on 6-3-2022
          !cacheHit.postcodeDistrict ||
          needsLastSaleLandworth ||
          (!loadedOldSearch &&
            (rmHistoryExpired ||
              lwDataExpired ||
              lwLatestMissing ||
              sqftDataExpired)))) ||
      (isPlanningResult && !cacheHit.planningAppID);

    // debug(
    //   isCacheMiss,
    //   rmHistoryExpired,
    //   sqftDataExpired,
    //   lwDataExpired,
    //   lwLatestMissing,
    //   needsLastSaleLandworth,
    //   isPlanningResult,
    //   loadedOldSearch,
    //   !cacheHit.sqft,
    //   !cacheHit.landworth,
    //   !cacheHit.zHistoryURL,
    //   !cacheHit.rmHistoryURL,
    //   !cacheHit.lrSoldPriceHistory,
    //   !cacheHit.lrSoldPriceHistoryURL,
    //   !cacheHit.lrSoldPriceHistory && !cacheHit.lrSoldPriceHistoryURL,
    //   !cacheHit.postcodeDistrict
    // );

    return isCacheMiss;
  };

  // get just the IDs that are still missing
  const gatherMisses = (cache, propertyIDs = []) =>
    propertyIDs.filter(isMissingFromCache(cache));

  const getCacheMisses = createSelector(
    ({ properties }) => properties, // cache
    (_, propertyIds) => propertyIds, // ids,
    gatherMisses
  );

  const misses = useSelector((state) => getCacheMisses(state, propertyIds));

  // when the search ID alters, reset
  useEffect(() => {
    if (searchID) {
      debug("Resetting Search as searchID has changed");
      // return () => {
      setRemaining(TOTAL_SEARCHES);
      setDelay(DELAY);
      setFinished(false);
      // };
    }
  }, [searchID]);

  const giveUp = useCallback(() => {
    dispatch(giveUpOnProperties(misses));
    setDelay(null); // clears the interval
    setFinished(true);
  }, [misses, dispatch]);

  // when all available attempts are made, clean up
  const timeToGiveUp = remaining <= 0 && !finished;
  useEffect(() => {
    if (timeToGiveUp) {
      debug("Giving up collecting PropertyData");
      giveUp();
    }
  }, [timeToGiveUp, giveUp]);

  const checkPropertyCache = async () => {
    debug(
      `There remain ${misses.length} misses (${remaining} checks remaining)`
    );

    if (!searchID) {
      setRemaining(0);
      debug("No searchID supplied!");
      return;
    }

    // remove duplicates (might be able to skip this)
    const uniquelyStillMissing = Array.from(new Set(misses));
    // random upto 100
    const randomMissingBatch = shuffle(uniquelyStillMissing).slice(0, 100);

    // if we have work to do... do it
    if (uniquelyStillMissing.length) {
      debug(
        `Still looking for ${uniquelyStillMissing.length} properties [${
          TOTAL_SEARCHES - remaining + 1
        }]`
      );
      // grab random 100
      const newProperties = await getPropertyData(randomMissingBatch);
      // add the results to the cache
      if (newProperties && newProperties.length) {
        // debug(`found ${newProperties.length} properties`);
        dispatch(addPropertiesToCache(newProperties));
      }
    }

    // are we done?
    if (searchFinished && !uniquelyStillMissing.length) {
      setRemaining(0);
      debug("PropertyData check Complete!");
    } else {
      setRemaining(decrement);
    }
  };

  // run the checker on interval!
  useInterval(checkPropertyCache, remaining === TOTAL_SEARCHES ? 250 : delay);

  return finished;
}

export default usePropertyData;
