import { Field } from "formik";
import Button from "@material-ui/core/Button";

const IncludeFreeholdLeasehold = (props) => {
  const { values, setFieldValue } = props;

  return (
    <>
      <div className={"search-form-item"}>
        <div className={"search-form-label"}>Freehold&nbsp;&nbsp;&nbsp;</div>
        <Field
          component={Button}
          name="include_freehold"
          id="include_freehold"
          variant={values?.include_freehold ? "contained" : "outlined"}
          color={values?.include_freehold ? "secondary" : "default"}
          size="small"
          onClick={(e) => setFieldValue("include_freehold", true)}
        >
          Include
        </Field>
        <Field
          component={Button}
          name="exclude_freehold"
          id="exclude_freehold"
          variant={values?.include_freehold ? "outlined" : "contained"}
          color={values?.include_freehold ? "default" : "secondary"}
          size="small"
          onClick={(e) => setFieldValue("include_freehold", false)}
        >
          Exclude
        </Field>
      </div>
      <br />
      <label htmlFor="include_freehold"></label>
      <div className={"search-form-item"}>
        <div className={"search-form-label"}>Leasehold&nbsp;</div>

        <Field
          component={Button}
          name="include_leasehold"
          id="include_leasehold"
          variant={values?.include_leasehold ? "contained" : "outlined"}
          color={values?.include_leasehold ? "secondary" : "default"}
          size="small"
          onClick={(e) => setFieldValue("include_leasehold", true)}
        >
          Include
        </Field>
        <Field
          component={Button}
          name="exclude_leasehold"
          id="exclude_leasehold"
          variant={!values?.include_leasehold ? "contained" : "outlined"}
          color={!values?.include_leasehold ? "secondary" : "default"}
          size="small"
          onClick={(e) => setFieldValue("include_leasehold", false)}
        >
          Exclude
        </Field>
      </div>
    </>
  );
};

export default IncludeFreeholdLeasehold;
