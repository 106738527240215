import { useContext, useCallback } from "react";
import { Context } from "./Store";

const useSearchDrawer = () => {
  const [state, dispatch] = useContext(Context);

  const setSearchDrawerOpen = useCallback(
    (newState) => {
      dispatch({ type: "SET_SEARCH_DRAWER_OPEN", newState });
    },
    [dispatch]
  );

  return [state.searchDrawerOpen, setSearchDrawerOpen];
};

export default useSearchDrawer;
