import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports.js";
import { Provider } from "react-redux";
import store from "./app/store";
import Store from "./pages/Search/Data/Store";
import ConsoleMessage from "./consoleMessage";

import "@aws-amplify/ui/dist/style.css";

import "./index.css";

import * as serviceWorker from "./serviceWorker";

const devAPIConfig = {
  aws_appsync_graphqlEndpoint:
    "https://i4iszmgq2zbzpeggtdzbbocloa.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-mi2huzjj6bcxzlzbywlsliqy4m",
  aws_cognito_identity_pool_id:
    "eu-west-1:02f27463-9c3e-4ee3-93b9-85e740482097",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_63IIVXo9c",
  aws_user_pools_web_client_id: "36rnie95oh7q26ef62fd88n0fl",
  aws_mobile_analytics_app_id: "2397519a90fe49bdb957ef6a9de6f187",
};

const prodAPIConfig = {
  aws_appsync_graphqlEndpoint:
    "https://f7b3a5usdzfcvfiijotqnywdla.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-oi5tjxqkkvbifev5wq6qfiyxv4",
  aws_cognito_identity_pool_id:
    "eu-west-1:642df253-09c7-483d-928a-5413a7f83a48",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_sAHEHEiGJ",
  aws_user_pools_web_client_id: "7dmt69grdb5nk99bn0tmcshdu",
  aws_mobile_analytics_app_id: "4aa7513094954d91bba6def224cd8d26",
};

const appConfig =
  process.env.NODE_ENV === "production"
    ? { ...awsconfig, ...prodAPIConfig }
    : { ...awsconfig, ...devAPIConfig };

Amplify.configure(appConfig);

const initialiseAnalytics = () => {
  const segmentApiKey =
    process.env.NODE_ENV === "production"
      ? "XRmdKW0JJTVW4e3zsZOBvSFFM4yjBsIs"
      : "ZpMLTTgsfdJnE7tFWq72NVSUMTfI6z2m";

  window.analytics.load(segmentApiKey);
};

const render = () => {
  initialiseAnalytics();

  const App = require("./App").default;

  ReactDOM.render(
    <Store>
      <ConsoleMessage />
      <Provider store={store}>
        <App />
      </Provider>
    </Store>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
