import React, { useState } from "react";

import { Field } from "formik";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PropertyTypePicker = (props) => {
  // console.log("Rendering PropertyTypePicker");
  const { values, setFieldValue } = props;
  return (
    <>
      <div className={"search-form-item"}>
        <div className={"search-form-label"}></div>
        <Field
          component={Button}
          name="property_type_houses"
          id="property_type_houses"
          className="search-button-wide"
          variant={
            values?.property_type === "houses" ? "contained" : "outlined"
          }
          color={values?.property_type === "houses" ? "secondary" : "default"}
          size="small"
          onClick={(e) => setFieldValue("property_type", "houses")}
        >
          <FontAwesomeIcon icon={["far", "home-alt"]} />
          &nbsp;&nbsp;Houses
        </Field>
        <Field
          component={Button}
          name="property_type_both"
          id="property_type_both"
          className="search-button-all"
          variant={values?.property_type === "all" ? "contained" : "outlined"}
          color={values?.property_type === "all" ? "secondary" : "default"}
          size="small"
          onClick={(e) => setFieldValue("property_type", "all")}
        >
          All
        </Field>
        <Field
          component={Button}
          name="property_type_flats"
          id="property_type_flats"
          className="search-button-wide"
          variant={values?.property_type === "flats" ? "contained" : "outlined"}
          color={values?.property_type === "flats" ? "secondary" : "default"}
          size="small"
          onClick={(e) => setFieldValue("property_type", "flats")}
        >
          <FontAwesomeIcon icon={["far", "building"]} />
          &nbsp;&nbsp;Flats
        </Field>
      </div>
    </>
  );
};

export default PropertyTypePicker;
