import zooplaLogo from "assets/zooplaLogoTiny.png";
import HMLRLogo from "assets/HMLRLogo52px.png";
import rmLogo from "assets/rmLogo24px.png";

import { formatDateDDMMMYYY } from "services/formatting";

const visitSource = (e, propertyID, propertyType) => {
  e.stopPropagation();
  window.analytics.track("Click Property Source Link", {
    propertyID,
    propertyType,
    source: "logo",
  });
};

const PropertyLink = ({ property }) => {
  const {
    propertyType,
    propertySource,
    propertyID,
    listingURL,
    dateSold,
    dateFirstListed,
  } = property;

  return (
    <div style={{ display: "flex-column" }}>
      {propertySource === "zoopla" || propertySource === "rightmove" ? (
        <>
          <div>
            <a
              href={listingURL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => visitSource(e, propertyID, propertyType)}
              style={{ cursor: "alias" }}
            >
              {propertySource === "zoopla" ? (
                <img height="20px" src={zooplaLogo} alt="Zoopla" />
              ) : (
                <img height="20px" src={rmLogo} alt="Rightmove" />
              )}
            </a>
          </div>
          <div className="result-date">
            {/* <ReactTimeAgo date={new Date(dateFirstListed)} /> */}
            {formatDateDDMMMYYY(dateFirstListed)}
          </div>
        </>
      ) : (
        <>
          <div>
            <a
              href={`https://landregistry.data.gov.uk/data/ppi/transaction/${propertyID.toUpperCase()}/current`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => visitSource(e)}
              style={{ cursor: "alias" }}
            >
              <img height="26px" src={HMLRLogo} alt="Land Registry" />
            </a>
          </div>
          <div className="result-date">
            {/* <ReactTimeAgo date={new Date(dateSold)} /> */}
            {formatDateDDMMMYYY(dateSold)}
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyLink;
