import useSearchView from "data/hooks/useSearchView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Button } from "@material-ui/core";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";

const SearchViewControlButton = (props) => {
  const { variant, totalResults } = props;
  const [searchView, setSearchView] = useSearchView();

  const changeView = (newView) => {
    window.analytics.track("Change Results View", { newView });
    setSearchView(newView);
  };

  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.narrow}px)`);
  const isUnder1300px = useMediaQuery(`(max-width:1300px)`);

  return variant === "list_button" ? (
    <Button
      variant="contained"
      size="small"
      onClick={() =>
        changeView(
          searchView === "full_list" && !isNarrow ? "split" : "full_map"
        )
      }
      className={
        (searchView !== "full_list" || isUnder1300px) &&
        "search-view-control-button"
      }
      style={{
        backgroundColor: "white",
        minWidth: "40px",
      }}
    >
      <div className="flex-center-center">
        {searchView === "full_list" ? (
          isNarrow ? (
            <>
              &nbsp;
              <FontAwesomeIcon
                icon={regular("map-location-dot")}
                style={{ fontSize: "1.1em" }}
              />
              &nbsp;
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={regular("map-location-dot")}
                style={{ fontSize: "1.1em" }}
              />
              <div style={{ margin: "2px 0 0 8px" }}>Map</div>
            </>
          )
        ) : (
          <>
            &nbsp;
            <FontAwesomeIcon
              icon={regular("arrow-right")}
              style={{ fontSize: "1.1em" }}
            />
            &nbsp;
          </>
        )}
      </div>
    </Button>
  ) : variant === "map_button" ? (
    <Button
      variant="contained"
      size="small"
      color={
        totalResults > 0 && searchView === "full_map" ? "primary" : "default"
      }
      onClick={() =>
        changeView(
          searchView === "full_map" && !isNarrow ? "split" : "full_list"
        )
      }
      style={{
        backgroundColor:
          totalResults > 0 && searchView === "full_map" ? null : "white",
        minWidth: "40px",
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
        // padding: props.search ? "3px 6px" : "17px",
      }}
    >
      &nbsp;
      {/* <div className="flex-center-center" style={{ padding: "1px 2px 0" }}> */}
      <FontAwesomeIcon
        icon={regular("arrow-left")}
        style={{ fontSize: "1.1em" }}
      />
      {/* </div> */}
      &nbsp;
      {searchView !== "split" && (
        <>
          {totalResults > 0 ? (
            <>
              <div
                className="flex-center-center"
                style={{ padding: "0px 2px 0", fontSize: "15px" }}
              >
                {totalResults}
              </div>
              &nbsp;
              <FontAwesomeIcon
                icon={["far", "building"]}
                style={{ fontSize: "1.1em" }}
              />
            </>
          ) : (
            <div
              className="flex-center-center"
              style={{ padding: "2px 0 1px" }}
            >
              &nbsp;
            </div>
          )}
        </>
      )}
    </Button>
  ) : (
    <></>
  );
};

export default SearchViewControlButton;
