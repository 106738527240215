import { combineReducers } from "redux";
import circleReducer from "pages/Search/circleSlice";
import propertiesReducer from "data/slices/propertiesSlice";
import searchReducer from "data/slices/searchSlice";
import valuationReducer from "data/slices/valuationSlice";
import locationsReducer from "pages/Search/locationsSlice";
import settingsReducer from "./settingsSlice";
import mapReducer from "data/slices/mapSlice";
import locationHistoryReducer from "data/slices/locationHistorySlice";
import creditsReducer from "data/slices/creditsSlice";

export default combineReducers({
  circle: circleReducer,
  properties: propertiesReducer,
  searches: searchReducer,
  valuations: valuationReducer,
  locations: locationsReducer,
  settings: settingsReducer,
  locationHistorys: locationHistoryReducer,
  map: mapReducer,
  credits: creditsReducer,
});
