import { useMemo } from "react";
import { formatMoneyFull } from "../services/formatting";
import { useSelector } from "react-redux";
import { getSearchSelector } from "../data/selectors";
import {
  measurePropertiesPpsfAverage,
  measurePropertiesPriceAverage,
  measurePropertiesLatestPpsfAverage,
  measurePropertiesLatestPriceAverage,
  measurePropertiesSqftAverage,
  getAnalysedProperties,
} from "../services/propertyHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import useDeviceWidth from "data/hooks/useDeviceWidth";

import SearchResultsStats from "./tooltips/SearchResultsStats";

const PriceComponent = (props) => {
  const {
    searchType,
    priceAverage,
    yieldAverage,
    ppsfAverage,
    isValuationSearch,
    showTodayPrice,
    onlyShowPrice = false, // Currently redundant
  } = props;

  return (
    <div
      className="flex-center-center"
      style={{ color: showTodayPrice ? "green" : "black" }}
    >
      <span style={{ color: "#666666", padding: "0 3px" }}>
        <FontAwesomeIcon
          icon={regular("gauge")}
          // style={{ color: "green" }}
        />
        &nbsp;
      </span>

      <span style={{ fontSize: "1.1em", fontWeight: "bold", color: "#323232" }}>
        £{formatMoneyFull(priceAverage, 0)}
        {(searchType === "rental" || searchType === "rent") && "/month"}
      </span>

      {!onlyShowPrice && (ppsfAverage > 0 || yieldAverage > 0) && (
        <b>
          &nbsp;
          <span
            style={{ fontSize: "1em", fontWeight: "normal", color: "#666666" }}
          >
            at
          </span>
          <span style={{ fontSize: "1.1em", color: "#323232" }}>
            {(searchType === "rental" || searchType === "rent") &&
            isValuationSearch ? (
              <>
                {" "}
                {(Math.round(yieldAverage * 10) / 10).toFixed(1)}% Gross Yield
              </>
            ) : (
              !isNaN(ppsfAverage) && (
                <>
                  &nbsp;£{formatMoneyFull(ppsfAverage, 0)}
                  /ft²
                  {(searchType === "rental" || searchType === "rent") && "/yr"}
                </>
              )
            )}
          </span>
        </b>
      )}

      {/* <span>
        {showTodayPrice && (
          <>
            &nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>TODAY</span>
          </>
        )}
      </span> */}
    </div>
  );
};

const SearchStatusOneLine = (props) => {
  const {
    searchFinished,
    cacheFinished,
    searchId,
    properties,
    searchType,
    valuationData,
    showTodayPrice,
    isSmallWidth,
  } = props;
  const search = useSelector((state) => getSearchSelector(state, searchId));
  const isValuationSearch = !!valuationData?.valuationPrice;
  const comparablePropertyIds =
    search.comparableProperties && JSON.parse(search?.comparableProperties);
  const comps = properties.filter((prop) =>
    comparablePropertyIds?.includes(prop.propertyID)
  );

  const allResultsPriceAverage = measurePropertiesPriceAverage(
    search?.spec?.search_type,
    properties
  );
  const allResultsPpsfAverage = measurePropertiesPpsfAverage(
    search?.spec?.search_type,
    properties
  );

  const allResultsSqftAverage = measurePropertiesSqftAverage(
    search?.spec?.search_type,
    properties
  );

  const compsPriceAverage = measurePropertiesPriceAverage(
    search?.spec?.search_type,
    comps
  );
  const compsPpsfAverage = measurePropertiesPpsfAverage(
    search?.spec?.search_type,
    comps
  );
  const compsLatestPriceAverage = measurePropertiesLatestPriceAverage(
    search?.spec?.search_type,
    comps
  );
  const compsLatestPpsfAverage = measurePropertiesLatestPpsfAverage(
    search?.spec?.search_type,
    comps
  );

  const yieldAverage =
    (100 *
      ((comps.length > 0 ? compsPriceAverage : allResultsPriceAverage) * 12)) /
    valuationData?.valuationPrice;

  const compsMonthlyAverage =
    comps.reduce((a, b) => a + b.price, 0) / comps.length;
  const compsYieldAverage =
    (100 * (compsMonthlyAverage * 12)) / valuationData?.valuationPrice;

  const PropertySelectionText = ({ searchType }) => {
    return (
      <>
        {searchType === "sold" &&
        search.spec?.search_landreg &&
        !search.spec?.search_rm &&
        !search.spec?.search_zoopla
          ? // ? " Sold" // Removing this as interferes in case when all 3x property sources are on
            " Sold"
          : searchType === "forsale"
          ? " Listed"
          : searchType === "rental"
          ? " Rental"
          : ""}
      </>
    );
  };

  const NoResults = (props) => "No Results Found";

  const allSearchesDone =
    search?.zooplaSearchComplete &&
    search?.landregSearchComplete &&
    search?.rmSearchComplete;

  const totalLandregResults = properties.filter(
    (prop) => prop.propertySource === "landreg"
  ).length;
  const totalRmResults = properties.filter(
    (prop) => prop.propertySource === "rightmove"
  ).length;
  const totalZooplaResults = properties.filter(
    (prop) => prop.propertySource === "zoopla"
  ).length;

  const searchLimitHit =
    totalLandregResults >= 300 ||
    totalRmResults >= 300 ||
    totalZooplaResults >= 300;

  return (
    <div
      style={{
        fontSize: isSmallWidth ? "16px" : "19px",
        color: "#666666",
        display: "flex",
        alignItems: "center",
      }}
    >
      <SearchResultsStats
        properties={properties}
        totalResults={search?.propertyIds?.length}
        search={search}
      >
        {properties.length === 0 ? (
          !searchFinished || !cacheFinished ? (
            <>Searching...</>
          ) : (
            <>No Results Found</>
          )
        ) : comps?.length > 0 ? (
          <div
            className="flex-center-center"
            style={{ flexDirection: isSmallWidth ? "column" : "row" }}
          >
            <div className="flex-center-center">
              Comparing&nbsp;
              {comps.length}
              &nbsp;of&nbsp;
              {getAnalysedProperties(search, properties).length !==
              properties.length ? (
                <span style={{ WebkitAnimation: "fade 1s infinite" }}>
                  {getAnalysedProperties(search, properties).length}
                </span>
              ) : (
                properties.length
              )}
              &nbsp;
              {properties.length > 1 ? "Properties" : "Property"}
              &nbsp;
            </div>
            {showTodayPrice && searchType === "sold" ? (
              <PriceComponent
                searchType={searchType}
                priceAverage={compsLatestPriceAverage}
                yieldAverage={yieldAverage}
                ppsfAverage={compsLatestPpsfAverage}
                isValuationSearch={isValuationSearch}
                showTodayPrice={showTodayPrice}
                isSmallWidth={isSmallWidth}
                onlyShowPrice={
                  compsLatestPpsfAverage <= 0 || isNaN(compsLatestPpsfAverage)
                }
              />
            ) : (
              <PriceComponent
                searchType={searchType}
                priceAverage={compsPriceAverage}
                yieldAverage={yieldAverage}
                ppsfAverage={compsPpsfAverage}
                isValuationSearch={isValuationSearch}
                isSmallWidth={isSmallWidth}
                onlyShowPrice={compsPpsfAverage <= 0 || isNaN(compsPpsfAverage)}
              />
            )}
          </div>
        ) : searchFinished && cacheFinished ? (
          <div
            className="flex-center-center"
            style={{ flexDirection: isSmallWidth ? "column" : "row" }}
          >
            <div className="flex-center-center">
              Found {properties.length}
              {!search?.spec?.search_plan ? (
                <>
                  <PropertySelectionText searchType={searchType} /> Propert
                  {search?.propertyIds?.length === 1 ? "y" : "ies"}
                  &nbsp;
                </>
              ) : (
                ` Planning Applications`
              )}
            </div>
            {!search?.spec?.search_plan && (
              <PriceComponent
                searchType={searchType}
                priceAverage={allResultsPriceAverage}
                yieldAverage={yieldAverage}
                ppsfAverage={allResultsPpsfAverage}
                isValuationSearch={isValuationSearch}
                isSmallWidth={isSmallWidth}
                onlyShowPrice={
                  allResultsPpsfAverage <= 0 || isNaN(allResultsPpsfAverage)
                }
              />
            )}
          </div>
        ) : (
          <div
            className="flex-center-center"
            style={{ flexDirection: isSmallWidth ? "column" : "row" }}
          >
            <div className="flex-center-center">
              <div className="loader-tiny"></div>
              <div>&nbsp;&nbsp;Analysing&nbsp;</div>
              <span
                style={{
                  WebkitAnimation: "fade 1s infinite",
                }}
              >
                {getAnalysedProperties(search, properties).length}
              </span>
              &nbsp;of&nbsp;
              <span
                style={{
                  WebkitAnimation: allSearchesDone ? "" : "fade 1s infinite",
                }}
              >
                {search.propertyIds.length}
              </span>
              &nbsp;
              {!search?.spec?.search_plan ? (
                <>
                  Propert{search?.propertyIds?.length === 1 ? "y" : "ies"}&nbsp;
                </>
              ) : (
                ` Planning Applications`
              )}
            </div>
            {!search?.spec?.search_plan && (
              <PriceComponent
                searchType={searchType}
                priceAverage={allResultsPriceAverage}
                yieldAverage={yieldAverage}
                ppsfAverage={allResultsPpsfAverage}
                onlyShowPrice={
                  allResultsPpsfAverage <= 0 || isNaN(allResultsPpsfAverage)
                }
                isValuationSearch={isValuationSearch}
              />
            )}
          </div>
        )}
        {properties.length > 0 && (
          <>
            &nbsp;&nbsp;
            {
              searchLimitHit ? (
                <FontAwesomeIcon
                  icon={["far", "exclamation-triangle"]}
                  style={{
                    color: "red",
                    fontSize: isSmallWidth ? "14px" : "16px",
                  }}
                />
              ) : null
              // <FontAwesomeIcon
              //   icon={["far", "info-circle"]}
              //   style={{
              //     color: "#666666",
              //     fontSize: isSmallWidth ? "14px" : "16px",
              //   }}
              // />
            }
          </>
        )}
        {/* <SearchDetailsInfo search={search} /> */}
      </SearchResultsStats>
    </div>
  );
};

export default SearchStatusOneLine;
