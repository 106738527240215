import { Field } from "formik";
import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { formatMoneyRounded } from "../../../../services/formatting";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InputBase from "@material-ui/core/InputBase";
// prettier-ignore
const salePrices = [0, 10000, 25000, 50000, 75000, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 300000, 350000, 400000, 450000, 500000, 550000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 950000, 1000000, 1100000, 1250000, 1500000, 1750000, 2000000, 2500000, 3000000, 4000000, 5000000, 7500000, 10000000, 15000000, 20000000, 50000000, 100000000, 200000000];
// prettier-ignore
const rentPrices = [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 4000, 5000, 10000, 15000, 20000, 30000, 50000];

const MenuItem = withStyles({
  root: {
    // justifyContent: "center",
  },
})(MuiMenuItem);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    // fontSize: "17px !important",
    padding: "6px 26px 6px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

const PricePickers = ({ values, setFieldValue }) => {
  const prices = values?.search_type === "sale" ? salePrices : rentPrices;

  let displayPrices = prices;
  if (!displayPrices.includes(values?.pricemin))
    displayPrices.push(values?.pricemin);
  if (!displayPrices.includes(values?.pricemax))
    displayPrices.push(values?.pricemax);

  displayPrices.sort((a, b) => a - b);

  return (
    <div className={"search-form-item"}>
      {/* <div className={"search-form-label"}></div> */}
      <Field
        component={Select}
        name="pricemin"
        value={values?.pricemin}
        onChange={(e, newValue) => {
          if (newValue) {
            setFieldValue("pricemin", newValue.props.value);
          }
        }}
        aria-labelledby="price-min"
        style={{ width: "110px" }}
        input={<BootstrapInput />}
        // MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        {displayPrices.map((price) => {
          return price <= values?.pricemax ? (
            <MenuItem key={price} value={price} dense>
              {displayPrices[0] === price ? (
                <>
                  Min&nbsp;&nbsp;
                  <FontAwesomeIcon icon={["far", "pound-sign"]} />
                </>
              ) : (
                <>
                  £{formatMoneyRounded(price, 0)}
                  {values?.search_type === "rent" ? "pm" : ""}
                </>
              )}
            </MenuItem>
          ) : null;
        })}
      </Field>
      <div className={"search-form-to-label"}>to</div>
      <Field
        component={Select}
        name="pricemax"
        type="number"
        value={values?.pricemax}
        onChange={(e, newValue) => {
          if (newValue) {
            setFieldValue("pricemax", newValue.props.value);
          }
        }}
        aria-labelledby="price-max"
        style={{ width: "110px" }}
        input={<BootstrapInput />}
        // MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        {displayPrices.map((price) => {
          return price >= values?.pricemin ? (
            <MenuItem key={price} value={price} dense>
              {displayPrices[displayPrices.length - 1] === price ? (
                <>
                  Max&nbsp;&nbsp;
                  <FontAwesomeIcon icon={["far", "pound-sign"]} />
                </>
              ) : (
                <>
                  £{formatMoneyRounded(price, 0)}
                  {values?.search_type === "rent" ? "pm" : ""}
                </>
              )}
            </MenuItem>
          ) : null;
        })}
      </Field>
    </div>
  );
};

export default PricePickers;
