import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { addNameForLocation } from "./locationsSlice";
import { getLocationFromLatLng } from "../../services/geocoding";

/**
 * Get the properties from the cache
 */
const getLocation = createSelector(
  ({ locations }) => locations, // locations slice
  (_, position) => `${position[0]}::${position[1]}`, // position key
  (locations, key) => locations[key] // location name for key
);

/**
 * Convencience hook for getting access to property results
 */
const useLocation = (position) => {
  const dispatch = useDispatch();
  // cache hits
  const location = useSelector((state) => getLocation(state, position));

  const fetchAndStoreMissingLocation = useCallback(
    async (position) => {
      const newLocation = await getLocationFromLatLng(position[1], position[0]);
      dispatch(
        addNameForLocation({
          lng: position[0],
          lat: position[1],
          name: newLocation,
        })
      );
    },
    [dispatch]
  );

  // if it's missing, get it and stash it
  useEffect(() => {
    if (!location) {
      fetchAndStoreMissingLocation(position);
    }
  }, [position, location, fetchAndStoreMissingLocation]);

  return location;
};

export default useLocation;
