import { useEffect } from "react";
import Debug from "debug";
import {
  fetchLocationHistoryPrice,
  fetchLocationHistorySales,
} from "../../graphql/functions";
import { useSelector, useDispatch } from "react-redux";
import { setLocationHistoryDataRedux } from "data/slices/locationHistorySlice";
import { getLocationHistorySelector } from "data/selectors";

const debug = Debug("lw:useLocationHistory");

/**
 * Custom hook to get locationHistory data. returns [error, locationhistoryData, isFinished]
 *
 * @param {*} locData
 */
function useLocationHistory(
  mode,
  locData,
  startDate,
  yearsToCheck,
  propertyExists
) {
  const dispatch = useDispatch();

  const populateRedux = async (locData) => {
    console.log(
      `Querying backend for LocationHistory Data - ${yearsToCheck} years`
    );
    // const yearsToCheck = 3;

    // Start both fetch operations in parallel
    const [locationHistoryPriceData, locationHistorySalesData] =
      await Promise.all([
        fetchLocationHistoryPrice({
          mode,
          locData,
          date: startDate,
          monthsToCheck: yearsToCheck * 6 + 1,
        }),
        fetchLocationHistorySales({
          mode,
          locData,
          date: startDate,
          grouping: "quarter",
          monthsToCheck: yearsToCheck * 12 + 1,
        }),
      ]);

    // Filter price data
    const filteredPriceData = {
      dataPoints: locationHistoryPriceData.dataPoints.filter(
        (item) => item.lw > 0
      ),
    };

    // Dispatch to Redux if the filtered data is available
    if (filteredPriceData) {
      dispatch(
        setLocationHistoryDataRedux({
          locationHistoryId: locData,
          locationHistoryPriceData: filteredPriceData,
          locationHistorySalesData,
        })
      );
    }
  };

  // grab locationHistory data if we need to
  useEffect(() => {
    if (locData && propertyExists) {
      // runDataPolling(true);
      debug("Run data polling......" + locData);
      populateRedux(locData);
    } else {
      console.log("empty val id");
    }
  }, [locData]);

  const { locationHistoryPriceData, locationHistorySalesData } = useSelector(
    (state) => getLocationHistorySelector(state, locData)
  );

  return [locationHistoryPriceData, locationHistorySalesData];
}

export default useLocationHistory;
