import React from "react";
import {
  formatMoneyFull,
  formatDateDDMMMYYY,
} from "../../../../services/formatting";

const PropertyPriceChange = ({ oldPrice, change }) => {
  return (
    <div
      style={{
        fontWeight: "bold",
        color: change.direction === "down" ? "green" : "red",
      }}
    >
      {formatDateDDMMMYYY(change.date)}:{" "}
      <span style={{ textDecoration: "line-through", fontWeight: "normal" }}>
        £{formatMoneyFull(oldPrice, 0)}
      </span>{" "}
      £{formatMoneyFull(change.price, 0)}
    </div>
  );
};

const ListingHistory = ({ property }) => {
  const priceChange = property.priceChange && JSON.parse(property.priceChange);

  const firstItemLabel =
    property.rmAutoEmailReasonType === "price_reduced"
      ? "Reduced to"
      : "Listed for";

  return (
    <div style={{ display: "flex", flexDirection: "column-reverse" }}>
      <div>
        {formatDateDDMMMYYY(property.dateFirstListed)}: {firstItemLabel} £
        {formatMoneyFull(property.priceFirstListed || property.price, 0)}
      </div>
      {priceChange &&
        priceChange.length > 1 &&
        priceChange.map((change, index) => {
          if (index === 0) return null;
          return (
            <PropertyPriceChange
              key={index}
              index={index}
              oldPrice={priceChange[index - 1].price}
              change={change}
            />
          );
        })}
    </div>
  );
};

export default ListingHistory;
