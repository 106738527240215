import { createSlice } from "@reduxjs/toolkit";

const creditsSlice = createSlice({
  name: "credits",
  initialState: {},
  reducers: {
    setCreditsDataRedux(state, action) {
      const {
        creditsRemaining,
        creditsMonthly,
        stripePriceId,
        stripeCustomerId,
        hasPaidMoney,
        orgId,
      } = action.payload;

      return {
        ...state,
        creditsRemaining,
        creditsMonthly,
        stripeCustomerId,
        stripePriceId,
        hasPaidMoney,
        orgId,
      };
    },
  },
});

export const { setCreditsDataRedux } = creditsSlice.actions;

export default creditsSlice.reducer;
