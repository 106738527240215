import { parse, parseISO } from "date-fns";

const cleanPhrases = (text) =>
  text.replace("Added on ", "").replace("Reduced on ", "");

const getDateHelper = (item) =>
  item?.dateSold
    ? parseISO(item.dateSold) // When landreg record found
    : item?.linkDate
    ? item?.propertySource === "rm"
      ? item?.linkPrice
        ? parse(item.linkDate, "yyyyMMdd", new Date()) // When old RM /properties listing found
        : item.linkDate.length > 15 // Check for longer linkDate (needs cleaning)
        ? parse(cleanPhrases(item.linkDate), "dd/MM/yyyy", new Date()) // When old RM /properties listing not found and listing date not found on history page
        : parse(item.linkDate, "MMMM yyyy", new Date()) // When old RM /properties listing not found
      : item?.propertySource === "zoopla" &&
        (item?.listingType === "currentlyforsale" ||
          item?.listingType === "currentlyforrent")
      ? parseISO(item.linkDate)
      : parse(item.linkDate, "MMMM yyyy", new Date()) // When old zoopla listing not found
    : item?.date
    ? parse(item.date, "MMM yyyy", new Date())
    : new Date(100000000000); // Default error case

export default getDateHelper;
