import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { fetchUserSearchHistory } from "graphql/functions";
import SearchHistoryTable from "./SearchHistoryTable";

import "./userHistory.css";

async function getHistory(setSearchHistory) {
  const history = await fetchUserSearchHistory();
  const sortedHistory = history?.searches.sort(
    (a, b) => new Date(b.dateRun) - new Date(a.dateRun)
  );
  setSearchHistory(sortedHistory);
}

const NoSearchHistory = ({ history }) => (
  <div
    className="flex-center-center"
    style={{ textAlign: "center", flexDirection: "column", fontSize: "1.1em" }}
  >
    You haven't run any searches yet!
    <br />
    <br />
    <div>
      Visit the&nbsp;
      <a
        href="/search/history"
        onClick={(event) => {
          history.push("/search/history");
          event.preventDefault();
        }}
        style={{ fontWeight: "bold" }}
      >
        New Search
      </a>
      &nbsp;page to start
    </div>
  </div>
);

const SearchHistory = ({ history, isLoggedIn }) => {
  const [searchHistory, setSearchHistory] = useState();

  useEffect(() => {
    document.title = "Landworth: Search History";
    getHistory(setSearchHistory);
  }, []);

  const loadSearch = (searchID) => {
    history.push("/search/" + searchID);
  };

  return (
    <>
      <div
        className="flex-center-center"
        style={{
          flex: "1 1 auto",
          flexDirection: "column",
          overflowY: "auto",
          height: "100%",
          borderTop: "1px solid grey",
        }}
      >
        {searchHistory === undefined ? (
          <div className="loader-big" />
        ) : searchHistory.length === 0 ? (
          <NoSearchHistory history={history} />
        ) : (
          <SearchHistoryTable
            searchHistory={searchHistory}
            loadURL={loadSearch}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(SearchHistory);
