import { useEffect } from "react";
import { withRouter } from "react-router-dom";

import "../../App.css";
import Map from "../Map";

import { Formik } from "formik";
import DEFAULT_SEARCH_SPEC from "pages/Search/DEFAULT_SEARCH_SPEC";

/**
 * Basic home component
 */
const Home = ({ isLoggedIn }) => {
  useEffect(() => {
    if (!isLoggedIn) window.analytics.page();
  }, [isLoggedIn]);

  return (
    <div className="app-wrapper">
      <div className="row">
        <div className={`map-column column100pc`}>
          <Formik initialValues={DEFAULT_SEARCH_SPEC} enableReinitialize>
            <Map isLoggedIn={isLoggedIn} />
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
