import { createSlice } from "@reduxjs/toolkit";

/**
 * A reverse geocode cache
 */
const locationsSlice = createSlice({
  name: "locations",
  initialState: {},
  reducers: {
    addNameForLocation(state, action) {
      const { lat, lng, name } = action.payload;
      const key = `${lng}::${lat}`;
      return {
        ...state,
        [key]: name,
      };
    },
  },
});

export const { addNameForLocation } = locationsSlice.actions;

export default locationsSlice.reducer;
