import Checkbox from "@material-ui/core/Checkbox";
import { Field } from "formik";

const SearchFormItem = ({ checked, setChecked, label }) => {
  return (
    <div className={"search-form-item"} style={{ alignItems: "center" }}>
      <label>
        <Field
          component={Checkbox}
          id={`include_house_type_${label}`}
          color={checked ? "secondary" : "default"}
          size="small"
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        >
          X
        </Field>
        <span
          style={{
            position: "relative",
            top: "2px",
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          {label}
        </span>
      </label>
    </div>
  );
};

export default SearchFormItem;
