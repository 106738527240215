import useLocalStorage from "@rehooks/local-storage";
import GenericSplash from "./GenericSplash";

const AcceptTermsSplash = () => {
  const [acceptedTerms, setAcceptedTerms] = useLocalStorage(
    "acceptedTerms",
    false
  );

  const clickAcceptHandler = () => {
    // const now = new Date();
    // window.analytics.track("Clicked Accept Terms", { time: now.toISOString() });
    setAcceptedTerms(true);
  };

  return (
    <>
      {!acceptedTerms && (
        <GenericSplash
          title={`Welcome to Landworth`}
          message={
            <>
              <span style={{ fontSize: "1.0rem" }}>
                As this is your first time signing in from this device, please
                read and accept our terms of service
              </span>
              <ul style={{ fontSize: "0.8rem" }}>
                <li>
                  <span>
                    You understand that Landworth does not provide financial
                    advice. Analysis of historical market performance is not a
                    reliable indicator of future outcomes.
                  </span>
                </li>
                <br />
                <li>
                  <span>
                    All data and information provided should be independently
                    verified with a qualified professional before being used as
                    part of any financial decision.
                  </span>
                </li>
                <br />
                <li>
                  <span>
                    When you use this website, all your data is processed in
                    compliance with local Privacy Laws as well the General Data
                    Protection Regulation (“GDPR”).
                  </span>
                </li>
                <br />
                <li>
                  <span>
                    We will not process your data unless it is essential for
                    delivering our Service or improving your User Experience on
                    the website.
                  </span>
                </li>
              </ul>
              <div style={{ paddingTop: "2px" }}></div>
              <hr
                style={{
                  backgroundColor: "#ddd",
                  border: "0px",
                  height: "1px",
                }}
              />
              <div
                style={{
                  fontSize: "0.7em",
                  lineHeight: "16px",
                  // textAlign: "right",
                }}
              >
                <b>Data is licensed under the Open Government Licence v3.0</b>
                <br />
                Contains HM Land Registry data © Crown copyright and database
                right 2023
                <br />
                Contains National Statistics data © Crown copyright and database
                rights 2023
                <br />
                Contains OS data © Crown copyright and database rights 2023
                <br />
                Contains Royal Mail data © Royal Mail copyright and database
                rights 2023
              </div>
            </>
          }
          buttonLabel={"ACCEPT TERMS & CONDITIONS"}
          analyticsMessage={"Splash - Accept T&C's"}
          buttonTrigger={() => clickAcceptHandler()}
        />
      )}
    </>
  );
};

export default AcceptTermsSplash;
