import React from "react";
import ReactTimeAgo from "react-time-ago";
import PropertyListingStatus from "../../../components/PropertyListingStatus";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import PropertyLandworthScore from "../../../components/PropertyLandworthScore";
import PropertyBeds from "../../../components/PropertyBeds";
import PropertyBaths from "components/PropertyBaths";
import PropertyType from "../../../components/PropertyType";
import PropertySqft from "../../../components/PropertySqft";
import GenericTooltip from "components/tooltips/GenericTooltip";

import PropertyThumbnail from "components/PropertyThumbnail";

import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import useSearchView from "data/hooks/useSearchView";
import useDeviceWidth from "data/hooks/useDeviceWidth";
import { PlanningStatusLarge } from "components/planning/PlanningStatus";

/**
 * Selector to get property from the Redux property slice
 */
const getPropertySelector = createSelector(
  ({ properties }) => properties,
  (_, propertyId) => propertyId,
  (cache, propertyId) => cache[propertyId]
);

const styles = {
  container: {
    justifyContent: "center",
    textAlign: "center",
    minWidth: "175px",
    fontSize: "15px",
    padding: "12px",
  },
  title: {
    maxWidth: "300px",
    textAlign: "left",
    marginTop: "3px",
    marginBottom: "3px",
    color: "#555",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.3em",
  },
  price: { marginLeft: "auto" },
  spec: { display: "flex", justifyContent: "space-between", color: "#555" },
};

const PropertyPopup = ({ property, select }) => {
  const {
    propertyID,
    propertySource,
    price,
    address,
    sqft,
    dateFirstListed,
    dateSold,
    listingURL,
    listingStatus,
    firstListed,
  } = property;

  const [searchView, setSearchView] = useSearchView();
  const [isSmallWidth] = useDeviceWidth();
  const showPropertyBeds = property.beds || property.rmBeds || property.zBeds;
  const showPropertyBaths =
    property.baths || property.rmBaths || property.zBaths;
  const handleDetailsButtonClick = () => {
    if (searchView === "full_map") {
      if (isSmallWidth) {
        setSearchView("full_list");
      } else {
        setSearchView("split");
      }
    }
    setTimeout(() => select(propertyID), 1);
  };

  return (
    <>
      <PropertyThumbnail
        property={property}
        thumbHeight={"150px"}
        fullWidth={true}
      />
      <div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <div style={{ paddingRight: "8px" }}>
            {property.propertySource === "plan" ? (
              <PlanningStatusLarge status={property.appState} />
            ) : (
              <PropertyListingStatus property={property} />
            )}
          </div>
          {price > 0 && (
            <div
              style={{
                fontWeight: "bold",
                marginRight: "10px",
                color: "#555",
              }}
            >
              {price >= 100000 && price < 1000000 && price % 1000 === 0
                ? `£${Math.round(price / 1000)}k`
                : `£${price.toLocaleString("en-UK", {
                    minimumFractionDigits: 0,
                  })}`}
              {(listingStatus === "for_rent" ||
                listingStatus === "rented" ||
                listingStatus === "rent_under_offer") && (
                <span style={{ fontSize: "12px", color: "#555" }}>/month</span>
              )}
            </div>
          )}
          <div style={{ padding: "4px 0 4px", marginRight: "10px" }}>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "0px 6px",
                borderRadius: "5px",
                backgroundColor: "#f0f0f0",
                color: "#555",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={solid("clock")}
                style={{ fontSize: "1.1em", marginRight: "4px" }}
              />
              <ReactTimeAgo
                date={new Date(dateFirstListed || dateSold || firstListed)}
              />
            </div>
          </div>
        </div>
        <GenericTooltip text={address} style={styles.title}>
          <div style={styles.title}>{address}</div>
        </GenericTooltip>
        {propertySource !== "plan" && (
          <div style={styles.spec}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "13px",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={solid("house")}
                />
                <PropertyType property={property} hideHouseText={true} />
              </div>
              {showPropertyBeds && (
                <div
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={solid("bed-front")}
                  />
                  <PropertyBeds property={property} hideBedText={true} />
                </div>
              )}
              {showPropertyBaths > 0 && (
                <div
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={solid("bath")}
                  />
                  <PropertyBaths property={property} hideBathText={true} />
                </div>
              )}
              <div
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={solid("arrows-maximize")}
                />
                <PropertySqft sqft={sqft} hideSizeText={true} />
              </div>
            </div>
            {/* <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              {["for_sale", "sold", "under_offer"].includes(listingStatus) && (
                <PropertyLandworthScore property={property} />
              )}
            </div> */}
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          {propertySource === "zoopla" && (
            <a
              target="_new"
              href={listingURL}
              rel="noopener noreferrer"
              style={{ textDecoration: "none", marginRight: "8px" }}
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  marginRight: "8px",
                }}
              >
                Zoopla
              </Button>
            </a>
          )}
          {propertySource === "rightmove" && (
            <a
              target="_new"
              href={listingURL}
              rel="noopener noreferrer"
              style={{ textDecoration: "none", marginRight: "8px" }}
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#CCCCCC",
                  color: "black",
                  marginRight: "8px",
                }}
              >
                Rightmove&nbsp;&nbsp;
                <FontAwesomeIcon
                  style={{ marginRight: "5px", fontSize: "1.1em" }}
                  icon={solid("arrow-up-right-from-square")}
                />
              </Button>
            </a>
          )}
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#CCCCCC",
              color: "black",
            }}
            onClick={handleDetailsButtonClick}
          >
            Details&nbsp;
            <FontAwesomeIcon
              icon={regular("memo-circle-info")}
              style={{ marginLeft: "4px", fontSize: "1.2em" }}
            />
          </Button>
        </div>
      </div>
    </>
  );
};

const MapPropertyPopup = ({ feature, select }) => {
  const property = useSelector((state) =>
    getPropertySelector(state, feature.properties.propertyID)
  );

  return (
    <div style={styles.container} key={property.propertyID}>
      <PropertyPopup property={property} select={select} />
    </div>
  );
};

export default MapPropertyPopup;
