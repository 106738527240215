import { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

const EditableCell = (props) => {
  const { value, onSave, classes } = props;

  const [editing, setEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  const startEditing = (e) => {
    e.stopPropagation();
    setEditing(true);
    window.analytics.track("Search History - Edit Search Name", {
      searchName: value,
    });
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setTempValue(value);
    setEditing(false);
    // onCancel();
  };

  const handleSave = (e) => {
    e.stopPropagation();
    // onChange(tempValue);
    setEditing(false);
    onSave(tempValue);
  };

  const handleChange = (event) => {
    setTempValue(event.target.value);
  };

  if (editing) {
    return (
      <TableCell>
        <TextField
          value={tempValue}
          onChange={handleChange}
          fullWidth
          margin="none"
          variant="standard"
          style={{ width: "80%" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <IconButton onClick={handleSave}>
          <CheckIcon />
        </IconButton>
        <IconButton onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    );
  }

  return (
    <TableCell style={{ padding: "0px" }}>
      {value}&nbsp;
      <IconButton onClick={startEditing} className={classes.editButton}>
        <EditIcon className={classes.smallIcon} />
      </IconButton>
    </TableCell>
  );
};

export default EditableCell;
