import { Switch, Route, Redirect } from "react-router-dom";

import NewSignInComponent from "./auth/NewSignInComponent";
import NewSignUpComponent from "./auth/NewSignUpComponent";

import Home from "./pages/UnauthenticatedHome/Home";
import Search from "./pages/Search";
import SearchHistory from "./pages/UserHistory/SearchHistory";
import SearchFavourites from "./pages/UserHistory/SearchFavourites";
import Report from "./pages/Report";
import Account from "./pages/Account";
import Settings from "./pages/Settings";

const Routes = ({ isLoggedIn }) => {
  return (
    <Switch>
      <Route exact path="/">
        <Home isLoggedIn={isLoggedIn} />
      </Route>
      <Route path="/map">
        <Home isLoggedIn={isLoggedIn} />
      </Route>
      <Route path="/search/history">
        {isLoggedIn ? (
          <SearchHistory isLoggedIn={isLoggedIn} />
        ) : (
          <NewSignInComponent />
        )}
      </Route>
      <Route path="/search/favourites">
        {isLoggedIn ? (
          <SearchFavourites isLoggedIn={isLoggedIn} />
        ) : (
          <NewSignInComponent />
        )}
      </Route>
      <Route path="/search">
        {isLoggedIn ? (
          <Search isLoggedIn={isLoggedIn} />
        ) : (
          <NewSignInComponent />
        )}
      </Route>
      <Route path="/report">
        <Report isLoggedIn={isLoggedIn} />
      </Route>

      <Route path="/account/annual">
        {isLoggedIn ? (
          <Account isLoggedIn={isLoggedIn} plans={"annual"} />
        ) : (
          <NewSignInComponent />
        )}
      </Route>
      <Route path="/account">
        {isLoggedIn ? (
          <Account isLoggedIn={isLoggedIn} plans={"monthly"} />
        ) : (
          <NewSignInComponent />
        )}
      </Route>

      <Route path="/settings">
        {isLoggedIn ? (
          <Settings isLoggedIn={isLoggedIn} />
        ) : (
          <NewSignInComponent />
        )}
      </Route>

      <Route path="/join/:referrer?" component={NewSignUpComponent} />

      <Route
        path={`/signin/:originalPath`}
        render={({ location }) => {
          return isLoggedIn ? (
            <Redirect to={`${location.pathname.slice(7)}`} />
          ) : (
            <NewSignInComponent />
          );
        }}
      ></Route>
      <Route
        path="/:referrer?"
        render={(route) => {
          if (!route.match.params.referrer) {
            window.location = "https://www.landworth.org/";
          } else {
            window.location =
              // "https://www.landworth.org/" + route.match?.params?.referrer; // "landworth-landing" URL format
              "https://www.landworth.org/?r=" + route.match?.params?.referrer; // landworth-webflow-react URL format
          }
        }}
      ></Route>
    </Switch>
  );
};

export default Routes;
