import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import { formatMonthsAsYears } from "services/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// prettier-ignore
const soldMonthsOptions = [6, 12, 18, 24, 30, 36, 48, 60, 90, 120, 180, 240, 300];

const MenuItem = withStyles({
  root: {
    justifyContent: "center",
  },
})(MuiMenuItem);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    lineHeight: "1.43",
  },
  input: {
    // borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    fontWeight: "bold",
    padding: "0px 3px 0px 6px",
    fontSize: "13px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

const TransactionAge = (props) => {
  const { values, setFieldValue } = props;
  return (
    <div className={"search-form-item"} style={{ marginTop: "5px" }}>
      <div
        className={"search-form-label"}
        style={{ marginRight: "10px", marginTop: "0px" }}
      >
        <FontAwesomeIcon icon={["far", "calendar-alt"]} />
        &nbsp;&nbsp;Sold within
      </div>

      <Select
        name="sold_history_months"
        id="sold_history_months"
        value={values.sold_history_months}
        input={<BootstrapInput />}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue) {
            setFieldValue("sold_history_months", newValue);
          }
        }}
      >
        {soldMonthsOptions.map((months) => (
          <MenuItem key={months} value={months} dense>
            {formatMonthsAsYears(months)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TransactionAge;
