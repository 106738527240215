import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";

import SearchFormAccordion from "./SearchForm/SearchFormAccordion";
import useSearchDrawer from "./Data/useSearchDrawer";
import "./SearchForm/formStyle.css";

const getCircleDetails = createSelector(
  ({ circle }) => circle,
  (circle) => circle
);

const SearchFormSlim = (props) => {
  const { values, setFieldValue, setValues, submitForm } = props;

  const [, setSearchDrawerOpen] = useSearchDrawer();

  return (
    <>
      <div
        className="sidebar-container nice-scrollbar"
        style={{ userSelect: "none", backgroundColor: "#f8f4ff" }}
      >
        <div className="search-form-container">
          <div>
            <div
              className="popup-close-button"
              onClick={() => setSearchDrawerOpen(false)}
            >
              X
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "1.4rem",
                fontWeight: "bold",
                userSelect: "none",
                paddingTop: "15px",
              }}
            >
              Data Sources
            </div>
            <SearchFormAccordion
              setFieldValue={setFieldValue}
              values={values}
              submitForm={submitForm}
              setValues={setValues}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SearchFormSlim);
