import { Field } from "formik";
import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import PlanningAppMultiSelect from "./PlanningAppMultiSelect";

const states = [
  "Permitted",
  "Conditions",
  "Rejected",
  "Withdrawn",
  "Undecided",
  "Referred",
  "Other",
];

const PlanningAppState = (props) => {
  // const { values, setFieldValue } = props;

  return (
    <div className="flex-center-center">
      <FontAwesomeIcon icon={regular("gavel")} />
      &nbsp;&nbsp;&nbsp;Status&nbsp;&nbsp;&nbsp;
      <PlanningAppMultiSelect
        {...props}
        options={states}
        fieldName={"planningAppStates"}
      />
    </div>
  );
};

export default PlanningAppState;
