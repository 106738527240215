import { Field } from "formik";
import Button from "@material-ui/core/Button";

const SearchType = (props) => {
  const { values, setFieldValue } = props;

  const setDefaultMinMaxPrice = (type) => {
    if (type === "rent") {
      setFieldValue("pricemin", 0);
      setFieldValue("pricemax", 50000);
    } else if (type === "sale") {
      setFieldValue("pricemin", 0);
      setFieldValue("pricemax", 200000000);
    }
  };

  return (
    <>
      <div className={"search-form-item"}>
        <div className={"search-form-label"}></div>
        <Field
          component={Button}
          name="search_type_sales"
          id="search_type_sales"
          className="search-button-wide"
          variant={values.search_type === "sale" ? "contained" : "outlined"}
          color={values.search_type === "sale" ? "secondary" : "default"}
          size="small"
          onClick={(e) => {
            setFieldValue("search_type", "sale");
            setDefaultMinMaxPrice("sale");
          }}
        >
          Sales
        </Field>
        <Field
          component={Button}
          name="search_source_zoopla"
          id="search_source_zoopla"
          className="search-button-wide"
          variant={values.search_type === "rent" ? "contained" : "outlined"}
          color={values.search_type === "rent" ? "secondary" : "default"}
          size="small"
          onClick={(e) => {
            setFieldValue("search_type", "rent");
            setDefaultMinMaxPrice("rent");
          }}
        >
          Rentals
        </Field>
      </div>
    </>
  );
};

export default SearchType;
