import { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import HeaderHolder from "./HeaderHolder";
import Routes from "./Routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import AcceptTermsSplash from "components/splashes/AcceptTermsSplash";

const AuthContainer = () => {
  const { route, signOut, user } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  const params = new URLSearchParams(window.location.search);
  const mode = params.get("mode");

  useEffect(() => {
    if (route === "authenticated") {
      window.analytics.identify(user.attributes.email, {
        email: user.attributes.email,
        name: user.attributes.name,
        subscriber_id: user.attributes.sub,
        DELTAUSER: true,
      });
      window.analytics.page();
    }
  }, [route]);

  const isLoggedIn = !!(route === "authenticated" && user);

  const handleSignOut = () => {
    window.analytics.track("Click Sign Out");
    signOut();
    window.location.href = "/";
  };

  return (
    <>
      <CssBaseline />

      {/* {mode !== "noheader" && window.location.pathname === "/" && (
        <AcceptTermsSplash />
      )} */}
      <div className="app-wrapper">
        {mode !== "noheader" && (
          <div style={{ flex: "0 1 auto" }}>
            <HeaderHolder
              isLoggedIn={isLoggedIn}
              handleSignOut={handleSignOut}
            />
          </div>
        )}

        <Routes isLoggedIn={isLoggedIn} />
      </div>
    </>
  );
};

export default AuthContainer;
