import { createSlice } from "@reduxjs/toolkit";

export const defaultMap = {
  popupInfo: null,
};

const mapSlice = createSlice({
  name: "map",
  initialState: defaultMap,
  reducers: {
    setPopupInfo(state, action) {
      const { popupInfo } = action.payload;
      return {
        ...state,
        popupInfo,
      };
    },
  },
});

export const { setPopupInfo } = mapSlice.actions;

export default mapSlice.reducer;
