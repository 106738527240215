import usePropertyData from "data/hooks/usePropertyData";
import useSearchResults from "data/hooks/useSearchResults";
import { getComparableScore } from "services/comparables";

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

const getPropertySortDate = (property) => {
  return property.dateFirstListed || property.dateSold || property.firstListed;
};

const sortProperties = ({
  properties,
  sortMode,
  searchLat,
  searchLng,
  valuation,
  valuationProperty,
}) => {
  let sortedProperties;

  const valuationPropertyMerged = {
    price: valuation?.valuationPrice,
    sqft: valuationProperty?.sqft,
    beds: valuationProperty?.beds,
    valuationDate: valuation?.valuationDate,
    lat: valuationProperty?.lat,
    lng: valuationProperty?.lng,
  };

  if (sortMode === "bestdeal") {
    sortedProperties = properties.sort((a, b) => {
      if (!a.landworthScore || a.landworthScore === -1) return 1;
      if (!b.landworthScore || b.landworthScore === -1) return -1;
      return parseFloat(a.landworthScore) - parseFloat(b.landworthScore);
    });
  } else if (sortMode === "cheapest") {
    sortedProperties = properties.sort(
      (a, b) => parseFloat(a.price) - parseFloat(b.price)
    );
  } else if (sortMode === "expensive") {
    sortedProperties = properties.sort(
      (a, b) => parseFloat(b.price) - parseFloat(a.price)
    );
  } else if (sortMode === "newest") {
    sortedProperties = properties.sort(
      (a, b) =>
        new Date(getPropertySortDate(b)) - new Date(getPropertySortDate(a))
    );
  } else if (sortMode === "oldest") {
    sortedProperties = properties.sort(
      (a, b) =>
        new Date(getPropertySortDate(a)) - new Date(getPropertySortDate(b))
    );
  } else if (sortMode === "smallest") {
    sortedProperties = properties.sort((a, b) => {
      const aa = parseFloat(a.sqft);
      const bb = parseFloat(b.sqft);
      if (aa === -1) return 1;
      else if (bb === -1) return -1;
      else return aa - bb;
    });
  } else if (sortMode === "biggest") {
    sortedProperties = properties.sort((a, b) => {
      const aa = parseFloat(a.sqft);
      const bb = parseFloat(b.sqft);
      if (aa < -1) return 1;
      else if (bb < -1) return -1;
      else return bb - aa;
    });
  } else if (sortMode === "mostbeds") {
    sortedProperties = properties.sort((a, b) => {
      if (!a.beds && !a.rmBeds && !a.zBeds) {
        return 1;
      }
      if (!b.beds && !b.rmBeds && !b.zBeds) {
        return -1;
      }
      const numBedsA =
        parseFloat(a.beds) || parseFloat(a.rmBeds) || parseFloat(a.zBeds);
      const numBedsB =
        parseFloat(b.beds) || parseFloat(b.rmBeds) || parseFloat(b.zBeds);

      return numBedsB - numBedsA;
    });
  } else if (sortMode === "leastbeds") {
    sortedProperties = properties.sort((a, b) => {
      if (!a.beds && !a.rmBeds && !a.zBeds) {
        return 1;
      }
      if (!b.beds && !b.rmBeds && !b.zBeds) {
        return -1;
      }
      const numBedsA =
        parseFloat(a.beds) || parseFloat(a.rmBeds) || parseFloat(a.zBeds);
      const numBedsB =
        parseFloat(b.beds) || parseFloat(b.rmBeds) || parseFloat(b.zBeds);

      return numBedsA - numBedsB;
    });
  } else if (sortMode === "lowppsf") {
    sortedProperties = properties.sort((a, b) => {
      const aa = parseFloat(a.price / a.sqft);
      const bb = parseFloat(b.price / b.sqft);
      if (aa < 0) return 1;
      else if (bb < 0) return -1;
      else return aa - bb;
    });
  } else if (sortMode === "highppsf") {
    sortedProperties = properties.sort(
      (a, b) => parseFloat(b.price / b.sqft) - parseFloat(a.price / a.sqft)
    );
  } else if (sortMode === "distancetocenter") {
    sortedProperties = properties.sort((a, b) => {
      const distanceA = getDistanceFromLatLonInKm(
        a.lat,
        a.lng,
        searchLat,
        searchLng
      );
      const distanceB = getDistanceFromLatLonInKm(
        b.lat,
        b.lng,
        searchLat,
        searchLng
      );
      return distanceA - distanceB;
    });
  } else if (sortMode === "similarityscore") {
    sortedProperties = properties.sort((a, b) => {
      const scoreA = getComparableScore(valuationPropertyMerged, a);
      const scoreB = getComparableScore(valuationPropertyMerged, b);
      return scoreB - scoreA;
    });
  } else {
    sortedProperties = properties;
  }
  return sortedProperties;
};

export default sortProperties;
