import { useState, useEffect } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";

import { fetchCheckoutUrl, fetchPortalUrl } from "graphql/functions";

import { Button } from "@material-ui/core";

import { getCreditData as getCreditDataRedux } from "data/selectors";
import { useSelector } from "react-redux";
import PriceBoxMC from "./PriceBoxMC";

import "./account.css";

import StripeLogo from "assets/Powered by Stripe - grey.png";
import EmailUsWidget from "components/EmailUsWidget";

const navigateToCheckoutUrl = async (purchaseType, price) => {
  const url = await fetchCheckoutUrl({
    purchaseType,
    price: price,
    successUrl:
      process.env.NODE_ENV === "production"
        ? `https://${window.location.hostname}/account?success`
        : "http://localhost:3000/account?success",
    cancelUrl:
      process.env.NODE_ENV === "production"
        ? `https://${window.location.hostname}/account?cancelled`
        : "http://localhost:3000/account?cancelled",
  });
  window.location.assign(url);
};
const navigateToPortalUrl = async () => {
  const returnUrl =
    process.env.NODE_ENV === "production"
      ? `https://${window.location.hostname}/account`
      : "http://localhost:3000/account";
  const url = await fetchPortalUrl({ returnUrl });
  window.location.assign(url);
};

const availablePricePlansMonthly =
  process.env.NODE_ENV === "production"
    ? [
        "price_1NlFONIaq90fsQY4O2p3nGmA", // 10 credits
        "price_1OwbQRIaq90fsQY4cvT7amcj", // £49/month
        // "price_1KuLGtIaq90fsQY46pTbcG5N", // £99/month
        "price_1OwbQVIaq90fsQY4RqIwnKOq", // £149/month
        // "price_1KuLI3Iaq90fsQY4OMX9Rjaj", // £299/month
        "ENTERPRISE_CUSTOM", // Enterprise/Custom
      ]
    : [
        "price_1NlFK1Iaq90fsQY4GgWcAUPY", // 10 credits
        // "price_1NQVDkIaq90fsQY45YHC0OeL", // £39/month [[Not a real price ID]]
        "price_1Owb1AIaq90fsQY4QFqoxSBT", // £49/month
        // "price_1KwhJBIaq90fsQY4ZY4asO02", // £99/month
        // "price_1NQVGAIaq90fsQY4PMSGRLF5", // £139/month
        "price_1Owb3TIaq90fsQY4YQjTonJf", // £149/month
        // "price_1KwgQIIaq90fsQY4jh4YyPgR", // £555/month
        "ENTERPRISE_CUSTOM", // Enterprise/Custom
      ];
const availablePricePlansYearly =
  process.env.NODE_ENV === "production"
    ? [
        "price_1NlFONIaq90fsQY4O2p3nGmA", // 10 credits
        "price_1PUUWrIaq90fsQY4GwKse7Ka", // £490/year
        // "price_1M8BhlIaq90fsQY4JPlqJohF", // £990/year
        "price_1OwbQWIaq90fsQY4kp9Iqdnk", // £1490/year
        // "price_1M8BifIaq90fsQY4Eg7u6hD0", // £2990/year
        "ENTERPRISE_CUSTOM", // Enterprise/Custom
      ]
    : [
        "price_1NlFK1Iaq90fsQY4GgWcAUPY", // 10 credits
        "price_1OwbMaIaq90fsQY49y7wbYxh", // £490/year
        // "price_1M8BVvIaq90fsQY4Ry2CM35g", // £990/year
        "price_1OwbLzIaq90fsQY4lcr74OGL", // £1490/year
        // "price_1M8BXOIaq90fsQY4oVYAV0Mf", // £5550/year
        "ENTERPRISE_CUSTOM", // Enterprise/Custom
      ];

const ActionButton = (props) => {
  const [clicked, setClicked] = useState(false);

  const clickButton = async () => {
    setClicked(true);
    await props.onClick();
    // setClicked(false);
  };

  return (
    <Button
      className="graphik-font"
      variant="outlined"
      size="small"
      color="secondary"
      disabled={clicked}
      onClick={clickButton}
      style={{
        backgroundColor: clicked
          ? "inherit"
          : props.color
          ? props.color
          : "#168039",
        color: "white",
        padding: "8px 15px",
        minWidth: "140px",
      }}
    >
      {clicked ? (
        <>
          &nbsp;
          <div className="loader-tiny" />
          &nbsp;
        </>
      ) : (
        props.text
      )}
    </Button>
  );
};

const PageTitle = (props) => {
  return (
    <div
      className="graphik-font"
      style={{
        padding: "28px 0 22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontSize: "1.7em",
        }}
      >
        {props.text}
      </div>

      <span
        style={{
          fontSize: "1em",
          maxWidth: "240px",
          paddingTop: "8px",
        }}
      >
        1 credit is used each time you run a Property Search or Valuation Report
      </span>
    </div>
  );
};

const CreditsRemaining = ({ creditData }) => {
  return (
    <>
      You have{" "}
      <span style={{ textShadow: "0px 1px 2px #592e9a" }}>
        {creditData.creditsRemaining}
      </span>{" "}
      credits available
    </>
  );
};

const SendMessage = ({ showEmailUs, setShowEmailUs }) => {
  // const [showEmailUs, setShowEmailUs] = useState(false);

  return !showEmailUs ? (
    <div style={{ textAlign: "center" }}>
      Please{" "}
      <a
        onClick={(event) => {
          setShowEmailUs(true);
          window.analytics.track("Clicked Send Message on Account page");
          event.preventDefault();
        }}
        href=""
        className="send-a-message-link"
      >
        send us a message
      </a>{" "}
      if you have any questions
    </div>
  ) : (
    <EmailUsWidget />
  );
};

const AccountDetails = ({ creditData, plans, history }) => {
  const matchParams = useRouteMatch("/account/:plan")?.params;
  const plan = matchParams?.plan;
  const [showEmailUs, setShowEmailUs] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        flex: "1 1 100px",
      }}
    >
      <div
        style={{
          display: "flex",
          overflow: "auto",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {creditData?.stripePriceId ? (
          <div
            className="flex-center-center"
            style={{ flexDirection: "column" }}
          >
            <PageTitle text={<CreditsRemaining creditData={creditData} />} />
            <PriceBoxMC priceId={creditData?.stripePriceId} active={true} />
          </div>
        ) : (
          !creditData?.stripePriceId && (
            <>
              <PageTitle
                text={
                  creditData?.creditsRemaining > 0 ? (
                    <CreditsRemaining creditData={creditData} />
                  ) : (
                    "Please select one of our packages below"
                  )
                }
              />
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="graphik-font"
                    variant="contained"
                    size="small"
                    color="secondary"
                    disabled={!plan}
                    onClick={() => history.push("/account")}
                    style={{
                      backgroundColor: !plan ? "#168039" : "white",
                      color: !plan ? "white" : "#168039",
                      padding: "8px 15px",
                      minWidth: "130px",
                    }}
                  >
                    Monthly Billing
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="graphik-font"
                    variant={"contained"}
                    size="small"
                    color="secondary"
                    disabled={plan === "annual"}
                    onClick={() => history.push("/account/annual")}
                    style={{
                      backgroundColor: plan === "annual" ? "#168039" : "white",
                      color: plan === "annual" ? "white" : "#168039",
                      padding: "8px 15px",
                      minWidth: "130px",
                    }}
                  >
                    Annual Billing
                  </Button>
                </div>
                {plan === "annual" && (
                  <div
                    style={{
                      position: "absolute",
                      left: "100%",
                      paddingLeft: "10px",
                      alignSelf: "center",
                      fontSize: "1em",
                      fontWeight: "400",
                      color: "#168039",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span>2 months free!</span>
                  </div>
                )}
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexFlow: "wrap",
                }}
              >
                {(plans === "monthly"
                  ? availablePricePlansMonthly
                  : availablePricePlansYearly
                ).map((price) => (
                  <PriceBoxMC
                    key={price}
                    priceId={price}
                    active={false}
                    setShowEmailUs={setShowEmailUs}
                  />
                ))}
              </div>
            </>
          )
        )}

        {/* {plan === "annual" && (
          <div
            style={{
              fontSize: "1.15em",
              fontWeight: "700",
              padding: "28px 0 29px",
              color: "#168039",
            }}
          >
            Get 2 free months when you pay annually
          </div>
        )} */}

        {creditData?.stripePriceId && (
          <div className="flex-center-center" style={{ color: "green" }}>
            <ActionButton
              onClick={() => navigateToPortalUrl()}
              text={"Cancel Membership"}
            />
          </div>
        )}

        {plan !== "annual" &&
          creditData?.stripePriceId &&
          creditData?.creditsRemaining === 0 && (
            <div
              className="flex-center-center"
              style={{ color: "#592E9A", padding: "20px 0" }}
            >
              <ActionButton
                onClick={() => {
                  window.analytics.track(`Clicked PAYG Purchase`);
                  process.env.NODE_ENV === "production"
                    ? navigateToCheckoutUrl(
                        "payment",
                        "price_1NlFONIaq90fsQY4O2p3nGmA"
                      )
                    : navigateToCheckoutUrl(
                        "payment",
                        "price_1NlFK1Iaq90fsQY4GgWcAUPY"
                      );
                }}
                text={"Purchase 10 credits"}
                color={"#592E9A"}
              />
            </div>
          )}

        <SendMessage
          showEmailUs={showEmailUs}
          setShowEmailUs={setShowEmailUs}
        />
        <br />
        <img src={StripeLogo} alt="stripe-logo" style={{ width: "120px" }} />
        <br />
      </div>

      {/* <OldUI creditData={creditData} /> */}
    </div>
  );
};

const Account = ({ history, plans }) => {
  useEffect(() => {
    document.title = "Landworth: Account Management";
  }, []);

  const creditData = useSelector((state) => getCreditDataRedux(state));

  return (
    <>
      <div
        className="nice-scrollbar"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          // flex: "1 1 100px",
          overflowY: "auto",
          height: "100%",
          borderTop: "1px solid grey",
        }}
      >
        {!creditData.hasOwnProperty("creditsRemaining") ? (
          <div className="flex-center-center" style={{ height: "100%" }}>
            <div className="loader-small" />
          </div>
        ) : creditData.creditsRemaining === undefined ? (
          <div>ERROR: User Data not found</div>
        ) : (
          <AccountDetails
            history={history}
            creditData={creditData}
            plans={plans}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(Account);
