import { useContext, useCallback } from "react";
import { Context } from "./Store";

const useSelectedMapProperty = () => {
  const [state, dispatch] = useContext(Context);

  const select = useCallback(
    (id) => {
      dispatch({ type: "SET_SELECTED_MAP_PROPERTY", id });
    },
    [dispatch]
  );

  return [state.MAP_SELECTED, select];
};

export default useSelectedMapProperty;
