import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const PropertyBaths = ({ property, hideBathText }) => {
  const { baths, propertySource, rmBaths, zBaths, propertyType } = property;
  let bathString = null;

  if (
    propertySource === "zoopla" ||
    (propertySource === "rightmove" && propertyType !== "Land")
  ) {
    bathString = `${baths}`;
  } else {
    if (rmBaths) {
      bathString = rmBaths;
    } else if (zBaths) {
      bathString = zBaths;
    }
  }
  return (
    bathString && (
      <>
        {bathString}
        {!hideBathText && " Bath"}
      </>
    )
  );
};

export default PropertyBaths;
