import React from "react";
// import Rating from "@material-ui/lab/Rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useDeviceWidth from "data/hooks/useDeviceWidth";
import { Button } from "@material-ui/core";

const ResultCompareButton = ({ propertyID, comparable, setComparable }) => {
  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();

  const toggleFlag = (e) => {
    e.stopPropagation();
    setComparable(!comparable);
  };

  return (
    <div style={{ width: isMediumWidth ? "15px" : "40px", marginLeft: "5px" }}>
      <Button
        variant="text"
        size="small"
        onClick={(e) => toggleFlag(e)}
        style={{ minWidth: isMediumWidth ? "15px" : "40px" }}
      >
        {comparable ? (
          <FontAwesomeIcon
            key={propertyID}
            icon={duotone("flag-pennant")}
            style={{
              fontSize: "1.5rem",
              color: "#9d5616",
              "--fa-secondary-opacity": "0.5",
              animation: "iconPopper 0.25s",
              animationIterationCount: "1",
              animationTimingFunction: "ease-in-out",
            }}
          />
        ) : (
          <FontAwesomeIcon
            key={propertyID}
            icon={duotone("flag-pennant")}
            style={{
              fontSize: "1.2rem",
              color: "grey",
              transition: "font-size 1s",
            }}
          />
        )}
      </Button>
    </div>
  );
};

export default ResultCompareButton;
