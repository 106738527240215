import { Container, Paper, Button } from "@material-ui/core";
import { fetchCheckoutUrl, fetchPortalUrl } from "graphql/functions";
import { useState } from "react";

const navigateToCheckoutUrl = async (purchaseType, price) => {
  const url = await fetchCheckoutUrl({
    purchaseType,
    price: price,
    successUrl:
      process.env.NODE_ENV === "production"
        ? `https://${window.location.hostname}/account?success`
        : "http://localhost:3000/account?success",
    cancelUrl:
      process.env.NODE_ENV === "production"
        ? `https://${window.location.hostname}/account?cancelled`
        : "http://localhost:3000/account?cancelled",
  });
  window.location.assign(url);
};

//prettier-ignore
const priceMetadata = {
    // ENTERPRISE - CONTACT US
    "ENTERPRISE_CUSTOM": {"id": "ENTERPRISE", "name": "Enterprise", "description": "For larger companies that require bespoke support and API services", "unitAmount": "Custom", "currency": "gbp", "interval": "custom", "intervalCount": 1,"credits": "Unlimited"},
    "ENTERPRISE_MONTHLY": {"id": "ENTERPRISE", "name": "Enterprise", "description": "For larger companies that require bespoke support and API services", "unitAmount": "49900", "currency": "gbp", "interval": "month", "intervalCount": 1,"credits": "Unlimited"},
    "ENTERPRISE_YEARLY": {"id": "ENTERPRISE", "name": "Enterprise", "description": "For larger companies that require bespoke support and API services", "unitAmount": "499000", "currency": "gbp", "interval": "year", "intervalCount": 1,"credits": "Unlimited"},

    // PROD - PAYG (£20)
    "price_1NlFONIaq90fsQY4O2p3nGmA": {"id": "price_1NlFONIaq90fsQY4O2p3nGmA", "name": "Member", "description": "For occasional users and explorers looking to understand the market", "unitAmount": "Free", "currency": "gbp", "interval": "once", "intervalCount": 1, "credits": "PAYG"},
    // DEV - PAYG (£20)
    "price_1NlFK1Iaq90fsQY4GgWcAUPY": {"id": "price_1NlFK1Iaq90fsQY4GgWcAUPY", "name": "Member", "description": "For occasional users and explorers looking to understand the market", "unitAmount": "Free", "currency": "gbp", "interval": "once", "intervalCount": 1, "credits": "PAYG"},
    

    /////////////// PROD
    // PROD - MONTHLY
    "price_1NR5QkIaq90fsQY4qlklxQkv": {"id": "price_1NR5QkIaq90fsQY4qlklxQkv", "name": "Starter", "description": "For individuals who source and analyse on- and off-market deals", "unitAmount": 3900, "currency": "gbp", "interval": "month", "intervalCount": 1,"credits": 50},
    "price_1OwbQRIaq90fsQY4cvT7amcj": {"id": "price_1OwbQRIaq90fsQY4cvT7amcj", "name": "Starter", "description": "For individuals who source and analyse on- and off-market deals", "unitAmount": 4900, "currency": "gbp", "interval": "month", "intervalCount": 1,"credits": 50},
    "price_1NR5QmIaq90fsQY4a0vvik9t": {"id": "price_1NR5QmIaq90fsQY4a0vvik9t", "name": "Professional", "description": "For small teams of up to 10 users to share credits and collaborate", "unitAmount": 13900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 200},
    "price_1OwbQVIaq90fsQY4RqIwnKOq": {"id": "price_1OwbQVIaq90fsQY4RqIwnKOq", "name": "Professional", "description": "For small teams of up to 10 users to share credits and collaborate", "unitAmount": 14900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 200},
    "price_1KuLI3Iaq90fsQY4OMX9Rjaj": {"id": "price_1KuLI3Iaq90fsQY4OMX9Rjaj", "name": "Enterprise", "description": "For larger businesses with dedicated sourcing and analysis teams", "unitAmount": 49900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": "Unlimited"},
    // PROD - YEARLY
    "price_1NR5QnIaq90fsQY4BcMWJvgc": {"id": "price_1NR5QnIaq90fsQY4BcMWJvgc", "name": "Starter", "description": "For individuals who source and analyse on- and off-market deals", "unitAmount": 39000, "currency": "gbp", "interval": "year", "intervalCount": 1,"credits": 600},
    "price_1PUUWrIaq90fsQY4GwKse7Ka": {"id": "price_1PUUWrIaq90fsQY4GwKse7Ka", "name": "Starter", "description": "For individuals who source and analyse on- and off-market deals", "unitAmount": 49000, "currency": "gbp", "interval": "year", "intervalCount": 1,"credits": 600},
    "price_1NR5QoIaq90fsQY4X7InnSXB": {"id": "price_1NR5QoIaq90fsQY4X7InnSXB", "name": "Professional", "description": "For small teams of up to 10 users to share credits and collaborate", "unitAmount": 139000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 2400},
    "price_1OwbQWIaq90fsQY4kp9Iqdnk": {"id": "price_1OwbQWIaq90fsQY4kp9Iqdnk", "name": "Professional", "description": "For small teams of up to 10 users to share credits and collaborate", "unitAmount": 149000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 2400},
    "price_1M8BifIaq90fsQY4Eg7u6hD0": {"id": "price_1M8BifIaq90fsQY4Eg7u6hD0", "name": "Enterprise", "description": "For larger businesses with dedicated sourcing and analysis teams", "unitAmount": 499000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": "Unlimited"},
    // PROD-LEGACY
    "price_1OwbQXIaq90fsQY4yJdcP082": {"id": "price_1OwbQXIaq90fsQY4yJdcP082", "name": "Starter", "description": "For individual users sourcing and assessing on- and off-market deals", "unitAmount": 49000, "currency": "gbp", "interval": "year", "intervalCount": 1,"credits": 600},
    "price_1KiqnQIaq90fsQY41xv7lzf9": {"id": "price_1KiqnQIaq90fsQY41xv7lzf9", "name": "Starter", "description": "For casual research and people starting out in the market", "unitAmount": 2900, "currency": "gbp", "interval": "month", "intervalCount": 1,"credits": 30},
    "price_1KuLDnIaq90fsQY4w89zBL6C": {"id": "price_1KuLDnIaq90fsQY4w89zBL6C", "name": "Team", "description": "For businesses needing to share credits between multiple accounts", "unitAmount": 4900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 60},
    "price_1M8BhlIaq90fsQY4JPlqJohF": {"id": "price_1M8BhlIaq90fsQY4JPlqJohF", "name": "Professional", "description": "For businesses needing to share credits between multiple accounts", "unitAmount": 99000, "currency": "gbp", "interval": "year", "intervalCount": 1,"credits": 2400},
    "price_1KuLGtIaq90fsQY46pTbcG5N": {"id": "price_1KuLGtIaq90fsQY46pTbcG5N", "name": "Professional", "description": "For businesses needing to share credits between multiple accounts", "unitAmount": 9900, "currency": "gbp", "interval": "month", "intervalCount": 1,"credits": 200},
    "price_1LaVxYIaq90fsQY49PQmbzsr": {"id": "price_1LaVxYIaq90fsQY49PQmbzsr", "name": "Starter", "description": "For independent Property Professionals and casual market research", "unitAmount": 3900, "currency": "gbp", "interval": "month", "intervalCount": 1,"credits": 60},
    "price_1MteFdIaq90fsQY4hb8JFbA7": {"id": "price_1MteFdIaq90fsQY4hb8JFbA7", "name": "Professional", "description": "For businesses needing to share credits between multiple accounts", "unitAmount": 13900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 300},
    "price_1M8Be0Iaq90fsQY4aPuv4CZH": {"id": "price_1M8Be0Iaq90fsQY4aPuv4CZH", "name": "Starter", "description": "For independent Property Professionals and casual market research", "unitAmount": 39000, "currency": "gbp", "interval": "year", "intervalCount": 1,"credits": 720},
    "price_1MteqtIaq90fsQY4inUU1usC": {"id": "price_1MteqtIaq90fsQY4inUU1usC", "name": "Professional", "description": "For businesses needing to share credits between multiple accounts", "unitAmount": 139000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 3600},
    "LEGACY_TEXT": {"id": "LEGACY_TEXT", "name": "Professional", "description": "For larger organisations with a dedicated appraisal team", "unitAmount": 9900, "currency": "gbp", "interval": "month", "intervalCount": 1,"credits": 200},
    // PROD-CUSTOM
    "price_1IgtGhIaq90fsQY409B5LD0g": {"id": "price_1IgtGhIaq90fsQY409B5LD0g", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 4900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 60},
    "price_1JfZzMIaq90fsQY4Aybl5fcu": {"id": "price_1JfZzMIaq90fsQY4Aybl5fcu", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 4900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 60},
    "price_1HgsIaIaq90fsQY4eHaGM17a": {"id": "price_1HgsIaIaq90fsQY4eHaGM17a", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 9900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 150},
    "price_1K6cLaIaq90fsQY458WaF8MV": {"id": "price_1K6cLaIaq90fsQY458WaF8MV", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 3900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 60},
    "price_1Ki73RIaq90fsQY4RLskmZ9A": {"id": "price_1Ki73RIaq90fsQY4RLskmZ9A", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 2500, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 30},
    "price_1Kgze8Iaq90fsQY4KqW2n1pO": {"id": "price_1Kgze8Iaq90fsQY4KqW2n1pO", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 500, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 5},
    "price_1JfabKIaq90fsQY4b0T1aG3V": {"id": "price_1JfabKIaq90fsQY4b0T1aG3V", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 9900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 150},
    "price_1O6urWIaq90fsQY4j2a5sIrF": {"id": "price_1O6urWIaq90fsQY4j2a5sIrF", "name": "Custom", "client": "Goldentree FS", "description": "You are on a Custom Landworth price plan", "unitAmount": 69900, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 1200},
    
    /////////////// DEV
    // DEV - MONTHLY
    "price_1Owb1AIaq90fsQY4QFqoxSBT": {"id": "price_1Owb1AIaq90fsQY4QFqoxSBT", "name": "Starter", "description": "For individuals who source and analyse on- and off-market deals", "unitAmount": 4900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 50},
    "price_1Owb3TIaq90fsQY4YQjTonJf": {"id": "price_1Owb3TIaq90fsQY4YQjTonJf", "name": "Professional", "description": "For small teams of up to 10 users to share credits and collaborate", "unitAmount": 14900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 200},
    // DEV - YEARLY
    "price_1OwbMaIaq90fsQY49y7wbYxh": {"id": "price_1OwbMaIaq90fsQY49y7wbYxh", "name": "Starter", "description": "For individuals who source and analyse on- and off-market deals", "unitAmount": 49000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 600},
    "price_1OwbLzIaq90fsQY4lcr74OGL": {"id": "price_1OwbLzIaq90fsQY4lcr74OGL", "name": "Professional", "description": "For small teams of up to 10 users to share credits and collaborate", "unitAmount": 149000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 2400},
    // DEV - LEGACY
    "price_1NQVDkIaq90fsQY45YHC0OeL": {"id": "price_1NQVDkIaq90fsQY45YHC0OeL", "name": "Starter", "description": "For individual users sourcing and assessing on- and off-market deals", "unitAmount": 3900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 50},
    "price_1NQVGAIaq90fsQY4PMSGRLF5": {"id": "price_1NQVGAIaq90fsQY4PMSGRLF5", "name": "Professional", "description": "For businesses to share credits across unlimited user accounts", "unitAmount": 13900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 200},
    // "price_1KwhJBIaq90fsQY4ZY4asO02": {"id": "price_1KwhJBIaq90fsQY4ZY4asO02", "name": "Professional", "description": "For businesses needing to share credits between multiple accounts", "unitAmount": 9900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 200},
    // "price_1M8BVvIaq90fsQY4Ry2CM35g": {"id": "price_1M8BVvIaq90fsQY4Ry2CM35g", "name": "Professional", "description": "For businesses needing to share credits between multiple accounts", "unitAmount": 99000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 2400},
    "price_1M8BXOIaq90fsQY4oVYAV0Mf": {"id": "price_1M8BXOIaq90fsQY4oVYAV0Mf", "name": "Enterprise", "description": "For larger businesses with dedicated sourcing and analysis teams", "unitAmount": 299000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": "Unlimited"},
    "price_1KwgQIIaq90fsQY4jh4YyPgR": {"id": "price_1KwgQIIaq90fsQY4jh4YyPgR", "name": "Enterprise", "description": "For larger businesses needing unlimited access via App and API", "unitAmount": 29900, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": "Unlimited"},
    "price_1NR4GvIaq90fsQY4CIC9YxoE": {"id": "price_1NR4GvIaq90fsQY4CIC9YxoE", "name": "Starter", "description": "For individual users sourcing and assessing on- and off-market deals", "unitAmount": 39000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 600},
    "price_1NR4ImIaq90fsQY4azMfnwUK": {"id": "price_1NR4ImIaq90fsQY4azMfnwUK", "name": "Professional", "description": "For companies to share credits across up to 10 user accounts", "unitAmount": 139000, "currency": "gbp", "interval": "year", "intervalCount": 1, "credits": 2400},
    "price_abc123": {"id": "price_abc123", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 3300, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 60},


    // LEGACY
    "price_abc123": {"id": "price_abc123", "name": "Legacy", "description": "You are on a legacy Landworth price plan", "unitAmount": 3300, "currency": "gbp", "interval": "month", "intervalCount": 1, "credits": 60},
    // PROD - PAYG (OLD £10)
    "price_1L8Zy8Iaq90fsQY4V0nz3rKQ": {"id": "price_1L8Zy8Iaq90fsQY4V0nz3rKQ", "name": "Member", "description": "For occasional users and explorers looking to understand the market", "unitAmount": "Free", "currency": "gbp", "interval": "once", "intervalCount": 1, "credits": "PAYG"},
    // DEV - PAYG (OLD £10)
    "price_1KwgYVIaq90fsQY4y7LbtyWx": {"id": "price_1KwgYVIaq90fsQY4y7LbtyWx", "name": "Member", "description": "For occasional users and explorers looking to understand the market", "unitAmount": "Free", "currency": "gbp", "interval": "once", "intervalCount": 1, "credits": "PAYG"},

};

const PriceBoxMC = (props) => {
  const { priceId, active, setShowEmailUs } = props;

  const name = priceMetadata[priceId]?.name;
  const price = priceMetadata[priceId]?.unitAmount;
  const interval = priceMetadata[priceId]?.interval;
  const credits = priceMetadata[priceId]?.credits;
  const description = priceMetadata[priceId]?.description;

  const [clicked, setClicked] = useState(false);

  const clickUpgrade = async () => {
    setClicked(true);
    window.analytics.track(`Clicked Subscription Purchase`, {
      name,
      price,
      credits,
    });
    const type =
      interval === "month" || interval === "year" ? "subscription" : "payment";
    await navigateToCheckoutUrl(type, priceId);
    // setClicked(false);
  };

  return (
    <div style={{ paddingBottom: "10px" }}>
      <Paper
        elevation={4}
        className="account-price-box"
        style={{
          overflow: "hidden",
          padding: "1.2rem",
          textAlign: "center",
          minWidth: "212px",
          maxWidth: "18ch",
          minHeight: "290px",
          color: "#241c15",
          ...(active && { backgroundColor: "#f7f3fe" }),
        }}
      >
        {active && (
          <div
            style={{
              fontSize: "0.6rem",
              fontWeight: "bold",
              paddingBottom: "8px",
              color: "#592E9A",
            }}
          >
            ACTIVE
          </div>
        )}
        <div
          className="means-font"
          style={{
            fontSize: "1.8rem",
          }}
        >
          {name}
        </div>
        <div
          className="means-font"
          style={{
            fontSize: "1.8rem",
            padding: "2px 0 2px",
          }}
        >
          {isNaN(price) ? price : `£${(price / 100).toLocaleString()}`}

          <span className="graphik-font" style={{ fontSize: "0.8rem" }}>
            {interval === "month" || interval === "year"
              ? " / " + interval
              : !isNaN(price) && interval === "once"
              ? " once"
              : ""}
          </span>
        </div>
        <div
          className="graphik-font"
          style={{ fontSize: "0.8rem", padding: "4px 0 8px" }}
        >
          {!!credits ? (
            <>
              {credits.toLocaleString()}{" "}
              {credits === "Unlimited"
                ? ""
                : (interval === "month" || interval === "year") &&
                  interval + "ly "}
              credits
            </>
          ) : (
            <>&nbsp;-&nbsp;</>
          )}
        </div>
        {/* <hr style={{ margin: "16px 0" }} /> */}
        <div className="graphik-font" style={{ fontSize: "0.9rem" }}>
          {description}
        </div>
        <div style={{ fontSize: "0.9rem", paddingTop: "25px" }}>
          {credits !== "Unlimited" && (price > 0 || credits === "PAYG") ? (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={active || clicked}
              onClick={clickUpgrade}
              style={{
                backgroundColor: active || clicked ? "inherit" : "#592E9A",
                color: active ? "black" : "white",
                padding: "8px 15px",
                minWidth: "150px",
              }}
            >
              {clicked ? (
                <>
                  &nbsp;
                  <div className="loader-tiny" />
                  &nbsp;
                </>
              ) : active ? (
                `Current Package`
              ) : interval === "month" || interval === "year" ? (
                `Upgrade Account`
              ) : interval === "once" ? (
                `Purchase 10 Credits`
              ) : (
                ``
              )}
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={active || clicked}
              onClick={() => setShowEmailUs(true)}
              style={{
                backgroundColor: active || clicked ? "inherit" : "#592E9A",
                color: active ? "black" : "white",
                padding: "8px 15px",
                minWidth: "150px",
              }}
            >
              {clicked ? (
                <>
                  &nbsp;
                  <div className="loader-tiny" />
                  &nbsp;
                </>
              ) : (
                "Contact Us"
              )}
            </Button>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default PriceBoxMC;
