import { useEffect, useReducer } from "react";
import cancellablePromise from "./cancellablePromise";
import { useDebounce } from "use-debounce";
import {
  getSoldPropertyData,
  getEPCPropertyData,
  fetchOsAddressAutocomplete,
} from "graphql/functions";
import {
  getAutocompleteFromText,
  getAutocompleteFromTextOS,
} from "services/geocoding";

const MIN_SEARCH_LENGTH = 5;

// this is the contract the UX component gets to subscribe too
const SEARCH_RESULTS_INITIAL_STATE = {
  epc: {
    results: [],
    loading: false,
  },
  location: {
    results: [],
    loading: false,
  },
  landreg: {
    results: [],
    loading: false,
  },
  loading: false, // top level loading
};

const searchResultsReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return SEARCH_RESULTS_INITIAL_STATE;
    // starting search
    case "epcSearching":
      return {
        ...state,
        epc: {
          results: [],
          loading: true,
        },
        loading: true,
      };
    case "locationSearching":
      return {
        ...state,
        location: {
          results: [],
          loading: true,
        },
        loading: true,
      };
    case "landregSearching":
      return {
        ...state,
        landreg: {
          results: [],
          loading: true,
        },
        loading: true,
      };
    // results
    case "epcResults":
      // console.log(action.payload);
      return {
        ...state,
        epc: {
          results: action.payload,
          loading: false,
        },
        loading: state.location.loading || state.landreg.loading,
      };
    case "locationResults":
      // console.log(action);
      return {
        ...state,
        location: {
          header: action.payload?.header,
          results: action.payload?.results,
          loading: false,
        },
        loading: state.epc.loading || state.landreg.loading,
      };
    case "landregResults":
      // console.log(action.payload);
      return {
        ...state,
        landreg: {
          results: action.payload,
          loading: false,
        },
        loading: state.epc.loading || state.location.loading,
      };
    default:
      throw new Error(`saw action with unkonwn type ${action.type}`);
  }
};

const enoughDataToSearch = (location) => location?.length >= MIN_SEARCH_LENGTH;

/**
 * Are we ready to kick off a search
 * @param {*} location live from input
 * @param {*} debouncedLocation debounced location
 */
const shouldSearch = (location, debouncedLocation) =>
  location === debouncedLocation && enoughDataToSearch(location);

const performLandregSearch = (location, dispatch) => {
  const searchFn = () => getSoldPropertyData(location);
  return _performSearch(searchFn, "landreg", dispatch);
};

const performLocationSearch = (location, dispatch) => {
  const searchFn = () => fetchOsAddressAutocomplete(location);
  // const searchFn = () => getAutocompleteFromTextOS(location);
  return _performSearch(searchFn, "location", dispatch);
};

const performEPCSearch = (location, dispatch) => {
  const searchFn = () => getEPCPropertyData(location);
  return _performSearch(searchFn, "epc", dispatch);
};

/**
 * Peroform a search and dispatch results unless cancelled
 */
const _performSearch = (searchFn, type, dispatch) => {
  dispatch({ type: `${type}Searching` });
  const { promise, cancel } = cancellablePromise(searchFn());
  promise
    .then((results) => {
      dispatch({ type: `${type}Results`, payload: results });
    })
    .catch((e) => {
      // is it a failure (as apposed to a cancel)
      if (e?.value !== true) {
        console.log(`Problem calling ${type} API`, e);
        // reset the search so UX knows it's done (silent fail with no results)
        dispatch({ type: `${type}Results`, payload: [] });
      }
    });
  return cancel;
};

/**
 * @param {*} _location as captured by autocomplete live
 * @param {*} options which in future can be used to tweak the search
 * @returns results and search status
 */
export const useValuationSearchResults = (location, searchOptions) => {
  const [results, dispatch] = useReducer(
    searchResultsReducer,
    SEARCH_RESULTS_INITIAL_STATE
  );
  const [debouncedLocation] = useDebounce(location, 350);

  // when locaction === debouncedLocation (they have stopped typing and we can fire a search)
  useEffect(() => {
    if (shouldSearch(location, debouncedLocation)) {
      const cancels = [];
      // cancels.push(performLandregSearch(location, dispatch));
      // cancels.push(performEPCSearch(location, dispatch));
      cancels.push(performLocationSearch(location, dispatch));
      return () => {
        console.log("Previous search unmounting...");
        cancels.forEach((cancel) => cancel());
        dispatch({ type: "reset" });
      };
    }
  }, [location, debouncedLocation]);

  return {
    tooSmall: !enoughDataToSearch(location), // this needs to reflect LIVE typing (not debounced)
    results,
  };
};
