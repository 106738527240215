import React from "react";
import useLocationHistory from "data/hooks/useLocationHistory";

import PriceGraph from "components/graphs/PriceGraph";
import SalesGraph from "components/graphs/SalesGraph";
import Button from "@material-ui/core/Button";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDispatch } from "react-redux";
import { setCircleCenter } from "pages/Search/circleSlice";
import useSearchView from "data/hooks/useSearchView";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";

import { formatDateMMMYYYY } from "services/formatting";
import PriceChangeInfo from "components/tooltips/PriceChangeInfo";
// import QuickLocationSelect from "components/QuickLocationSelect";
import SearchViewControlButton from "components/SearchViewControlButton";

import "./searchHome.css";

const PercentageChangeIndicator = ({ value, popupHistoryData }) => {
  return (
    <>
      {/* {popupHistoryData ? (value > 0 ? "+" : null) + value + "%" : "...."} */}
      <PriceChangeInfo
        priceChange={value}
        iconText={
          <div
            className="percent-change-indicator"
            style={{
              backgroundColor: "#dbf0ec",
              color:
                value > 0 ? "#168039" : value < 0 ? "red" : "darkslategrey",
              // fontWeight: "bold",
              paddingTop: "1px",
              cursor: "help",
            }}
          >
            &nbsp;
            {popupHistoryData ? (value > 0 ? "+" : null) + value + "%" : "...."}
          </div>
        }
      />
    </>
  );
};

const SearchHome = () => {
  const [locationHistoryPriceData, locationHistorySalesData] =
    useLocationHistory(
      "all",
      `{"location":"all"}`,
      process.env.REACT_APP_CURRENT_DATE,
      25,
      true // Makes sure the hook runs - workaround as cannot trigger hook conditionally in VRReportHolder
    );

  const latestMonthPriceData = locationHistoryPriceData?.dataPoints[0];

  const dispatch = useDispatch();

  const [searchView, setSearchView] = useSearchView();
  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.narrow}px)`);
  const setSelectedLocationHandler = (center) => {
    window.analytics.track("Search Home - Location Selected");
    if (searchView === "full_list") {
      isNarrow ? setSearchView("full_map") : setSearchView("split");
    }
    setTimeout(
      () => {
        dispatch(setCircleCenter(center));
      },
      searchView === "split" ? 0 : 750
    );
  };

  return (
    <div
      className="nice-scrollbar"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="home-container-top">
        <div
          style={{
            position: "relative",
            top: "0",
            left: "0",
            paddingTop: "5px",
            zIndex: "999",
            float: "left",
          }}
        >
          <SearchViewControlButton variant={"list_button"} />
        </div>
        <div>
          <div className="home-latest-data">
            <div>
              UK Average Price{" "}
              {latestMonthPriceData &&
                "for " + formatDateMMMYYYY(latestMonthPriceData.date)}
              <br />
            </div>
            {latestMonthPriceData ? (
              <>
                <div className="flex-center-center">
                  <b>£{Math.round(latestMonthPriceData.lw)}/ft²</b>
                  {/* <FontAwesomeIcon
                  icon={[
                    "far",
                    latestMonthPriceData.lwc1y > 0 ? "arrow-up" : "arrow-down",
                  ]}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                /> */}
                  &nbsp;&nbsp;
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    <PercentageChangeIndicator
                      value={latestMonthPriceData.lwc1y}
                      popupHistoryData={latestMonthPriceData}
                    />
                  </div>
                </div>
                <div style={{ fontSize: "13px", color: "grey" }}>
                  Source:{" "}
                  <a
                    href="https://www.gov.uk/government/news/june-2024-transaction-data"
                    target="_blank"
                    rel="noreferrer"
                    className="external-link"
                  >
                    Land Registry Data - June 2024
                    {/* <FontAwesomeIcon
                      icon={["far", "external-link-alt"]}
                      size="xs"
                    /> */}
                  </a>
                </div>
              </>
            ) : (
              <div
                className="loader-tiny"
                style={{ display: "inline-block", marginTop: "4px" }}
              ></div>
            )}
          </div>
          {searchView === "full_list" && (
            <>
              <div className="home-action-buttons">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    window.analytics.track("Home - See the Map");
                    isNarrow
                      ? setSearchView("full_map")
                      : setSearchView("split");
                  }}
                >
                  See the Map
                </Button>

                {/* <QuickLocationSelect
              setSelectedLocationHandler={setSelectedLocationHandler}
            /> */}
              </div>
              <br />
            </>
          )}
          {/* <br />
        <div className="home-instructions">
          Select a location to start a&nbsp;&nbsp;
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => {
              window.analytics.track("Show Search Form", {
                clickSource: "empty-search",
              });
              setSearchDrawerOpen(true);
            }}
          >
            Property Search
          </Button>
        </div> */}
        </div>

        <div className="horizontal-line"></div>
      </div>
      <div className="home-container-bottom">
        <br />
        {/* <PriceGraph
          mode={"latlng"}
          locData={`{\"lat\":\"51.5466605531034\", \"long\":\"-0.182130351733306\", \"type\":\"flats\"}`}
          yearsToCheck={0.5}
          startDate={"2021-05-04"}
          // startDate={process.env.REACT_APP_CURRENT_DATE}
        /> */}
        {/* <PriceGraph
          locData={`{"wardID":"E05000063"}`}
          yearsToCheck={0.5}
          startDate={"2021-03-01"}
        /> */}
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            paddingBottom: "2px",
          }}
        >
          England & Wales - Average £/sqft History
        </div>
        <br />
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          <PriceGraph locationHistoryPriceData={locationHistoryPriceData} />
        </div>
        <br />
        <br />
        <div>
          <div
            style={{
              textAlign: "center",
              fontSize: "18px",
              paddingBottom: "2px",
            }}
          >
            England & Wales - Total Quarterly Sales History
          </div>
          <br />
          <div style={{ maxWidth: "800px", margin: "0 auto" }}>
            <SalesGraph locationHistorySalesData={locationHistorySalesData} />
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default SearchHome;
