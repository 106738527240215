import Button from "@material-ui/core/Button";
import { useState } from "react";
import { fetchFullAddress, runValuation } from "graphql/functions";
import { generateMatchAddress } from "services/formatting";

const FullAddress = ({ property }) => {
  const { postcode, price } = property;
  const [fullAddressData, setFullAddressData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  console.log(fullAddressData);

  const getAddress = async () => {
    setIsFetchingData(true);
    const data = await fetchFullAddress(postcode, price);
    console.log(data);
    setFullAddressData(data);
    setIsFetchingData(false);
  };

  const ValuationSearchButton = (props) => {
    const { label, onClick, disabled } = props;

    return (
      <>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          disabled={disabled}
          onClick={onClick}
        >
          {label}
        </Button>
      </>
    );
  };

  const run = async (data) => {
    const valuationParameters = {
      valuationName: fullAddressData.fullAddress,
      postcode: property.postcode,
      lat: fullAddressData.lat,
      lng: fullAddressData.lng,
      propertyType: property.propertyType,
      propertyTypeParent: property.propertyTypeParent,
      sqft: Math.round(property.sqft),
      matchAddress: generateMatchAddress(fullAddressData.lpi),
      uprn: fullAddressData.uprn,

      beds: property.bedrooms,

      // size: propertySpec.size,
      // sizeUnit: propertySpec.sizeUnit,
      // epcSqm: localLocationSpec.epcSqm,
      // newbuild: propertySpec.newbuild,
      // hmlrid: propertySpec.hmlrid,
      // lmk_key: localLocationSpec.lmk_key,
      // inspection_date: localLocationSpec.inspection_date,
      // currentEnergyRating: localLocationSpec.currentEnergyRating,
      // currentEnergyScore: localLocationSpec.currentEnergyScore,
      // potentialEnergyRating: localLocationSpec.potentialEnergyRating,
      // potentialEnergyScore: localLocationSpec.potentialEnergyScore,
      // local_authority_id: localLocationSpec.local_authority_id,
      // local_authority_name: localLocationSpec.local_authority_name,
    };

    // console.log(valuationParameters);

    const valuationData = await runValuation({
      valuationMode: "uprn",
      valuationInputSpec: valuationParameters,
      isLoggedIn: true,
    });

    // console.log(valuationData);
    window.open(`/report/${valuationData.valuationId}`);
  };

  const runValuationLocationSearch = async (valuationParameters) => {
    console.log("Setting valuation search data");
    console.log(valuationParameters);
    console.log(fullAddressData);
    console.log(postcode);
    console.log(property.lat);
    console.log(property.lng);
    console.log(property.name);
    console.log(property.address);
    // Set localLocationSpec
    // Switch page to report/edit

    // Pass localLocationSpec to report/edit ??
  };

  return (
    <>
      <div
        // className="flex-center-center"
        style={{
          color: "red",
          fontSize: "0.9em",
          textAlign: "center",
          paddingBottom: "12px",
        }}
      >
        This feature is in currently in BETA - please contact us if you have any
        questions
      </div>
      <div style={{ padding: "4px 0", textAlign: "center" }}>
        {postcode ? (
          isFetchingData ? (
            <ValuationSearchButton label="Searching..." disabled />
          ) : !fullAddressData ? (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                window.analytics.track("Clicked Find Full Address", {
                  postcode,
                  price,
                });
                getAddress();
              }}
            >
              Search for full address
            </Button>
          ) : (
            // "HIDDEN CASE"
            ""
          )
        ) : (
          "NO POSTCODE CASE"
        )}
        {/* <hr /> */}
        {fullAddressData && fullAddressData.findFullAddress === null ? (
          <>
            Full address not found!
            <br />
            <ValuationSearchButton
              label="Run ValuationLocationSearch"
              onClick={() => {
                window.analytics.track("Clicked ValuationLocationSearch");
                runValuationLocationSearch(fullAddressData);
              }}
            />
          </>
        ) : fullAddressData ? (
          <>
            <a href={fullAddressData?.zooplaURL} target="_blank">
              {fullAddressData?.fullAddress}
            </a>
            <br />
            <br />
            <ValuationSearchButton
              label="Run Valuation"
              onClick={() => {
                window.analytics.track("Clicked Valuation from Full Address", {
                  postcode,
                  price,
                });
                run(fullAddressData);
              }}
            />
          </>
        ) : !postcode ? (
          // Shows when there's no postcode data, so we need to manually specify type/size
          <ValuationSearchButton
            label="Run ValuationLocationSearch"
            onClick={() => {
              window.analytics.track("Clicked ValuationLocationSearch");
              runValuationLocationSearch(fullAddressData);
            }}
          />
        ) : (
          // "HIDDEN CASE"
          ""
        )}
      </div>
    </>
  );
};

export default FullAddress;
