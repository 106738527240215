import React from "react";
import PropertyBeds from "./PropertyBeds";
import PropertyType from "./PropertyType";

const PropertyConstructionYear = ({ property }) => {
  const { epcConstructionAgeBand } = property;

  return (
    <div style={{ textAlign: "center" }}>
      {epcConstructionAgeBand && (
        <>
          Year of Construction -&nbsp;{epcConstructionAgeBand}
          {/* <br /> */}
        </>
      )}
    </div>
  );
};

export default PropertyConstructionYear;
