import { Field } from "formik";
import Button from "@material-ui/core/Button";

const IncludeForSaleSold = (props) => {
  const { values, setFieldValue } = props;
  const isSold = values?.include_sold && !values?.include_forsale;
  const isBoth = values?.include_sold && values?.include_forsale;
  const isForsale = !values?.include_sold && values?.include_forsale;

  const setListingStatus = (includeSold, includeForsale) => {
    setFieldValue("include_sold", includeSold);
    setFieldValue("include_forsale", includeForsale);
  };

  return (
    <>
      <div className={"search-form-item"}>
        <div className={"search-form-label"}></div>
        <Field
          component={Button}
          name="listing_status_sold"
          id="listing_status_sold"
          className="search-button-wide"
          variant={isSold ? "contained" : "outlined"}
          color={isSold ? "secondary" : "default"}
          size="small"
          onClick={(e) => setListingStatus(true, false)}
        >
          {values?.search_type === "sale" ? "Under Offer" : "Rented"}
        </Field>
        <Field
          component={Button}
          name="listing_status_both"
          id="listing_status_both"
          className="search-button-all"
          variant={isBoth ? "contained" : "outlined"}
          color={isBoth ? "secondary" : "default"}
          size="small"
          onClick={(e) => setListingStatus(true, true)}
        >
          All
        </Field>
        <Field
          component={Button}
          name="listing_status_forsale"
          id="listing_status_forsale"
          className="search-button-wide"
          variant={isForsale ? "contained" : "outlined"}
          color={isForsale ? "secondary" : "default"}
          size="small"
          onClick={(e) => setListingStatus(false, true)}
        >
          {values?.search_type === "sale" ? "For Sale" : "For Rent"}
        </Field>
      </div>
    </>
  );
};

export default IncludeForSaleSold;
