import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { fetchUserSearchFavourites } from "graphql/functions";
import SearchHistoryTable from "./SearchHistoryTable";

import "./userHistory.css";

async function getHistory(setSearchFavourites) {
  const history = await fetchUserSearchFavourites();
  const sortedHistory = history?.searches.sort(
    (a, b) => new Date(b.dateRun) - new Date(a.dateRun)
  );
  setSearchFavourites(sortedHistory);
}

const NoFavouriteSearches = ({ history }) => (
  <div
    className="flex-center-center"
    style={{ textAlign: "center", flexDirection: "column", fontSize: "1.1em" }}
  >
    You don't have any favourite searches yet!
    <br />
    <br />
    <div>
      Visit your&nbsp;
      <a
        href="/search/history"
        onClick={(event) => {
          history.push("/search/history");
          event.preventDefault();
        }}
        style={{ fontWeight: "bold" }}
      >
        Search History
      </a>
      &nbsp;to star some
    </div>
  </div>
);

const SearchFavourites = ({ history }) => {
  const [searchFavourites, setSearchFavourites] = useState();

  useEffect(() => {
    document.title = "Landworth: Search Favourites";
    getHistory(setSearchFavourites);
  }, []);

  const loadSearch = (searchID) => {
    history.push("/search/" + searchID);
  };

  return (
    <>
      <div
        className="flex-center-center"
        style={{
          flex: "1 1 auto",
          flexDirection: "column",
          overflowY: "auto",
          height: "100%",
          borderTop: "1px solid grey",
        }}
      >
        {searchFavourites === undefined ? (
          <div className="loader-big" />
        ) : searchFavourites.length === 0 ? (
          <NoFavouriteSearches history={history} />
        ) : (
          <SearchHistoryTable
            searchHistory={searchFavourites}
            loadURL={loadSearch}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(SearchFavourites);
