const PropertyPpsf = ({ property }) => {
  const { listingStatus, ppsf } = property;
  const isRental =
    listingStatus === "rented" ||
    listingStatus === "rent_under_offer" ||
    listingStatus === "for_rent";
  let display;
  if (ppsf < 0) display = <span></span>;
  else if (ppsf === 0)
    display = <span style={{ fontWeight: "normal" }}>Need Price</span>;
  else if (ppsf < 5000)
    display = isRental ? (
      <>
        <span>
          £{Math.round(ppsf * 12)}
          <span style={{ fontSize: "13px" }}>/sqft/yr</span>
        </span>
      </>
    ) : (
      <span>
        £{Math.round(ppsf).toLocaleString()}
        <span style={{ fontSize: "13px" }}>/ft²</span>
      </span>
    );
  return <>{display}</>;
};

export default PropertyPpsf;
