import { createSlice } from "@reduxjs/toolkit";

export const defaultCircle = {
  center: [-0.1817481, 51.5466251],
  radius: 402,
  visible: false,
  didMapTriggerChange: false,
};

const circleSlice = createSlice({
  name: "circle",
  initialState: defaultCircle,
  reducers: {
    setCircleCenter(state, action) {
      const { lng, lat, didMapTriggerChange = true } = action.payload;
      return {
        ...state,
        center: [lng, lat],
        didMapTriggerChange: didMapTriggerChange,
      };
    },
    setCircleRadius(state, action) {
      const radius = action.payload;
      return {
        ...state,
        radius,
      };
    },
    toggleCircle(state) {
      return {
        ...state,
        visible: !state.visible,
      };
    },
    displayCircle(state) {
      return {
        ...state,
        visible: true,
      };
    },
  },
});

export const {
  setCircleCenter,
  setCircleRadius,
  toggleCircle,
  displayCircle,
} = circleSlice.actions;

export default circleSlice.reducer;
