const filterPropsByKeyword = (properties, keyword) => {
  const keywordLower = keyword?.toLowerCase();

  const filteredProps = properties?.filter((prop) => {
    try {
      if (prop?.address?.toLowerCase().includes(keywordLower)) return true;
      if (prop?.addressLong?.toLowerCase().includes(keywordLower)) return true;
      if (prop?.propertyType?.toLowerCase().includes(keywordLower)) return true;
      if (prop?.lrPropertyType?.toLowerCase().includes(keywordLower))
        return true;
      if (prop?.postcodeDistrict?.toLowerCase().includes(keywordLower))
        return true;
      if (prop?.agentName?.toLowerCase().includes(keywordLower)) return true;
      if (prop.tags || prop.zHistoryTags || prop.rmHistoryTags) {
        const allTags = [
          ...(prop.tags || []),
          ...(prop.zHistoryTags || []),
          ...(prop.rmHistoryTags || []),
        ];
        for (let tag of allTags) {
          if (tag?.toLowerCase().includes(keywordLower)) {
            return true;
          }
        }
      }
    } catch {
      console.log("Error in filterProperties");
      return false;
    }

    return false;
  });

  return filteredProps;
};

export default filterPropsByKeyword;
