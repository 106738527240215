export const clusterLayer = {
  id: "clusters",
  type: "circle",
  sourceId: "property-results",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "hsla(0, 0%, 90%, 0.6)",
      100,
      "hsla(0, 0%, 90%, 0.8)",
      300,
      "hsla(0, 0%, 90%, 0.95)",
    ],
    "circle-radius": ["step", ["get", "point_count"], 15, 100, 25, 300, 40],
    "circle-stroke-width": {
      base: 2,
      stops: [
        [12, 3],
        [22, 6],
      ],
    },
    "circle-stroke-color": "hsla(0, 0%, 10%, 0.8)",
  },
};

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  sourceId: "property-results",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 15,
  },
  paint: {
    "text-color": "#000000",
  },
};

export const searchResultsLayer = {
  id: "search-results-point",
  type: "circle",
  sourceId: "property-results",
  filter: ["!", ["has", "point_count"]],
  paint: {
    // make circles larger as the user zooms from z12 to z22
    "circle-radius": {
      base: 1.75,
      stops: [
        [12, 4],
        [22, 120],
      ],
    },
    "circle-stroke-width": {
      base: 3,
      stops: [
        [12, 3],
        [22, 66],
      ],
    },
    "circle-stroke-color": [
      "match",
      ["get", "listingStatus"],
      ["for_sale"],
      "green",
      ["for_rent"],
      "green",
      ["sale_under_offer"],
      "blue",
      ["rent_under_offer"],
      "blue",
      ["sold"],
      [
        "match",
        ["get", "propertySource"],
        ["landreg"],
        "purple",
        ["rightmove"],
        "blue",
        ["zoopla"],
        "blue",
        "red",
      ],
      ["rented"],
      "purple",
      "hsla(0, 0%, 0%, 1)",
    ],
    "circle-color": [
      "case",
      ["==", ["get", "isComparable"], true],
      "#ffb400",
      "hsla(0, 0%, 90%, 0.7)",
    ],
  },
};

export const searchResultsLayerSolid = {
  id: "search-results-point",
  type: "circle",
  sourceId: "property-results",
  filter: ["!", ["has", "point_count"]],
  paint: {
    // make circles larger as the user zooms from z12 to z22
    "circle-radius": {
      base: 1.75,
      stops: [
        [12, 5],
        [22, 60],
      ],
    },
    "circle-stroke-width": {
      base: 2,
      stops: [
        [12, 2],
        [22, 10],
      ],
    },
    "circle-stroke-color": [
      "case",
      ["==", ["get", "isComparable"], true],
      "#ffb400",
      "hsla(0, 0%, 30%, 1.0)",
    ],
    "circle-color": [
      "match",
      ["get", "listingStatus"],
      ["for_sale"],
      "green",
      ["for_rent"],
      "green",
      ["sale_under_offer"],
      "blue",
      ["rent_under_offer"],
      "blue",
      ["sold"],
      [
        "match",
        ["get", "propertySource"],
        ["landreg"],
        "purple",
        ["rightmove"],
        "blue",
        ["zoopla"],
        "blue",
        "red",
      ],
      ["rented"],
      "purple",
      "hsla(0, 0%, 0%, 1)",
    ],
  },
};
