import React, { Fragment } from "react";
import MapPropertyPopup from "./MapPropertyPopup";

const MapClusterPopup = ({ features, select }) => {
  return (
    <div className="cluster-popup nice-scrollbar">
      {features.properties.children.map((feature, i) => (
        <Fragment key={feature.properties.propertyID}>
          {/* {"Property " + (i + 1)} */}
          <MapPropertyPopup
            feature={feature}
            select={select}
          ></MapPropertyPopup>
          {i !== features.properties.children.length - 1 ? (
            <hr style={{ marginTop: "15px" }} />
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

export default MapClusterPopup;
