const Reducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_SELECTED_MAP_PROPERTY":
      return {
        ...state,
        MAP_SELECTED: action.id,
      };
    case "SET_SELECTED_RESULTS_PROPERTY":
      return {
        ...state,
        RESULTS_SELECTED: action.id,
      };
    case "SET_SEARCH_DRAWER_OPEN":
      return {
        ...state,
        searchDrawerOpen: action.newState,
      };
    case "SET_SEARCH_FILTER_DRAWER_OPEN":
      return {
        ...state,
        searchFilterDrawerOpen: action.newState,
      };
    case "SET_MAP_SETTINGS_DRAWER_OPEN":
      return {
        ...state,
        mapSettingsDrawerOpen: action.newState,
      };
    default:
      return state;
  }
};

export default Reducer;
