import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const LWDatePicker = (props) => {
  const { values, setFieldValue, fieldName, labelName } = props;
  return (
    <KeyboardDatePicker
      margin="normal"
      id="date-picker-dialog"
      label={labelName}
      format="MMM yyyy"
      value={values[fieldName] || new Date()}
      onChange={(e, newValue) => {
        try {
          if (newValue) {
            setFieldValue(fieldName, e.toISOString());
          }
        } catch (err) {
          console.log(err);
        }
      }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      variant="dialog"
      inputVariant="standard"
      disableFuture={true}
      openTo="year"
      views={["year", "month"]}
      style={{ width: "95px" }}
      maxDate={new Date(2024, 11, 31)}
    />
  );
};

const PlanningAppAge = (props) => {
  const { values, setFieldValue } = props;

  return (
    <div className="flex-center-center planning-date-entry">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <LWDatePicker
          {...props}
          fieldName={"planningFromDate"}
          labelName={"From"}
        />
      </MuiPickersUtilsProvider>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <LWDatePicker
          {...props}
          fieldName={"planningToDate"}
          labelName={"To"}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default PlanningAppAge;
