export const getStyleColor = (attrib) => {
  if (attrib !== "landworth" && attrib !== "weight" && attrib !== "m") {
    return [
      "interpolate",
      ["linear"],
      ["get", attrib],
      -15,
      "hsl(0, 100%, 47%)",
      -4,
      "hsla(0, 50%, 47%, 1)",
      3,
      "hsla(59, 100%, 100%, 1)",
      8,
      "hsla(108, 50%, 47%, 1)",
      20,
      "hsl(108, 100%, 47%)",
    ];
  } else if (attrib === "m") {
    return [
      "interpolate",
      ["exponential", 5],
      ["get", attrib],
      0,
      "hsl(0, 100%, 50%)",
      0.00000005,
      "hsla(0, 50%, 50%, 1)",
      0.00000015,
      "hsla(60, 100%, 50%, 1)",
      0.0000004,
      "hsla(120, 50%, 50%, 1)",
      0.0000008,
      "hsl(120, 100%, 50%)",
    ];
  } else {
    let steps;
    if (attrib === "m") {
      steps = [0, 0.00000005, 0.00000015, 0.0000004, 0.0000008];
    }
    if (attrib === "landworth") {
      steps = [120, 350, 500, 750, 3000];
    }
    if (attrib === "weight") {
      steps = [10, 200, 450, 700, 2000];
    }

    return [
      "interpolate",
      ["linear"],
      ["get", attrib],
      steps[0],
      "hsl(120, 0%, 100%)",
      steps[1],
      "hsl(120, 100%, 35%)",
      steps[2],
      "hsl(242, 100%, 75%)",
      steps[3],
      "hsl(0, 100%, 47%)",
      steps[4],
      "hsl(0, 100%, 100%)",
    ];
  }
};
export const getLandworthStyleColor = (min, avg, max) => {
  return [
    "interpolate",
    ["linear"],
    ["get", "landworth"],
    49,
    "hsl(120, 0%, 100%)",
    min,
    "hsl(120, 100%, 35%)",
    avg,
    "hsl(242, 100%, 75%)",
    max,
    "hsl(0, 100%, 47%)",
    3000,
    "hsl(0, 100%, 100%)",
  ];
};

export const getStyleHeight = (attrib) => {
  if (attrib !== "landworth" && attrib !== "weight" && attrib !== "m") {
    return [
      "interpolate",
      ["linear"],
      ["zoom"],
      7,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        -10,
        0,
        0,
        500,
        20,
        10000,
        50,
        20000,
      ],
      8,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        -10,
        0,
        1,
        150,
        20,
        5000,
        50,
        10000,
      ],
      9,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        -10,
        0,
        1,
        100,
        20,
        2000,
        50,
        5000,
      ],
      13,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        -10,
        0,
        1,
        50,
        20,
        250,
        50,
        1000,
      ],
      14,
      0,
    ];
  } else if (attrib === "m") {
    return [
      "interpolate",
      ["linear"],
      ["zoom"],
      7,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        0.0000001,
        100,
        0.0000002,
        1000,
        0.000001,
        50000,
      ],
      8,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        0.0000001,
        150,
        0.0000002,
        5000,
        0.0000004,
        10000,
      ],
      9,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        0.0000001,
        100,
        0.0000002,
        2000,
        0.0000004,
        5000,
      ],
      13,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        0.0000001,
        50,
        0.0000002,
        250,
        0.0000004,
        1000,
      ],
      14,
      0,
    ];
  } else {
    let max, multipliers;
    if (attrib === "m") {
      max = 0.0000008;
      multipliers = [30, 10, 5, 1.3, 0.05, 0];
    }
    if (attrib === "landworth") {
      max = 2000;
      multipliers = [30, 10, 5, 1.3, 0.05, 0];
    }
    if (attrib === "weight") {
      max = 500;
      multipliers = [30, 10, 5, 1.3, 0.1, 0];
    }

    return [
      "interpolate",
      ["linear"],
      ["zoom"],
      7,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        max,
        ["*", ["get", attrib], multipliers[0]],
      ],
      8,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        max,
        ["*", ["get", attrib], multipliers[1]],
      ],
      9,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        max,
        ["*", ["get", attrib], multipliers[2]],
      ],
      12,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        max,
        ["*", ["get", attrib], multipliers[3]],
      ],
      13,
      [
        "interpolate",
        ["linear"],
        ["get", attrib],
        0,
        0,
        max,
        ["*", ["get", attrib], multipliers[4]],
      ],
      14,
      0,
    ];
  }
};

export const getLandworthStyleHeight = (min, max) => {
  // max = 2200;
  let attrib = "landworth";
  let multipliers = [30, 10, 5, 1.3, 0.05, 0];

  return [
    "interpolate",
    ["linear"],
    ["zoom"],
    7,
    [
      "interpolate",
      ["linear"],
      ["get", attrib],
      min,
      0,
      max,
      ["*", ["get", attrib], multipliers[0]],
    ],
    8,
    [
      "interpolate",
      ["linear"],
      ["get", attrib],
      min,
      0,
      max,
      ["*", ["get", attrib], multipliers[1]],
    ],
    9,
    [
      "interpolate",
      ["linear"],
      ["get", attrib],
      min,
      0,
      max,
      ["*", ["get", attrib], multipliers[2]],
    ],
    12,
    [
      "interpolate",
      ["linear"],
      ["get", attrib],
      min,
      0,
      max,
      ["*", ["get", attrib], multipliers[3]],
    ],
    13,
    [
      "interpolate",
      ["linear"],
      ["get", attrib],
      min,
      0,
      max,
      ["*", ["get", attrib], multipliers[4]],
    ],
    14,
    1,
  ];
};

export const getDataFilter = (attrib) => {
  if (attrib !== "landworth" && attrib !== "weight") {
    return [
      "all",
      ["in", "$type", "Polygon", "LineString", "Point"],
      ["all", ["<", attrib, 20]],
    ];
  }

  return [
    "all",
    ["in", "$type", "Polygon", "LineString", "Point"],
    ["all", [">", "weight", 10]],
  ];
};

export const getStyleOpacity = () => {
  return [
    "interpolate",
    ["linear"],
    ["zoom"],
    8,
    1,
    12,
    0.5,
    15,
    0.3,
    16,
    0.1,
    18,
    0,
  ];
};

const getOpacityByWeight = (maxOpacity) => {
  return [
    "interpolate",
    ["linear"],
    ["get", "weight"],
    15,
    0,
    25,
    maxOpacity / 4,
    35,
    maxOpacity,
  ];
};

export const get2DStyleOpacity = () => {
  return [
    "interpolate",
    ["linear"],
    ["zoom"],
    8,
    getOpacityByWeight(1),
    12,
    getOpacityByWeight(0.6),
    15,
    getOpacityByWeight(0.3),
    16,
    getOpacityByWeight(0.1),
    18,
    getOpacityByWeight(0),
  ];
};
