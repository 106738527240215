import { useDispatch } from "react-redux";

// import ReactTimeAgo from "react-time-ago";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import PropertyListingStatus from "components/PropertyListingStatus";
import PropertyLwEstimatedPrice from "components/PropertyLwEstimatedPrice";
import PropertyPrice from "components/PropertyPrice";
import PropertyAddress from "components/PropertyAddress";
import PropertyLandworthScore from "components/PropertyLandworthScore";
import PropertySqft from "components/PropertySqft";
import PropertyBuildBedsType from "components/PropertyBuildBedsType";
import PropertyThumbnail from "components/PropertyThumbnail";
import ResultCompareButton from "../ResultCompareButton";

import {
  // starProperty,
  // unstarProperty,
  // hideProperty,
  // unhideProperty,
  setPropertySqft,
  setPropertyComparable,
} from "graphql/functions";
import {
  // starProperty as starPropertyLocal,
  // unstarProperty as unstarPropertyLocal,
  // hideProperty as hidePropertyLocal,
  // unhideProperty as unhidePropertyLocal,
  setPropertySqft as setPropertySqftLocal,
} from "data/slices/propertiesSlice";
import { setPropertyComparableRedux } from "data/slices/searchSlice";

import useSelectedResultsProperty from "pages/Search/Data/useSelectedResultsProperty";
import PropertyFloorplan from "components/PropertyFloorplan";

import PropertyLink from "./PropertyLink";
import PropertyPpsf from "./PropertyPpsf";
import TagsSection from "./TagsSection";

import { formatDateDDMMMYYY } from "services/formatting";
import PropertyComparableGrade from "components/PropertyComparableGrade";
import ComparableGradeInfo from "components/tooltips/ComparableGradeInfo";
import GenericTooltip from "components/tooltips/GenericTooltip";

const DateDisplay = ({ property }) => {
  const date = property.dateSold || property.dateFirstListed;

  return (
    <div
      className="result-date"
      style={{ fontWeight: "normal", fontSize: "0.8em" }}
    >
      {/* <ReactTimeAgo date={new Date(dateSold)} /> */}
      {formatDateDDMMMYYY(date)}
    </div>
  );
};

const ResponsiveSearchResult = (props) => {
  const {
    searchId,
    property,
    // selectMapResult,
    // selected,
    viewType,
    isComparable,
    isSmallWidth,
    isMediumWidth,
    isLargeWidth,
    valuationData,
    valuationProperty,
    search,
    expanded,
  } = props;

  const [, setSelectedResult] = useSelectedResultsProperty();

  const { propertyID, dateSold } = property;
  const dispatch = useDispatch();

  const showTodayPrice = search.showTodayPrice;

  const setSqft = (event) => {
    const sqftValue = event.target.sqftValue.value;
    window.analytics.track("Set Manual Property Size", {
      propertyID,
      sqftValue,
    });
    event.preventDefault();

    setPropertySqft(propertyID, sqftValue);
    dispatch(setPropertySqftLocal({ propertyID, sqftValue }));

    setTimeout(() => {
      setSelectedResult(propertyID);
    }, 100);
  };

  const setComparableProperty = (newState) => {
    window.analytics.track(
      newState ? "Comparable Property - Add" : "Comparable Property - Remove",
      {
        searchId,
        propertyID,
        newState,
      }
    );
    setPropertyComparable(searchId, propertyID, newState);
    dispatch(
      setPropertyComparableRedux({ searchID: searchId, propertyID, newState })
    );
  };
  const showComparableGrade =
    !!valuationData && property.propertySource === "landreg";

  const hiddenImage = viewType === "slim" || property.hidden;
  const hideLWScore =
    property.listingStatus === "for_rent" ||
    property.listingStatus === "rent_under_offer" ||
    property.listingStatus === "rented" ||
    showComparableGrade;

  const getMinHeight = () => {
    if (!isSmallWidth && hiddenImage) {
      return "64px";
    }
    if (isSmallWidth && hiddenImage) {
      return "100px";
    }
    if (isSmallWidth && !hiddenImage) {
      return "110px";
    }
    return "120px";
  };

  const styles = {
    address: {
      maxWidth: "220px",
      textAlign: "center",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 4,
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "15px",
    },
    addressSmallWidth: {
      maxWidth: "300px",
      textAlign: "left",
      justifyContent: "center",
      fontWeight: "bold",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
      alignItems: "start",
      width: "100%",
      margin: "2px 0 2px",
    },
  };

  return (
    <div
      style={{
        minHeight: getMinHeight(),
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LazyLoadComponent effect="opacity">
        <div
          className={`result-item-container fade-in ${
            property.starred ? "result-starred" : ""
          } ${property.hidden ? "result-hidden" : ""}`}
          style={{
            maxHeight: hiddenImage ? "65px" : "120px",
            cursor: expanded ? "n-resize" : "pointer",
            justifyContent: isSmallWidth && "justify-content: flex-start",
          }}
        >
          {isSmallWidth ? (
            <>
              <div className="result-first-column">
                {property.propertySource === "landreg" && !showTodayPrice && (
                  <span style={{ paddingBottom: "2px" }}>
                    <DateDisplay property={property} />
                  </span>
                )}

                {!(showTodayPrice && showComparableGrade) && (
                  <>
                    <div className="result-search-type">
                      <PropertyListingStatus
                        listingStatus={property.listingStatus}
                        property={property}
                      />
                    </div>
                    <div className="result-price">
                      <PropertyPrice property={property} />
                    </div>
                  </>
                )}
                {property.propertySource === "landreg" && showTodayPrice && (
                  <PropertyLwEstimatedPrice property={property} />
                )}
                {(property.propertySource === "rightmove" ||
                  property.propertySource === "zoopla") &&
                  property.listingStatus !== "for_rent" &&
                  property.listingStatus !== "rented" && (
                    <div
                      className="result-landworth-score"
                      style={{
                        fontSize: "0.8em",
                        lineHeight: "1.2",
                        paddingTop: "4px",
                      }}
                    >
                      <PropertyLandworthScore property={property} />
                    </div>
                  )}
                {showComparableGrade && (
                  <div
                    className="result-landworth-score"
                    style={{ paddingTop: "2px" }}
                  >
                    <ComparableGradeInfo>
                      <PropertyComparableGrade
                        valuationData={valuationData}
                        valuationProperty={valuationProperty}
                        property={property}
                        showScore={false}
                      />
                    </ComparableGradeInfo>
                  </div>
                )}
              </div>{" "}
              {!hiddenImage && (
                <div className="result-thumbnail" style={{ height: "110px" }}>
                  <PropertyThumbnail
                    property={property}
                    viewType={viewType}
                    smallImage={true}
                  />
                </div>
              )}{" "}
              <GenericTooltip
                text={property.addressLong || property.address}
                style={styles.addressSmallWidth}
              >
                <div
                  className="result-address"
                  style={{
                    flexDirection: "column",
                    // width: "100%",
                    maxWidth: styles.addressSmallWidth.maxWidth,
                  }}
                >
                  <div
                    className={`${isSmallWidth && "font-size-14"}`}
                    style={styles.addressSmallWidth}
                  >
                    <PropertyAddress
                      property={property}
                      // address={
                      //   property.address.slice(0, property.address.indexOf(",")) +
                      //   ", " +
                      //   property.postcode
                      // }
                    />
                  </div>
                  <div
                    className={`result-size-ppsf ${
                      isSmallWidth && "font-size-12"
                    }`}
                    style={{
                      justifyContent: "start",
                      width: "100%",
                      maxWidth: styles.addressSmallWidth.maxWidth,
                      marginLeft: "0",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        justifyContent: "start",
                      }}
                    >
                      <div className="result-size">
                        <PropertySqft sqft={property.sqft} setSqft={setSqft} />
                      </div>
                      <div className="result-ppsf">
                        <PropertyPpsf property={property} />
                      </div>
                    </div>
                  </div>
                </div>
              </GenericTooltip>
            </>
          ) : isMediumWidth ? (
            <>
              <div className="result-item result-compare-button">
                <ResultCompareButton
                  propertyID={propertyID}
                  comparable={isComparable}
                  setComparable={setComparableProperty}
                />
              </div>
              <div className="result-first-column">
                {!(property.propertySource === "landreg" && showTodayPrice) && (
                  <span style={{ fontSize: "1.1em", paddingBottom: "2px" }}>
                    <DateDisplay property={property} />
                  </span>
                )}
                <div className="result-search-type">
                  <PropertyListingStatus
                    listingStatus={property.listingStatus}
                    property={property}
                  />
                </div>

                <div className="result-price">
                  <PropertyPrice property={property} />
                </div>

                {property.propertySource === "landreg" && showTodayPrice && (
                  <PropertyLwEstimatedPrice property={property} />
                )}
              </div>
              {!hiddenImage && (
                <div className="result-thumbnail">
                  <PropertyThumbnail property={property} viewType={viewType} />
                </div>
              )}
              <GenericTooltip
                text={property.addressLong || property.address}
                style={styles.address}
              >
                <div className="result-address">
                  <PropertyAddress property={property} />
                </div>
              </GenericTooltip>
              <div className="result-size-ppsf">
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <span style={{ fontWeight: "normal", fontSize: "0.9em" }}>
                    <PropertyBuildBedsType property={property} />
                  </span>
                  <div className="result-size">
                    <PropertySqft sqft={property.sqft} setSqft={setSqft} />
                  </div>
                  <div className="result-ppsf">
                    <PropertyPpsf property={property} />
                  </div>
                </div>
              </div>{" "}
              {!hideLWScore && (
                <div className="result-landworth-score">
                  <PropertyLandworthScore property={property} />
                </div>
              )}
              {showComparableGrade && (
                <div className="result-landworth-score">
                  <ComparableGradeInfo>
                    <PropertyComparableGrade
                      valuationData={valuationData}
                      valuationProperty={valuationProperty}
                      property={property}
                    />
                  </ComparableGradeInfo>
                </div>
              )}
            </>
          ) : isLargeWidth ? (
            <>
              <div className="result-item result-compare-button">
                <ResultCompareButton
                  propertyID={propertyID}
                  comparable={isComparable}
                  setComparable={setComparableProperty}
                />
              </div>
              <div className="result-first-column">
                <div className="result-search-type">
                  <PropertyListingStatus
                    listingStatus={property.listingStatus}
                    property={property}
                  />
                </div>
                <div className="result-price">
                  <PropertyPrice property={property} />
                </div>
                {property.propertySource === "landreg" && showTodayPrice && (
                  <PropertyLwEstimatedPrice property={property} />
                )}
              </div>

              {!hiddenImage && (
                <div className="result-thumbnail">
                  <PropertyThumbnail property={property} viewType={viewType} />
                </div>
              )}
              <GenericTooltip
                text={property.addressLong || property.address}
                style={styles.address}
              >
                <div className="result-address">
                  <PropertyAddress property={property} />
                </div>
              </GenericTooltip>
              <div className="result-build-beds-type">
                <PropertyBuildBedsType property={property} />
              </div>
              <div className="result-size-ppsf">
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <div className="result-size">
                    <PropertySqft sqft={property.sqft} setSqft={setSqft} />
                  </div>
                  <div className="result-ppsf">
                    <PropertyPpsf property={property} />
                  </div>
                </div>
              </div>

              {!hideLWScore && (
                <div className="result-landworth-score">
                  <PropertyLandworthScore property={property} />
                </div>
              )}
              {showComparableGrade && (
                <div className="result-landworth-score">
                  <ComparableGradeInfo>
                    <PropertyComparableGrade
                      valuationData={valuationData}
                      valuationProperty={valuationProperty}
                      property={property}
                    />
                  </ComparableGradeInfo>
                </div>
              )}
              <div className="result-link">
                <PropertyLink property={property} />
              </div>
            </>
          ) : (
            <>
              <div className="result-item result-compare-button">
                <ResultCompareButton
                  propertyID={propertyID}
                  comparable={isComparable}
                  setComparable={setComparableProperty}
                />
              </div>
              <div className="result-first-column">
                <div className="result-search-type">
                  <PropertyListingStatus
                    listingStatus={property.listingStatus}
                    property={property}
                  />
                </div>
                <div className="result-price">
                  <PropertyPrice property={property} />
                </div>
                {property.propertySource === "landreg" && showTodayPrice && (
                  <PropertyLwEstimatedPrice property={property} />
                )}
              </div>

              {!hiddenImage && (
                <div className="result-thumbnail">
                  <PropertyThumbnail property={property} viewType={viewType} />
                </div>
              )}
              {!hiddenImage && (
                <div
                  className="result-thumbnail"
                  style={{ paddingLeft: "10px" }}
                >
                  <PropertyFloorplan
                    url={
                      property.floorplanURL ||
                      property.rmHistoryFloorplanURL ||
                      property.zHistoryFloorplanURL
                    }
                    maxHeight={"120px"}
                  />
                </div>
              )}
              <GenericTooltip
                text={property.addressLong || property.address}
                style={styles.address}
              >
                <div className="result-address">
                  <PropertyAddress property={property} />
                </div>
              </GenericTooltip>
              <div className="result-build-beds-type">
                <PropertyBuildBedsType property={property} />
              </div>
              <div className="result-tags-list nice-slim-scrollbar">
                <TagsSection property={property} />
              </div>

              <div className="result-size-ppsf">
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <div className="result-size">
                    <PropertySqft sqft={property.sqft} setSqft={setSqft} />
                  </div>
                  <div className="result-ppsf">
                    <PropertyPpsf property={property} />
                  </div>
                </div>
              </div>

              {!hideLWScore && (
                <div className="result-landworth-score">
                  <PropertyLandworthScore property={property} />
                </div>
              )}
              {showComparableGrade && (
                <div className="result-landworth-score">
                  <ComparableGradeInfo>
                    <PropertyComparableGrade
                      valuationData={valuationData}
                      valuationProperty={valuationProperty}
                      property={property}
                    />
                  </ComparableGradeInfo>
                </div>
              )}

              <div className="result-link">
                <PropertyLink property={property} />
              </div>
            </>
          )}
        </div>
      </LazyLoadComponent>
    </div>
  );

  // return (
  //   <LazyLoadComponent effect="opacity">
  //     <div
  //       className={`result-item-container fade-in ${
  //         property.starred ? "result-starred" : ""
  //       } ${property.hidden ? "result-hidden" : ""}`}
  //     >
  //       <div className="result-item result-compare-button">
  //         <ResultCompareButton
  //           propertyID={propertyID}
  //           comparable={isComparable}
  //           setComparable={setComparableProperty}
  //         />
  //       </div>
  //       <div className="result-first-column">
  //         <div className="result-search-type">
  //           <PropertyListingStatus
  //             listingStatus={property.listingStatus}
  //             property={property}
  //           />
  //         </div>
  //         <div className="result-price">
  //           <PropertyPrice property={property} />
  //         </div>
  //         {property.propertySource === "landreg" && showTodayPrice && (
  //           <PropertyLwEstimatedPrice property={property} />
  //         )}
  //       </div>

  //       {!hiddenImage && (
  //         <div className="result-thumbnail">
  //           <PropertyThumbnail property={property} viewType={viewType} />
  //         </div>
  //       )}
  //       {!hiddenImage && showFloorplan && (
  //         <div className="result-thumbnail" style={{ paddingLeft: "10px" }}>
  //           <PropertyFloorplan
  //             url={property.floorplanURL || property.rmHistoryFloorplanURL}
  //             maxHeight={"120px"}
  //           />
  //         </div>
  //       )}
  //       <div className="result-address">
  //         <PropertyAddress property={property} />
  //       </div>
  //       <div className="result-build-beds-type">
  //         <PropertyBuildBedsType property={property} />
  //       </div>
  //       {!hiddenImage && showFloorplan && <TagsSection property={property} />}
  //       <div className="result-size-ppsf">
  //         <div style={{ textAlign: "center" }}>
  //           <div className="result-size">
  //             <PropertySqft sqft={property.sqft} setSqft={setSqft} />
  //           </div>
  //           <div className="result-ppsf">
  //             <PropertyPpsf property={property} />
  //           </div>
  //         </div>
  //       </div>
  //       {/* <div className="result-size">
  //       <PropertySqft sqft={property.sqft} setSqft={setSqft} />
  //     </div> */}

  //       {!hideLWScore && (
  //         <div className="result-landworth-score">
  //           <PropertyLandworthScore property={property} />
  //         </div>
  //       )}

  //       <div className="result-link">
  //         <PropertyLink property={property} />
  //       </div>
  //       {/* <ResultActionButton property={property} /> */}
  //       {/* {!property.starred ? (
  //       <StarPropertyButton
  //         propertyID={property.propertyID}
  //         starThisProperty={starThisProperty}
  //       />
  //     ) : (
  //       <UnStarPropertyButton
  //         propertyID={property.propertyID}
  //         unstarThisProperty={unstarThisProperty}
  //       />
  //     )}
  //     {!property.hidden ? (
  //       <HidePropertyButton
  //         propertyID={property.propertyID}
  //         hideProperty={hideThisProperty}
  //       />
  //     ) : (
  //       <UnHidePropertyButton
  //         propertyID={property.propertyID}
  //         unhideProperty={unhideThisProperty}
  //       />
  //     )} */}
  //     </div>
  //   </LazyLoadComponent>
  // );
};

export default ResponsiveSearchResult;
