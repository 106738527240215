import IncludeNewOld from "./Widgets/IncludeNewOld";
import PlanningAppSize from "./Widgets/PlanningAppSize";
import PlanningAppState from "./Widgets/PlanningAppState";
import PlanningAppType from "./Widgets/PlanningAppType";
import PlanningAppAge from "./Widgets/PlanningAppAge";
import PropertyTypePicker from "./Widgets/PropertyTypePicker";
import SearchRadiusPicker from "./Widgets/SearchRadiusPicker";
import KeywordField from "./Widgets/KeywordField";

const PlanningForm = (props) => {
  return (
    <>
      <br />
      <PlanningAppAge {...props} />
      <br />
      <PlanningAppSize {...props} />
      <br />
      <PlanningAppState {...props} />
      <br />
      <PlanningAppType {...props} />
      <br />
      <KeywordField
        {...props}
        value={props.values?.keywords}
        placeholder={`"Quotes for 1+ word"`}
      />
      <br />
    </>
  );
};

export default PlanningForm;
