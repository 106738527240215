export const heatmaps = [
  {
    name: "off",
    viewValue: "  Off",
    legendValue: "Heatmap Off",
    type: "heatmap",
    icon: "",
  },
  {
    name: "landworth",
    viewValue: "  Average Price",
    legendValue: "Average Price",
    type: "heatmap",
    icon: "pound-sign",
  },
  {
    name: "weight",
    viewValue: "Sale Volume",
    legendValue: "Sale Volume",
    type: "heatmap",
    icon: "hand-holding-usd",
  },
  {
    name: "lwc1y",
    viewValue: "1yr Price Change",
    legendValue: "1yr Price Change",
    type: "heatmap",
    icon: "chart-line",
  },
  {
    name: "lwc3y",
    viewValue: "3yr Price Change",
    legendValue: "3yr Price Change",
    type: "heatmap",
    icon: "chart-line",
  },
  {
    name: "sc1y",
    viewValue: "1yr Sales Change",
    legendValue: "1yr Sales Change",
    type: "heatmap",
    icon: "hand-holding-usd",
  },
  {
    name: "sc3y",
    viewValue: "3yr Sales Change",
    legendValue: "3yr Sales Change",
    type: "heatmap",
    icon: "hand-holding-usd",
  },
  // {
  //   name: "m",
  //   viewValue: "Price Velocity",
  //   legendValue: " Price Velocity",
  //   type: "heatmap",
  //   icon: "fas fa-chart-line",
  // },
];

//prettier-ignore
export const basemaps = [
  { name: 'hybrid', viewValue: '  Default', icon: 'house' },
  { name: 'osm', viewValue: '  OpenStreetMap', icon: 'map' },
  { name: 'mastermap', viewValue: ' OS Mastermap', icon: 'drafting-compass' },
  { name: 'satellite', viewValue: '  Satellite', icon: 'satellite' },
  { name: 'transport', viewValue: '  Transport', icon: 'train' }
]

// prettier-ignore
// export const heatmapLayers = [
//   {    source: "prchapman.do_build200_dec20",    name: "hex02",    minzoom: 2,    maxzoom: 3,  },
//   {    source: "prchapman.do_build200_dec20",    name: "hex01",    minzoom: 3,    maxzoom: 7,  },
//   {    source: "prchapman.do_build200_dec20",    name: "hex006",    minzoom: 7,    maxzoom: 9,  },
//   {    source: "prchapman.do_build200_dec20",    name: "hex003",    minzoom: 9,    maxzoom: 10,  },
//   {    source: "prchapman.do_build200_dec20",    name: "hex002",    minzoom: 10,    maxzoom: 11,  },
//   {    source: "prchapman.do_build200_dec20",    name: "hex001",    minzoom: 11,    maxzoom: 12,  },
//   {    source: "prchapman.do_build200_dec20",    name: "district_buildings",    minzoom: 12,    maxzoom: 14,  },
//   {    source: "prchapman.do_build200_dec20",    name: "local_buildings",    minzoom: 14,    maxzoom: 22,  },
// ];
export const heatmapLayers = [
  {    source: "prchapman.do_build302_jan22",    name: "hex02",    minzoom: 2,    maxzoom: 3,  },
  {    source: "prchapman.do_build302_jan22",    name: "hex01",    minzoom: 3,    maxzoom: 7,  },
  {    source: "prchapman.do_build302_jan22",    name: "hex006",    minzoom: 7,    maxzoom: 9,  },
  {    source: "prchapman.do_build302_jan22",    name: "hex003",    minzoom: 9,    maxzoom: 10,  },
  {    source: "prchapman.do_build302_jan22",    name: "hex002",    minzoom: 10,    maxzoom: 11,  },
  {    source: "prchapman.do_build302_jan22",    name: "hex001",    minzoom: 11,    maxzoom: 12,  },
  {    source: "prchapman.do_build302_jan22",    name: "district_buildings",    minzoom: 12,    maxzoom: 14,  },
  {    source: "prchapman.do_build302_jan22",    name: "local_buildings",    minzoom: 14,    maxzoom: 22,  },
];
