import { useState } from "react";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmailUsWidget from "../../components/EmailUsWidget";

import "./searchError.css";

const ErrorMessage = () => (
  <div className="search-error-title">
    Contact us to upgrade your account and
    <br />
    experience the full power of Landworth
  </div>
);
const ContactUsButton = ({ setShowEmailUs }) => (
  <Button
    variant="contained"
    color="secondary"
    size="medium"
    onClick={() => setShowEmailUs(true)}
    style={{ fontSize: "16px", marginTop: "10px" }}
  >
    Send a Message&nbsp;&nbsp;
    <FontAwesomeIcon
      icon={["far", "comment-dots"]}
      style={{ fontSize: "18px" }}
    />
  </Button>
);

const SearchError = () => {
  const [showEmailUs, setShowEmailUs] = useState(false);

  return (
    <div className="search-error-container">
      <ErrorMessage />
      {!showEmailUs && <ContactUsButton setShowEmailUs={setShowEmailUs} />}
      <br />
      {showEmailUs && <EmailUsWidget />}
    </div>
  );
};

export default SearchError;
