import React from "react";
import useDeviceWidth from "data/hooks/useDeviceWidth";

// Import material library for adding css within this file
import { makeStyles } from "@material-ui/core/styles";

// Define a Chip component for displaying the property listing status
const useStyles = makeStyles((theme) => ({
  chip: {
    paddingTop: "1px",
    color: "white",
    background: "linear-gradient(90deg, purple, #aaa, green)",
  },
}));

const PropertyListingStatus = ({ property }) => {
  const { listingStatus, propertySource, zActiveLinks } = property;
  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();

  const classes = useStyles();

  let display;
  if (listingStatus === "for_sale") {
    display = (
      <div style={{ backgroundColor: "green", paddingTop: "1px" }}>
        FOR SALE
      </div>
    );
  } else if (listingStatus === "for_rent") {
    display = (
      <div style={{ backgroundColor: "green", paddingTop: "1px" }}>
        FOR RENT
      </div>
    );
  } else if (propertySource === "landreg") {
    if (JSON.parse(zActiveLinks)?.length > 0) {
      display = <div className={classes.chip}>SOLD</div>;
    } else {
      display = (
        <div style={{ backgroundColor: "purple", paddingTop: "1px" }}>SOLD</div>
      );
    }
  } else if (
    (propertySource === "zoopla" || propertySource === "rightmove") &&
    (listingStatus === "sold" ||
      listingStatus === "sale_under_offer" ||
      listingStatus === "rent_under_offer")
  ) {
    display = (
      <div
        style={{
          backgroundColor: "blue",
          fontSize: "11px",
          padding: "4px 0 3px",
          lineHeight: "12px",
        }}
      >
        UNDER OFFER
      </div>
    );
  } else if (listingStatus === "rented") {
    display = (
      <div style={{ backgroundColor: "purple", paddingTop: "1px" }}>RENTED</div>
    );
  } else {
    display = <div style={{ backgroundColor: "grey" }}>UNKNOWN</div>;
  }
  return (
    <div
      // className="property-listing-status-div"
      className={`property-listing-status-div ${
        isSmallWidth && "font-size-13"
      }`}
      style={{ width: isSmallWidth ? "70px" : "86px" }}
    >
      {display}
    </div>
  );
};

export default PropertyListingStatus;
