import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";

import PlanningItem from "./PlanningItem";
import { formatDateDDMMMYYY } from "services/formatting";

const PlanningSearchResult = (props) => {
  const {
    propertyID,
    planningAppID,
    address,
    lat,
    lng,
    numDocuments,
    planningDocsURL,
    numDwellings,
    pageURL,
    postcode,
    appSize,
    appState,
    appType,
    firstListed,
    lastChanged,
    planningDescription,
    tags,
  } = props.property;
  return (
    <Table className="planning-results-table">
      <TableBody>
        <PlanningItem
          isSmallWidth={props.isSmallWidth}
          isMediumWidth={props.isMediumWidth}
          isLargeWidth={props.isLargeWidth}
          status={appState}
          thumbnailImgUrl="https://i.picsum.photos/id/322/200/200.jpg?hmac=h5_-NQtnn86YBEwVT2_4zcSeuxpCnMAdriBcZchtfas"
          address={address}
          lat={lat}
          lng={lng}
          tags={tags}
          date={formatDateDDMMMYYY(firstListed)}
          description={planningDescription}
          planningAppID={planningAppID}
          numDocuments={numDocuments}
          planningDocsURL={planningDocsURL}
          pageURL={pageURL}
          appSize={appSize}
          numDwellings={numDwellings}
          property={props.property}
        />
      </TableBody>
    </Table>
  );
};

export default PlanningSearchResult;
