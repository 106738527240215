import { Field } from "formik";
import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputBase from "@material-ui/core/InputBase";

// prettier-ignore
const beds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 50]

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    // fontSize: "17px !important",
    padding: "6px 26px 6px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

const MenuItem = withStyles({
  root: {
    // justifyContent: "center",
  },
})(MuiMenuItem);

const BedPickers = (props) => {
  const { values, setFieldValue } = props;
  return (
    <div className={"search-form-item"}>
      {/* <div className={"search-form-label"}>
        <FontAwesomeIcon icon={["far", "bed"]} />
        &nbsp;&nbsp;Bedrooms
      </div> */}{" "}
      {/* <div className={"search-form-label"}></div> */}
      <Field
        component={Select}
        name="bedmin"
        id="bedmin"
        value={values?.bedmin}
        onChange={(e, newValue) => {
          if (newValue) {
            setFieldValue("bedmin", newValue.props.value);
          }
        }}
        input={<BootstrapInput />}
        style={{ width: "110px" }}
      >
        {beds.map((bed) => {
          return bed <= values?.bedmax ? (
            <MenuItem key={bed} value={bed} dense>
              {bed === beds[0] ? (
                <>
                  Min&nbsp;&nbsp;
                  <FontAwesomeIcon icon={["far", "bed"]} />
                </>
              ) : bed === beds[beds.length - 1] ? (
                "10+ Bed"
              ) : (
                `${bed} Bed`
              )}
            </MenuItem>
          ) : null;
        })}
      </Field>
      <div className={"search-form-to-label"}>to</div>
      <Field
        component={Select}
        name="bedmax"
        id="bedmax"
        value={values?.bedmax}
        onChange={(e, newValue) => {
          if (newValue) {
            setFieldValue("bedmax", newValue.props.value);
          }
        }}
        input={<BootstrapInput />}
        style={{ width: "110px" }}
      >
        {beds.map((bed) => {
          return bed >= values?.bedmin ? (
            <MenuItem key={bed} value={bed} dense>
              {bed === beds[0] ? (
                "0 Beds"
              ) : bed === beds[beds.length - 1] ? (
                <>
                  Max&nbsp;&nbsp;
                  <FontAwesomeIcon icon={["far", "bed"]} />
                </>
              ) : (
                `${bed} Bed`
              )}
            </MenuItem>
          ) : null;
        })}
      </Field>
    </div>
  );
};

export default BedPickers;
