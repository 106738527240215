import { useState, useMemo } from "react";
import Debug from "debug";

import SearchRadiusPicker from "./Widgets/SearchRadiusPicker";

import RightmoveForm from "./RightmoveForm";
import LandRegForm from "./LandRegForm";
import ZooplaForm from "./ZooplaForm";
import PlanningForm from "./PlanningForm";

import DEFAULT_SEARCH_SPEC from "../DEFAULT_SEARCH_SPEC";
import Button from "@material-ui/core/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import AccordionItem from "./AccordionItem";

import SearchRadiusDropdown from "./Widgets/SearchRadiusDropdown";

const debug = Debug("lw:SearchFormAccordion");

const radiusMarks = [
  { value: 201, label: "⅛", bubbleLabel: "⅛mi", dropdownLabel: "⅛ mile" },
  { value: 402, label: "", bubbleLabel: "¼mi", dropdownLabel: "¼ mile" },
  { value: 805, label: "", bubbleLabel: "½mi", dropdownLabel: "½ mile" },
  { value: 1207, label: "", bubbleLabel: "¾mi", dropdownLabel: "¾ mile" },
  { value: 1609, label: "1", bubbleLabel: "1mi", dropdownLabel: "1 mile" },
  { value: 2414, label: "", bubbleLabel: "1½mi", dropdownLabel: "1½ miles" },
  { value: 3218, label: "2", bubbleLabel: "2mi", dropdownLabel: "2 miles" },
  // { value: 4023, label: "2½", bubbleLabel: "2½mi" },
  { value: 4827, label: "3", bubbleLabel: "3mi", dropdownLabel: "3 miles" },
  // { value: 5632, label: "3½", bubbleLabel: "3½mi" },
  { value: 6436, label: "4", bubbleLabel: "4mi", dropdownLabel: "4 miles" },
  // { value: 7241, label: "4½", bubbleLabel: "4½mi" },
  { value: 8045, label: "5", bubbleLabel: "5mi", dropdownLabel: "5 miles" },
  { value: 9654, label: "6", bubbleLabel: "6mi", dropdownLabel: "6 miles" },
  { value: 11263, label: "7", bubbleLabel: "7mi", dropdownLabel: "7 miles" },
  // { value: 12068, label: "7.5", bubbleLabel: "7.5mi" },
  { value: 12872, label: "8", bubbleLabel: "8mi", dropdownLabel: "8 miles" },
  { value: 14481, label: "9", bubbleLabel: "9mi", dropdownLabel: "9 miles" },
  { value: 16090, label: "10", bubbleLabel: "10mi", dropdownLabel: "10 miles" },
];
const SearchFormAccordion = (props) => {
  debug("Rendering SearchFormAccordion");
  const { values, setFieldValue, setValues, isSubmitting, submitForm } = props;
  const [secretClickCount, setSecretClickCount] = useState(0);

  const noSourcesEnabled = useMemo(
    () =>
      !values.search_rm &&
      !values.search_landreg &&
      !values.search_zoopla &&
      !values.search_plan,
    [values]
  );

  const secretClick = () => {
    window.analytics.track("Clicked Secret Search Text");
    setSecretClickCount(secretClickCount + 1);
  };

  const finalRadiusMarks =
    secretClickCount <= 2
      ? radiusMarks
      : secretClickCount <= 5
      ? [
          ...radiusMarks,
          {
            value: 24135,
            label: "15",
            bubbleLabel: "15mi",
            dropdownLabel: "15 miles",
          },
          {
            value: 32180,
            label: "20",
            bubbleLabel: "20mi",
            dropdownLabel: "20 miles",
          },
        ]
      : [
          ...radiusMarks,
          {
            value: 24135,
            label: "15",
            bubbleLabel: "15mi",
            dropdownLabel: "15 miles",
          },
          {
            value: 32180,
            label: "20",
            bubbleLabel: "20mi",
            dropdownLabel: "20 miles",
          },
          {
            value: 48270,
            label: "30",
            bubbleLabel: "30mi",
            dropdownLabel: "30 miles",
          },
          {
            value: 64360,
            label: "40",
            bubbleLabel: "40mi",
            dropdownLabel: "40 miles",
          },
          // { value: 80450, label: "50", bubbleLabel: "50mi" },
          // { value: 120675, label: "75", bubbleLabel: "75mi" },
          // { value: 160900, label: "100", bubbleLabel: "100mi" },
        ];
  return (
    <div>
      {/* <div className={"search-results-wrapper-inner scrollbox nice-scrollbar"}> */}
      <div style={{ margin: "10px 0 12px 20px " }}>
        {/* <SearchRadiusPicker radiusMarks={finalRadiusMarks} /> */}
        <SearchRadiusDropdown
          radiusMarks={finalRadiusMarks}
          searchradius={values?.searchradius}
          setFieldValue={setFieldValue}
          secretClick={secretClick}
        />
        {/* <div
          className={"search-form-label flex-center-center"}
          style={{ margin: "0 10px 0 20px", flexDirection: "column" }}
        >
          <span onClick={secretClick}>Search</span>
          <span style={{ fontSize: "15px" }}>
            &#8314;&#8725;&#8331;&nbsp;
            <span style={{ fontSize: "14px" }}>Miles</span>
          </span>
        </div> */}
      </div>

      <AccordionItem
        {...props}
        sourceName=" Land Registry Sold Prices"
        parameterName="search_landreg"
        expanded={values.search_landreg}
        accordionExpansion={<LandRegForm {...props} />}
      />

      <AccordionItem
        {...props}
        sourceName=" Rightmove Listings"
        parameterName="search_rm"
        expanded={values.search_rm}
        accordionExpansion={<RightmoveForm {...props} />}
      />

      {/* <AccordionItem
          {...props}
          sourceName={
            <div className="flex-center-center">
              &nbsp;Zoopla&nbsp;&nbsp;
              <span style={{ fontSize: "0.7em", color: "#cb3c3c" }}>BETA</span>
            </div>
          }
          parameterName="search_zoopla"
          accordionExpansion={<ZooplaForm {...props} />}
        /> */}

      <AccordionItem
        {...props}
        sourceName={
          <div className="flex-center-center">
            &nbsp;Planning Applications&nbsp;&nbsp;
            {/* <span style={{ fontSize: "0.7em", color: "#3e3ccb" }}>NEW</span> */}
          </div>
        }
        parameterName="search_plan"
        expanded={values.search_plan}
        accordionExpansion={<PlanningForm {...props} />}
      />
      <br />

      <div
        style={{
          position: "sticky",
          bottom: "0px",
          backgroundColor: "#f8f4ff",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingRight: "20px",
          }}
        >
          {!noSourcesEnabled && (
            <Button
              color="default"
              variant="contained"
              size="small"
              onClick={() => setValues(DEFAULT_SEARCH_SPEC)}
            >
              Reset
            </Button>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            disabled={noSourcesEnabled || isSubmitting}
            onClick={submitForm}
          >
            {noSourcesEnabled ? (
              "Select a Source"
            ) : (
              <>
                Run Search&nbsp;&nbsp;
                <FontAwesomeIcon
                  icon={regular("magnifying-glass")}
                  style={{ fontSize: "1.2em" }}
                />
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchFormAccordion;
