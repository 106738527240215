import { Heading, View, Button, useTheme } from "@aws-amplify/ui-react";

export const signUpFormFields = {
  signUp: {
    name: {
      label: "Name",
      labelHidden: false,
      placeholder: "What should we call you?",
      isRequired: true,
      autocomplete: "name",
      order: 2,
    },
    email: {
      label: "Email Address",
      labelHidden: false,
      placeholder: "How can we reach you?",
      isRequired: true,
      autocomplete: "username",
      order: 1,
    },
    password: {
      label: "Password",
      labelHidden: false,
      placeholder: "At least 8 characters",
      isRequired: true,
      autocomplete: "new-password",
      order: 3,
    },
    confirm_password: {
      order: 4,
      autocomplete: "new-password",
    },
  },
};

export const signUpComponents = {
  SignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} ${tokens.space.xl} 0 ${tokens.space.xl}`}
          level={5}
        >
          Join for free - no credit card required
        </Heading>
      );
    },
    Footer() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={`0 0 ${tokens.space.large} 0`}>
          <Button
            fontWeight="normal"
            onClick={() => {
              window.analytics.track("Click Book a Demo");
              window.open(
                "https://calendly.com/landworth/landworth-demo",
                "_blank"
              );
            }}
            size="medium"
            variation="link"
          >
            Book a Demo
          </Button>
        </View>
      );
    },
  },
};
