import React from "react";
import {
  formatMoneyFull,
  formatDateDDMMYYY,
} from "../../../../services/formatting";

import getDateHelper from "services/dates";

import useDeviceWidth from "data/hooks/useDeviceWidth";
import PropertyBuildBedsType from "components/PropertyBuildBedsType";
import PropertyAddress from "components/PropertyAddress";
import PropertyConstructionYear from "components/PropertyConstructionYear";

const ExternalLink = ({ url, text }) => (
  <span className="external-link">
    <a href={url} target="_blank" rel="noopener noreferrer">
      <span style={{ textDecoration: "underline" }}>{text}</span>
      {/* <FontAwesomeIcon icon={["far", "external-link-alt"]} /> */}
    </a>
  </span>
);

const ItemDate = ({ item }) => (
  <span style={{ fontWeight: "bold" }}>
    {formatDateDDMMYYY(getDateHelper(item))}
  </span>
);

const PropertyRightmoveHistoryLink = ({ item }) => (
  <div>
    Listed on <ExternalLink url={item.linkURL} text="Rightmove" />
    {item.linkPrice && (
      <>
        &nbsp;for&nbsp;
        <span style={{ fontWeight: "bold", color: "green" }}>
          £{formatMoneyFull(item.linkPrice, 0)}
          {item.listingType === "forrent" && `pcm`}
        </span>
      </>
    )}
    {item.linkDate && (
      <>
        &nbsp;-&nbsp;
        <ItemDate item={item} />
      </>
    )}
  </div>
);

const PropertyZooplaHistoryLink = ({ item }) => (
  <div>
    Listed on <ExternalLink url={item.linkURL} text="Zoopla" /> for{" "}
    <span style={{ fontWeight: "bold", color: "green" }}>{item.linkPrice}</span>
    {item.linkDate && (
      <>
        &nbsp;-&nbsp;
        <ItemDate item={item} />
      </>
    )}
  </div>
);

const PropertyZooplaLiveSaleLink = ({ item }) => (
  <div>
    Available for <ExternalLink url={item.linkURL} text="Sale on Zoopla" />
    &nbsp;-&nbsp;
    <ItemDate item={item} />{" "}
  </div>
);

const PropertyZooplaLiveRentLink = ({ item }) => (
  <div>
    Available for <ExternalLink url={item.linkURL} text="Rent on Zoopla" />
    &nbsp;-&nbsp;
    <ItemDate item={item} />{" "}
  </div>
);

const PropertyLandregHistoryLink = ({ item }) => (
  <div>
    {item.newbuild === "Y" && "NEW "}
    {item.tenure === "L" ? "Leasehold" : item.tenure === "F" && "Freehold"} sold
    for{" "}
    <span style={{ fontWeight: "bold", color: "purple" }}>
      £{formatMoneyFull(item.priceSold, 0)}
    </span>
    &nbsp;-&nbsp;
    <ItemDate item={item} />
    {/* <a href={link.linkURL} target="_blank" rel="noopener noreferrer">
        View
      </a> */}
  </div>
);
const PropertyEstimatedTodayPrice = ({ item }) => (
  <div>
    Estimated &nbsp;
    <span style={{ fontWeight: "bold" }}>
      £{formatMoneyFull(item.price, 0)}
    </span>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <b>Today</b>
    {/* <a href={link.linkURL} target="_blank" rel="noopener noreferrer">
        View
      </a> */}
  </div>
);

const LandRegPriceHistory = ({ property }) => {
  const [isSmallWidth] = useDeviceWidth();

  const zHistoryLinks =
    property.zHistoryLinks?.length > 0
      ? JSON.parse(property.zHistoryLinks)
      : [];
  const zActiveLinks =
    property.zActiveLinks?.length > 0 ? JSON.parse(property.zActiveLinks) : [];
  const rmHistoryLinks =
    property.rmHistoryLinks?.length > 0
      ? JSON.parse(property.rmHistoryLinks)
      : [];
  const lrSoldPriceHistory =
    property.lrSoldPriceHistory?.length > 0
      ? JSON.parse(property.lrSoldPriceHistory)
      : [];

  const estimatedTodayPrice = property.latestLandworth
    ? [
        {
          listingType: "price_estimate",
          price: property.latestLwEstimatedPrice,
          date: property.latestLwCachedDate,
        },
      ]
    : [];

  let mergedHistoryData = [
    ...zHistoryLinks,
    ...zActiveLinks,
    ...rmHistoryLinks,
    ...lrSoldPriceHistory,
  ];
  mergedHistoryData.sort((a, b) => getDateHelper(b) - getDateHelper(a));
  mergedHistoryData = [...estimatedTodayPrice, ...mergedHistoryData];

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "1.1em", fontWeight: "bold" }}>
        <PropertyAddress property={property} />
      </div>

      <div style={{ fontSize: "0.9em", color: "grey", paddingTop: "2px" }}>
        <PropertyConstructionYear property={property} />
      </div>
      <div style={{ fontSize: "0.9em", color: "grey", paddingTop: "2px" }}>
        <PropertyBuildBedsType property={property} />
      </div>

      <div
        style={{
          textAlign: "right",
          fontSize: isSmallWidth ? "13px" : "15px",
          padding: "12px 0 6px",
        }}
      >
        {mergedHistoryData &&
          mergedHistoryData.length > 0 &&
          mergedHistoryData.map((item, index) => {
            if (item.listingType === "sold")
              return <PropertyLandregHistoryLink key={index} item={item} />;
            else if (
              item.listingType === "forsale" ||
              item.listingType === "forrent"
            ) {
              if (item.propertySource === "rm") {
                return (
                  item.linkDate && (
                    <PropertyRightmoveHistoryLink key={index} item={item} />
                  )
                );
              } else {
                return <PropertyZooplaHistoryLink key={index} item={item} />;
              }
            } else if (
              item.listingType === "currentlyforsale" ||
              item.listingType === "live"
            )
              return <PropertyZooplaLiveSaleLink key={index} item={item} />;
            else if (item.listingType === "currentlyforrent")
              return <PropertyZooplaLiveRentLink key={index} item={item} />;
            else if (item.listingType === "price_estimate")
              return <PropertyEstimatedTodayPrice key={index} item={item} />;
            else return null;
          })}
      </div>
    </div>
  );
};

export default LandRegPriceHistory;
