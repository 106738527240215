import React from "react";

const AgentDetails = ({ property }) => {
  return (
    <>
      <div style={{ padding: "4px 0" }}>{property.agentName}</div>
      <div style={{ padding: "4px 0" }}>
        <img src={property.agentLogo} alt="Agent Logo" />
      </div>
      <div style={{ padding: "4px 0" }}>{property.agentPhone}</div>
    </>
  );
};

export default AgentDetails;
