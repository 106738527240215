import PlanningAppMultiSelect from "./PlanningAppMultiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const sizes = ["Small", "Medium", "Large"];

const PlanningAppSize = (props) => {
  // const { values, setFieldValue } = props;
  return (
    <div className="flex-center-center">
      <FontAwesomeIcon icon={regular("memo-circle-info")} />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Size&nbsp;&nbsp;&nbsp;
      <PlanningAppMultiSelect
        {...props}
        options={sizes}
        fieldName={"planningAppSizes"}
      />
    </div>
  );
};

export default PlanningAppSize;
