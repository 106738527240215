import { useEffect, useState } from "react";

import LocationSearchHolder from "./LocationSearchHolder";
import ValuationSpecsHolder from "./ValuationSpecsHolder";
import ValuationReportHolder from "./ValuationReportHolder";
import ReportHistory from "../UserHistory/ReportHistory";

import NewSignInComponent from "../../auth/NewSignInComponent";

import {
  useRouteMatch,
  Switch,
  Route,
  withRouter,
  useParams,
} from "react-router-dom";
import NotEnoughCredits from "components/credits/NotEnoughCredits";

const GenericError = ({ errorCode }) => (
  <>
    <div>
      <span style={{ fontSize: "1.2em" }}>
        Sorry, an error occurred when
        <br />
        creating this valuation
      </span>
      <br />
      <br />
      <b>You have not been charged</b>
      <br />
      <br />
    </div>
    <div style={{ color: "red" }}>The error is: {errorCode}</div>
  </>
);

const ValuationLoadError = () => {
  const { errorCode } = useParams();
  useEffect(() => {
    window.analytics.track("Error Loading Valuation", { errorCode });
  }, []);
  return (
    <div
      className="flex-center-center"
      style={{ height: "100%", textAlign: "center", flexDirection: "column" }}
    >
      {errorCode === "ORG_NOT_FOUND" ? (
        <div style={{ fontSize: "1.2em" }}>
          <span style={{ color: "red" }}>
            Sorry the referral code used is not valid
          </span>
          <br />
          <br />
          You can <a href="/report">Sign In</a> to Landworth instead
        </div>
      ) : errorCode === "NOT_ENOUGH_CREDITS" ? (
        <NotEnoughCredits actionType="valuation" />
      ) : (
        <GenericError errorCode={errorCode} />
      )}
    </div>
  );
};

const Report = ({ history, isLoggedIn }) => {
  const match = useRouteMatch();
  const [localLocationSpec, setLocalLocationSpec] = useState();

  return (
    <>
      <Switch>
        <Route path={`${match.path}/history`}>
          {isLoggedIn ? <ReportHistory /> : <NewSignInComponent />}
        </Route>

        <Route path={`${match.path}/referral/:orgId`}>
          <LocationSearchHolder setLocalLocationSpec={setLocalLocationSpec} />
        </Route>

        <Route path={`${match.path}/new`}>
          {isLoggedIn ? (
            <LocationSearchHolder setLocalLocationSpec={setLocalLocationSpec} />
          ) : (
            <NewSignInComponent />
          )}
        </Route>

        <Route path={`${match.path}/edit`}>
          {isLoggedIn || !!localLocationSpec?.referringDomainName ? (
            <ValuationSpecsHolder
              isLoggedIn={isLoggedIn}
              localLocationSpec={localLocationSpec}
            />
          ) : (
            <NewSignInComponent />
          )}
        </Route>

        <Route path={`${match.path}/error/:errorCode`}>
          <ValuationLoadError />
        </Route>

        <Route path={`${match.path}/:valuationId`}>
          <ValuationReportHolder isLoggedIn={isLoggedIn} history={history} />
        </Route>

        <Route path={`${match.path}`}>
          {isLoggedIn ? (
            <LocationSearchHolder setLocalLocationSpec={setLocalLocationSpec} />
          ) : (
            <NewSignInComponent />
          )}
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(Report);
