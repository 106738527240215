import { useMemo } from "react";
import { getCreditData as getCreditDataRedux } from "data/selectors";
import { useSelector } from "react-redux";

import HouseType from "./Widgets/HouseType";
import IncludeNewOld from "./Widgets/IncludeNewOld";
import PricePickers from "./Widgets/PricePickers";
import PropertyTypePicker from "./Widgets/PropertyTypePicker";
import SizePickers from "./Widgets/SizePickers";
import IncludeFreeholdLeasehold from "./Widgets/IncludeFreeholdLeasehold";
import TransactionAge from "./Widgets/TransactionAge";
import LandOnly from "./Widgets/LandOnly";

const LandRegForm = (props) => {
  const creditData = useSelector((state) => getCreditDataRedux(state));

  const isBetaTester = useMemo(
    () =>
      creditData.orgId === "landworth.org" ||
      creditData.orgId === "octopus.realestate"
        ? true
        : false,
    [creditData.orgId]
  );

  return (
    <>
      <PropertyTypePicker {...props} />
      <br />
      <IncludeNewOld {...props} />
      <br />
      <PricePickers {...props} />
      <br />
      <SizePickers {...props} />
      <br />
      <IncludeFreeholdLeasehold {...props} />
      {props?.values?.house_types && props?.values?.property_type === "houses" && (
        <>
          <br />
          <HouseType {...props} />
        </>
      )}
      <br />
      <TransactionAge {...props} />
      <br />
      {isBetaTester && (
        <>
          <LandOnly {...props} />
          <br />
        </>
      )}
    </>
  );
};

export default LandRegForm;
