import { useEffect, useCallback } from "react";
import { getValuation } from "../../graphql/functions";
import { useDispatch } from "react-redux";
import { setValuationData } from "../slices/valuationSlice";
import { addDummySearch } from "../slices/searchSlice";

/**
 * Custom hook to get valuation data results. returns [error, valuationData]
 *
 * @param {*} valuationId
 */
function useValuation(valuationId) {
  const dispatch = useDispatch();

  // get the valuation data and then sort the valuation data / create a search
  const fetchValuationData = useCallback(
    async (valuationId) => {
      if (valuationId) {
        const valuationData = await getValuation(valuationId);
        if (valuationData) {
          dispatch(
            setValuationData({
              id: valuationId,
              valuationPropertyId: valuationData.valuationPropertyId,
              valuationPrice: valuationData.valuationPrice,
              valuationPriceSource: valuationData.valuationPriceSource,
              valuationName: valuationData.valuationName,
              valuationDate: valuationData.valuationDate,
              soldSearchId: valuationData.soldSearchId,
              forsaleSearchId: valuationData.forsaleSearchId,
              rentalSearchId: valuationData.rentalSearchId,
              planningSearchId: valuationData.planningSearchId,
              newBuildSearchId: valuationData.newBuildSearchId,
            })
          );
          dispatch(
            addDummySearch({
              valuationId,
              valuationPropertyId: valuationData.valuationPropertyId,
            })
          );
        }
      }
    },
    [dispatch]
  );

  // whenever we see a new valuation id, kick off the async fetch(s)
  useEffect(
    () => fetchValuationData(valuationId),
    [valuationId, fetchValuationData]
  );
}

export default useValuation;
