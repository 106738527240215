import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import useDeviceWidth from "data/hooks/useDeviceWidth";

const ZooplaHistoryButton = ({ property, zHistoryURL }) => {
  return (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      onClick={() =>
        window.analytics.track("View Zoopla History", {
          propertyID: property.propertyID,
          zHistoryURL: zHistoryURL,
        })
      }
      disabled={!zHistoryURL || zHistoryURL === "-1" ? true : false}
    >
      {!zHistoryURL ? (
        <>
          Checking Zoopla&nbsp;&nbsp;
          <CircularProgress size="15px" thickness={6} />
        </>
      ) : `${zHistoryURL}` === "-1" ? (
        "No Zoopla History Found"
      ) : (
        <a
          href={zHistoryURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "white" }}
        >
          {zHistoryURL ? "Zoopla History" : "LOADING"}
        </a>
      )}
    </Button>
  );
};

const RMHistoryButton = ({ property, rmHistoryURL }) => {
  return (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      onClick={() =>
        window.analytics.track("View RM History", {
          propertyID: property.propertyID,
          rmHistoryURL: rmHistoryURL,
        })
      }
      disabled={!rmHistoryURL || rmHistoryURL === "-1" ? true : false}
    >
      {!rmHistoryURL ? (
        <>
          Checking Rightmove&nbsp;&nbsp;
          <CircularProgress size="15px" thickness={6} />
        </>
      ) : `${rmHistoryURL}` === "-1" ? (
        "No Rightmove History Found"
      ) : (
        <a
          href={rmHistoryURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "white" }}
        >
          {rmHistoryURL ? "Rightmove History" : "LOADING"}
        </a>
      )}
    </Button>
  );
};

const LandRegHistory = ({ property }) => {
  const { zHistoryURL, zBeds, zBaths, rmHistoryURL, rmBeds, rmBaths } =
    property;

  const [isSmallWidth] = useDeviceWidth();

  return (
    <div style={{ textAlign: "center", paddingTop: "10px" }}>
      <div
        className="flex-center-center"
        style={{
          paddingBottom: "18px",
          fontSize: isSmallWidth ? "14px" : "15px",
        }}
      >
        <RMHistoryButton property={property} rmHistoryURL={rmHistoryURL} />
        &nbsp;&nbsp;&nbsp;
        {rmBeds && <>{rmBeds} Bed</>}
        {rmBaths && <> & {rmBaths} Bath</>}
      </div>

      <div
        className="flex-center-center"
        style={{
          paddingBottom: "8px",
          fontSize: isSmallWidth ? "14px" : "15px",
        }}
      >
        <ZooplaHistoryButton property={property} zHistoryURL={zHistoryURL} />
        &nbsp;&nbsp;&nbsp;
        {zBeds && <>{zBeds} Bed</>}
        {zBeds && zBaths && <> & </>}
        {zBaths && <>{zBaths} Bath</>}
      </div>
    </div>
  );
};

export default LandRegHistory;
