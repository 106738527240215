import LandworthIndicator from "../../../../components/landworthIndicator";
import { Components } from "@landworth/fiverr-chiran-landworth";

const TitleAndIndicator = ({
  totalWeight,
  recentLabel,
  lwData,
  value,
  date,
  property,
}) => (
  <>
    <br />
    <div style={{ margin: "0 0 12px", textAlign: "center" }}>
      Comparing to{" "}
      <span style={{ fontWeight: "bold" }}>{Math.round(totalWeight)}</span>{" "}
      {recentLabel}
      <br />
      <div style={{ opacity: "0.8", fontSize: "0.7em" }}>
        <Components.AccuracyStars accuracy={totalWeight} />
      </div>
    </div>
    <LandworthIndicator
      property={property}
      landworthData={lwData}
      value={value}
      date={date}
      propertyTypeParent={property.propertyTypeParent}
    />
  </>
);

const PpsfAnalysis = ({ property }) => {
  const { ppsf, landworth, lwsd, latestLandworth, latestLwsd } = property;
  const landworthData = {
    worth: landworth,
    stdev: lwsd,
  };
  const latestLandworthData = {
    worth: latestLandworth,
    stdev: latestLwsd,
  };

  return (
    <div style={{ maxWidth: "300px" }}>
      {property.landworth > 0 ? (
        <TitleAndIndicator
          totalWeight={property.lwTotalWeight}
          recentLabel="nearby sales"
          lwData={landworthData}
          value={ppsf > 0 && ppsf !== Infinity ? ppsf : null}
          date={property.dateSold}
          property={property}
        />
      ) : property.landworth === -1 ? (
        <div
          className="flex-center-center"
          style={{ height: "200px", minWidth: "260px", color: "red" }}
        >
          Not enough sales data
        </div>
      ) : (
        <div
          className="flex-center-center"
          style={{ height: "200px", minWidth: "260px" }}
        >
          <div className="loader-small"></div>
        </div>
      )}
      {property.propertySource === "landreg" && (
        <TitleAndIndicator
          totalWeight={property.latestLwTotalWeight}
          recentLabel="recent nearby sales"
          lwData={latestLandworthData}
          value={null}
          date={property.latestLwCachedDate}
          property={property}
        />
      )}
    </div>
  );
};

export default PpsfAnalysis;
