import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import PropertyThumbnail from "components/PropertyThumbnail";
import PropertyBuildBedsType from "components/PropertyBuildBedsType";

import PropertyLinkButtonRow from "./PropertyLinkButtonRow";
import PpsfAnalysis from "./Tabs/PpsfAnalysis";
import Floorplan from "./Tabs/Floorplan";
import ListingHistory from "./Tabs/ListingHistory";
import LandRegHistory from "./Tabs/LandRegHistory";
import LandRegPriceHistory from "./Tabs/LandRegPriceHistory";
import AgentDetails from "./Tabs/AgentDetails";
import RentalData from "./Tabs/RentalData";
import TagsTab from "./Tabs/TagsTab";
import EPCTab from "./Tabs/EPCTab";

import useDeviceWidth from "data/hooks/useDeviceWidth";
import FullAddress from "./Tabs/FullAddress";

import { getCreditData as getCreditDataRedux } from "data/selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // backgroundColor: "white",
    padding: "5px 10px",
  },
  expansionTabContentHalfWidth: {
    width: "50%",
  },
  slideContainer: {
    width: 100,
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
        p={3}
      >
        {children}
      </Box>
    </div>
  );
};

const LandRegHistoryPhoto = ({ property }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <div>
        <LandRegPriceHistory property={property} />
        <hr />
        <LandRegHistory property={property} />
      </div>
      <div className="expanded-property-thumbnail">
        <PropertyThumbnail property={property} viewType={"bigthumb"} />
      </div>
    </div>
  );
};
const PpsfAnalysisPhoto = ({ property }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <div>
        <PropertyThumbnail property={property} viewType={"bigthumb"} />
      </div>
      <div>
        <PpsfAnalysis property={property} />
      </div>
    </div>
  );
};

const GenericTabs = ({ property, select, activeTab, setActiveTab }) => {
  const creditData = useSelector((state) => getCreditDataRedux(state));

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };
  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();

  let tabListKeys;
  if (property.propertySource === "zoopla")
    tabListKeys = [
      "ppsf",
      "image",
      "size",
      "tags",
      "zhistory",
      "rental",
      "agent",
      ...(creditData.orgId === "landworth.org" ? ["fulladdress"] : []),
    ];
  if (property.propertySource === "rightmove")
    tabListKeys = [
      "ppsf",
      "image",
      "size",
      "tags",
      "zhistory",
      "rental",
      "agent",
      ...(creditData.orgId === "landworth.org" ? ["fulladdress"] : []),
    ];
  if (property.propertySource === "landreg")
    tabListKeys = [
      "landreghistory",
      "image",
      "ppsf",
      "size",
      "epc",
      "tags",
      "rental",
    ];
  if (property.propertySource === "plan")
    tabListKeys = ["description", "tags", "image"];

  const isRental =
    property.listingStatus === "for_rent" ||
    property.listingStatus === "rent_under_offer" ||
    property.listingStatus === "rented";

  if (isRental) tabListKeys = tabListKeys.filter((tab) => tab !== "ppsf");

  const tabList = [
    {
      key: "image",
      label: "Image",
      component: (
        <PropertyThumbnail property={property} viewType={"bigthumb"} />
      ),
    },
    {
      key: "landreghistory",
      label: "Details",
      component:
        isSmallWidth || isMediumWidth ? (
          <div>
            <LandRegPriceHistory property={property} />
            <hr />
            <LandRegHistory property={property} />
          </div>
        ) : (
          <LandRegHistoryPhoto property={property} />
        ),
    },
    {
      key: "ppsf",
      label: "£/sqft",
      component:
        property.propertySource === "landreg" ||
        isSmallWidth ||
        isMediumWidth ? (
          <>
            <b>
              <PropertyBuildBedsType property={property} />
            </b>
            <PpsfAnalysis property={property} />
          </>
        ) : (
          <PpsfAnalysisPhoto property={property} />
        ),
    },
    {
      key: "rental",
      label: "Rental",
      component: <RentalData property={property} />,
    },
    {
      key: "epc",
      label: "EPC",
      component: <EPCTab property={property} />,
    },
    {
      key: "size",
      label: "Size & Floorplan",
      component: <Floorplan property={property} />,
    },
    { key: "tags", label: "Tags", component: <TagsTab property={property} /> },
    {
      key: "zhistory",
      label: "History",
      component: <ListingHistory property={property} />,
    },
    {
      key: "agent",
      label: "Agent",
      component: <AgentDetails property={property} />,
    },
    {
      key: "fulladdress",
      label: "Full Address",
      component: <FullAddress key={property.propertyID} property={property} />,
    },
    {
      key: "description",
      label: "Description",
      component: (
        <div className="flex-center-center">
          {property.description || property.planningDescription}
        </div>
      ),
    },
  ];

  return (
    <Paper className={classes.root} elevation={0}>
      <AppBar position="static" color="transparent">
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          {tabListKeys.map((key) => {
            const t = tabList.find((tab) => tab.key === key);
            return <Tab key={t.key} label={t.label} />;
          })}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={"x"}
        index={activeTab}
        onChangeIndex={handleChangeIndex}
        springConfig={{
          duration: "0.5s",
          easeFunction: "ease-out",
          delay: "0s",
        }}
        slideClassName="result-expansion-tab-slide"
      >
        {tabListKeys.map((key, index) => {
          const t = tabList.find((tab) => tab.key === key);
          return (
            <TabPanel key={t.key} value={activeTab} index={index}>
              {t.component}
            </TabPanel>
          );
        })}
      </SwipeableViews>
    </Paper>
  );
};

const HalfWidthExpansion = ({ property, select, activeTab, setActiveTab }) => {
  const { propertyID, propertySource } = property;

  const setTab = (tabIndex) => {
    window.analytics.track("Change Property Tab", {
      propertyID,
      propertySource,
      tabIndex,
    });
    setActiveTab(tabIndex);
  };

  return (
    <div
      key={propertyID}
      id={propertyID}
      className={`result-expansion-container ${
        property.starred ? "result-starred" : ""
      } ${property.hidden ? "result-hidden" : ""}`}
    >
      <PropertyLinkButtonRow property={property} />
      <br />
      <GenericTabs
        property={property}
        select={select}
        activeTab={activeTab}
        setActiveTab={setTab}
      />
    </div>
  );
};

export default HalfWidthExpansion;
