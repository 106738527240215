import { useState, useEffect } from "react";
import filterPropsByKeyword from "services/filterProperties";
import Debug from "debug";
import { useSelector } from "react-redux";
import useSearchFilter from "./useSearchFilter";

import { getSearchSelector } from "data/selectors";
import useSortedSearchResults from "data/hooks/useSortedSearchResults";

const debug = Debug("lw:useFilteredSearchResults");

// const filterSource = (properties, source) => {
//   return properties.filter((prop) => prop.propertySource === source);
// };

const runFilter = (properties, searchFilter) => {
  // debug(`Filtering using '${JSON.stringify(searchFilter)}'`);
  let props = properties;

  if (
    searchFilter.includeTags.length > 0 ||
    searchFilter.excludeTags.length > 0
  ) {
    props = props.filter((prop) => {
      const allTags = [].concat(
        prop?.rmHistoryTags,
        prop?.zHistoryTags,
        prop?.tags
      );

      const hasIncludeTag =
        searchFilter.includeTags.length === 0 ||
        allTags?.some((tag) => searchFilter.includeTags.includes(tag));
      const hasExcludeTag = allTags?.some((tag) =>
        searchFilter.excludeTags.includes(tag)
      );

      return hasIncludeTag && !hasExcludeTag;
    });
  }

  if (searchFilter.keyword && searchFilter.keyword !== "")
    props = filterPropsByKeyword(props, searchFilter.keyword);

  if (!searchFilter.includeZoopla)
    props = props.filter((prop) => prop.propertySource !== "zoopla");
  if (!searchFilter.includeRightmove)
    props = props.filter((prop) => prop.propertySource !== "rightmove");
  if (!searchFilter.includeLandreg)
    props = props.filter((prop) => prop.propertySource !== "landreg");
  if (!searchFilter.includeUnknownBed)
    props = props.filter(
      (prop) =>
        !(
          !prop.beds &&
          prop.beds !== 0 &&
          !prop.rmBeds &&
          prop.rmBeds !== 0 &&
          !prop.zBeds &&
          prop.zBeds !== 0
        )
    );
  if (!searchFilter.include0Bed)
    props = props.filter(
      (prop) => !(prop.beds === 0 || prop.rmBeds === 0 || prop.zBeds === 0)
    );
  if (!searchFilter.include1Bed)
    props = props.filter(
      (prop) => !(prop.beds === 1 || prop.rmBeds === 1 || prop.zBeds === 1)
    );
  if (!searchFilter.include2Bed)
    props = props.filter(
      (prop) => !(prop.beds === 2 || prop.rmBeds === 2 || prop.zBeds === 2)
    );
  if (!searchFilter.include3Bed)
    props = props.filter(
      (prop) => !(prop.beds === 3 || prop.rmBeds === 3 || prop.zBeds === 3)
    );
  if (!searchFilter.include4PlusBed)
    props = props.filter(
      (prop) => !(prop.beds >= 4 || prop.rmBeds >= 4 || prop.zBeds >= 4)
    );

  if (!searchFilter.includeSold)
    props = props.filter(
      (prop) =>
        !(prop.propertySource === "landreg" && prop.listingStatus === "sold")
    );
  if (!searchFilter.includeForSale)
    props = props.filter(
      (prop) =>
        !(
          (prop.propertySource === "rightmove" ||
            prop.propertySource === "zoopla") &&
          prop.listingStatus === "for_sale"
        )
    );
  if (!searchFilter.includeUnderOffer)
    props = props.filter(
      (prop) =>
        !(
          (prop.propertySource === "rightmove" ||
            prop.propertySource === "zoopla") &&
          prop.listingStatus === "sold"
        )
    );

  return props;
};

/**
 * Convenience hook for getting filtered results
 */
const useFilteredSearchResults = (searchId) => {
  const search = useSelector((state) => getSearchSelector(state, searchId));
  const sortOrder = search.searchSortOrder;

  const [properties, propertyIds] = useSortedSearchResults(
    searchId,
    sortOrder,
    search?.spec?.searchlat,
    search?.spec?.searchlng
  );
  const [searchFilter] = useSearchFilter();
  const [filteredProperties, setFilteredProperties] = useState([]);

  // const filterKeyword = useSelector(getFilterKeyword);

  // only re-filter properties when a variable changes
  useEffect(() => {
    const p = runFilter(properties, searchFilter);
    setFilteredProperties(p);
  }, [properties, searchFilter, sortOrder]);

  // const filteredProperties = useMemo(() => {
  //   debug(`Filtering by '${filterKeyword}'`);
  //   const props =
  //     filterKeyword !== ""
  //       ? filterProperties(properties, filterKeyword)
  //       : properties;
  //   return props;
  // }, [properties, filterKeyword]);
  // const filteredProperties = useMemo(() => {
  //   if (filterKeyword) debug(`Filtering by '${filterKeyword}'`);
  //   const props =
  //     filterKeyword !== ""
  //       ? filterProperties(properties, filterKeyword)
  //       : properties;
  //   return props;
  // }, [properties, filterKeyword]);

  return [filteredProperties, propertyIds];
};

export default useFilteredSearchResults;
