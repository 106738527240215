import React from "react";
import { capitaliseFirstLetter } from "../services/formatting";

const PropertyType = ({ property, hideHouseText }) => {
  const { propertyType, propertySource } = property;
  let typeText;

  if (
    propertySource === "zoopla" ||
    (propertySource === "rightmove" && propertyType)
  ) {
    typeText = capitaliseFirstLetter(propertyType);
  } else if (propertySource === "landreg" && propertyType) {
    typeText =
      propertyType === "F" || propertyType === "flat"
        ? "Flat"
        : propertyType === "D" || propertyType === "detached house"
        ? "Detached House"
        : propertyType === "T" || propertyType === "terraced house"
        ? "Terraced House"
        : propertyType === "S" || propertyType === "semi-detached house"
        ? "Semi-Detached House"
        : "Other";
  } else {
    typeText = "Property";
  }

  if (hideHouseText && typeText !== "House") {
    typeText = typeText.replace(/ House$/, "");
  }

  return <>{typeText}</>;
};

export default PropertyType;
