import React from "react";
import { heatmaps } from "../pages/Map/heatmap.definitions";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const HeatmapSelector = ({
  selectedHeatmap,
  setSelectedHeatmap,
  showCircle,
  setShowCircle,
}) => {
  return (
    <Select
      name="heatmap"
      id="heatmap"
      value={selectedHeatmap}
      onChange={(e) => {
        e.stopPropagation();
        const newHeatmap = e.target.value;
        window.analytics.track("Change Heatmap", {
          heatmap: newHeatmap,
        });
        setSelectedHeatmap(newHeatmap);
        if (newHeatmap !== "landworth" && showCircle) setShowCircle();
      }}
      className="heatmap-selector-select"
    >
      {heatmaps.map((heatmap) => (
        <MenuItem key={heatmap.name} value={heatmap.name}>
          {heatmap.viewValue}
        </MenuItem>
      ))}
    </Select>
  );
};

export default HeatmapSelector;
