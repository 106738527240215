import SearchFormItem from "../SearchFormItem";

const HouseType = (props) => {
  const { setFieldValue, values } = props;
  const { house_types } = values;

  const updateHouseType = (type, checked) => {
    let newHouseTypes;
    if (!checked) {
      newHouseTypes = house_types.filter((item) => ![type].includes(item));
    } else {
      newHouseTypes = house_types.slice();
      newHouseTypes.push(type);
    }
    setFieldValue("house_types", newHouseTypes);
  };

  return (
    values?.house_types &&
    values?.property_type === "houses" && (
      <div className={"search-form-item"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 19px",
          }}
        >
          House
          <br />
          Type
        </div>
        <div className={"search-form-item-group"}>
          <SearchFormItem
            checked={house_types.includes("T")}
            setChecked={(checked) => updateHouseType("T", checked)}
            label="Terraced"
          />
          <SearchFormItem
            checked={house_types.includes("S")}
            setChecked={(checked) => updateHouseType("S", checked)}
            label="Semi-Detached"
          />
          <SearchFormItem
            checked={house_types.includes("D")}
            setChecked={(checked) => updateHouseType("D", checked)}
            label="Detached"
          />
        </div>
      </div>
    )
  );
};

export default HouseType;
