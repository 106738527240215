import useDeviceWidth from "data/hooks/useDeviceWidth";

const PropertyPrice = ({ property }) => {
  const { listingStatus, price, soldDate } = property;
  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();

  return (
    <div className={`result-price-element ${isSmallWidth && "font-size-15"}`}>
      {price === 0
        ? "POA"
        : "£" +
          price.toLocaleString("en-UK", { maximumFractionDigits: 0 })}{" "}
      {(listingStatus === "for_rent" ||
        listingStatus === "rented" ||
        listingStatus === "rent_under_offer") && (
        <span style={{ fontSize: "13px", whiteSpace: "nowrap" }}>/month</span>
      )}
    </div>
  );
};

export default PropertyPrice;
