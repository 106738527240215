const RentalData = ({ property }) => {
  const { postcodeDistrict } = property;
  return (
    <>
      <div style={{ padding: "4px 0", textAlign: "center" }}>
        You can find average rental prices for <b>{postcodeDistrict}</b> on{" "}
        <a
          href={`https://www.home.co.uk/for_rent/current_rents?location=${postcodeDistrict}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Home.co.uk
        </a>
        {/* <br />
        <br />
        Or you can run a rental search here <button>SEARCH</button> */}
      </div>
    </>
  );
};

export default RentalData;
