import TagChips from "components/TagChips";

const TagsSection = ({ property }) => {
  return (
    <div
      className="result-tags"
      style={{
        maxWidth: "156px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <TagChips key={property.propertyID} property={property} size="small" />
    </div>
  );
};

export default TagsSection;
