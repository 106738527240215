import { withStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  expandIcon: {
    "&$expanded": {
      transform: "none",
    },
  },
  expanded: {},
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    fontSize: "12px",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const AccordionItem = (props) => {
  const {
    values,
    setFieldValue,
    parameterName,
    sourceName,
    expanded,
    accordionExpansion,
  } = props;
  const classes = useStyles();

  const toggleSourceStatus = (event) => {
    if (parameterName === "search_plan" && !values.search_plan) {
      // setFieldValue("search_type", "plan");
      setFieldValue("search_rm", false);
      setFieldValue("search_landreg", false);
    } else if (!expanded) {
      // setFieldValue("search_type", "sale");
      setFieldValue("search_plan", false);
    }
    setFieldValue(parameterName, !expanded);

    if (!expanded) {
      window.analytics.track(`Enable Source: ${parameterName}`);
    } else {
      window.analytics.track(`Disable Source: ${parameterName}`);
    }
  };

  const tooltipText = `Enable${sourceName} Search`;

  return (
    <>
      <Accordion
        className={`search-form-accordion-summary`}
        onChange={toggleSourceStatus}
        elevation={expanded ? 6 : 3}
        expanded={expanded}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          classes={{
            expandIcon: classes.expandIcon,
            expanded: classes.expanded,
          }}
          expandIcon={
            <Tooltip id="show-details" title={tooltipText}>
              {/* <ArrowDropDownCircle style={{ color: "#592E9A" }} /> */}
              <>
                <span style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                  Off&nbsp;
                </span>
                <AntSwitch checked={expanded} name="rightmove_switch" />
                <span style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                  &nbsp;On&nbsp;
                </span>
              </>
            </Tooltip>
          }
          aria-controls="search-result-content"
          key={sourceName}
          style={{ fontSize: "1rem" }}
        >
          &nbsp;&nbsp;{sourceName}
        </AccordionSummary>
        <AccordionDetails>{accordionExpansion}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default AccordionItem;
