import { Field } from "formik";
import Button from "@material-ui/core/Button";

const IncludeNewOld = (props) => {
  const { values, setFieldValue } = props;
  const isNew = values?.newbuild && !values?.oldbuild;
  const isBoth = values?.newbuild && values?.oldbuild;
  const isOld = !values?.newbuild && values?.oldbuild;
  const setBuildType = (newBuild, oldBuild) => {
    setFieldValue("newbuild", newBuild);
    setFieldValue("oldbuild", oldBuild);
  };
  return (
    <>
      <div className={"search-form-item"}>
        <div className={"search-form-label"}></div>
        <Field
          component={Button}
          name="build_type_old"
          id="build_type_old"
          className="search-button-wide"
          variant={isOld ? "contained" : "outlined"}
          color={isOld ? "secondary" : "default"}
          size="small"
          onClick={(e) => setBuildType(false, true)}
        >
          2nd Hand
        </Field>
        <Field
          component={Button}
          name="build_type_both"
          id="build_type_both"
          className="search-button-all"
          variant={isBoth ? "contained" : "outlined"}
          color={isBoth ? "secondary" : "default"}
          size="small"
          onClick={(e) => setBuildType(true, true)}
        >
          All
        </Field>
        <Field
          component={Button}
          name="build_type_new"
          id="build_type_new"
          className="search-button-wide"
          variant={isNew ? "contained" : "outlined"}
          color={isNew ? "secondary" : "default"}
          size="small"
          onClick={(e) => setBuildType(true, false)}
        >
          New Build
        </Field>
      </div>
    </>
  );
};

export default IncludeNewOld;
