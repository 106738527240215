import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./epcGraph.css";
import { formatDateDDMMMYYY, formatMoneyFull } from "services/formatting";

const convertScoreToOffset = (score) => {
  if (score >= 92) return 25;
  else if (score >= 81) return 75;
  else if (score >= 69) return 125;
  else if (score >= 55) return 175;
  else if (score >= 39) return 225;
  else if (score >= 21) return 275;
  else return 325;
};

const EPCRatingGraph = ({
  currentEnergyRating,
  currentEnergyScore,
  potentialEnergyRating,
  potentialEnergyScore,
}) => {
  // const currentYOffset = 325;
  const currentYOffset = convertScoreToOffset(currentEnergyScore);
  const potentialYOffset = convertScoreToOffset(potentialEnergyScore);

  return (
    <svg
      width="369"
      height="226"
      viewBox="0 0 615 376"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
      aria-labelledby="svg-title"
      aria-describedby="svg-desc"
      role="img"
      className="epc-energy-rating-graph"
    >
      <title id="svg-title">Energy efficiency chart</title>
      <desc id="svg-desc">
        This property’s current energy rating is {currentEnergyRating} with a
        score of {currentEnergyScore}. It has a potential energy rating of{" "}
        {potentialEnergyRating} with a score of {potentialEnergyScore}.
      </desc>

      <line
        x1="72"
        y1="0"
        x2="72"
        y2="376"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>
      <line
        x1="410"
        y1="0"
        x2="410"
        y2="376"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>
      <line
        x1="510"
        y1="0"
        x2="510"
        y2="376"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>
      <line
        x1="0"
        y1="25"
        x2="615"
        y2="25"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>
      <line
        x1="0"
        y1="0"
        x2="615"
        y2="0"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>

      {/* <line
        x1="0"
        y1="0"
        x2="615"
        y2="0"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line> */}
      <line
        x1="0"
        y1="0"
        x2="0"
        y2="376"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>
      <line
        x1="615"
        y1="376"
        x2="615"
        y2="0"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>
      <line
        x1="615"
        y1="376"
        x2="0"
        y2="376"
        style={{ stroke: "#b1b4b6", strokeWidth: "1" }}
      ></line>

      <rect width="78" height="50" x="72" y="25" className="band-a"></rect>
      <rect width="118" height="50" x="72" y="75" className="band-b"></rect>
      <rect width="158" height="50" x="72" y="125" className="band-c"></rect>
      <rect width="198" height="50" x="72" y="175" className="band-d"></rect>
      <rect width="238" height="50" x="72" y="225" className="band-e"></rect>
      <rect width="278" height="50" x="72" y="275" className="band-f"></rect>
      <rect width="318" height="50" x="72" y="325" className="band-g"></rect>

      <rect width="72" height="50" x="0" y="25" className="band-a-score"></rect>
      <rect width="72" height="50" x="0" y="75" className="band-b-score"></rect>
      <rect
        width="72"
        height="50"
        x="0"
        y="125"
        className="band-c-score"
      ></rect>
      <rect
        width="72"
        height="50"
        x="0"
        y="175"
        className="band-d-score"
      ></rect>
      <rect
        width="72"
        height="50"
        x="0"
        y="225"
        className="band-e-score"
      ></rect>
      <rect
        width="72"
        height="50"
        x="0"
        y="275"
        className="band-f-score"
      ></rect>
      <rect
        width="72"
        height="50"
        x="0"
        y="325"
        className="band-g-score"
      ></rect>

      <text
        role="presentation"
        aria-hidden="true"
        x="0"
        y="0"
        className="letter"
      >
        <tspan x="107" y="64">
          A
        </tspan>
        <tspan x="147" y="114">
          B
        </tspan>
        <tspan x="187" y="164">
          C
        </tspan>
        <tspan x="227" y="214">
          D
        </tspan>
        <tspan x="267" y="264">
          E
        </tspan>
        <tspan x="307" y="314">
          F
        </tspan>
        <tspan x="347" y="364">
          G
        </tspan>
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="0"
        y="0"
        className="small"
      >
        <tspan x="8" y="56">
          92+
        </tspan>
        <tspan x="8" y="106">
          81-91
        </tspan>
        <tspan x="8" y="156">
          69-80
        </tspan>
        <tspan x="8" y="206">
          55-68
        </tspan>
        <tspan x="8" y="256">
          39-54
        </tspan>
        <tspan x="8" y="306">
          21-38
        </tspan>
        <tspan x="8" y="356">
          1-20
        </tspan>
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="8"
        y="15"
        className="small"
        dominantBaseline="middle"
      >
        Score
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="85"
        y="15"
        className="small"
        dominantBaseline="middle"
      >
        Energy rating
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="460"
        y="15"
        className="small"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        Current
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="565"
        y="15"
        className="small"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        Potential
      </text>

      <svg
        role="presentation"
        aria-hidden="true"
        x="415"
        y={currentYOffset}
        width="90"
        height="50"
      >
        <rect
          width="75"
          height="50"
          className={`band-${currentEnergyRating.toLowerCase()}`}
          x="25"
        ></rect>
        <polygon
          points="0,25 25,50 25,0 0,25"
          className={`band-${currentEnergyRating.toLowerCase()}`}
        ></polygon>
        <text x="18" y="32" className="current-potential-number">
          {currentEnergyScore} |
        </text>
        <text x="67" y="32" className="small-letter">
          {currentEnergyRating}
        </text>
      </svg>

      <svg
        role="presentation"
        aria-hidden="true"
        x="515"
        y={potentialYOffset}
        width="90"
        height="50"
      >
        <rect
          width="75"
          height="50"
          className={`band-${potentialEnergyRating.toLowerCase()}`}
          x="25"
        ></rect>
        <polygon
          points="0,25 25,50 25,0 0,25"
          className={`band-${potentialEnergyRating.toLowerCase()}`}
        ></polygon>
        <text x="18" y="32" className="current-potential-number">
          {potentialEnergyScore} |
        </text>
        <text x="67" y="32" className="small-letter">
          {potentialEnergyRating}
        </text>
      </svg>
    </svg>
  );
};

const EPCTab = ({ property }) => {
  const {
    // propertySource,
    inspection_date,
    postcode,
    sqft,
    heatedRooms,
    currentEnergyRating,
    currentEnergyScore,
    potentialEnergyRating,
    potentialEnergyScore,
  } = property;

  const sqm = Math.round(sqft * 0.092903);
  const epcURL = `https://find-energy-certificate.service.gov.uk/find-a-certificate/search-by-postcode?postcode=${postcode}`;

  return (
    <div style={{ textAlign: "center", fontSize: "0.9em" }}>
      {inspection_date && (
        <>
          EPC Surveyed on {formatDateDDMMMYYY(inspection_date)}
          <br />
        </>
      )}
      <a
        href={epcURL}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Check Certificates in {postcode}&nbsp;
        <FontAwesomeIcon icon={["far", "external-link-alt"]} />
      </a>
      <br />
      <br />
      {sqft !== -1
        ? `Size: ${sqm} m² = ${formatMoneyFull(Math.round(sqft), 0)} ft²`
        : "Cannot match to EPC Certificate"}
      <br />
      {heatedRooms && (
        <>
          {heatedRooms} Heated Rooms
          <br />
        </>
      )}
      <br />
      {currentEnergyRating && (
        <EPCRatingGraph
          currentEnergyRating={currentEnergyRating}
          currentEnergyScore={currentEnergyScore}
          potentialEnergyRating={potentialEnergyRating}
          potentialEnergyScore={potentialEnergyScore}
        />
      )}
    </div>
  );
};

export default EPCTab;
