import React from "react";
import PlacesAutocomplete from "./PlacesAutocomplete";
import { useDispatch } from "react-redux";
import { addNameForLocation } from "../locationsSlice";
import { setCircleCenter } from "../circleSlice";

import useSearchView from "data/hooks/useSearchView";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";

const Locator = ({ defaultValue }) => {
  const dispatch = useDispatch();
  const [searchView, setSearchView] = useSearchView();
  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.narrow}px)`);

  const setSelectedLocationHandler = (center) => {
    if (searchView === "full_list") {
      isNarrow ? setSearchView("full_map") : setSearchView("split");
    }
    dispatch(setCircleCenter(center));
  };

  return (
    <div style={{ textAlign: "center" }}>
      <PlacesAutocomplete
        defaultValue={defaultValue}
        placeholder="Fly to a location..."
        setSelectedLocation={(center) => setSelectedLocationHandler(center)}
        addNameForLocation={(lat, lng, name) =>
          dispatch(
            addNameForLocation({
              lat,
              lng,
              name,
            })
          )
        }
      />
    </div>
  );
};

export default Locator;
