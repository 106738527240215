import TodayPriceWarning from "./tooltips/TodayPriceWarning";
import useDeviceWidth from "data/hooks/useDeviceWidth";

const PropertyLwEstimatedPrice = ({ property }) => {
  const {
    latestLwEstimatedPrice,
    lwTotalWeight,
    latestLwTotalWeight,
  } = property;
  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();

  return (
    <div className="result-price-element">
      <div
        className={`property-price-estimate-div`}
        style={{
          width: isSmallWidth ? "70px" : "80px",
        }}
      >
        <div
          className={`${isSmallWidth && "font-size-13"}`}
          style={{
            color: "white",
            backgroundColor: "green",
            paddingTop: "1px",
            marginTop: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          TODAY&nbsp;
        </div>
      </div>

      {latestLwEstimatedPrice > 0 ? (
        <span
          className={`result-price-element ${isSmallWidth && "font-size-15"}`}
          style={{ fontWeight: "bold" }}
        >
          £
          {latestLwEstimatedPrice.toLocaleString("en-UK", {
            maximumFractionDigits: 0,
          })}
          {(lwTotalWeight < 10 || latestLwTotalWeight < 10) && (
            <TodayPriceWarning />
          )}
        </span>
      ) : (
        <div className="loader-tiny" style={{ marginTop: "4px" }}></div>
      )}
    </div>
  );
};
//latestLwEstimatedPrice.toLocaleString("en-UK", ${ maximumFractionDigits: 0 })
export default PropertyLwEstimatedPrice;
