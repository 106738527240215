import useMapSettingsDrawer from "../Search/Data/useMapSettingsDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Button } from "@material-ui/core";

const MapSettingsButton = (props) => {
  const [
    mapSettingsDrawerOpen,
    setMapSettingsDrawerOpen,
  ] = useMapSettingsDrawer();

  return (
    <div
      style={{ position: "absolute", top: "0", left: "0", paddingTop: "5px" }}
    >
      <Button
        variant="contained"
        size="medium"
        onClick={() => setMapSettingsDrawerOpen(true)}
        style={{
          backgroundColor: "white",
          minWidth: "40px",
          borderRadius: "0 4px 4px 0",
          padding: "3px 4px 3px 4px",
        }}
      >
        &nbsp;
        <FontAwesomeIcon
          icon={regular("layer-group")}
          style={{ fontSize: "1.1em" }}
        />
        &nbsp;
      </Button>
    </div>
  );
};

export default MapSettingsButton;
