import { useCallback, useState, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getSearchSelector, getFilterKeyword } from "../../data/selectors";

import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLocalStorage } from "@rehooks/local-storage";
import Button from "@material-ui/core/Button";

import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { setFilterKeyword as setFilterKeywordAction } from "../../app/settingsSlice";
// import TodayPriceInfo from "../../components/tooltips/TodayPriceInfo";

import useDeviceWidth from "data/hooks/useDeviceWidth";
import { TextField, useMediaQuery } from "@material-ui/core";
import useSearchFilter from "./useSearchFilter";
// import useFilteredSearchResults from "pages/Search/useFilteredSearchResults";

import SearchViewShowFiltersButton from "components/SearchViewShowFiltersButton";
import DownloadSearchResultsButton from "components/DownloadSearchResultsButton";
import { setShowTodayPrice as setServerShowTodayPrice } from "graphql/functions";
import { setShowTodayPrice as setShowTodayPriceAction } from "data/slices/searchSlice";
import SearchResultsSortButton from "components/SearchResultsSortButton";
import useFilteredSearchResults from "./useFilteredSearchResults";

const styles = {
  viewButtons: { minWidth: "34px", minHeight: "34px" },
  editSearchButton: { minHeight: "34px" },
};

const TodayPriceSwitch = ({ showTodayPrice, setShowTodayPrice, disabled }) => {
  const [isSmallWidth, isMediumWidth] = useDeviceWidth();

  const toggleSwitch = (checked) => {
    window.analytics.track("Toggle Today Price", {
      checked,
    });
    setShowTodayPrice(checked);
  };

  return (
    <div
      className="flex-center-center"
      style={{ userSelect: "none", paddingLeft: "6px" }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={showTodayPrice || false}
            onChange={(e) => toggleSwitch(e.target.checked)}
            name="todayPrice"
            color="secondary"
            size="small"
            disabled={disabled}
          />
        }
        label={
          <span className={`${isSmallWidth ? "font-size-13" : "font-size-13"}`}>
            Today{!isMediumWidth && " Price"}
            {/* <TodayPriceInfo disabled={disabled} /> */}
          </span>
        }
      />
    </div>
  );
};

const ComparablesOnlyButton = ({ comparablesOnly, setComparablesOnly }) => {
  return (
    <Button
      variant={comparablesOnly ? "contained" : "outlined"}
      color={comparablesOnly ? "primary" : "default"}
      onClick={() => setComparablesOnly(!comparablesOnly)}
      style={styles.viewButtons}
      size="small"
    >
      <FontAwesomeIcon
        icon={comparablesOnly ? solid("star") : regular("star")}
      />
    </Button>
  );
};

const ResultViewChangeButton = ({ viewType, setViewType }) => {
  return (
    <div className="flex-center-center">
      <Button
        variant={viewType === "full" ? "outlined" : "text"}
        color={viewType === "full" ? "default" : "default"}
        // disabled={viewType === "full"}
        onClick={() => setViewType("full")}
        style={styles.viewButtons}
        size="small"
      >
        {viewType === "full" ? (
          <FontAwesomeIcon icon={["far", "list"]} />
        ) : (
          <>
            &nbsp;
            <FontAwesomeIcon icon={["far", "list"]} />
            &nbsp;
          </>
        )}
      </Button>
      <Button
        variant={viewType === "slim" ? "outlined" : "text"}
        color={viewType === "slim" ? "default" : "default"}
        onClick={() => setViewType("slim")}
        style={styles.viewButtons}
        size="small"
      >
        {viewType === "slim" ? (
          <FontAwesomeIcon icon={["far", "align-justify"]} />
        ) : (
          <>
            &nbsp;
            <FontAwesomeIcon icon={["far", "align-justify"]} />
            &nbsp;
          </>
        )}
      </Button>
      {/* <button
          disabled={viewType === "bigthumb"}
          onClick={() => setViewType("bigthumb")}
          style={styles.viewButtons}
          size="small"
        >
          <FontAwesomeIcon icon={["far", "rectangle-landscape"]} />
        </button> */}
    </div>
  );
};

const KeywordFilterTextbox = ({ filterKeyword, setReduxFilterKeyword }) => {
  const [isSmallWidth] = useDeviceWidth();

  const [searchFilter, setSearchFilter] = useSearchFilter();

  return (
    <div
      className="search-results-header-controls-keyword"
      style={{ userSelect: "none" }}
    >
      <TextField
        // multiline
        value={searchFilter.keyword}
        onChange={(e) => setSearchFilter({ keyword: e.target.value })}
        placeholder={`${!isSmallWidth ? "Text " : ""}Filter...`}
        style={{
          fontSize: isSmallWidth ? "13px" : "14px",
          minWidth: "80px",
          width: isSmallWidth ? "90%" : "100%",
          padding: "0px",
          backgroundColor: "white",
        }}
        variant="outlined"
        size="small"
      />
      {searchFilter.keyword !== "" && (
        <IconButton
          type="submit"
          aria-label="search"
          onClick={() => setSearchFilter({ keyword: "" })}
          style={{ maxHeight: "32px", maxWidth: "32px", color: "green" }}
        >
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};

const SearchResultsCommandBar = (props) => {
  const { searchId, searchSpec, properties, searchFinished } = props;
  const dispatch = useDispatch();
  // const sortOrder = props.searchSortOrder;
  const search = useSelector((state) => getSearchSelector(state, searchId));
  const filterKeyword = useSelector(getFilterKeyword);
  const [searchFilter, setSearchFilter] = useSearchFilter();
  const [filteredProperties] = useFilteredSearchResults(searchId);

  // const [comparablesOnly, setComparablesOnly] = useState(false);

  const [isSmallWidth] = useDeviceWidth();
  const isNarrow = useMediaQuery(`(max-width:780px)`);

  const setReduxFilterKeyword = useCallback(
    (newFilterKeyword) => dispatch(setFilterKeywordAction(newFilterKeyword)),
    [dispatch]
  );
  const setReduxShowTodayPrice = useCallback(
    (searchId, newBoolean) =>
      dispatch(
        setShowTodayPriceAction({
          searchID: searchId,
          showTodayPrice: newBoolean,
        })
      ),
    [dispatch]
  );

  const setShowTodayPrice = useCallback(
    (newBoolean) => {
      setReduxShowTodayPrice(searchId, newBoolean);
      setServerShowTodayPrice(searchId, newBoolean);
    },
    [searchId]
  );

  const [viewType, setViewType] = useLocalStorage("result_style");
  const totalFilteredProperties = filteredProperties?.length;

  const route = useRouteMatch();
  const pathname = route?.path;

  const isOnReportUrl = useMemo(() => {
    return pathname?.startsWith("/report");
  }, [pathname]);

  return (
    <>
      <div className="search-results-header-controls">
        {/* <ComparablesOnlyButton
          comparablesOnly={comparablesOnly}
          setComparablesOnly={setComparablesOnly}
        /> */}
        <div className="flex-center-center">
          {!!searchSpec?.search_landreg && (
            <TodayPriceSwitch
              showTodayPrice={search.showTodayPrice}
              setShowTodayPrice={setShowTodayPrice}
              disabled={!searchSpec?.search_landreg}
            />
          )}

          {((isOnReportUrl && !isNarrow) || !isSmallWidth) && (
            <>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <ResultViewChangeButton
                viewType={viewType}
                setViewType={setViewType}
              />
            </>
          )}
        </div>
        <div
          className="flex-center-center"
          style={{ flexGrow: "1", padding: "0px 30px" }}
        >
          <KeywordFilterTextbox
            filterKeyword={filterKeyword}
            setReduxFilterKeyword={setReduxFilterKeyword}
          />
          <div
            style={{
              fontSize: isSmallWidth ? "13px" : "13px",
              paddingTop: "2px",
            }}
          >
            {searchFilter.keyword !== "" && (
              <>
                {totalFilteredProperties}&nbsp;Match
                {totalFilteredProperties !== 1 && "es"}
              </>
            )}
          </div>
        </div>

        <div className="flex-center-center">
          {((isOnReportUrl && !isNarrow) || !isSmallWidth) && (
            <>
              <DownloadSearchResultsButton
                searchId={searchId}
                search={search}
                properties={properties}
                searchFinished={searchFinished}
                isOnReportUrl={isOnReportUrl}
                showTodayPrice={search.showTodayPrice}
              />
              &nbsp;
            </>
          )}
          {!isOnReportUrl && (
            <>
              <SearchViewShowFiltersButton
                totalFilteredProperties={totalFilteredProperties}
                properties={properties}
                isSmallWidth={isSmallWidth}
              />
              &nbsp;
            </>
          )}
          <SearchResultsSortButton
            sortOrder={search.searchSortOrder}
            searchSpec={searchSpec}
            searchId={searchId}
          />
        </div>
      </div>
    </>
  );
};

export default SearchResultsCommandBar;
