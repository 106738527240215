import React, { useState, useEffect, useRef, useMemo } from "react";

import { useSelector } from "react-redux";
import { getSearchSelector } from "../../../data/selectors";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDropDownCircle from "@material-ui/icons/ArrowDropDownCircle";
import Tooltip from "@material-ui/core/Tooltip";

import HalfWidthResult from "./ResponsiveSearchResult";
import HalfWidthExpansion from "./HalfWidthExpansion.js";

import "./searchResult.css";

// import useDeviceWidth from "data/hooks/useDeviceWidth";
import useSelectedMapProperty from "pages/Search/Data/useSelectedMapProperty";
import PlanningSearchResult from "./PlanningSearchResult";

import { debounce } from "debounce";
const MAP_TRIGGER_DEBOUNCE_A = 250;
const MAP_TRIGGER_DEBOUNCE_B = 350;
const MAP_TRIGGER_DEBOUNCE_C = 100;

// convenience hook to remember the last value of something
function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}

const SearchResult = ({
  searchId,
  property,
  isSelected,
  viewType,
  isSmallWidth,
  isMediumWidth,
  isLargeWidth,
  valuationData,
  valuationProperty = {},
}) => {
  const [selected, selectMapResult] = useSelectedMapProperty();
  const [activeTab, setActiveTab] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const prevIsSelected = usePrevious(isSelected);

  const { propertyID, propertySource } = property;

  // open if its transitioning to selected
  useEffect(() => {
    if (!prevIsSelected && isSelected) {
      setExpanded(true);
    }
  }, [prevIsSelected, isSelected]);

  const clickAccordion = (event, isExpanded) => {
    setExpanded(isExpanded);

    if (isExpanded) {
      window.analytics.track("Expand Property", {
        searchId,
        propertyID,
      });
    } else {
      window.analytics.track("Collapse Property", {
        searchId,
        propertyID,
      });
    }
  };

  const debounceSetSelectMapResultA = useMemo(
    () => debounce(selectMapResult, MAP_TRIGGER_DEBOUNCE_A),
    [selectMapResult]
  );
  const debounceSetSelectMapResultB = useMemo(
    () => debounce(selectMapResult, MAP_TRIGGER_DEBOUNCE_B),
    [selectMapResult]
  );
  const debounceSetSelectMapResultC = useMemo(
    () => debounce(selectMapResult, MAP_TRIGGER_DEBOUNCE_C),
    [selectMapResult]
  );

  useEffect(() => {
    if (expanded) {
      selectMapResult(propertyID);
    } else {
      if (selected === propertyID) {
        selectMapResult(null);
      }
    }
  }, [expanded, propertyID]);

  const search = useSelector((state) => getSearchSelector(state, searchId));
  const isComparable =
    search.comparableProperties &&
    search.comparableProperties.includes(propertyID);

  return (
    <Accordion
      className={`search-result-accordion-summary ${
        isSelected ? "selected" : ""
      } ${isComparable ? "comparable" : ""}`}
      onChange={clickAccordion}
      elevation={2}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
      key={propertyID}
      // onMouseEnter={(e) => {
      //   debounceSetSelectMapResultC(propertyID);
      // }}
    >
      <AccordionSummary
        expandIcon={
          <Tooltip id="show-details" title="Show Details">
            <ArrowDropDownCircle style={{ color: "#592E9A" }} />
          </Tooltip>
        }
        aria-controls="search-result-content"
        id={propertyID}
        className={
          isSmallWidth
            ? "accordion-summary-issmall accordion-summary"
            : "accordion-summary"
        }
      >
        {propertySource === "plan" ? (
          <PlanningSearchResult
            key={propertyID}
            property={property}
            isSmallWidth={isSmallWidth}
            isMediumWidth={isMediumWidth}
            isLargeWidth={isLargeWidth}
          />
        ) : (
          <HalfWidthResult
            key={propertyID}
            searchId={searchId}
            property={property}
            selectMapResult={selectMapResult}
            selected={isSelected}
            viewType={viewType}
            isComparable={isComparable}
            isSmallWidth={isSmallWidth}
            isMediumWidth={isMediumWidth}
            isLargeWidth={isLargeWidth}
            valuationData={valuationData}
            search={search}
            valuationProperty={valuationProperty}
            expanded={expanded}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <HalfWidthExpansion
          key={propertyID}
          property={property}
          selectMapResult={selectMapResult}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchResult;
