import React, { useMemo } from "react";
import { Source, Layer } from "react-mapbox-gl";
import GeoJSON from "geojson";
import {
  clusterCountLayer,
  clusterLayer,
  searchResultsLayer,
} from "./map.propertyLayers";

const parseProperties = (properties) => {
  return GeoJSON.parse(properties, {
    Point: ["lat", "lng"],
  });
};

const enhanceComparableProperties = (properties, comparableProperties) =>
  properties.map((prop) => ({
    ...prop,
    isComparable: comparableProperties?.includes(prop.propertyID) || false,
  }));

const filterHiddenProperties = (properties) => {
  return properties.filter((prop) => !prop.hidden);
};

// create a string representative of all properties visible in a render
// I've chosen to accumulate each properties 'propertyID' and 'comparable status'
const renderPropertiesHash = (properties) =>
  properties.reduce(
    (acc, prop) => acc + `${prop.propertyID}_${prop.isComparable}`,
    ""
  );

// never needs to change i.e. PureComponent
const PureLayers = React.memo(() => (
  <>
    <Layer {...clusterLayer} />
    <Layer {...clusterCountLayer} />
    <Layer {...searchResultsLayer} />
  </>
));

// does the tree need re-rendering?
function propertyPropsAreEqual(prevProps, nextProps) {
  const prevEnhanced = enhanceComparableProperties(
    prevProps.properties,
    prevProps.comparableProperties
  );
  const nextEnhanced = enhanceComparableProperties(
    nextProps.properties,
    nextProps.comparableProperties
  );
  return (
    renderPropertiesHash(prevEnhanced) === renderPropertiesHash(nextEnhanced)
  );
}

// Performance tunes to only re-render when properties change
const PropertySource = React.memo(({ properties, comparableProperties }) => {
  const enhancedProperties = useMemo(
    () =>
      enhanceComparableProperties(
        filterHiddenProperties(properties),
        comparableProperties
      ),
    [properties, comparableProperties]
  );

  const geoJsonData = useMemo(
    () => parseProperties(enhancedProperties),
    [enhancedProperties]
  );

  return (
    <Source
      id="property-results"
      key="property-results"
      geoJsonSource={{
        type: "geojson",
        data: geoJsonData,
        cluster: true,
        clusterMaxZoom: 20,
        clusterRadius: 20,
      }}
    />
  );
}, propertyPropsAreEqual);

const MapProperties = ({ properties, comparableProperties }) => (
  <>
    <PropertySource
      properties={properties}
      comparableProperties={comparableProperties}
    />
    <PureLayers />
  </>
);

export default MapProperties;
