export const LINE_ATTRIBS = {
  "line-color": [
    "interpolate",
    ["linear"],
    ["zoom"],
    10,
    "hsla(24, 0%, 20%, 100%)",
    12,
    [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      "hsla(24, 0%, 50%, 1)",
      ["boolean", ["feature-state", "hover"], false],
      "hsla(24, 0%, 50%, 1)",
      "hsla(24, 0%, 20%, 100%)",
    ],
  ],
  "line-width": ["interpolate", ["linear"], ["zoom"], 13, 1.5, 17, 10],
};

export const FILL_ATTRIBS = {
  "fill-color": "hsla(24, 100%, 50%, 0.7)",
  "fill-outline-color": "hsl(0, 100%, 100%)",
  "fill-opacity": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    1,
    ["boolean", ["feature-state", "hover"], false],
    0.5,
    0,
  ],
};

export const LABEL_ATTRIBS = {
  "text-color": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    "hsla(24, 0%, 10%, 100)",
    ["boolean", ["feature-state", "hover"], false],
    "hsla(24, 0%, 10%, 100)",
    "hsla(24, 0%, 0%, 100)",
  ],
  "text-halo-color": "#FFF",
  "text-halo-width": 1.5,
  "text-halo-blur": 0,
};

export const LABEL_LAYOUT = (fieldName) => ({
  "text-field": ["get", fieldName],
  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
  "text-size": ["interpolate", ["linear"], ["zoom"], 10, 13, 15, 20, 17, 30],
});
