import SearchFormItem from "../SearchFormItem";

const LandOnly = (props) => {
  const { setFieldValue, values } = props;
  const { lr_only_land } = values;

  return (
    <div className={"search-form-item"}>
      <div className={"search-form-item-group"}>
        <SearchFormItem
          checked={!!lr_only_land}
          setChecked={(checked) => setFieldValue("lr_only_land", checked)}
          label="Land Transactions Only"
        />
      </div>
    </div>
  );
};

export default LandOnly;
