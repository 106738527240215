import { Field } from "formik";
import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    padding: "6px 26px 6px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const MenuItem = withStyles({
  root: {
    // justifyContent: "center",
  },
})(MuiMenuItem);

const PlanningAppMultiSelect = (props) => {
  const { values, setFieldValue, fieldName, options, width = "110px" } = props;

  const allOptions = ["All", ...options.filter((option) => option !== "All")];

  const filteredValues =
    values[fieldName]?.filter((type) => type && type !== "All") || [];
  const filteredOptions = options.filter((type) => type && type !== "All");

  const renderValue = (selected) => {
    const filteredSelected = selected.filter((option) => option !== "All");
    return filteredSelected.length === filteredOptions.length
      ? "All"
      : filteredSelected.length > 1
      ? `${filteredSelected.length} Selected`
      : filteredSelected.length === 1
      ? filteredSelected[0]
      : "None";
  };

  const handleSelectAll = () => {
    if (filteredValues.length === filteredOptions.length) {
      setFieldValue(fieldName, []);
    } else {
      setFieldValue(fieldName, options);
    }
  };
  return (
    <Field
      component={Select}
      name={fieldName}
      id={fieldName}
      multiple
      value={values && values[fieldName] ? values[fieldName] : []}
      onChange={(e, newValue) => {
        if (newValue) {
          setFieldValue(fieldName, e.target.value);
        }
      }}
      input={<BootstrapInput />}
      style={{ width: width, fontWeight: "bold" }}
      renderValue={renderValue}
      displayEmpty={true}
    >
      {allOptions.map((option) => {
        if (option === "All") {
          return (
            <div key={option} style={{ width: "100%" }}>
              <MenuItem
                style={{
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
                key={option}
                value={option}
                onClick={handleSelectAll}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filteredValues.length === filteredOptions.length}
                      indeterminate={
                        filteredValues.length > 0 &&
                        filteredValues.length < filteredOptions.length
                      }
                      onChange={handleSelectAll}
                    />
                  }
                  label={
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      All
                    </Typography>
                  }
                  style={{
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                    backgroundColor: `#CCC`,
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontWeight: "bold",
                  }}
                />
              </MenuItem>
            </div>
          );
        } else {
          return (
            <MenuItem
              style={{
                paddingTop: "1px",
                paddingBottom: "1px",
                paddingLeft: 8,
                paddingRight: 8,
              }}
              key={option}
              value={option}
            >
              <Checkbox
                checked={values && values[fieldName]?.indexOf(option) > -1}
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
              {option}
            </MenuItem>
          );
        }
      })}
    </Field>
  );
};

export default PlanningAppMultiSelect;
