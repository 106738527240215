import style from "./planningItem.module.scss";

const getStatusStyle = (status) => {
  switch (status) {
    case "Approved":
    case "Conditions":
    case "Permitted":
      return { backgroundColor: "#12b300" };
    case "Rejected":
      return { backgroundColor: "#d61a2c" };

    // return {
    //   background:
    //     "repeating-linear-gradient(135deg, #e18c34, #e18c34 5px, #12b300 5px, #12b300 10px)",
    // };
    case "In Review":
    case "Undecided":
    case "Referred":
    case "Unresolved":
      return { backgroundColor: "#e18c34" };
    case "Other":
    case "Withdrawn":
      return { backgroundColor: "#56A2CA" };
    default:
      return { backgroundColor: "#a1a1a1" };
  }
};

const PlanningStatusLarge = ({ status }) => {
  return (
    <div className={style.status} style={getStatusStyle(status)}>
      {status?.toUpperCase() || "UNKNOWN"}
    </div>
  );
};

const PlanningStatusSmall = ({ status }) => {
  return <div className={style.mobileStatus} style={getStatusStyle(status)} />;
};

export { PlanningStatusLarge, PlanningStatusSmall };
