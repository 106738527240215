import React, { PureComponent } from "react";
import { Source, Layer } from "react-mapbox-gl";
import { heatmapLayers } from "./heatmap.definitions";
import {
  getStyleColor,
  getStyleHeight,
  getLandworthStyleHeight,
  getLandworthStyleColor,
  getStyleOpacity,
  get2DStyleOpacity,
} from "./heatmap.styles";

export default class Heatmap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render2DLayer(layer, fillColor) {
    const name = "heatmap_" + layer.name + "_2D";
    return (
      <Layer
        key={name}
        id={name}
        type="fill"
        sourceId={"heatmap_" + layer.name}
        sourceLayer={layer.name}
        minZoom={layer.minzoom}
        maxZoom={layer.maxzoom}
        paint={{
          "fill-opacity": get2DStyleOpacity(),
          "fill-color": fillColor,
          "fill-outline-color": "rgba(255, 255, 0, 0)",
        }}
        before="HEATMAP_LAYER"
      />
    );
  }

  render3DLayer(layer, fillColor, fillHeight) {
    const name = "heatmap_" + layer.name + "_3D";
    return (
      <Layer
        key={name}
        id={name}
        type="fill-extrusion"
        sourceId={"heatmap_" + layer.name}
        sourceLayer={layer.name}
        minZoom={layer.minzoom}
        maxZoom={layer.maxzoom}
        paint={{
          "fill-extrusion-opacity": getStyleOpacity(),
          "fill-extrusion-color": fillColor,
          "fill-extrusion-height": fillHeight,
          "fill-extrusion-vertical-gradient": true,
        }}
        before="HEATMAP_LAYER"
      />
    );
  }

  addMapboxLayers(layer, selectedPerspective, selectedHeatmap, range) {
    let fillColor;
    let fillHeight;
    if (range && selectedHeatmap === "landworth") {
      fillColor = getLandworthStyleColor(range[0], range[1], range[2]);
      fillHeight = getLandworthStyleHeight(range[0], range[2]);
    } else {
      fillColor = getStyleColor(selectedHeatmap);
      fillHeight = getStyleHeight(selectedHeatmap);
    }

    return selectedPerspective === "2D"
      ? this.render2DLayer(layer, fillColor)
      : this.render3DLayer(layer, fillColor, fillHeight);
  }

  renderLayers(selectedHeatmap, selectedPerspective, range) {
    return (
      <>
        {heatmapLayers.map((layer) => {
          return (
            <React.Fragment key={"heatmap_" + layer.name}>
              <Source
                id={"heatmap_" + layer.name}
                key={"heatmap_" + layer.name}
                geoJsonSource={{
                  type: "vector",
                  url: `https://api.mapbox.com/v4/${layer.source}.json?secure&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
                }}
              />
              {this.addMapboxLayers(
                layer,
                selectedPerspective,
                selectedHeatmap,
                range
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  render() {
    return (
      <>
        {this.props.selectedHeatmap !== "off" &&
          this.renderLayers(
            this.props.selectedHeatmap,
            this.props.selectedPerspective,
            this.props.heatmapRange
          )}
      </>
    );
  }
}
