import { formatMoneyRounded, formatMonthsAsYears } from "./formatting";

const getPropertySearchString = (spec) => {
  let friendlySearchString = " ";
  if ((spec.search_rm || spec.search_zoopla) && spec.search_landreg) {
    friendlySearchString += "all ";
  }

  if ((spec.search_rm || spec.search_zoopla) && !spec.search_landreg) {
    let bedmin = spec.bedmin;
    let bedmax = spec.bedmax;
    if (bedmin && bedmax && bedmin === bedmax) {
      friendlySearchString += `${bedmin} bed `;
    } else if (bedmin && bedmax < 50) {
      friendlySearchString += `${bedmin}-${bedmax} bed `;
    } else {
      friendlySearchString += bedmin ? `${bedmin}+ bed ` : ``;
      friendlySearchString += bedmax < 50 ? `${bedmax} or less bed ` : ``;
    }
  }

  if (spec.oldbuild && !spec.newbuild) {
    friendlySearchString += "old ";
  }
  if (spec.newbuild && !spec.oldbuild) {
    friendlySearchString += "new ";
  }

  friendlySearchString += spec.property_type === "flats" ? "flats" : ``;
  friendlySearchString += spec.property_type === "houses" ? "houses" : ``;
  friendlySearchString += spec.property_type === "all" ? "properties" : ``;

  if (
    (spec.search_rm || spec.search_zoopla) &&
    !spec.search_landreg &&
    spec.include_forsale &&
    !spec.include_sold &&
    spec.search_type === "sale"
  ) {
    friendlySearchString += " for sale";
  } else if (
    (spec.search_rm || spec.search_zoopla) &&
    !spec.search_landreg &&
    spec.search_type === "rent"
  ) {
    friendlySearchString += " for rent";
  }

  if (spec.search_type === "sale" && !spec.search_landreg) {
    friendlySearchString += ` on ${
      spec.search_rm && !spec.search_zoopla
        ? "Rightmove"
        : !spec.search_rm && spec.search_zoopla
        ? "Zoopla"
        : "Rightmove and Zoopla"
    }`;
  }
  if (
    spec.search_landreg &&
    !spec.search_rm &&
    !spec.search_zoopla &&
    !spec.search_plan
  ) {
    friendlySearchString += " sold";
    friendlySearchString += ` in the last ${formatMonthsAsYears(
      spec.sold_history_months
    )}`;
  }

  friendlySearchString += spec.pricemin
    ? ` from £${formatMoneyRounded(spec.pricemin, 0)}`
    : ``;
  friendlySearchString +=
    spec.pricemax < 200000000
      ? ` up to £${formatMoneyRounded(spec.pricemax, 0)}`
      : ``;
  return friendlySearchString;
};

const getPlanningSearchString = (spec) => {
  let string = " ";
  if (
    spec.planningAppSizes?.length !== 3 &&
    Array.isArray(spec.planningAppSizes)
  )
    spec.planningAppSizes?.forEach((size, i) => {
      console.log(size, i);
      if (i === 0) {
        string += " ";
      }
      string += `${size.toLowerCase()}`;
      if (i < spec.planningAppSizes.length - 1) {
        string += "/";
      }
    });
  if (
    spec.planningAppStates?.length !== 7 &&
    Array.isArray(spec.planningAppStates)
  )
    spec.planningAppStates?.forEach((state, i) => {
      if (string !== " " && i === 0) {
        string += ", ";
      }
      string += `${state.toLowerCase()}`;
      if (i < spec.planningAppStates.length - 1) {
        string += "/";
      }
    });
  if (
    spec.planningAppTypes?.length !== 9 &&
    Array.isArray(spec.planningAppTypes)
  )
    spec.planningAppTypes?.forEach((type, i) => {
      if (string !== " " && i === 0) {
        string += ", ";
      }
      string += `${type.toLowerCase()}`;
      if (i < spec.planningAppTypes.length - 1) {
        string += "/";
      }
    });
  // TODO: Add the from/to dates
  if (
    spec.planningAppSizes?.length === 3 &&
    spec.planningAppStates?.length === 7 &&
    spec.planningAppTypes?.length === 9
  ) {
    string += " all";
  }
  return string + " planning applications";
};

export const convertSpecToReadable = (search) => {
  const { locationName, spec } = search;
  let friendlySearchString = "Searching for";
  const searchSpecifics = spec.search_plan
    ? getPlanningSearchString(spec)
    : getPropertySearchString(spec);
  friendlySearchString += searchSpecifics;

  friendlySearchString += ` - within ${(spec.searchradius / 1609).toFixed(
    2
  )} miles of `;
  friendlySearchString += locationName;

  return friendlySearchString;
};

export const planningSearchRadiusSimplify = (radius) => {
  // convert 0.03 miles to 50m , 0.06 miles to 100m etc
  if (radius === ".03 mile") return "50m";
  if (radius === ".06 mile") return "100m";
  if (radius === ".12 mile") return "200m";
  if (radius === ".24 mile") return "400m";
  if (radius === ".48 mile") return "800m";
  if (radius === ".96 mile") return "1.6km";
  if (radius === "1.92 mile") return "3.2km";
  // Double up recursively from here with a calculation
  return radius;
};
