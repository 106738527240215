import React from "react";
import { Source, Layer } from "react-mapbox-gl";

const {
  REACT_APP_OS_SERVICE_URL,
  REACT_APP_OS_API_KEY_PROD,
  REACT_APP_OS_API_KEY_DEV,
} = process.env;

const MASTERMAP_RASTER_SOURCE_OPTIONS_DEV = {
  type: "raster",
  tiles: [
    REACT_APP_OS_SERVICE_URL +
      "/Road_3857/{z}/{x}/{y}.png?key=" +
      REACT_APP_OS_API_KEY_DEV,
  ],
  tileSize: 300,
  maxzoom: 20,
};

const MASTERMAP_RASTER_SOURCE_OPTIONS_PROD = {
  type: "raster",
  tiles: [
    REACT_APP_OS_SERVICE_URL +
      "/Road_3857/{z}/{x}/{y}.png?key=" +
      REACT_APP_OS_API_KEY_PROD,
  ],
  tileSize: 300,
  maxzoom: 20,
};

const SATELLITE_RASTER_SOURCE_OPTIONS = {
  type: "raster",
  url: "mapbox://mapbox.satellite",
};
const TRANSPORT_RASTER_SOURCE_OPTIONS = {
  type: "raster",
  tiles: [
    "https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=4fdc395f892a4dcfbf25edbe6efe6fa5",
  ],
  tileSize: 192,
};

const MASTERMAP_TRANSPARENCY = {
  "raster-opacity": ["interpolate", ["linear"], ["zoom"], 16, 0, 17, 1],
};

const Basemap = ({ selectedBasemap }) => {
  let rasterSource;
  if (selectedBasemap === "mastermap" || selectedBasemap === "hybrid")
    rasterSource =
      process.env.NODE_ENV === "production"
        ? MASTERMAP_RASTER_SOURCE_OPTIONS_PROD
        : MASTERMAP_RASTER_SOURCE_OPTIONS_DEV;
  else if (selectedBasemap === "satellite")
    rasterSource = SATELLITE_RASTER_SOURCE_OPTIONS;
  else if (selectedBasemap === "transport")
    rasterSource = TRANSPORT_RASTER_SOURCE_OPTIONS;
  else return <></>;

  return (
    <>
      <Source id="basemap-source" tileJsonSource={rasterSource} />
      {selectedBasemap === "hybrid" ? (
        <Layer
          type="raster"
          id="basemap-layer"
          sourceId="basemap-source"
          before="country-label"
          paint={MASTERMAP_TRANSPARENCY}
          minZoom={15}
        />
      ) : (
        <Layer
          type="raster"
          id="basemap-layer"
          sourceId="basemap-source"
          before={
            selectedBasemap === "satellite" ? "LOWER_LAYER" : "country-label"
          }
          minZoom={0}
        />
      )}
    </>
  );
};

export default Basemap;
