import React, { Component, useState } from "react";
import { Popup } from "react-mapbox-gl";
import MapPropertyPopup from "./Popups/MapPropertyPopup";
import MapLandworthPopup from "./Popups/MapLandworthPopup";
import MapClusterPopup from "./Popups/MapClusterPopup";
import MiniMapPopup from "./Popups/MiniMapPopup";

const MapPopup = (props) => {
  const {
    popupInfo,
    close,
    popupData,
    setCircleCenter,
    select,
    propertyTypeToggle,
    updatePropertyTypeToggle,
    showFullPopup,
    setShowFullPopup,
    submitForm,
    isSubmitting,
    isLoggedIn,
  } = props;
  const { popupLandworthData } = popupData;

  return (
    popupInfo && (
      <Popup tipSize={5} coordinates={popupInfo.geometry.coordinates}>
        {(popupInfo.properties.isLandworthPopup || showFullPopup) && (
          <div className="popup-close-button" onClick={close}>
            <div
              style={{
                backgroundColor: "#ffffff80",
                borderRadius: "10px",
                padding: "2px",
                marginRight: "-2px",
              }}
            >
              X
            </div>
          </div>
        )}
        {popupInfo.properties.children && (
          <div className="wide-popup">
            <MapClusterPopup features={popupInfo} select={select} />
          </div>
        )}
        {popupInfo.properties.propertyID &&
          (showFullPopup ? (
            <div className="wide-popup">
              <MapPropertyPopup feature={popupInfo} select={select} />
            </div>
          ) : (
            <MiniMapPopup
              property={popupInfo.properties}
              setShowFullPopup={setShowFullPopup}
            />
          ))}
        {popupInfo.properties.isLandworthPopup && (
          <div className="wide-popup">
            <MapLandworthPopup
              feature={popupInfo}
              popupLandworthData={popupLandworthData}
              // popupHistoryData={popupHistoryData}
              setCircleCenter={setCircleCenter}
              propertyTypeToggle={propertyTypeToggle}
              updatePropertyTypeToggle={updatePropertyTypeToggle}
              submitForm={submitForm}
              isSubmitting={isSubmitting}
              isLoggedIn={isLoggedIn}
            />
          </div>
        )}
      </Popup>
    )
  );
};

export default MapPopup;
