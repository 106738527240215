import React, { useEffect, useState } from "react";
import { MapContext } from "react-mapbox-gl";
import MapboxCircle from "mapbox-gl-circle";
import Debug from "debug";

export const MIN_RADIUS = 201; // 1/8m
// export const MAX_RADIUS = 6436; // 4m
// export const MAX_RADIUS = 8045; // 5m
// export const MAX_RADIUS = 16090; // 10m
export const MAX_RADIUS = 64360; // 40m

const debug = Debug("lw:mapCircle");

/**
 * Lets make a functional attempt at this.
 */
const MapCircle = ({
  circleCenter,
  circleRadius,
  setCircleCenter,
  setCircleRadius,
  mapRef,
}) => {
  const [circle, setCircle] = useState(null);
  const [addCircleToMap, setAddCircleToMap] = useState(false);

  // handles the single render problem
  const circleBuilt = !!circle;
  useEffect(() => {
    if (addCircleToMap) {
      setAddCircleToMap(false);
    }
    if (circleBuilt) {
      setAddCircleToMap(true);
    }
  }, [circleBuilt, addCircleToMap]);

  useEffect(() => {
    if (circleRadius) {
      const circle = new MapboxCircle(
        { lng: circleCenter[0], lat: circleCenter[1] },
        circleRadius,
        {
          editable: true,
          refineStroke: true,
          minRadius: MIN_RADIUS,
          maxRadius: MAX_RADIUS,
          fillColor: "#000",
          fillOpacity: 0,
          strokeColor: "#444",
          strokeWeight: 2,
        }
      );

      circle.on("centerchanged", (circleObj) => {
        debug("New center:", circleObj.getCenter());
        window.analytics.track("Move Search Circle", {
          lngLat: circleObj.getCenter(),
        });
        setCircleCenter({
          lat: circleObj.getCenter().lat,
          lng: circleObj.getCenter().lng,
        });
      });

      circle.on("radiuschanged", (circleObj) => {
        debug("New radius:", circleObj.getRadius());
        window.analytics.track("Resize Search Circle", {
          radius: circleObj.getRadius(),
        });
        setCircleRadius(circleObj.getRadius());
      });

      setCircle(circle);

      return () => {
        try {
          circle.remove();
        } catch (e) {
          console.log("Cannot remove MapCircle (likely doesn't exist)");
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // two way binding for state update changing circle radius
    if (circle && circleRadius && circle.getRadius() !== circleRadius) {
      circle.setRadius(circleRadius);
    }
  }, [circle, circleRadius]);

  useEffect(() => {
    // two way binding for state update changing circle position
    if (
      circle &&
      (circle.getCenter().lat !== circleCenter[1] ||
        circle.getCenter().lng !== circleCenter[0])
    ) {
      circle.setCenter({
        lat: circleCenter[1],
        lng: circleCenter[0],
      });
    }
  }, [circle, circleCenter]);

  return (
    <MapContext.Consumer>
      {(map) => {
        if (circle && !addCircleToMap) {
          debug("Adding circle to map");
          circle.addTo(map, "TOP_LAYER");
        }
      }}
    </MapContext.Consumer>
  );
};

export default MapCircle;
