import { Component } from "react";

const house =
  "              _                    \n" +
  "            _|=|__________         \n" +
  "           /              \\        \n" +
  "          /                \\       \n" +
  "         /__________________\\      \n" +
  "          ||  || /--\\ ||  ||        \n" +
  "          ||[]|| | .| ||[]||       \n" +
  "        ()||__||_|__|_||__||()     \n" +
  "       ( )|-|-|-|====|-|-|-|( )    \n" +
  "       ^^^^^^^^^^====^^^^^^^^^^^   \n";

const message = `
${house}
      ---  L A N D W O R T H ---

    Hi there, fellow developer! Welcome backstage.
    We're always on the lookout for smart people to 
    join us, so if you’re an aspiring entrepreneur, 
    programmer, hacker, or just have a passion for 
    property we would love to hear from you.
                                                       
    tech@landworth.org

    Let's build something amazing

    — The Landworth Team
      @LandworthIT

`;

class ConsoleMessage extends Component {
  componentDidMount() {
    if (typeof console === "object") {
      console.log(message);
    }
  }

  render() {
    return null;
  }
}

export default ConsoleMessage;
