import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { fetchUserReportHistory } from "graphql/functions";
import ReportHistoryTable from "./ReportHistoryTable";

import "./userHistory.css";

async function getHistory(setReportHistory) {
  const history = await fetchUserReportHistory();
  const sortedHistory = history?.valuations.sort(
    (a, b) => new Date(b.valuationDate) - new Date(a.valuationDate)
  );
  setReportHistory(sortedHistory);
}

const NoFavouriteReports = ({ history }) => (
  <div
    className="flex-center-center"
    style={{ textAlign: "center", flexDirection: "column", fontSize: "1.1em" }}
  >
    You haven't run any Valuation Reports yet!
    <br />
    <br />
    <div>
      Visit the&nbsp;
      <a
        href="/report"
        onClick={(event) => {
          history.push("/report");
          event.preventDefault();
        }}
        style={{ fontWeight: "bold" }}
      >
        New Valuation Report
      </a>
      &nbsp;page to start
    </div>
  </div>
);

const ReportHistory = ({ history, isLoggedIn }) => {
  const [reportHistory, setReportHistory] = useState();

  useEffect(() => {
    document.title = "Landworth: Valuation Report History";
    getHistory(setReportHistory);
  }, []);

  const loadReport = (valuationId) => {
    history.push("/report/" + valuationId);
  };

  return (
    <>
      <div
        className="flex-center-center"
        style={{
          flex: "1 1 auto",
          flexDirection: "column",
          overflowY: "auto",
          height: "100%",
          borderTop: "1px solid grey",
        }}
      >
        {reportHistory === undefined ? (
          <div className="loader-big" />
        ) : reportHistory.length === 0 ? (
          <NoFavouriteReports history={history} />
        ) : (
          <ReportHistoryTable
            searchHistory={reportHistory}
            loadURL={loadReport}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(ReportHistory);
