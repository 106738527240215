import React from "react";
import { Line } from "react-chartjs-2";
import { getValueChartOptions } from "./chartjs-options";

const getValueDataLow = (data, sqft) => {
  return JSON.parse(JSON.stringify(data)).map((item) => {
    return {
      x: item.xPlot * 1000,
      y: sqft
        ? sqft * Math.round(+item.lw - +item.lwsd / 2)
        : Math.round(+item.lw - +item.lwsd / 2),
    };
  });
};
const getValueDataAvg = (data, sqft) => {
  return JSON.parse(JSON.stringify(data)).map((item) => {
    return {
      x: item.xPlot * 1000,
      y: sqft ? Math.round(+item.lw * sqft) : Math.round(+item.lw),
    };
  });
};
const getValueDataHigh = (data, sqft) => {
  return JSON.parse(JSON.stringify(data)).map((item) => {
    return {
      x: item.xPlot * 1000,
      y: sqft
        ? sqft * Math.round(+item.lw + +item.lwsd / 2)
        : Math.round(+item.lw + +item.lwsd / 2),
    };
  });
};

const AveragePriceGraph = ({ averagePriceData, sqft }) => {
  const mode = sqft ? "price" : "ppsf";
  const valueChartOptions = getValueChartOptions(mode);

  const lowLocationData = getValueDataLow(averagePriceData.dataPoints, sqft);
  const avgLocationData = getValueDataAvg(averagePriceData.dataPoints, sqft);
  const highLocationData = getValueDataHigh(averagePriceData.dataPoints, sqft);

  averagePriceData = {
    datasets: [
      {
        label: "Low",
        backgroundColor: "rgba(0,36,125,0.4)",
        borderColor: "rgba(160,160,160,0)",
        data: lowLocationData,
        fill: 1,
        pointRadius: 0,
      },
      {
        label: mode === "price" ? "Price £GBP" : "Average £/sqft",
        backgroundColor: "rgba(0,0,0,1)",
        borderColor: "rgba(0,0,0,1)",
        data: avgLocationData,
        fill: false,
        pointRadius: 1,
      },
      {
        label: "High",
        backgroundColor: "rgba(119,0,0,0.4)",
        borderColor: "rgba(119,0,0,0)",
        data: highLocationData,
        fill: 1,
        pointRadius: 0,
      },
    ],
  };

  return (
    <Line
      data={averagePriceData}
      height={250}
      width={500}
      options={valueChartOptions}
    />
  );
};

export default AveragePriceGraph;
