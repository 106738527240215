import React from "react";

import PropertyFloorplan from "components/PropertyFloorplan";
import { formatMoneyFull } from "services/formatting";

const Floorplan = ({ property }) => {
  const {
    // propertySource,
    sqft,
    floorplanURL,
    rmHistoryFloorplanURL,
    zHistoryFloorplanURL,
  } = property;

  const sqm = Math.round(sqft * 0.092903);

  const sizeSource =
    property.propertySource === "landreg" ? "EPC" : "Floorplan";

  return (
    <div style={{ textAlign: "center" }}>
      {sqft !== -1
        ? `${sizeSource} Size: ${sqm}m² = ${formatMoneyFull(
            Math.round(sqft),
            0
          )}ft²`
        : "Unknown Size"}

      {/* <button disabled>Edit</button> */}

      <br />
      <br />
      <PropertyFloorplan
        url={floorplanURL || rmHistoryFloorplanURL || zHistoryFloorplanURL}
        maxHeight={"300px"}
      />
    </div>
  );
};

export default Floorplan;
