import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyLoadImage } from "react-lazy-load-image-component";
import no_photo from "../assets/no_photo.jpg";
import GoogleStreetViewThumbnail from "./GoogleStreetViewThumbnail";

import getDateHelper from "services/dates";

const LazyImage = ({ url, height, width }) => (
  <LazyLoadImage
    alt="property thumbnail"
    effect="opacity"
    height={height}
    width={width}
    src={url}
    style={{ objectFit: "cover", borderRadius: "2px" }}
  />
);

const NO_IMAGE_THUMB = (height) => (
  <img height={height} src={no_photo} alt="thumbnail" />
);

const isHistoryPicAvailable = (
  rmListingPrimaryPictureURL,
  zHistoryPrimaryPictureUrl
) =>
  (!!rmListingPrimaryPictureURL && rmListingPrimaryPictureURL !== "-1") ||
  (!!zHistoryPrimaryPictureUrl && zHistoryPrimaryPictureUrl !== "-1");

const EnhancedImage = ({
  url,
  height,
  thumbHeight,
  smallImage,
  width,
  totalImages,
}) => (
  <div
    style={{
      position: "relative",
      height: height,
      display: "flex",
      justifyContent: "center",
    }}
  >
    <div
      style={{
        position: "relative",
        width: "100%",
        cursor: "alias",
      }}
    >
      <LazyImage url={url} height={height} width={width} />
      {!!totalImages && (
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            backgroundColor: "#444b",
            color: "white",
            fontSize: smallImage ? "12px" : "14px",
            borderRadius: "3px",
            padding: "1px",
          }}
        >
          &nbsp;{totalImages}&nbsp;
          <FontAwesomeIcon icon={["far", "camera"]} />
          &nbsp;
        </div>
      )}
    </div>
  </div>
);

const getFirstZooplaURL = (zHistoryLinks) => {
  return JSON.parse(zHistoryLinks)[0]?.linkURL;
};

const PropertyThumbnail = ({
  property,
  viewType,
  smallImage,
  thumbHeight,
  fullWidth,
}) => {
  const {
    propertyID,
    propertyType,
    propertySource,
    listingURL,
    thumbnailURL,
    zHistoryURL,
    rmHistoryURL,
    zHistoryLinks,
    zActiveLinks,
    rmHistoryLinks,
    rmListingPrimaryPictureURL,
    zHistoryPrimaryPictureUrl,
    rmTotalImages,
    zHistoryTotalImages,
    photoCount,
  } = property;

  let mergedHistoryData = [
    ...(zHistoryLinks ? JSON.parse(zHistoryLinks) : []),
    ...(zActiveLinks ? JSON.parse(zActiveLinks) : []),
    ...(rmHistoryLinks ? JSON.parse(rmHistoryLinks) : []),
  ];
  mergedHistoryData.sort((a, b) => getDateHelper(b) - getDateHelper(a));

  const height = thumbHeight
    ? thumbHeight
    : viewType === "bigthumb"
    ? "230px"
    : !smallImage
    ? "120px"
    : "90px";
  // const height = viewType === "bigthumb" ? "230px" : "120px";
  const width =
    viewType === "bigthumb"
      ? "318px"
      : !!fullWidth
      ? "100%"
      : !smallImage
      ? "166px"
      : "125px";

  if (propertySource === "zoopla" || propertySource === "rightmove") {
    return (
      <div style={{ minHeight: height }}>
        <a
          href={listingURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {thumbnailURL && thumbnailURL !== "null" ? (
            <EnhancedImage
              url={thumbnailURL}
              height={height}
              thumbHeight={thumbHeight}
              smallImage={smallImage}
              width={width}
              totalImages={photoCount}
            />
          ) : (
            NO_IMAGE_THUMB(height)
          )}
        </a>
      </div>
    );
  } else if (propertySource === "landreg" || propertySource === "valuation") {
    let url;
    let thumbUrl;
    let totalImages;

    if (mergedHistoryData.length > 0) {
      for (let i = 0; i < mergedHistoryData.length; i++) {
        if (
          mergedHistoryData[i]?.propertySource === "rm" &&
          rmListingPrimaryPictureURL &&
          rmListingPrimaryPictureURL !== "-1"
        ) {
          url = mergedHistoryData[i]?.linkURL;
          thumbUrl = rmListingPrimaryPictureURL;
          totalImages = rmTotalImages;
          break;
        } else if (
          mergedHistoryData[i]?.propertySource === "rm" &&
          mergedHistoryData[i]?.primaryImageURL &&
          mergedHistoryData[i]?.primaryImageURL !== "-1"
        ) {
          url = mergedHistoryData[i]?.linkURL;
          thumbUrl = mergedHistoryData[i]?.primaryImageURL;
          totalImages = rmTotalImages;
          break;
        } else if (
          mergedHistoryData[i]?.propertySource === "zoopla" &&
          mergedHistoryData[i]?.primaryImageUrl &&
          mergedHistoryData[i]?.primaryImageUrl !== "-1"
        ) {
          url = !!mergedHistoryData[i]?.badLink
            ? zHistoryURL
            : mergedHistoryData[i]?.linkURL;
          thumbUrl = mergedHistoryData[i]?.primaryImageUrl;
          totalImages = zHistoryTotalImages;
          break;
        } else if (
          mergedHistoryData[i]?.propertySource === "zoopla" &&
          zHistoryPrimaryPictureUrl &&
          zHistoryPrimaryPictureUrl !== "-1"
        ) {
          url = mergedHistoryData[i]?.linkURL;
          thumbUrl = zHistoryPrimaryPictureUrl;
          totalImages = zHistoryTotalImages;
          break;
        }
      }
    }

    // if (
    //   mergedHistoryData.length > 0 &&
    //   mergedHistoryData[0]?.propertySource === "rm" &&
    //   rmListingPrimaryPictureURL &&
    //   rmListingPrimaryPictureURL !== "-1"
    // ) {
    //   url = mergedHistoryData[0]?.linkURL;
    //   thumbUrl = rmListingPrimaryPictureURL;
    //   totalImages = rmTotalImages;
    // } else if (
    //   mergedHistoryData.length > 0 &&
    //   mergedHistoryData[0]?.propertySource === "zoopla" &&
    //   zHistoryPrimaryPictureUrl &&
    //   zHistoryPrimaryPictureUrl !== "-1"
    // ) {
    //   url = mergedHistoryData[0]?.linkURL;
    //   thumbUrl = zHistoryPrimaryPictureUrl;
    //   totalImages = zHistoryTotalImages;
    // } else {
    //   url = mergedHistoryData[0]?.linkURL;
    //   thumbUrl = thumbnailURL;
    // }

    // if (
    //   isHistoryPicAvailable(
    //     rmListingPrimaryPictureURL,
    //     zHistoryPrimaryPictureUrl
    //   )
    // ) {
    //   const historyURL =
    //     rmListingPrimaryPictureURL && rmListingPrimaryPictureURL !== "-1"
    //       ? rmHistoryURL
    //       : zHistoryLinks &&
    //         JSON.parse(zHistoryLinks).length > 0 &&
    //         getFirstZooplaURL(zHistoryLinks);

    //   const historyPictureUrl =
    //     rmListingPrimaryPictureURL && rmListingPrimaryPictureURL !== "-1"
    //       ? rmListingPrimaryPictureURL
    //       : zHistoryPrimaryPictureUrl;

    if (thumbUrl && thumbUrl !== "null") {
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            window.analytics.track("Click Property Source Link", {
              propertyID,
              propertyType,
              source: "logo",
            });
          }}
        >
          <EnhancedImage
            url={thumbUrl}
            height={height}
            thumbHeight={thumbHeight}
            smallImage={smallImage}
            width={width}
            totalImages={totalImages}
          />
        </a>
      );
    } else {
      return (
        <GoogleStreetViewThumbnail
          width={width}
          property={property}
          height={height}
        />
      );
    }
  } else if (propertySource === "plan") {
    return <GoogleStreetViewThumbnail property={property} height={height} />;
  } else {
    return NO_IMAGE_THUMB(height);
  }
};

export default PropertyThumbnail;
