import { useContext, useCallback } from "react";
import { Context } from "./Store";

const useMapSettingsDrawer = () => {
  const [state, dispatch] = useContext(Context);

  const setMapSettingsDrawerOpen = useCallback(
    (newState) => {
      dispatch({ type: "SET_MAP_SETTINGS_DRAWER_OPEN", newState });
    },
    [dispatch]
  );

  return [state.mapSettingsDrawerOpen, setMapSettingsDrawerOpen];
};

export default useMapSettingsDrawer;
