import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import sortModes from "pages/Search/sortModes";
import { setSearchSortOrder as setServerSearchSortOrder } from "graphql/functions";
import { setSearchSortOrder as setReduxSearchSortOrder } from "data/slices/searchSlice";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";

const SearchResultsSortButton = ({ sortOrder, searchId, searchSpec }) => {
  const dispatch = useDispatch();

  const showSimilaritySort = searchSpec?.search_landreg;

  const setSortOrder = (e) => {
    const newSortOrder = e.target.value;
    window.analytics.track("Change Sort Order", {
      searchId,
      newSortOrder,
    });
    setServerSearchSortOrder(searchId, newSortOrder);
    dispatch(
      setReduxSearchSortOrder({
        searchID: searchId,
        searchSortOrder: newSortOrder,
      })
    );
  };

  // const isNarrow = useMediaQuery(`(max-width:${deviceSizes.narrow}px)`);

  const renderValue = (value) => {
    const mode = sortModes.find((mode) => mode.name === value);
    return (
      <div className="flex-center-center" style={{ fontSize: "0.8125rem" }}>
        <div style={{ margin: "0px 6px 0 0" }}>Sort</div>
        {mode.arrowIcon && (
          <>
            <FontAwesomeIcon
              icon={["far", mode.icon]}
              style={{ fontSize: "0.9em" }}
            />
            &nbsp;
            <FontAwesomeIcon
              icon={["far", mode.arrowIcon]}
              style={{ paddingRight: "3px" }}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="search-results-header-controls-sort"
      style={{ userSelect: "none" }}
    >
      <Select
        name="sort"
        variant="outlined"
        value={sortOrder || "cheapest"}
        onChange={setSortOrder}
        renderValue={renderValue}
        IconComponent={() => null}
        style={{ padding: "0 !important", backgroundColor: "white" }}
      >
        <MenuItem value="" disabled dense>
          Show First...
        </MenuItem>
        {sortModes.map((mode) => {
          if (mode.name === "similarityscore" && !showSimilaritySort)
            return null;
          else {
            return (
              <MenuItem key={mode.name} value={mode.name} dense>
                {sortOrder === mode.name ? (
                  <>
                    <div style={{ width: "20px", textAlign: "center" }}>
                      <FontAwesomeIcon icon={["far", mode.icon]} />
                    </div>
                    &nbsp;&nbsp;&nbsp;<u>{mode.display}</u>
                  </>
                ) : (
                  <>
                    <div style={{ width: "20px", textAlign: "center" }}>
                      <FontAwesomeIcon icon={["far", mode.icon]} />
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    {mode.display}
                  </>
                )}
              </MenuItem>
            );
          }
        })}
      </Select>
    </div>
  );
};

export default SearchResultsSortButton;
