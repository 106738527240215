const DEFAULT_SEARCH_SPEC = {
  //// Search Spec fields
  search_type: "sale", // Eventually we should be able to change this to "rent"
  search_zoopla: false,
  search_landreg: false,
  search_rm: false,
  search_plan: false,
  // search_source: "both",
  searchlat: -0.181748,
  searchlng: 51.546625,
  //   searchradius: 444,
  include_forsale: true,
  include_sold: true,
  // listing_status: "both",
  sold_history_months: 12,
  lr_only_land: false,

  //// Property Spec fields
  property_type: "all",
  house_types: ["T", "S", "D"],
  pricemin: 0,
  pricemax: 200000000,
  bedmin: 0,
  bedmax: 50,
  sizemin: 0,
  sizemax: 100000,
  newbuild: true,
  oldbuild: true,
  build_type: "both",
  keywords: "",
  include_freehold: true,
  include_leasehold: true,
  include_shared: true,

  // Planning Permission fields
  planningAppSizes: ["Small", "Medium", "Large"],
  planningAppStates: [
    "Permitted",
    "Conditions",
    "Rejected",
    "Withdrawn",
    "Undecided",
    "Referred",
    "Other",
  ],
  planningAppTypes: [
    "Full",
    "Outline",
    "Amendment",
    "Conditions",
    "Heritage",
    "Trees",
    "Advertising",
    "Telecoms",
    "Other",
  ],
  planningFromDate: new Date("2014-01-01"),
  planningToDate: new Date(),
};

export default DEFAULT_SEARCH_SPEC;
