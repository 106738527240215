import { Authenticator } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

import { signInFormFields, signInComponents } from "./signInObjects";
import { signUpFormFields, signUpComponents } from "./signUpObjects";

import "@aws-amplify/ui-react/styles.css";
import "./authTheme.css";

I18n.putVocabulariesForLanguage("en", {
  "Sign In": "Log In", // Tab header
  "Sign in": "Enter Landworth", // Button label
  "Sign in to your account": "Welcome Back!",
  Username: "Enter your username", // Username label
  Password: "Enter your password", // Password label
  "Forgot your password?": "Reset Password",
});

const formFields = {
  ...signInFormFields,
  ...signUpFormFields,
};

const components = {
  ...signInComponents,
  ...signUpComponents,
};

const NewSignInComponent = (props) => {
  return (
    <div
      className="flex-center-center nice-scrollbar"
      style={{
        flex: "1 1 auto",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        borderTop: "1px solid grey",
        justifyContent: "flex-start",
      }}
    >
      <Authenticator
        // hideSignUp={true}
        loginMechanisms={["email"]}
        initialState={"signIn"}
        formFields={formFields}
        components={components}
      >
        {props.children}
      </Authenticator>
    </div>
  );
};
export default NewSignInComponent;
