import { useEffect } from "react";
import { connect } from "formik";

/**
 * Monitor form changes that need to be exposed to the wider app
 */
export const MonitorOutgoing = connect(({ formik, setCircleRadius }) => {
  const { searchradius } = formik.values;

  useEffect(() => {
    setCircleRadius(searchradius);
  }, [searchradius, setCircleRadius]);

  return null;
});

/**
 * Monitor external changes that need to be reflected on the form
 */
export const MonitorIncoming = connect(
  ({ searchradius, searchlng, searchlat, formik }) => {
    const { setFieldValue } = formik;

    useEffect(() => {
      if (searchradius) {
        setFieldValue("searchradius", searchradius);
      }
    }, [searchradius, setFieldValue]);

    useEffect(() => {
      if (searchlat) {
        setFieldValue("searchlat", searchlat);
      }
    }, [searchlat, setFieldValue]);

    useEffect(() => {
      if (searchlng) {
        setFieldValue("searchlng", searchlng);
      }
    }, [searchlng, setFieldValue]);
    return null;
  }
);
