import React from "react";
import { withRouter } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { useLocalStorage } from "@rehooks/local-storage";

import useDeviceWidth from "data/hooks/useDeviceWidth";

const NarrowSkeleton = ({ viewType, rowHeight }) => {
  const addressWidth = 140;

  return (
    <div
      style={{
        display: "flex",
        height: `${rowHeight}px`,
        alignItems: "center",
        justifyContent: "left",
        padding: "10px 5px 0",
      }}
    >
      <div style={{ width: "80px" }}>
        <Skeleton animation="wave" variant="text" width={80} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>

      <div style={{ width: "135px" }}>
        <Skeleton animation="wave" variant="rect" width={125} height={90} />
      </div>

      <div style={{ width: "135px" }}>
        <Skeleton animation="wave" variant="text" width={addressWidth} />
        <Skeleton animation="wave" variant="text" width={addressWidth - 20} />
        <Skeleton animation="wave" variant="text" width={addressWidth} />
      </div>
    </div>
  );
};

const MediumSkeleton = ({ viewType, rowHeight }) => {
  const addressWidth = viewType === "slim" ? 140 : 80;

  return (
    <div
      style={{
        display: "flex",
        height: `${rowHeight}px`,
        alignItems: "center",
        justifyContent: "center",
        padding: "0 5px",
      }}
    >
      <div style={{ width: "120px", padding: "0 15px" }}>
        <Skeleton animation="wave" variant="rect" width={20} height={20} />
      </div>
      <div style={{ width: "120px", padding: "0 20px" }}>
        <Skeleton animation="wave" variant="text" width={80} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>

      {viewType !== "slim" && (
        <div style={{ width: "135px", padding: "0 20px" }}>
          <Skeleton animation="wave" variant="rect" width={170} height={120} />
        </div>
      )}
      <div style={{ width: "135px", padding: "0 120px" }}>
        <Skeleton animation="wave" variant="text" width={addressWidth} />
        <Skeleton animation="wave" variant="text" width={addressWidth - 20} />
        <Skeleton animation="wave" variant="text" width={addressWidth} />
      </div>

      <div style={{ width: "120px", padding: "0 20px" }}>
        <Skeleton animation="wave" variant="text" width={60} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>
      <div style={{ width: "130px", paddingRight: "40px" }}>
        <Skeleton animation="wave" variant="text" width={60} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>
    </div>
  );
};

const WideSkeleton = ({ viewType, rowHeight }) => {
  const addressWidth = viewType === "slim" ? 140 : 80;

  return (
    <div
      style={{
        display: "flex",
        height: `${rowHeight}px`,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 5px",
      }}
    >
      <div style={{ width: "120px", padding: "0 15px" }}>
        <Skeleton animation="wave" variant="rect" width={20} height={20} />
      </div>
      <div style={{ width: "120px", padding: "0 20px" }}>
        <Skeleton animation="wave" variant="text" width={80} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>
      {viewType !== "slim" && (
        <div style={{ width: "135px", padding: "0 20px" }}>
          <Skeleton animation="wave" variant="rect" width={170} height={120} />
        </div>
      )}
      <div style={{ width: "135px", padding: "0 120px" }}>
        <Skeleton animation="wave" variant="text" width={addressWidth} />
        <Skeleton animation="wave" variant="text" width={addressWidth - 20} />
        <Skeleton animation="wave" variant="text" width={addressWidth} />
      </div>
      <div style={{ width: "120px", padding: "0 20px" }}>
        <Skeleton animation="wave" variant="text" width={60} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>
      <div style={{ width: "120px", padding: "0 20px" }}>
        <Skeleton animation="wave" variant="text" width={60} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>
      <div style={{ width: "120px", padding: "0 20px" }}>
        <Skeleton animation="wave" variant="text" width={60} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>
      <div style={{ width: "130px", paddingRight: "40px" }}>
        <Skeleton animation="wave" variant="text" width={60} />
        <Skeleton animation="wave" variant="text" width={60} />
      </div>
    </div>
  );
};

const SearchResultSkeleton = () => {
  const [viewType] = useLocalStorage("result_style");

  const [isSmallWidth, isMediumWidth] = useDeviceWidth();

  const rowHeight = viewType === "slim" ? 80 : 144;

  return isSmallWidth ? (
    <NarrowSkeleton viewType={viewType} rowHeight={110} />
  ) : isMediumWidth ? (
    <MediumSkeleton viewType={viewType} rowHeight={rowHeight} />
  ) : (
    <WideSkeleton viewType={viewType} rowHeight={rowHeight} />
  );
};

export default withRouter(SearchResultSkeleton);
