import React, { useMemo } from "react";

import useSearchResults from "../../../data/hooks/useSearchResults";
import usePropertyData from "../../../data/hooks/usePropertyData";
import useSearch from "../../../data/hooks/useSearch";
import { getSearchSelector } from "../../../data/selectors";
// import filterProperties from "../../../services/filterProperties";
import useFilteredSearchResults from "pages/Search/useFilteredSearchResults";

import ValuationReportSearchResults from "./ValuationReportSearchResults";
import ValuationReportSearchSummary from "./ValuationReportSearchSummary";

import { setCircleRadius as setCircleRadiusAction } from "pages/Search/circleSlice";
import { useSelector, useDispatch } from "react-redux";

const ValuationReportSearch = (props) => {
  const { valuationData, searchId, searchType, valuationProperty } = props;

  const [searchError, searchFinished] = useSearch(searchId);
  const cacheFinished = usePropertyData(searchId, searchFinished);
  const [properties, propertyIds] = useSearchResults(searchId);
  const search = useSelector((state) => getSearchSelector(state, searchId));

  const [filteredProperties] = useFilteredSearchResults(searchId);

  // Triger update of circle radius when active Valuation Search tab changes
  const dispatch = useDispatch();
  useMemo(
    () =>
      search?.spec?.searchradius &&
      dispatch(setCircleRadiusAction(search?.spec?.searchradius)),
    [dispatch, search?.spec?.searchradius]
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        {/* {searchType === "planning" && (
          <>
            <div
              className="flex-center-center"
              style={{
                color: "red",
                fontWeight: "bold",
                textAlign: "center",
                paddingTop: "4px",
                paddingBottom: "8px",
              }}
            >
              This feature is in currently in BETA - please contact us if you
              have any questions
            </div>
          </>
        )} */}
        <ValuationReportSearchSummary
          valuationData={valuationData}
          properties={properties}
          search={search}
          searchType={searchType}
          searchFinished={searchFinished}
          cacheFinished={cacheFinished}
        />
        <div style={{ padding: "4px 0" }} />
        <ValuationReportSearchResults
          valuationData={valuationData}
          valuationProperty={valuationProperty}
          searchId={searchId}
          search={search}
          searchFinished={searchFinished}
          properties={properties}
          filteredProperties={filteredProperties}
        />
      </div>
    </>
  );
};

export default ValuationReportSearch;
