import { API, graphqlOperation } from "aws-amplify";
import Debug from "debug";
import {
  getLandworth,
  getLocationHistoryPrice,
  getLocationHistorySales,
  getPpsfChecker,
  getSearchData,
  getUserSearchHistory,
  getUserSearchFavourites,
  getUserValuationHistory,
  getValuationData,
  propertyData,
  soldPropertiesAutocomplete,
  epcPropertiesAutocomplete,
  osAddressAutocomplete,
  getUPRNDetails,
  getCredits,
  getStripeCheckout,
  getStripePortal,
  findFullAddress,
} from "../graphql/queries";
import {
  search,
  starProperty as starPropertyMutation,
  unstarProperty as unstarPropertyMutation,
  hideProperty as hidePropertyMutation,
  unhideProperty as unhidePropertyMutation,
  addManualSize,
  setComparableProperty as setPropertyComparableMutation,
  setFavouriteSearch as setFavouriteSearchMutation,
  setValuationPrice as setValuationPriceMutation,
  createValuation,
  runValuationSearch as runValuationSearchMutation,
  expandValuationSearch as expandValuationSearchMutation,
  runValuationSearches as runValuationSearchesMutation,
  setSearchSortOrder as setSearchSortOrderMutation,
  setShowTodayPrice as setShowTodayPriceMutation,
  setSearchCustomName as setSearchCustomNameMutation,
} from "../graphql/mutations";
import { onValuationPriceUpdate } from "../graphql/subscriptions";

const debug = Debug("lw:functions");

const isNotNull = (el) => el != null;

export const fetchLandworth = async (lat, lng, propertyType, date) => {
  try {
    const landworthData = await API.graphql({
      query: getLandworth,
      variables: {
        body: {
          dbConfig: "fromSLS",
          mode: "latlng2",
          date: `${date}`,
          locData: `{
              "lat": "${lat}",
              "long": "${lng}",
              "type": "${propertyType}"
            }`,
        },
      },
      authMode: "API_KEY",
    });
    return JSON.parse(JSON.parse(landworthData.data.getLandworth));
  } catch (err) {
    debug("🔥 error fetching landworth data", err.errors ? err.errors[0] : err);
  }
};

// export const fetchLocationHistory = async (lat, lng, date, monthsToCheck) => {
//   try {
//     const locationHistoryData = await API.graphql({
//       query: getLocationHistoryPrice,
//       variables: {
//         body: {
//           dbConfig: "fromSLS",
//           mode: "latlng",
//           date: `${date}`,
//           locData: `{
//               "lat": "${lat}",
//               "long": "${lng}"
//             }`,
//           monthsToCheck: monthsToCheck,
//         },
//       },
//       authMode: "API_KEY",
//     });
//     return JSON.parse(
//       JSON.parse(locationHistoryData.data.getLocationHistoryPrice)
//     );
//   } catch (err) {
//     debug("🔥 error fetching location history data", err);
//   }
// };

export const fetchLocationHistoryPrice = async (params) => {
  try {
    const locationHistoryPriceData = await API.graphql({
      query: getLocationHistoryPrice,
      variables: {
        body: {
          dbConfig: "fromSLS",
          ...params,
        },
      },
      authMode: "API_KEY",
    });
    return JSON.parse(
      JSON.parse(locationHistoryPriceData.data.getLocationHistoryPrice)
    );
  } catch (err) {
    debug("🔥 error fetching location history data", err);
  }
};

export const fetchLocationHistorySales = async (params) => {
  try {
    const locationHistorySalesData = await API.graphql({
      query: getLocationHistorySales,
      variables: {
        body: {
          dbConfig: "fromSLS",
          ...params,
        },
      },
      authMode: "API_KEY",
    });
    return JSON.parse(
      JSON.parse(locationHistorySalesData.data.getLocationHistorySales)
    );
  } catch (err) {
    debug("🔥 error fetching location Sales history data", err);
  }
};

export const fetchOsAddressAutocomplete = async (params) => {
  try {
    const results = await API.graphql({
      query: osAddressAutocomplete,
      variables: {
        searchText: params,
      },
      authMode: "API_KEY",
    });
    // console.log(results.data);
    // console.log(JSON.parse(results.data.osAddressAutocomplete).results);
    // JSON.parse(results.data.osAddressAutocomplete).results.map((result) =>
    //   console.log(result.LPI.CLASSIFICATION_CODE + " - " + result.LPI.ADDRESS)
    // );
    return JSON.parse(results.data.osAddressAutocomplete);
  } catch (err) {
    debug("🔥 error fetching location Sales history data", err);
  }
};

export const fetchUPRNDetails = async (params) => {
  try {
    const uprnDetails = await API.graphql({
      query: getUPRNDetails,
      variables: {
        uprn: params.uprn,
        postcode: params.postcode,
        matchAddress: params.matchAddress,
      },
      authMode: "API_KEY",
    });
    return JSON.parse(uprnDetails.data.getUPRNDetails)[0];
  } catch (err) {
    debug("🔥 error fetching location Sales history data", err);
  }
};

export const runSearch = async (searchName, searchSource, searchSpec) => {
  try {
    const { data } = await API.graphql({
      query: search,
      variables: {
        searchSpec: {
          ...searchSpec,
          searchradius: searchSpec.searchradius / 1609,
        },
        searchName,
        searchSource,
        searchLocationName: searchName,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return JSON.parse(data.search);
  } catch (err) {
    debug("🔥 error creating search", err);
  }
};

export const getSearchResults = async (searchID) => {
  try {
    const { data } = await API.graphql({
      query: getSearchData,
      variables: {
        searchID,
      },
      authMode: "API_KEY",
    });
    return data.getSearchData;
  } catch (err) {
    debug("🔥 error retrieving search", err);
  }
};

export const fetchUserSearchHistory = async () => {
  try {
    const { data } = await API.graphql({
      query: getUserSearchHistory,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.getUserSearchHistory;
  } catch (err) {
    debug("🔥 error retrieving search history", err);
  }
};
export const fetchUserSearchFavourites = async () => {
  try {
    const { data } = await API.graphql({
      query: getUserSearchFavourites,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.getUserSearchFavourites;
  } catch (err) {
    debug("🔥 error retrieving search favourites", err);
  }
};
export const fetchFullAddress = async (postcode, price) => {
  try {
    const { data } = await API.graphql({
      query: findFullAddress,
      variables: {
        postcode,
        price,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.findFullAddress ? JSON.parse(data.findFullAddress) : data;
  } catch (err) {
    debug("🔥 error retrieving search history", err);
  }
};

export const fetchUserReportHistory = async () => {
  try {
    const { data } = await API.graphql({
      query: getUserValuationHistory,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.getUserValuationHistory;
  } catch (err) {
    debug("🔥 error retrieving search history", err);
  }
};

export const setPropertyComparable = async (
  searchID,
  propertyID,
  isComparable
) => {
  try {
    const { data } = await API.graphql({
      query: setPropertyComparableMutation,
      variables: {
        searchID,
        propertyID,
        isComparable,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error setting comparable property", err);
  }
};
export const setFavouriteSearch = async (searchID, isFavourite) => {
  try {
    const { data } = await API.graphql({
      query: setFavouriteSearchMutation,
      variables: {
        searchID,
        isFavourite,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error setting favourite search", err);
  }
};
export const setSearchSortOrder = async (searchID, searchSortOrder) => {
  try {
    const { data } = await API.graphql({
      query: setSearchSortOrderMutation,
      variables: {
        searchID,
        searchSortOrder,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error setting searchSortOrder", err);
  }
};
export const setShowTodayPrice = async (searchID, showTodayPrice) => {
  try {
    const { data } = await API.graphql({
      query: setShowTodayPriceMutation,
      variables: {
        searchID,
        showTodayPrice,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error setting setShowTodayPrice", err);
  }
};
export const setSearchCustomName = async (searchID, searchCustomName) => {
  try {
    const { data } = await API.graphql({
      query: setSearchCustomNameMutation,
      variables: {
        searchID,
        searchCustomName,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error setting setSearchCustomName", err);
  }
};

export const fetchPpsfChecker = async ({ lat, lng }, radius) => {
  try {
    const ppsfCheckerData = await API.graphql({
      query: getPpsfChecker,
      variables: {
        body: {
          dbConfig: "fromSLS",
          lat: lat,
          lng: lng,
          radius: `${radius / 1609}`,
        },
      },
      authMode: "API_KEY",
    });
    return JSON.parse(ppsfCheckerData.data.getPpsfChecker);
  } catch (err) {
    debug("🔥 error fetching ppsf checker data", err);
  }
};

/////////
/// PROPERTY FUNCTIONS
////////

export const getPropertyData = async (ids) => {
  try {
    const { data } = await API.graphql({
      query: propertyData,
      variables: {
        ids,
      },
      authMode: "API_KEY",
    });
    const properties = data.propertyData || [];
    return properties.filter(isNotNull);
  } catch (err) {
    debug("🔥 error getting properties", err);
  }
};
export const getSoldPropertyData = async (searchString) => {
  try {
    const { data } = await API.graphql({
      query: soldPropertiesAutocomplete,
      variables: {
        body: { searchString },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const properties = data.soldPropertiesAutocomplete || [];
    return JSON.parse(JSON.parse(properties).body);
  } catch (err) {
    debug("🔥 error getting sold property data", err);
  }
};

export const getEPCPropertyData = async (searchString) => {
  try {
    const { data } = await API.graphql({
      query: epcPropertiesAutocomplete,
      variables: {
        body: { searchString },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const properties = data.epcPropertiesAutocomplete || [];
    return JSON.parse(properties);
  } catch (err) {
    debug("🔥 error getting epc property data", err);
  }
};

export const starProperty = async (propertyID) => {
  try {
    const { data } = await API.graphql({
      query: starPropertyMutation,
      variables: {
        propertyID: propertyID,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error starring property", err);
  }
};
export const unstarProperty = async (propertyID) => {
  try {
    const { data } = await API.graphql({
      query: unstarPropertyMutation,
      variables: {
        propertyID: propertyID,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error unstarring property", err);
  }
};
export const hideProperty = async (propertyID) => {
  try {
    const { data } = await API.graphql({
      query: hidePropertyMutation,
      variables: {
        propertyID: propertyID,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error hiding property", err);
  }
};
export const unhideProperty = async (propertyID) => {
  try {
    const { data } = await API.graphql({
      query: unhidePropertyMutation,
      variables: {
        propertyID: propertyID,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error unhiding property", err);
  }
};

export const setPropertySqft = async (propertyID, sqftValue) => {
  try {
    const { data } = await API.graphql({
      query: addManualSize,
      variables: {
        propertyID: propertyID,
        sqft: sqftValue,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.search;
  } catch (err) {
    debug("🔥 error setting property sqft", err);
  }
};

/////////
/// VALUATION FUNCTIONS
////////

export const runValuation = async ({
  valuationMode,
  valuationInputSpec,
  referringDomainName,
  isLoggedIn,
}) => {
  console.log(valuationInputSpec);
  try {
    const { data } = await API.graphql({
      query: createValuation,
      variables: {
        valuationMode,
        valuationInputSpec,
        referringDomainName,
      },
      authMode: isLoggedIn ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY", // if user is not logged in, authenticate with API KEY
    });
    return JSON.parse(data.createValuation);
  } catch (err) {
    debug("🔥 error creating valuation", err);
  }
};
export const getValuation = async (valuationId) => {
  try {
    const { data } = await API.graphql({
      query: getValuationData,
      variables: {
        valuationId,
      },
      authMode: "API_KEY",
    });
    return data.getValuationData;
  } catch (err) {
    console.log("ERROR");
    console.log(err);
    debug("🔥 error retrieving valuation data", err);
  }
};
export const setValuationPrice = async (
  valuationId,
  newPrice,
  newPriceSource
) => {
  try {
    const { data } = await API.graphql({
      query: setValuationPriceMutation,
      variables: {
        valuationId,
        newPrice,
        newPriceSource,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    // Is this needed here? Doesn't fully work...
    // await API.graphql(
    //   graphqlOperation(onValuationPriceUpdate, { valuationId })
    // ).subscribe({
    //   next: ({ provider, value }) => console.log({ provider, value }),
    //   error: (error) => console.warn(error),
    // });

    return data.setValuationPriceMutation;
  } catch (err) {
    debug("🔥 error setting valuation price", err);
  }
};
export const subscribeToValuation = async (valuationId, trigger) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(onValuationPriceUpdate, { valuationId })
    ).subscribe({
      next: ({ provider, value }) => {
        // console.log(value.data.onValuationPriceUpdate.valuationPrice);
        trigger(value.data.onValuationPriceUpdate.valuationPrice);
      },
      error: (error) => console.warn(error),
    });

    return data.onValuationPriceUpdate;
  } catch (err) {
    debug("🔥 error subscribing to onValuationPriceUpdate", err);
  }
};

export const runValuationSearch = async (
  valuationId,
  valuationName,
  searchType,
  lat,
  lng,
  propertyType,
  beds,
  price
) => {
  try {
    const { data } = await API.graphql({
      query: runValuationSearchMutation,
      variables: {
        valuationId,
        valuationName,
        searchType,
        lat,
        lng,
        propertyType,
        beds,
        price,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.runValuationSearch;
  } catch (err) {
    debug("🔥 error creating valuation", err);
  }
};

export const runValuationSearches = async (
  valuationId,
  valuationName,
  lat,
  lng,
  propertyType,
  beds,
  price
) => {
  try {
    const { data } = await API.graphql({
      query: runValuationSearchesMutation,
      variables: {
        valuationId,
        valuationName,
        lat,
        lng,
        propertyType,
        beds,
        price,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return JSON.parse(data.runValuationSearches);
  } catch (err) {
    debug("🔥 error creating valuation", err);
  }
};
export const expandValuationSearch = async ({
  valuationId,
  oldSearchId,
  oldSearchLocationName,
  searchType,
  newSearchSpec,
}) => {
  try {
    const { data } = await API.graphql({
      query: expandValuationSearchMutation,
      variables: {
        valuationId,
        oldSearchId,
        oldSearchLocationName,
        searchType,
        newSearchSpec: {
          ...newSearchSpec,
          searchradius: newSearchSpec.searchradius / 1609,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.expandValuationSearch;
  } catch (err) {
    debug("🔥 error creating valuation", err);
  }
};

export const getCreditData = async ({ initiator }) => {
  try {
    const { data } = await API.graphql({
      query: getCredits,
      variables: {
        initiator,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.getCredits;
  } catch (err) {
    debug("🔥 error getting creditData", err);
  }
};

export const fetchCheckoutUrl = async ({
  purchaseType,
  price,
  successUrl,
  cancelUrl,
}) => {
  try {
    const { data } = await API.graphql({
      query: getStripeCheckout,
      variables: {
        purchaseType,
        price,
        successUrl,
        cancelUrl,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log(data);
    return data.getStripeCheckout;
  } catch (err) {
    debug("🔥 error in getStripeCheckout", err);
  }
};
export const fetchPortalUrl = async ({ returnUrl }) => {
  try {
    const { data } = await API.graphql({
      query: getStripePortal,
      variables: {
        returnUrl,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return data.getStripePortal;
  } catch (err) {
    debug("🔥 error in getStripeCheckout", err);
  }
};
