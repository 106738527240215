import React from "react";
import AveragePriceGraph from "./AveragePriceGraph";

const PriceGraph = ({ locationHistoryPriceData, sqft }) => {
  return (
    <>
      {locationHistoryPriceData ? (
        <AveragePriceGraph
          sqft={sqft}
          averagePriceData={
            locationHistoryPriceData
              ? locationHistoryPriceData
              : {
                  dataPoints: [],
                }
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            paddingBottom: "30px",
          }}
        >
          <div className="loader-small"></div>
        </div>
      )}
    </>
  );
};

export default PriceGraph;
