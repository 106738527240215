import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const PlanningFullText = ({ text, tooltip }) => {
  return (
    <HtmlTooltip
      placement="bottom"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={tooltip}
    >
      {/* <div className="flex-center-center">{text}</div> */}
      <div>
        <FontAwesomeIcon
          icon={regular("memo-circle-info")}
          // style={{ fontSize: "1.2rem" }}
        />
      </div>
    </HtmlTooltip>
  );
};

export default PlanningFullText;
