import { useAuthenticator } from "@aws-amplify/ui-react";
import { Heading, View, Button, useTheme } from "@aws-amplify/ui-react";

export const signInFormFields = {
  signIn: {
    username: {
      label: "Email Address",
      labelHidden: false,
      placeholder: "e.g. joe@bloggs.com",
      isRequired: true,
      autocomplete: "username",
    },
    password: {
      label: "Password",
      labelHidden: false,
      placeholder: "Enter your password",
      isRequired: true,
      autocomplete: "current-password",
    },
  },
};

export const signInComponents = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={5}
        >
          Login to your account
        </Heading>
      );
    },
    Footer() {
      const { tokens } = useTheme();
      const { toResetPassword } = useAuthenticator((context) => [
        context.user,
        context.authStatus,
      ]);
      return (
        <View textAlign="center" padding={`0 0 ${tokens.space.large} 0`}>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="medium"
            variation="link"
          >
            Reset Password
          </Button>
          {/* &nbsp;
          <Button
            fontWeight="normal"
            onClick={() => {
              window.analytics.track("Click Request an account");
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdOZt7WP70WFURCjQLxPnULZPiRldK6f9nkLwNlWG6atCYejg/viewform",
                "_blank"
              );
            }}
            size="medium"
            variation="link"
          >
            Request an Account
          </Button> */}
        </View>
      );
    },
  },
};
