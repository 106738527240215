import { useMemo } from "react";
// import ReactMapboxGL from "react-mapbox-gl";

import useSearchView from "../../data/hooks/useSearchView";

import FormikEnabledContainer from "./FormikEnabledContainer";
// import Map from "../Map";

import "../../App.css";

const SearchInterface = (props) => {
  const { searchView } = props;
  const searchListColumnWidth = useMemo(
    () =>
      searchView === "full_list"
        ? "column100pc"
        : searchView === "split"
        ? "column50pc"
        : "column0pc",
    [searchView]
  );

  return (
    <FormikEnabledContainer
      {...props}
      searchListColumnWidth={searchListColumnWidth}
    />
  );
};

// const ReactMap = ReactMapboxGL({
//   accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
//   maxZoom: 19,
//   antialias: true,
//   bearingSnap: 10,
// });

const Search = () => {
  const [searchView] = useSearchView();
  const mapColumnWidth = useMemo(
    () =>
      searchView === "full_map"
        ? "column100pc"
        : searchView === "split"
        ? "column50pc"
        : "column0pc",
    [searchView]
  );

  return (
    <SearchInterface searchView={searchView} mapColumnWidth={mapColumnWidth}>
      {/* <Map isLoggedIn={true} ReactMap={<ReactMap />} /> */}
    </SearchInterface>
  );
};

export default Search;
