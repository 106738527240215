import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 210,
    textAlign: "center",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const InfoIcon = (
  <Icon style={{ fontSize: "1em", overflow: "initial", paddingTop: "1px" }}>
    <FontAwesomeIcon
      icon={["far", "info-circle"]}
      style={{ color: "#168039", fontSize: "15px" }}
    />
  </Icon>
);

const PriceChangeInfo = ({ priceChange, iconText }) => (
  <>
    <HtmlTooltip
      placement="bottom-start"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={
        <>
          The average price of land has gone {priceChange > 0 ? "up" : "down"}{" "}
          {Math.abs(priceChange)}% over the last 12 months
        </>
      }
    >
      {/* <Icon style={{ fontSize: "1em", overflow: "initial", paddingTop: "1px" }}> */}
      {iconText}
      {/* </Icon> */}
    </HtmlTooltip>
  </>
);

export default PriceChangeInfo;
