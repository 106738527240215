import { BrowserRouter as Router } from "react-router-dom";

import { Authenticator } from "@aws-amplify/ui-react";
import AuthContainer from "./AuthContainer";

import { library } from "@fortawesome/fontawesome-svg-core";
//prettier-ignore
import { faSubway, faPlaneDeparture, faShoppingCart, faHospital, faMapMarkerAlt, faCrosshairs, faAlignJustify, faList, faRectangleLandscape, faMapMarked, 
  faColumns, faSearch, faSignOut, faSignIn, faLongArrowRight, faHouse, faCog, faLayerGroup, faExternalLinkAlt, faStreetView, 
  faDove, faExpandAlt, faCompressAlt, faMap, faDraftingCompass, faSatellite, faTrain, faPoundSign, faHandHoldingUsd, faChartLine, faCommentDots, faEnvelopeOpenText, faPercent,
  faInfoCircle, faExclamationSquare, faQuestionCircle, faCamera, faEye, faAngleDoubleUp, faAngleDoubleDown, faRoad, faCity, faMailbox, faExclamationTriangle,
  faMoneyBillAlt, faCalendarAlt, faRuler, faBed, faCoins, faHomeAlt, faBuilding, faCalculator, faSearchDollar, faScaleUnbalanced, faArrowUp, faArrowDown, faCodeCompare, faArrowUpWideShort, faArrowDownWideShort
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheckCircle,
  faBalanceScaleLeft,
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { faBalanceScale } from "@fortawesome/pro-light-svg-icons";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

//prettier-ignore
library.add( faSubway, faPlaneDeparture, faShoppingCart, faHospital, faMapMarkerAlt, faCrosshairs, faAlignJustify, faList, faRectangleLandscape, faMapMarked,
  faColumns, faSearch, faSignOut, faSignIn, faLongArrowRight, faHouse, faBalanceScale, faBalanceScaleLeft, faCog, faLayerGroup, faExternalLinkAlt, faStreetView,
  faDove, faExpandAlt, faCompressAlt, faMap, faDraftingCompass, faSatellite, faTrain, faPoundSign, faHandHoldingUsd, faChartLine, faCommentDots,faEnvelopeOpenText, faPercent,
  faInfoCircle, faExclamationSquare,faCheckCircle, faQuestionCircle, faCamera, faEye, faAngleDoubleUp, faAngleDoubleDown, faRoad, faCity, faMailbox, faExclamationTriangle,
  faMoneyBillAlt, faCalendarAlt, faRuler, faBed, faCoins, faHomeAlt, faBuilding, faPlusCircle, faMinusCircle, faCalculator, faSearchDollar, faScaleUnbalanced, faArrowUp, faArrowDown, faCodeCompare, faArrowUpWideShort, faArrowDownWideShort
);

JavascriptTimeAgo.addLocale(en);

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <AuthContainer />
        </Authenticator.Provider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
