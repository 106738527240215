import React, { useState, useEffect, useCallback, useRef } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import Debug from "debug";
import ReactMapboxGL from "react-mapbox-gl";
import mapboxgl, { GeolocateControl, NavigationControl } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./map.styles.css";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  setCircleCenter as setCircleCenterAction,
  setCircleRadius as setCircleRadiusAction,
  toggleCircle as toggleCircleAction,
  displayCircle as displayCircleAction,
} from "../Search/circleSlice";
import { setPopupInfo as setMapPopupInfoAction } from "data/slices/mapSlice";
import { getMapPopupInfo, getSearchSelector } from "data/selectors";

import {
  fetchLandworth,
  fetchLocationHistoryPrice,
  fetchPpsfChecker,
} from "../../graphql/functions";

import Basemap from "./Basemap";
import Heatmap from "./Heatmap";
// import MapControl from "./MapControl";
import MapLegend from "./MapLegend";
import MapProperties from "./MapProperties";
import MapPopup from "./MapPopup";
import MapCircle from "./MapCircle";
import PostcodeOutlines from "./Outlines/PostcodeOutlines";

import useFilteredSearchResults from "../Search/useFilteredSearchResults";
import useSelectedResultsProperty from "../Search/Data/useSelectedResultsProperty";
import useSelectedMapProperty from "../Search/Data/useSelectedMapProperty";
import useSearchView from "../../data/hooks/useSearchView";
import MapSettings from "../MapSettings";
import MapSettingsButton from "./MapSettingsButton";
import SearchViewControlButton from "components/SearchViewControlButton";
import BoroughOutlines from "./Outlines/BoroughOutlines";

// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const debug = Debug("lw:map");

const bearing = [0];

const getCircleDetails = createSelector(
  ({ circle }) => circle,
  (circle) => circle
);
const defaultMapCenter = [-1.9, 52.8];
const defaultMapZoomInLevel = 14.1;

const setPopupLandworth = async (
  setPopupLandworthData,
  lngLat,
  propertyType
) => {
  const landworthData = await fetchLandworth(
    lngLat.lat,
    lngLat.lng,
    propertyType,
    process.env.REACT_APP_CURRENT_DATE
  );
  setPopupLandworthData(landworthData);
};

const setPopupHistory = async (setPopupHistoryData, lngLat) => {
  const data = await fetchLocationHistoryPrice(
    {
      mode: "latlng2",
      date: `${process.env.REACT_APP_CURRENT_DATE}`,
      locData: `{
              "lat": "${lngLat.lat}",
              "long": "${lngLat.lng}"
            }`,
      monthsToCheck: 1,
    }
    //   mode,
    //   locData,
    //   date: startDate,
    //   monthsToCheck: yearsToCheck * 6 + 1,
    // }
    // lngLat.lat,
    // lngLat.lng,
    // process.env.REACT_APP_CURRENT_DATE,
    // 1
  );
  setPopupHistoryData(data);
};

const params = new URLSearchParams(window.location.search);
const mode = params.get("mode");
const allowScrollZoom = mode !== "noheader";

const ReactMap = ReactMapboxGL({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  maxZoom: 19,
  antialias: true,
  attributionControl: false,
  bearingSnap: 10,
  scrollZoom: allowScrollZoom,
});

const Map = ({ isLoggedIn, submitForm, isSubmitting, history }) => {
  const initialZoom =
    window.innerHeight >= 900
      ? 6
      : window.innerHeight > 600
      ? 5.1
      : window.innerHeight > 300
      ? 4.8
      : 4.6;

  const [selectedBasemap, setSelectedBasemap] = useState(
    isLoggedIn ? "hybrid" : "osm"
  );
  const [selectedHeatmap, setSelectedHeatmap] = useState("landworth");
  const [selectedPerspective, setSelectedPerspective] = useState("3D");
  const [showPostcodeDistricts, setShowPostcodeDistricts] = useState(false);
  const [showBoroughs, setShowBoroughs] = useState(false);
  const [heatmapRange, setHeatmapRange] = useState(null);
  const [popupLandworthData, setPopupLandworthData] = useState(null);
  const [popupHistoryData, setPopupHistoryData] = useState(null);
  const [pitch, setPitch] = useState([45]);
  const [zoom] = useState([initialZoom]);
  const [initialZoomComplete, setInitialZoomComplete] = useState(false);
  const [isFlying, setIsFlying] = useState(false);
  const mapRef = useRef();
  const [showFullPopup, setShowFullPopup] = useState(true);

  const dispatch = useDispatch();

  const circle = useSelector(getCircleDetails);

  const popupInfo = JSON.parse(useSelector(getMapPopupInfo));
  const setPopupInfo = useCallback(
    (popupInfo) => {
      dispatch(
        setMapPopupInfoAction({
          popupInfo: JSON.stringify(popupInfo),
        })
      );
    },
    [dispatch]
  );

  const match = useRouteMatch("/search/:searchId") || { params: {} };
  const search = useSelector((state) =>
    getSearchSelector(state, match.params.searchId)
  );
  const [properties] = useFilteredSearchResults(match.params.searchId);
  const [selected] = useSelectedMapProperty();
  const [, select] = useSelectedResultsProperty();

  const [propertyTypeToggle, setPropertyTypeToggle] = useState("all");
  const updatePropertyTypeToggle = (newType) => {
    window.analytics.track("Toggle PPSF Indicator", {
      propertyType: newType,
    });
    setPropertyTypeToggle(newType);
    setPopupLandworthData(null);
    // setPopupHistoryData(null);
    setPopupLandworth(
      setPopupLandworthData,
      popupInfo.geometry.coordinates,
      newType
    );
  };

  const setCircleCenter = useCallback(
    (center, didMapTriggerChange) =>
      dispatch(
        setCircleCenterAction({
          ...center,
          didMapTriggerChange,
        })
      ),
    [dispatch]
  );

  const setCircleRadius = useCallback(
    (radius) => dispatch(setCircleRadiusAction(radius)),
    [dispatch]
  );

  const toggleCircle = useCallback(
    () => dispatch(toggleCircleAction()),
    [dispatch]
  );
  const displayCircle = useCallback(
    () => dispatch(displayCircleAction()),
    [dispatch]
  );

  const getProp = useCallback(
    (id) => properties.find((prop) => prop.propertyID === id),
    [properties]
  );

  // look in the search results for the selected property
  useEffect(() => {
    if (selected) {
      debug(`Property ID ${selected}`);
      const property = getProp(selected);
      if (mapRef?.current && property) {
        const bounds = mapRef.current.getBounds();
        const zoom = mapRef.current.getZoom();
        // fly to the active property if z<10 or out of mapbounds
        if (zoom < 10 || !bounds.contains([property.lng, property.lat])) {
          mapRef?.current?.flyTo({
            center: [property.lng, property.lat],
            ...(zoom < 10 && { zoom: 14 }),
            speed: 1,
          });
        }
        setShowFullPopup(false);
        setPopupInfo({
          geometry: {
            coordinates: [property.lng, property.lat],
          },
          properties: property,
        });
      }
    } else {
      setPopupInfo(null);
    }
  }, [selected, setPopupInfo]);

  const displayLandworthPopup = (lngLat) => {
    setPopupInfo({
      geometry: {
        coordinates: lngLat,
      },
      properties: {
        landworth: `?`,
        isLandworthPopup: true,
      },
    });
    setPopupLandworth(setPopupLandworthData, lngLat, propertyTypeToggle);
    // setPopupHistory(setPopupHistoryData, lngLat);
  };

  // update the pitch when the perspective is changed
  useEffect(() => {
    const newPitch = selectedPerspective === "2D" ? 0 : 45;
    debug(`Updating map pitch to ${newPitch}`);
    setPitch([newPitch]);
  }, [selectedPerspective]);

  const mapFlyTo = (center, zoom) => {
    mapRef?.current?.flyTo({
      center,
      zoom,
      pitch: 0,
      speed: 2,
      curve: 0.5,
    });
  };

  // zoom on circle change (if externally triggered)
  useEffect(() => {
    if (mapRef.current && circle.didMapTriggerChange) {
      const newZoomLevel = initialZoomComplete
        ? mapRef.current.getZoom()
        : defaultMapZoomInLevel;
      debug("Zoom to location");
      setPopupInfo(null);
      mapFlyTo(circle.center, newZoomLevel);
      setIsFlying(true);
    }
  }, [circle.center, circle.didMapTriggerChange]);

  // zooms to z15 when postcode districts turned on
  useEffect(() => {
    if (showPostcodeDistricts && mapRef.current?.getZoom() < 9) {
      mapFlyTo(circle.center, 11);
      // setIsFlying(true);
    }
  }, [showPostcodeDistricts]);

  // Monitors for changes in isFlying then connects a moveend event once to add LW popup (and set perspective/toggleCircle first time)
  useEffect(() => {
    if (mapRef.current && isFlying) {
      if (!initialZoomComplete) {
        debug("Adding MOVEEND once to listen for end of first change...");
        mapRef.current.once("moveend", () => {
          setSelectedPerspective("2D");
          displayCircle();
          const lngLat = {
            lat: circle.center[1],
            lng: circle.center[0],
          };
          displayLandworthPopup(lngLat);
          setInitialZoomComplete(true);
          setIsFlying(false);
        });
      } else {
        const lngLat = {
          lat: circle.center[1],
          lng: circle.center[0],
        };
        displayCircle();
        // if (!popupInfo) displayLandworthPopup(lngLat);
        setIsFlying(false);
      }
    }
  }, [isFlying, initialZoomComplete, circle.center]);

  // Fixes the map size when the search view mode is changed by user
  const [searchView] = useSearchView();
  useEffect(() => {
    if (mapRef.current) {
      setTimeout(() => mapRef.current.resize(), 1);
    }
  }, [searchView]);

  const onMapLoad = (map) => {
    mapRef.current = map;
    map.resize();
    map.addControl(new NavigationControl(), "bottom-right");

    map.addControl(
      new GeolocateControl({
        label: "Find Me",
        positionOptions: { enableHighAccuracy: true },
        trackUserLocation: true,
      }),
      "bottom-right"
    );

    map.on("mouseenter", "clusters", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseenter", "search-results-point", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "clusters", () => {
      map.getCanvas().style.cursor = "";
    });
    map.on("mouseleave", "search-results-point", () => {
      map.getCanvas().style.cursor = "";
    });
  };

  const onClick = (map, event) => {
    setPopupInfo(null);

    const clusterFeature = map.queryRenderedFeatures(event.point, {
      layers: ["clusters"],
    });
    const propertyFeature = map.queryRenderedFeatures(event.point, {
      layers: ["search-results-point"],
    });
    const lngLat =
      clusterFeature.length === 0 && propertyFeature.length === 0
        ? event.lngLat
        : null;
    const mapboxSource = map?.getSource("property-results");

    if (clusterFeature.length > 0) {
      const clusterId = clusterFeature[0].id;
      const clusterGeometry = clusterFeature[0].geometry;
      debug(
        `Clicked on cluster ID ${clusterId} with ${clusterFeature[0].properties.point_count} points: ${clusterGeometry}`
      );

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }
        if (zoom < 17) {
          !circle.showCircle && displayCircle();
          mapFlyTo(
            [clusterGeometry.coordinates[0], clusterGeometry.coordinates[1]],
            zoom
          );
          window.analytics.track("Click Property Cluster", {
            action: "Zoom",
          });
        } else {
          mapboxSource.getClusterLeaves(
            clusterId,
            Infinity,
            0,
            (err, children) => {
              debug(`Displaying cluster of ${children?.length} properties...`);
              setPopupInfo({
                geometry: {
                  coordinates: clusterGeometry.coordinates,
                },
                properties: {
                  children: children,
                },
              });
              setShowFullPopup(true);
            }
          );
          window.analytics.track("Click Property Cluster", {
            action: "Display Properties",
          });
        }
      });
    }

    if (propertyFeature.length > 0) {
      const { propertyID, propertySource } = propertyFeature[0].properties;
      debug(`Clicked on marker for Property ID = ${propertyID}`);
      window.analytics.track("Click Property", {
        propertyID,
        propertySource,
      });
      setShowFullPopup(true);
      setPopupInfo(propertyFeature[0]);
    }

    if (lngLat) {
      debug(`Clicked on Map at lngLat = ${lngLat}`);
      window.analytics.track("Click Map", { lngLat: lngLat });
      setPopupLandworthData(null);
      setPopupHistoryData(null);

      // console.log(propertyTypeToggle) // BUG: For some reason this doesn't return latest propertyTypeToggle so can't be passed to displayLandworthPopup. Workaround is line below to reset propertyType state with each click.
      setPropertyTypeToggle("all");

      displayLandworthPopup(lngLat);
    }
  };

  const onDblClick = (map, event) => {
    setPopupInfo(null);
  };

  const findMe = () => {
    debug("Attempting to find user...");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        setCircleCenter({
          lat,
          lng,
          didMapTriggerChange: false,
        });
        mapFlyTo(
          {
            lat,
            lng,
          },
          13.5
        );
      });
    }
  };

  const updateDynamicHeatmap = async (center, circleRadius) => {
    const rangeData = await fetchPpsfChecker(
      { lng: center[0], lat: center[1] },
      circleRadius
    );
    const data = rangeData && JSON.parse(rangeData);
    const newRange = [data?.min, data?.avg, data?.max];
    setHeatmapRange(newRange);
  };

  // Update the heatmap dynamically depending if circle is on/off
  useEffect(() => {
    if (circle.visible) {
      updateDynamicHeatmap(circle.center, circle.radius);
    } else {
      setHeatmapRange(null);
    }
  }, [circle.visible, circle.center, circle.radius]);

  return (
    <div className={"map"}>
      <ReactMap
        center={defaultMapCenter}
        zoom={zoom}
        bearing={bearing}
        pitch={pitch}
        maxBounds={[
          [-14.8544921875, 46.82380908513249], // Southwest coordinates
          [11.021484375, 58.712097173322924], // Northeast coordinates
        ]}
        style="mapbox://styles/prchapman/cklcsfd9u0zzc17miwfw6t70p"
        containerStyle={{
          height: "100%",
          width: "100%",
        }}
        onClick={onClick}
        onDblClick={onDblClick}
        onStyleLoad={onMapLoad}
        flyToOptions={{ speed: 2, curve: 0.5, essential: true }}
      >
        <Basemap selectedBasemap={selectedBasemap} />

        <Heatmap
          selectedHeatmap={selectedHeatmap}
          selectedPerspective={selectedPerspective}
          heatmapRange={heatmapRange}
        />

        {properties && (
          <MapProperties
            properties={properties}
            comparableProperties={search.comparableProperties}
          />
        )}

        {popupInfo && (
          <MapPopup
            popupInfo={popupInfo}
            close={() => setPopupInfo(null)}
            popupData={{
              popupLandworthData,
              popupHistoryData,
            }}
            setCircleCenter={setCircleCenter}
            select={select}
            propertyTypeToggle={propertyTypeToggle}
            updatePropertyTypeToggle={updatePropertyTypeToggle}
            showFullPopup={showFullPopup}
            setShowFullPopup={setShowFullPopup}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            isLoggedIn={isLoggedIn}
          />
        )}

        {circle.visible && circle.center && (
          <MapCircle
            circleCenter={circle.center}
            circleRadius={circle.radius}
            setCircleCenter={setCircleCenter}
            setCircleRadius={setCircleRadius}
            mapRef={mapRef}
          />
        )}

        {showPostcodeDistricts && <PostcodeOutlines />}
        {showBoroughs && <BoroughOutlines />}
      </ReactMap>

      {isLoggedIn && <MapSettingsButton />}
      {isLoggedIn && history.location.pathname.startsWith("/search") && (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            paddingTop: "5px",
            zIndex: "999",
          }}
        >
          <SearchViewControlButton
            variant={"map_button"}
            totalResults={search?.propertyIds?.length}
            search={search}
          />
        </div>
      )}
      <MapSettings
        selectedHeatmap={selectedHeatmap}
        setSelectedHeatmap={setSelectedHeatmap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        selectedPerspective={selectedPerspective}
        setSelectedPerspective={setSelectedPerspective}
        findMe={findMe}
        setShowCircle={toggleCircle}
        showCircle={circle.visible}
        setCircleRadius={setCircleRadius}
        showPostcodeDistricts={showPostcodeDistricts}
        setShowPostcodeDistricts={setShowPostcodeDistricts}
        showBoroughs={showBoroughs}
        setShowBoroughs={setShowBoroughs}
      />
      <MapLegend
        selectedHeatmap={selectedHeatmap}
        setSelectedHeatmap={setSelectedHeatmap}
        selectedPerspective={selectedPerspective}
        showCircle={circle.visible}
        heatmapRange={heatmapRange}
        setShowCircle={toggleCircle}
      />
    </div>
  );
};

export default withRouter(Map);
