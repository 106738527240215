import React, { useEffect, useRef, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  // getDetails,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import useScript from "../../../useScript";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Debug from "debug";
import "./styles.css";

import { useSelector, useDispatch } from "react-redux";
import useLocation from "pages/Search/useLocation";
import { getCircleDetails } from "data/selectors";

const debug = Debug("lw:placesAutocomplete");

const KEY = process.env.REACT_APP_GOOGLE_KEY;

let cachedVal = "";
const acceptedKeys = [38, 40, 13, 27];

const trimUK = (string) => {
  return string.substr(0, string.lastIndexOf(", UK"));
};

const PlacesAutocomplete = ({
  setSelectedLocation,
  placeholder,
  addNameForLocation,
  defaultValue,
}) => {
  const [currIndex, setCurrIndex] = useState(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "initMap",
    debounce: 250,
    requestOptions: { componentRestrictions: { country: "uk" } },
    defaultValue: defaultValue,
  });

  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${KEY}&libraries=places&callback=initMap`
  );

  const hasSuggestions = status === "OK";

  const dismissSuggestions = () => {
    setCurrIndex(null);
    clearSuggestions();
  };

  const ref = useRef();
  useOnclickOutside(ref, dismissSuggestions);

  const handleInput = (e) => {
    setValue(e.target.value);
    cachedVal = e.target.value;
  };

  const handleSelect = ({ description }) => async () => {
    const selected = trimUK(description);
    setValue(selected, false);
    dismissSuggestions();

    try {
      const results = await getGeocode({ address: description });
      console.log(results);
      const { lat, lng } = await getLatLng(results[0]);
      const postcodeComponent = results[0].address_components.filter((comp) =>
        comp.types.includes("postal_code")
      );
      const postcode = postcodeComponent[0]?.long_name;
      console.log(postcode);
      debug("📍 Location coordinates: ", { lat, lng });
      debug(`📍 ${results[0].formatted_address}`);
      window.analytics.track("Location Search", {
        selectedAddress: results[0].formatted_address,
        types: results[0].types,
      });
      addNameForLocation(lat, lng, selected, postcode);
      setSelectedLocation({ lat, lng });
    } catch (error) {
      debug("😱 Error: ", error);
    }
  };

  const handleEnter = (idx) => () => {
    setCurrIndex(idx);
  };

  const handleLeave = () => {
    setCurrIndex(null);
  };

  const handleKeyDown = (e) => {
    let shouldUpdateIndexes = false;
    let nextIndex;

    if (hasSuggestions && acceptedKeys.includes(e.keyCode)) {
      switch (e.keyCode) {
        case 27: // Handle Escape key
          dismissSuggestions();
          break;
        case 38: // Handle down arrow key
          e.preventDefault();
          nextIndex = currIndex !== null ? currIndex : data.length;
          nextIndex = nextIndex > 0 ? nextIndex - 1 : null;
          shouldUpdateIndexes = true;
          break;
        case 13: // Handle Enter key
          if (currIndex === null) {
            // Handle Escape key when nothing selected
            nextIndex = 0;
            shouldUpdateIndexes = true;
          } else {
            // Handle Escape key when something selected
            dismissSuggestions();
            handleSelect(data[currIndex])();
          }
          break;
        case 40: // Handle up arrow key
          nextIndex = currIndex !== null ? currIndex : -1;
          nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
          shouldUpdateIndexes = true;
          break;
        default:
          debug("Reached an unknown case! Keyboard code = " + e.keyCode);
      }
    }

    if (shouldUpdateIndexes) {
      setCurrIndex(nextIndex);
      setValue(
        data[nextIndex] ? trimUK(data[nextIndex].description) : cachedVal,
        false
      );
    }
  };

  const _renderSuggestionIcon = (types) => {
    if (
      types.includes("subway_station") ||
      types.includes("train_station") ||
      types.includes("transit_station")
    ) {
      return <FontAwesomeIcon icon={["far", "subway"]} />;
    } else if (types.includes("airport")) {
      return <FontAwesomeIcon icon={["far", "plane-departure"]} />;
    } else if (types.includes("supermarket")) {
      return <FontAwesomeIcon icon={["far", "shopping-cart"]} />;
    } else if (types.includes("hospital")) {
      return <FontAwesomeIcon icon={["far", "hospital"]} />;
    } else {
      return <FontAwesomeIcon icon={["far", "map-marker-alt"]} />;
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion, idx) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
        types,
      } = suggestion;

      const trimmedSecondaryText = trimUK(secondary_text);
      return (
        <li
          key={place_id}
          id={`ex-list-item-${idx}`}
          className={
            idx === currIndex
              ? "placeholder-listItem placeholder-listItemDarken"
              : "placeholder-listItem"
          }
          onClick={handleSelect(suggestion)}
          onMouseEnter={handleEnter(idx)}
          role="option"
          aria-selected={idx === currIndex}
        >
          <div style={{ display: "flex" }}>
            <div style={{ minWidth: "35px", textAlign: "center" }}>
              {_renderSuggestionIcon(types)}
            </div>
            <div>
              <strong>{main_text}</strong>{" "}
              <small className="placeholder-subtext">
                {trimmedSecondaryText}
              </small>
            </div>
          </div>
        </li>
      );
    });

  if (loaded) {
    return (
      <div className="placeholder-container">
        <div
          className="placeholder-autocomplete"
          ref={ref}
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          aria-owns="ex-list-box"
          aria-haspopup="listbox"
          aria-expanded={hasSuggestions}
        >
          <input
            className={
              hasSuggestions
                ? "placeholder-input placeholder-inputNoBottomRadius"
                : "placeholder-input"
            }
            value={value}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
            disabled={!ready}
            placeholder={placeholder}
            type="text"
            aria-autocomplete="list"
            aria-controls="ex-list-box"
            aria-activedescendant={
              currIndex !== null &&
              currIndex >= 0 &&
              currIndex <= data.length - 1
                ? `ex-list-item-${currIndex}`
                : null
            }
          />
          {hasSuggestions && (
            <ul
              id="ex-list-box"
              className="placeholder-listBox"
              onMouseLeave={handleLeave}
              role="listbox"
            >
              {renderSuggestions()}
            </ul>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default PlacesAutocomplete;
