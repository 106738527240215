import { useSelector } from "react-redux";

import useDeviceWidth from "data/hooks/useDeviceWidth";
import useSearchView from "data/hooks/useSearchView";

import SearchResultsCommandBar from "./SearchResultsCommandBar";
import SearchStatusOneLine from "components/SearchStatusOneLine";
import SearchViewControlButton from "components/SearchViewControlButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useSearchFilter from "./useSearchFilter";
import { getSearchSelector } from "../../data/selectors";

import DownloadSearchResultsButton from "components/DownloadSearchResultsButton";

const SearchResultsHeader = ({
  searchFinished,
  cacheFinished,
  searchId,
  // searchName,
  searchSpec,
  properties,
  propertyIds,
}) => {
  const search = useSelector((state) => getSearchSelector(state, searchId));
  const showTodayPrice = search.showTodayPrice;

  const [isSmallWidth, isMediumWidth] = useDeviceWidth();
  const [searchView, setSearchView] = useSearchView();

  const searchType = searchSpec?.search_plan
    ? "planning"
    : searchSpec?.search_type === "rent"
    ? "rental"
    : searchSpec?.search_type === "sale"
    ? !!searchSpec?.search_landreg &&
      !searchSpec?.search_rm &&
      !searchSpec?.search_zoopla
      ? "sold"
      : !searchSpec?.search_landreg &&
        (!!searchSpec?.search_rm || !!searchSpec?.search_zoopla)
      ? "forsale"
      : "sold" // If both search_landreg and search_rm are TRUE, then set to "sold" so that showTodayPrice triggers when toggle is TRUE
    : "all";

  return (
    <div className="search-results-header">
      <div
        className="search-results-header-title flex-center-center"
        style={{
          textAlign: "center",
          justifyContent: "space-between",
          alignItems: "flex-start",
          ...(searchView !== "full_list" && { maxWidth: "100%" }),
        }}
      >
        <div
          style={{
            position: "relative",
            top: "0",
            left: "0",
            zIndex: "999",
            float: "left",
            minHeight: "33px",
          }}
        >
          <SearchViewControlButton variant={"list_button"} />
        </div>
        <div
          className="flex-center-center"
          style={{
            minHeight: "32px",
            fontSize: isSmallWidth ? "18px" : "20px",
          }}
        >
          <SearchStatusOneLine
            searchFinished={searchFinished}
            cacheFinished={cacheFinished}
            searchId={searchId}
            searchType={searchType}
            searchResultCount={propertyIds.length}
            properties={properties}
            showTodayPrice={showTodayPrice}
            isSmallWidth={isSmallWidth}
          />
        </div>
        <div
          style={{
            zIndex: "999",
            float: "right",
          }}
        >
          {/* <DownloadSearchResultsButton
            searchId={searchId}
            search={search}
            properties={properties}
            searchFinished={searchFinished}
            showTodayPrice={showTodayPrice}
            isMediumWidth={isMediumWidth}
          /> */}
        </div>
      </div>

      <SearchResultsCommandBar
        search={search}
        searchId={searchId}
        searchSpec={searchSpec}
        properties={properties}
        searchFinished={searchFinished}
        cacheFinished={cacheFinished}
      />
    </div>
  );
};

export default SearchResultsHeader;
