import { extractMatchAddress } from "services/propertyHelpers";

export const formatMoney = (
  value,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    if (value < 100001) {
      return formatMoneyFull(value, decimalCount, decimal, thousands);
    } else if (value < 1000000) {
      return `${value / 1000}k`;
    } else if (value < 1000001) {
      return formatMoneyFull(value, decimalCount, decimal, thousands);
    } else {
      return `${Math.round(value / 10000) / 100}MM`;
    }
  } catch (e) {
    console.log(e);
  }
};

export const formatMoneyRounded = (
  value,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    if (value < 10001) {
      return formatMoneyFull(value, decimalCount, decimal, thousands);
    } else if (value < 1000000) {
      return `${Math.round(value / 1000)}k`;
    } else {
      return `${Math.round(value / 10000) / 100}m`;
    }
  } catch (e) {
    console.log(e);
  }
};

export const formatMoneyFull = (
  value,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = value < 0 ? "-" : "";

    let i = parseInt(
      (value = Math.abs(Number(value) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(value - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const formatDateDDMMYY = (date) => {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "numeric",
  };
  const dateObj = new Date(date);

  return dateObj.toLocaleDateString("en-GB", options);
};
export const formatDateDDMMMYYY = (date) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const dateObj = new Date(date);

  return dateObj.toLocaleDateString("en-GB", options);
};
export const formatDateDDMMYYY = (date) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString("en-GB", options);
};

export const formatDateMMMYYYY = (date) => {
  const options = {
    year: "numeric",
    month: "long",
  };
  const dateObj = new Date(date);

  return dateObj.toLocaleDateString("en-GB", options);
};

export const formatMonthsAsYears = (months) => {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years > 0 && remainingMonths === 0) {
    return `${years} year${years > 1 ? "s" : ""}`;
  } else if (years > 0 && remainingMonths === 6) {
    return `${years}½ years`;
  } else {
    return `${years > 0 ? `${years} years` : ""} ${remainingMonths} months`;
  }
};

export const getLandworthScoreColor = (score) => {
  if (!score) return "#555";

  if (score > 60) {
    return "rgb(31, 18, 67)";
  } else if (score > 20 && score <= 60) {
    return "rgb(51, 88, 133)";
  } else if (score > -20 && score <= 20) {
    return "rgb(53, 164, 206)";
  } else if (score > -60 && score <= -20) {
    return "rgb(63, 152, 120)";
  } else if (score <= -60) {
    return "rgb(23, 141, 23)";
  }
};

export const getLandworthScoreRating = (score) => {
  if (!score) return;

  if (score > 60) {
    return "Premium Price";
  } else if (score > 20 && score <= 60) {
    return "Expensive";
  } else if (score > -20 && score <= 20) {
    return "Market Average";
  } else if (score > -60 && score <= -20) {
    return "Cheap";
  } else if (score <= -60) {
    return "Great Deal";
  }
};

export const capitaliseFirstLetter = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
};
export const capitaliseFirstLetterEveryWord = (str) => {
  let splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const cleanOSPlacename = (LPI) => {
  let displayName = "";

  displayName += LPI.SAO_TEXT
    ? `${capitaliseFirstLetterEveryWord(LPI.SAO_TEXT)}, `
    : "";
  displayName += LPI.SAO_START_NUMBER
    ? `${capitaliseFirstLetterEveryWord(LPI.SAO_START_NUMBER)}`
    : "";
  displayName += LPI.SAO_START_SUFFIX ?? "";
  displayName += LPI.SAO_START_NUMBER || LPI.SAO_START_SUFFIX ? " " : "";
  displayName += LPI.SAO_END_NUMBER ? `-${LPI.SAO_END_NUMBER}` : "";
  displayName += LPI.SAO_END_SUFFIX ? `${LPI.SAO_END_SUFFIX}` : "";

  displayName += LPI.PAO_TEXT
    ? capitaliseFirstLetterEveryWord(LPI.PAO_TEXT) + ", "
    : "";
  displayName += LPI.PAO_START_NUMBER ?? "";
  displayName += LPI.PAO_START_SUFFIX ?? "";
  displayName += LPI.PAO_END_NUMBER ? `-${LPI.PAO_END_NUMBER}` : "";
  displayName += LPI.PAO_END_SUFFIX ? `${LPI.PAO_END_SUFFIX}` : "";

  displayName += LPI.STREET_DESCRIPTION
    ? ` ${capitaliseFirstLetterEveryWord(LPI.STREET_DESCRIPTION)}`
    : "";

  displayName += LPI.TOWN_NAME
    ? `, ${capitaliseFirstLetterEveryWord(LPI.TOWN_NAME)}`
    : "";
  displayName +=
    LPI.ADMINISTRATIVE_AREA && LPI.TOWN_NAME !== LPI.ADMINISTRATIVE_AREA
      ? `, ${capitaliseFirstLetterEveryWord(LPI.ADMINISTRATIVE_AREA)}`
      : ``;
  displayName += `, ${LPI.POSTCODE_LOCATOR}`;

  // console.log(displayName);
  return displayName;
};

export const lpiClassificationToPTP = (code) => {
  return code === "RD06"
    ? "flat"
    : code === "RD04"
    ? "terraced"
    : code === "RD03"
    ? "semi"
    : code === "RD02"
    ? "detached"
    : "UNKNOWN";
};

export const generateMatchAddress = (LPI) => {
  return extractMatchAddress(
    `${LPI.SAO_TEXT || LPI.SAO_START_NUMBER || ""}${
      LPI.SAO_START_SUFFIX || ""
    }${LPI.PAO_TEXT || ""}${LPI.PAO_START_NUMBER || ""}${
      LPI.PAO_END_NUMBER || ""
    }${LPI.PAO_START_SUFFIX || ""}${LPI.STREET_DESCRIPTION}`
  );
};

export const decimalToUnicodeFraction = (decimal) => {
  const fractions = [
    { value: 1 / 8, unicode: "\u215B" },
    { value: 1 / 4, unicode: "\u00BC" },
    { value: 1 / 2, unicode: "\u00BD" },
    { value: 3 / 4, unicode: "\u00BE" },
  ];

  let result = "";

  if (decimal >= 1) {
    result += Math.floor(decimal);
    decimal -= Math.floor(decimal);
  }

  let exactMatchFound = false;
  for (let i = 0; i < fractions.length; i++) {
    const fraction = fractions[i];
    if (decimal === fraction.value) {
      result += fraction.unicode;
      exactMatchFound = true;
      break;
    }
  }

  if (!exactMatchFound && decimal !== 0) {
    result += decimal.toFixed(2).slice(1);
  }

  return result;
};
