import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const NotEnoughCredits = (props) => {
  const { actionType } = props;
  const history = useHistory();

  const handleViewSubscriptions = () => {
    window.analytics.track("Click Buy more credits...", {
      source: history.location.pathname,
    });
    history.push("/account");
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <span
          style={{
            fontSize: "1.2em",
            color: "red",
          }}
        >
          You don't have enough credits left for this {actionType}
        </span>
        <br />
        <br />
      </div>

      <div style={{ fontSize: "1.2em" }}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleViewSubscriptions}
        >
          Buy more credits...
        </Button>
      </div>
    </div>
  );
};

export default NotEnoughCredits;
