import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_SORT = "cheapest";
const DEFAULT_SEARCH_FILTER = {
  includeLandreg: true,
  includeRightmove: true,
  includeZoopla: true,
  includeUnknownBed: true,
  include0Bed: true,
  include1Bed: true,
  include2Bed: true,
  include3Bed: true,
  include4PlusBed: true,
  includeSharedOwnership: true,
  includeSold: true,
  includeForSale: true,
  includeUnderOffer: true,
  keyword: "",
  tags: [],
  includeTags: [],
  excludeTags: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    sort: DEFAULT_SORT,
    filterKeyword: "",
    searchFilter: DEFAULT_SEARCH_FILTER,
    showTodayPrice: false,
  },
  reducers: {
    setFilterKeyword(state, action) {
      return {
        ...state,
        filterKeyword: action.payload,
      };
    },
    setSearchFilter(state, action) {
      return {
        ...state,
        searchFilter: { ...state.searchFilter, ...action.payload },
      };
    },
    resetSearchFilter(state) {
      return {
        ...state,
        searchFilter: DEFAULT_SEARCH_FILTER,
      };
    },
    setShowTodayPrice(state, action) {
      return {
        ...state,
        showTodayPrice: action.payload,
      };
    },
  },
});

export const {
  setFilterKeyword,
  setSearchFilter,
  resetSearchFilter,
  setShowTodayPrice,
} = settingsSlice.actions;

export default settingsSlice.reducer;
