import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const GoogleStreetViewThumbnail = ({ property, height, lat, lng, width }) => {
  const propertyUrl = `https://maps.googleapis.com/maps/api/streetview?key=${GOOGLE_KEY}&size=300x216&fov=65&location=${
    lat || property.lat
  },${lng || property.lng}`;
  const propertyLinkUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${
    lat || property.lat
  },${lng || property.lng}`;
  return (
    <a href={propertyLinkUrl} target="_blank" rel="noopener noreferrer">
      <LazyLoadImage
        src={propertyUrl}
        width={width}
        height={height}
        style={{ objectFit: "cover" }}
      ></LazyLoadImage>
    </a>
  );
};

export default GoogleStreetViewThumbnail;
