import React, { useState } from "react";

import { useField, Field } from "formik";
import TextField from "@material-ui/core/TextField";

const KeywordField = (props) => {
  const { placeholder } = props;
  const [field, , helpers] = useField("keywords");
  const handleChange = (e) => {
    helpers.setValue(e.target.value);
  };

  return (
    <div className={"search-form-item"}>
      <div className={"search-form-label"}>Keywords</div>
      <Field
        component={TextField}
        value={field.value}
        name="keywords"
        id="keywords"
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
};

export default KeywordField;
