import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Rating from "@material-ui/lab/Rating";

import { formatDateDDMMMYYY, formatDateDDMMYY } from "services/formatting";

import { setFavouriteSearch, setSearchCustomName } from "graphql/functions";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import EditableCell from "./EditableCell";

import LogoLR from "assets/LogoLR15px.png";
import LogoRM from "assets/LogoRM15px.png";
import LogoZoopla from "assets/LogoZoopla15px.png";
import LogoPlan from "assets/LogoBuilding.png";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    maxWidth: 1280,
    padding: "0 20px",
    "@media(max-width: 768px)": { padding: "0 3px" },
  },
  body: {},
  cell: {
    // backgroundColor: "red",
    padding: "8px",
    fontSize: "15px",
    minWidth: "130px",
    transition: (width) => `all 0.2s ease-in-out`,
    "@media(max-width: 768px)": {
      minWidth: "40px",
      fontSize: "12px",
      padding: "4px",
    },
    textAlign: "center",
  },
  addressCell: {
    padding: "8px",
    "@media(max-width: 768px)": {
      padding: "4px",
    },
    width: "100%",
    whiteSpace: "normal",
    wordWrap: "break-word",
    minWidth: "100px",
  },
  actionCell: {
    padding: "3px 2px 0px",
    // "@media(max-width: 768px)": {
    //   padding: "3px 2px 0px",
    // },
    width: "100%",
    whiteSpace: "normal",
    wordWrap: "break-word",
    minWidth: "30px",
  },
  favouriteStar: {
    fontSize: "28px",
    "@media(max-width: 768px)": {
      fontSize: "20px",
    },
  },
  row: {
    "&:hover $editButton": {
      visibility: "visible",
    },
  },
  editButton: {
    visibility: "hidden",
  },
  smallIcon: {
    fontSize: "2.5em",
    height: "0.3em",
    width: "0.3em",
  },
});

const SearchHistoryTable = ({ searchHistory, loadURL }) => {
  const classes = useStyles();
  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.n780}px)`);

  const [renderedSearchHistory, setRenderedSearchHistory] =
    useState(searchHistory);

  const setFavouriteHandler = (searchID, isFavourite) => {
    const updatedSearch = {
      ...renderedSearchHistory.find((item) => item.searchID === searchID),
      isFavourite,
    };
    const newArray = renderedSearchHistory.map((item) =>
      item.searchID === searchID ? updatedSearch : item
    );

    setRenderedSearchHistory(newArray);
    setFavouriteSearch(searchID, isFavourite);
  };

  const SearchFavouriteStar = ({ item, setFavouriteHandler }) => {
    return (
      <Rating
        name={`favourite-star-${item.searchID}`}
        value={item.isFavourite ? 1 : 0}
        onClick={(event) => {
          setFavouriteHandler(item.searchID, !item.isFavourite);
          event.stopPropagation();
          event.preventDefault();
        }}
        max={1}
        size="large"
        className={classes.favouriteStar}
      />
    );
  };

  const tableDefinitions = {
    search: [
      "Search Location",
      !isNarrow ? `Sources` : <FontAwesomeIcon icon={regular("database")} />,
      !isNarrow ? (
        `Total Results`
      ) : (
        <FontAwesomeIcon icon={regular("search")} />
      ),
      !isNarrow ? `Comparables` : <FontAwesomeIcon icon={regular("star")} />,
      !isNarrow ? `Date Run` : <FontAwesomeIcon icon={regular("clock")} />,
    ],
  };

  const handleEdit = (index, value) => {
    const searchID = renderedSearchHistory[index].searchID;
    window.analytics.track("Search History - Save SearchCustomName", {
      searchID,
      searchCustomName: value,
    });

    const updatedSearch = {
      ...renderedSearchHistory.find((item) => item.searchID === searchID),
      searchCustomName: value,
    };
    const newArray = renderedSearchHistory.map((item) =>
      item.searchID === searchID ? updatedSearch : item
    );
    setRenderedSearchHistory(newArray);

    setSearchCustomName(renderedSearchHistory[index].searchID, value);
  };

  const LWTableHeader = () => {
    return (
      <>
        <TableRow>
          {/* <TableCell style={{ fontWeight: "bold" }}></TableCell> */}
          <TableCell
            style={{ fontWeight: "bold" }}
            className={classes.addressCell}
            colSpan={1}
          >
            {tableDefinitions.search[0]}
          </TableCell>

          {tableDefinitions.search.map((item, index) => {
            if (index > 0 && index < tableDefinitions.search.length) {
              return (
                <TableCell
                  key={index}
                  style={{ fontWeight: "bold" }}
                  className={classes.cell}
                  colSpan={1}
                  align="right"
                >
                  <div className="flex-center-center">{item}</div>
                </TableCell>
              );
            } else return null;
          })}
          <TableCell
            style={{ fontWeight: "bold" }}
            className={classes.actionCell}
            colSpan={1}
          >
            {tableDefinitions.search[-1]}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const sourceIconMap = {
    landreg: { name: "Land Registry", logo: LogoLR },
    rm: { name: "Rightmove", logo: LogoRM },
    zoopla: { name: "Zoopla", logo: LogoZoopla },
    plan: { name: "Planning", logo: LogoPlan },
  };

  const SourceIcons = ({ item }) => {
    return (
      <div className="flex-center-center" style={{ paddingBottom: "2px" }}>
        {item?.sourceTypes &&
          JSON.parse(item.sourceTypes).map((source) => {
            return (
              <>
                <img
                  src={sourceIconMap[source].logo}
                  alt={sourceIconMap[source].name}
                  style={{ paddingRight: "3px" }}
                />
              </>
            );
          })}
      </div>
    );
  };

  const Row = ({ item, index }) => {
    // const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow
          hover
          key={item.searchID}
          onClick={() => loadURL(item.searchID)}
          className={classes.row}
        >
          {/* <TableCell
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
            className={classes.addressCell}
            component="th"
            scope="row"
          >
            {item.searchName}
            {/* <br />
            
          </TableCell> */}
          <EditableCell
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
            className={classes.addressCell}
            classes={classes}
            component="th"
            scope="row"
            colSpan={1}
            value={item.searchCustomName || item.searchName}
            onSave={(value) => handleEdit(index, value)}
          />
          <TableCell className={classes.cell} align="right">
            <SourceIcons item={item} />{" "}
          </TableCell>
          <TableCell className={classes.cell} align="right">
            {item.totalProperties ? item.totalProperties : 0}
          </TableCell>
          <TableCell className={classes.cell} align="right">
            {item.totalComps ? item.totalComps : 0}
          </TableCell>
          <TableCell className={classes.cell} align="right">
            {isNarrow
              ? formatDateDDMMYY(item.dateRun)
              : formatDateDDMMMYYY(item.dateRun)}
          </TableCell>
          <TableCell className={classes.actionCell} scope="row">
            <SearchFavouriteStar
              item={item}
              setFavouriteHandler={setFavouriteHandler}
            />
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer
      component={Paper}
      className="flex-center-center nice-scrollbar"
      style={{ height: "100%", width: "100%", alignItems: "flex-start" }}
    >
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <LWTableHeader />
        </TableHead>
        <TableBody className={classes.body}>
          {renderedSearchHistory?.map((item, index) => (
            <Row
              key={item.searchID}
              item={item}
              index={index}
              className={classes.row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SearchHistoryTable;
