/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "4aa7513094954d91bba6def224cd8d26",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:642df253-09c7-483d-928a-5413a7f83a48",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_sAHEHEiGJ",
    "aws_user_pools_web_client_id": "7dmt69grdb5nk99bn0tmcshdu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "Analytics": {
        "AWSPinpoint": {
            "appId": "2397519a90fe49bdb957ef6a9de6f187",
            "region": "eu-west-1"
        }
    }
};


export default awsmobile;
